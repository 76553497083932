import { ACTION_STATUSES, TRACK_EVENT_TYPES, TRACK_EVENT_NAMES } from 'shared/consts';
import { BUTTON_VARIANT, useBooleanState } from '@hqo/react-components-library';
import { CheckoutContainer, StyledBottomMobileModal } from './styles';
import React, { useCallback, useEffect, useState } from 'react';
import { deletePaymentMethod, getPaymentMethods } from 'store/payment/actions';
import {
  selectSubmitCartStatus,
  shouldSubmit3DSecure,
  selectCartDiscountSummary,
  selectCartId,
  select3DSecure,
} from 'store/cart/selectors';
import {
  selectCurrentPaymentMethodId,
  selectDeletePaymentMethodsStatus,
  selectGetPaymentMethodsStatus,
  selectSavePaymentMethodStatus,
} from 'store/payment/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { CheckoutDesktop } from './components/checkout-desktop';
import { CheckoutDesktopModalWrapper } from 'components/modals/checkoutModalWrapper';
import { ContentModal } from './components/content-modal';
import { useCart } from 'hooks/use-cart.hook';
import { useIntl } from 'react-intl';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { useLocation } from 'react-router-dom';
import { usePaymentMethods } from 'hooks/use-payment-methods.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { track } from '@hqo/web-tracking';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { formatCartItemsIds } from 'utils/formatTrackEventProps';
import { CheckoutMobileContent } from './components/checkout-mobile-content';
import { getTransaction } from 'store/transactions/actions';
import { selectTransactionStatus } from 'store/transactions/selectors';
import { selectCurrentLocationId } from 'store/app-instance-configs/selectors';
import { resetComplete3DSCart, resetGetCartStatus, resetSubmitCartStatus, submitCart } from 'store/cart/actions';
import qs from 'qs';
import { searchParams } from 'pages/resource/date-select-modal/interface';
import { ThreeDSModal } from 'components/modals/3dsModal';
import { CREDIT_PAYMENT_TYPE_ID, INVOICE_PAYMENT_TYPE_ID } from 'shared/consts/payment-method-types';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { Container } from 'hocs/shared-styles';
import { useAddCardButton } from 'hooks/use-add-card-button.hook';
import { resetAddOnsState } from 'store/add-ons/actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNativePaymentMethod } from 'components/payment/quick-checkout/components/use-native-payment-method.hook';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';

interface CheckoutProps {
  onCloseCheckout: VoidFunction;
  openReceipt: VoidFunction;
}

export const Checkout = ({ onCloseCheckout, openReceipt }: CheckoutProps): JSX.Element => {
  const intl = useIntl();
  const client = useMiniappSdk();
  const location = useLocation();
  const { enableHqoMiniappApplePay } = useFlags();
  const [submittedPaymentMethodId, setSubmittedPaymentMethodId] = useState<number>(undefined);
  const paymentId = useSelector(selectCurrentPaymentMethodId);
  const { expandAddCard, error, cartId: currentCartId, ...queryParams } = useSearchParams<searchParams>();
  const cartId = useSelector(selectCartId) || currentCartId;
  const locationId = useSelector(selectCurrentLocationId);
  const [paymentError, setPaymentError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const isMobileDevice = useIsSmallViewportWidth();
  const { isCartFulfilled, cart } = useCart(cartId);
  const { paymentMethods } = usePaymentMethods();
  const { setIsAddCardButtonDisabled, isAddCardButtonDisabled } = useAddCardButton();
  const submit3DSecure = useSelector(shouldSubmit3DSecure);
  const { total, currencyType } = formatSummaryValues(cart?.total_summary);

  const getPaymentMethodStatus = useSelector(selectGetPaymentMethodsStatus);
  const savePaymentMethodStatus = useSelector(selectSavePaymentMethodStatus);
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const deletePaymentMethodsStatus = useSelector(selectDeletePaymentMethodsStatus);

  const discountSummary = useSelector(selectCartDiscountSummary);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<string>(null);
  const { value: showConfirmCloseModal, toggle: toggleConfirmCloseModal } = useBooleanState(false);
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(false);
  const { value: showAddCardForm, toggle: toggleAddCardForm } = useBooleanState(!!expandAddCard);
  const [show3DS, setShow3DS] = useState<boolean>(false);
  const transactionStatus = useSelector(selectTransactionStatus);
  const { is3DSecureSupported } = useSelector(select3DSecure);
  const cartItemsIds = formatCartItemsIds(cart);
  const { isApplePayMethodSelected, setPendingNativePayment, pendingNativePayment, isNativePaymentError } =
    useNativePaymentMethod({
      cart,
      locationId,
    });

  const findCurrentPaymentMethod = useCallback(
    () =>
      enableHqoMiniappApplePay && navigator.userAgent.includes('iPhone')
        ? paymentMethods?.find(paymentMethod => paymentMethod.id.toString() === paymentId)
        : paymentMethods?.find(paymentMethod => paymentMethod.default),
    [getPaymentMethodStatus, savePaymentMethodStatus, paymentMethods, paymentId],
  );

  useEffect(() => {
    if (paymentId) {
      setSubmittedPaymentMethodId(Number(paymentId));
    }
  }, [paymentId]);

  useEffect(() => {
    setDefaultPaymentMethod(findCurrentPaymentMethod()?.id.toString());
  }, [paymentMethods, findCurrentPaymentMethod]);

  useEffect(() => {
    if (error === 'true') {
      setPaymentError(true);
    }
  }, [error]);

  const onStayClick = useCallback(() => {
    toggleReverseAnimation();
    setTimeout(() => {
      toggleConfirmCloseModal();
      toggleReverseAnimation();
    }, 300);
  }, [toggleConfirmCloseModal]);

  const onLeaveClick = useCallback(() => {
    toggleConfirmCloseModal();
    onCloseCheckout();
  }, [onCloseCheckout, toggleConfirmCloseModal]);

  const onBackClick = useCallback(() => {
    onCloseCheckout();
  }, [onCloseCheckout]);

  const onCloseClick = useCallback(() => {
    toggleConfirmCloseModal();
  }, [toggleConfirmCloseModal]);

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getTransaction.request(cart?.transaction_uuid));
    }
  }, [cart?.transaction_uuid, dispatch, submitCartStatus]);

  useEffect(() => {
    const hasSuccessfullyPurchasedWithCreditCard =
      submitCartStatus === ACTION_STATUSES.FULFILLED && transactionStatus === ACTION_STATUSES.FULFILLED;

    if (
      hasSuccessfullyPurchasedWithCreditCard &&
      (!submit3DSecure ||
        defaultPaymentMethod === CREDIT_PAYMENT_TYPE_ID ||
        defaultPaymentMethod === INVOICE_PAYMENT_TYPE_ID)
    ) {
      openReceipt();
      dispatch(resetAddOnsState());
      dispatch(resetSubmitCartStatus());
      dispatch(resetGetCartStatus());
    }
  }, [submitCartStatus, dispatch, openReceipt, transactionStatus, defaultPaymentMethod, submit3DSecure]);

  useEffect(() => {
    if (showAddCardForm && savePaymentMethodStatus === ACTION_STATUSES.FULFILLED) {
      toggleAddCardForm();
    }
  }, [savePaymentMethodStatus]);

  const onRemovePaymentMethod = useCallback(() => {
    dispatch(
      deletePaymentMethod.request({
        paymentMethodId: findCurrentPaymentMethod()?.id,
        paymentMethods,
      }),
    );
  }, [dispatch, findCurrentPaymentMethod, paymentMethods]);

  useEffect(() => {
    if (deletePaymentMethodsStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getPaymentMethods.request(cartId));
    }
  }, [dispatch, cartId, deletePaymentMethodsStatus]);

  const onAddPaymentMethodClick = useCallback(() => {
    toggleAddCardForm();
    setIsAddCardButtonDisabled(true);
  }, [toggleAddCardForm, setIsAddCardButtonDisabled]);

  const onCancelAddCardClick = useCallback(() => {
    setIsAddCardButtonDisabled(false);
    toggleAddCardForm();
  }, [toggleAddCardForm]);

  const buttons = [
    {
      title: intl.formatMessage({ id: 'stay' }),
      handler: onStayClick,
    },
    {
      title: intl.formatMessage({ id: 'leave' }),
      handler: onLeaveClick,
      variant: BUTTON_VARIANT.SECONDARY,
    },
  ];

  useEffect(() => {
    if (isCartFulfilled) {
      track(
        TRACK_EVENT_NAMES.FULL_CHECKOUT_IMPRESSION,
        {
          type: TRACK_EVENT_TYPES.IMPRESSION,
          cart_total: total,
          currency_code: currencyType,
          promo_code_applied: !!discountSummary,
          cart_type: cart.type,
          items: cartItemsIds,
          adapter: cart.config?.config?.adapter,
        },
        { sendToHqoTracking: true },
      );
    }
  }, [isCartFulfilled]);

  const onPayClick = useCallback(() => {
    track(
      TRACK_EVENT_NAMES.CHECKOUT_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        payment_method: findCurrentPaymentMethod().payment_method_type,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
      },
      { sendToHqoTracking: true },
    );
    setPaymentError(false);
    setSubmittedPaymentMethodId(findCurrentPaymentMethod().id);
    dispatch(resetComplete3DSCart());
    if (isApplePayMethodSelected) {
      setPendingNativePayment(true);
      client?.payments.showNativePayment(
        null,
        {
          currency: cart.total_summary.currency_type,
          total: cart.total_summary.total / 100,
          tax: cart.total_summary.tax_summary.taxes_total / 100,
          authorization_total: cart.total_summary.total / 100,
        },
        cart.config.payment_gateway_id,
      );
    } else {
      const paymentInfo = {
        cart_id: cartId,
        payment_method_id: defaultPaymentMethod,
        locationId,
      };

      const queryString = qs.stringify({ ...queryParams, cartId, paymentId: paymentInfo.payment_method_id });

      dispatch(
        submitCart.request(
          submit3DSecure &&
            defaultPaymentMethod !== CREDIT_PAYMENT_TYPE_ID &&
            defaultPaymentMethod !== INVOICE_PAYMENT_TYPE_ID
            ? {
                ...paymentInfo,
                browser_info: window.Spreedly?.ThreeDS?.serialize('01'),
                attempt_3dsecure: true,
                three_ds_version: '2',
                callback_url: `${window.location.origin}/redirect?${queryString}`,
                redirect_url: `${window.location.origin}/redirect?${queryString}`,
              }
            : paymentInfo,
        ),
      );
    }
  }, [
    findCurrentPaymentMethod,
    defaultPaymentMethod,
    total,
    currencyType,
    discountSummary,
    cart,
    dispatch,
    cartId,
    cartItemsIds,
    locationId,
    location.search,
    submit3DSecure,
    isApplePayMethodSelected,
  ]);

  return (
    <CheckoutContainer data-testid="checkout-modal">
      {!show3DS ? (
        <>
          {isMobileDevice
            ? isCartFulfilled && (
                <CheckoutMobileContent
                  cart={cart}
                  onRemovePaymentMethod={onRemovePaymentMethod}
                  defaultPaymentMethod={defaultPaymentMethod}
                  submittedPaymentMethodId={submittedPaymentMethodId}
                  paymentError={paymentError}
                  paymentMethods={paymentMethods}
                  onAddPaymentMethodClick={onAddPaymentMethodClick}
                  onPayClick={onPayClick}
                  submitCartStatus={submitCartStatus}
                  isCartFulfilled={isCartFulfilled}
                  showAddCardForm={showAddCardForm}
                  cartId={cartId}
                  onCancelAddCardClick={onCancelAddCardClick}
                  isAddCardButtonDisabled={isAddCardButtonDisabled}
                  setIsAddCardButtonDisabled={setIsAddCardButtonDisabled}
                  pendingNativePayment={pendingNativePayment}
                  isNativePaymentError={isNativePaymentError}
                />
              )
            : isCartFulfilled && (
                <CheckoutDesktopModalWrapper
                  visible
                  withBackButton
                  onPressBack={onBackClick}
                  content={
                    <CheckoutDesktop
                      cart={cart}
                      onRemovePaymentMethod={onRemovePaymentMethod}
                      currentPaymentMethodId={defaultPaymentMethod}
                      submittedPaymentMethodId={submittedPaymentMethodId}
                      currentPaymentError={paymentError}
                      paymentMethods={paymentMethods}
                      onAddPaymentMethodClick={onAddPaymentMethodClick}
                      onPayClick={onPayClick}
                      submitCartStatus={submitCartStatus}
                      showAddCardForm={showAddCardForm}
                      cartId={cartId}
                      onCancelAddCardClick={onCancelAddCardClick}
                      isAddCardButtonDisabled={isAddCardButtonDisabled}
                      setIsAddCardButtonDisabled={setIsAddCardButtonDisabled}
                      isNativePaymentError={isNativePaymentError}
                    />
                  }
                  onClose={onCloseClick}
                />
              )}
        </>
      ) : (
        <Container data-testid="loading-container" isLoading isModal>
          <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
        </Container>
      )}
      {is3DSecureSupported && (
        <ThreeDSModal
          setPaymentError={setPaymentError}
          setIs3DSVisible={setShow3DS}
          transactionToken={cart?.transaction?.token}
          spreedlyToken={cart?.ui_enablements.spreedly_env_token}
        />
      )}
      <StyledBottomMobileModal
        reverseAnimation={reverseAnimation}
        isVisible={showConfirmCloseModal}
        buttons={buttons}
        content={ContentModal()}
        showCloseIcon={false}
      />
    </CheckoutContainer>
  );
};
