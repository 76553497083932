import { hqoMetadata } from '@hqo/web-tracking/dist/utils';
import { track } from '@hqo/web-tracking';
import { useEffect } from 'react';

interface Props {
  isLoading: boolean;
  trackEventName: string;
  metadata: hqoMetadata;
  trackConfig?: TrackConfig;
}

interface TrackConfig {
  sendToHqoTracking?: boolean;
}

export const useHqoTracking = ({ isLoading, trackEventName, metadata, trackConfig }: Props) => {
  useEffect(() => {
    if (!isLoading) {
      track(trackEventName, metadata, trackConfig);
    }
  }, [isLoading]);
};
