import { formatCurrency } from './formatCurrency';
import { IntlShape } from 'react-intl';

interface AddOnTileLabels {
  subtitle?: string;
  title: string;
  price: string;
  requiredLabel?: string;
}

export const getAddOnLabels = (
  title: string,
  subtitle: string,
  price: number,
  intl: IntlShape,
  currencyType: string,
  buildingLocale: string,
): AddOnTileLabels => ({
  title,
  subtitle,
  price: formatCurrency(price, currencyType, buildingLocale, true, false),
  requiredLabel: intl.formatMessage({ id: 'required' }),
});
