import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library';
import { skeletonAnimation } from 'components/skeleton/styles';

interface ScheduleActiveBlockProps {
  top: number;
  isTransition: boolean;
}

interface CircleContainerProps {
  isTransition: boolean;
  height: number;
}

export const ScheduleActiveBlock = styled.div<ScheduleActiveBlockProps>`
  transition: ${({ isTransition }) => (isTransition ? 'top ease-in' : 'none')};
  top: ${({ top }) => (top ? `${top}px` : 0)};
  width: 100%;
  height: 66px;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: row;
`;

export const ScheduleActiveTime = styled.div`
  flex-basis: 10%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-basis: 20%;
  }
`;

export const CircleContainer = styled.div<CircleContainerProps>`
  transition: ${({ isTransition }) => (isTransition ? 'height ease-in' : 'none')};
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.accent[4]};
  opacity: 0.8;
  flex-basis: 90%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-basis: 85%;
  }
`;

export const TopCircle = styled.div`
  width: 42px;
  height: 8px;
  background: ${({ theme }) => theme.colors.universal.white};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  box-sizing: border-box;
  border-radius: 100px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
`;

export const TopCircleContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -31px auto 0 auto;
  cursor: ns-resize;
`;

export const BottomCircleContainer = styled(TopCircleContainer)`
  margin: 0 auto -31px auto;
`;

export const BottomCircle = styled.div`
  width: 42px;
  height: 8px;
  background: ${({ theme }) => theme.colors.universal.white};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  box-sizing: border-box;
  border-radius: 100px;
  cursor: ns-resize;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
`;

export const GhostScheduleBlock = styled.div`
  width: 100%;
  height: 300px;
  background: ${({ theme }) => theme.colors.greys.adminGreyLight};
  flex-basis: 90%;
  margin-left: auto;
  ${skeletonAnimation};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-basis: 85%;
  }
`;

export const GhostScheduleTime = styled.div`
  width: 35px;
  height: 15px;
  background: ${({ theme }) => theme.colors.greys.adminGreyLight};
  ${skeletonAnimation};
`;

export const GhostTimesBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const GhostContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ScheduleBlockContainer = styled.div`
  display: flex;
  position: relative;
  height: 66px;
`;

const dashedLinearGradient =
  'linear-gradient(135deg, rgba(0, 0, 0, 0) 49.9%, #d8d8d8 49.9%, #d8d8d8 60%, rgba(0, 0, 0, 0) 60%), linear-gradient(135deg, #d8d8d8 10%, rgba(0, 0, 0, 0) 10%)';

export const ScheduleTime = styled.div`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
  margin: -10px 0 0 0;
  padding: 0;
  flex-basis: 10%;
  user-select: none;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-basis: 20%;
  }
`;

export const ScheduleBlock = styled.div<{ isAvailable: boolean }>`
  width: 100%;
  height: 100%;
  background: ${({ isAvailable }) => (isAvailable ? 'none' : dashedLinearGradient)};
  background-size: ${({ isAvailable }) => (isAvailable ? 0 : '15px 15px')};
  background-color: ${({ isAvailable, theme }) =>
    isAvailable ? theme.colors.universal.white : theme.colors.greys.lightGrey4};
  flex-basis: 90%;
  margin-left: auto;
  border: 1px solid ${({ theme }) => theme.colors.greys.sharedMediumGrey};
  box-sizing: border-box;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-basis: 85%;
  }
`;
