import { Button, DIMENSIONS } from '@hqo/react-components-library';
import { RESOURCE_SECTIONS_Z_INDEXES } from 'pages/resource/constants';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';
import styled from 'styled-components';

interface ButtonProps {
  startDate?: boolean;
  isPaidResource?: boolean;
  isAdditionalInformationPage?: boolean;
}
interface FooterProps {
  isMobileDevice: boolean;
}

export const CtaButton = styled(Button)<ButtonProps>`
  width: 196px;
  height: 48px;
  padding: 16px;
  -webkit-tap-highlight-color: transparent;
  align-self: flex-end;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100%;
    font-size: 16px;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div<ButtonProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: ${({ startDate, isPaidResource }) => (startDate || isPaidResource ? 'space-between' : 'flex-end')};
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 16px;
  max-width: ${({ isAdditionalInformationPage }) => (isAdditionalInformationPage ? '940px' : '700px')};

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    align-items: center;
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: ${({ theme }) => `0px 2px 4px ${theme.colors.greys.adminBlack}33`};
`;

export const ErrorText = styled.span`
  font: italic 12px/20px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.admin.pink};
  align-self: flex-start;
  margin-bottom: 10px;
`;

export const AdditionalInfoRequiredText = styled.div`
  color: ${({ theme }) => theme.colors.fontColor};
  font-size: 14px;
  margin-bottom: 10px;
`;

export const FooterContainer = styled.div<FooterProps>`
  width: 100%;
  position: fixed;
  margin: 0 auto;
  right: 0;
  bottom: 0;
  z-index: ${({ isMobileDevice }) => (isMobileDevice ? RESOURCE_SECTIONS_Z_INDEXES.HEADER_AND_FOOTER : `unset`)};
`;

export const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-direction: column;
  }

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    align-items: center;
  }
`;
