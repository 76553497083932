import { useSearchParams } from 'hooks/use-search-params.hook';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBookingRequest } from 'store/bookings/actions';
import { resourceState, selectBookingRequest } from 'store/bookings/selectors';
import { selectPrice } from 'store/price/selectors';
import { selectGetResourceStatus } from 'store/resource/selectors';
import { ACTION_STATUSES } from 'shared/consts';

type searchParams = { [key: string]: string };

interface UseAddtitionalInformationProps {
  setUserResponse: React.Dispatch<React.SetStateAction<FormResponse[]>>;
  userResponse: Array<FormResponse>;
}

export const useAddtitionalInformation = (): UseAddtitionalInformationProps => {
  const { resource } = useSelector(resourceState);
  const dispatch = useDispatch();
  const { startTime, endTime } = useSearchParams<searchParams>();
  const price = useSelector(selectPrice);
  const getBookingRequest = useSelector(selectBookingRequest);
  const getResourceStatus = useSelector(selectGetResourceStatus);
  const userPrompts = useMemo<Array<FormResponse>>(
    () =>
      resource.user_prompts?.map(question => ({
        custom: true,
        type: question.response_type,
        question: question.prompt,
        required: question.is_required,
      })),
    [resource, getResourceStatus],
  );

  const [userResponse, setUserResponse] = useState<Array<FormResponse>>([]);

  useEffect(() => {
    if (getResourceStatus === ACTION_STATUSES.FULFILLED && !userResponse?.length) {
      setUserResponse(userPrompts);
    }
  }, [getResourceStatus, userPrompts, userResponse]);

  useEffect(() => {
    dispatch(
      setBookingRequest({
        name: resource.name,
        startAt: startTime,
        endAt: endTime,
        form_responses: userResponse,
        price: price ? price.price / 100 : null,
        cancellation_description: getBookingRequest.cancellation_description,
      }),
    );
  }, [startTime, endTime, userResponse, price]);

  return { setUserResponse, userResponse };
};
