import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ResourceBookingEnum } from 'store/app-instance-configs/enums';
import { selectAppInstanceConfigs, selectCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/selectors';

export const useIsUniqueImageUrl = (): boolean => {
  const appInstancesConfigs = useSelector(selectAppInstanceConfigs);
  const currentConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);

  return useMemo(() => {
    const adapterName = appInstancesConfigs?.find(config => config?.uuid === currentConfigUuid)?.vertical_adapter?.name;
    return adapterName === ResourceBookingEnum.NexudusAdapter || adapterName === ResourceBookingEnum.PrismAdapter;
  }, [appInstancesConfigs, currentConfigUuid]);
};
