import { PaymentMethodsSelector } from '@hqo/react-components-library/dist/payment/payment-methods-selector';
import { usePaymentMethodsSelector } from 'components/payment/quick-checkout/components/payment-methods-selector/hooks/usePaymentMethodsSelector.hook';
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { APPLE_PAY } from 'shared/consts/payment-method-types';

import { setCurrentPaymentMethodId } from 'store/payment/actions';
import { selectCurrentPaymentMethodId, selectPaymentMethods } from 'store/payment/selectors';

import { Title, ChangeButton, Header, PaymentMethodSelectorContainer } from './styles';

interface PaymentMethodManagerProps {
  onEditClick: VoidFunction;
}

export const PaymentMethodManager = ({ onEditClick }: PaymentMethodManagerProps) => {
  const intl = useIntl();

  const paymentMethods = useSelector(selectPaymentMethods);
  const dispatch = useDispatch();
  const options = usePaymentMethodsSelector(paymentMethods);
  const currentPaymentMethodId = useSelector(selectCurrentPaymentMethodId);

  const findCurrentPaymentMethod = useCallback(() => {
    const applePayMethod = paymentMethods?.find(paymentMethod => paymentMethod.payment_method_type === APPLE_PAY);
    if (applePayMethod) {
      return applePayMethod;
    }

    if (!!currentPaymentMethodId) {
      return paymentMethods?.find(({ id }) => `${id}` === currentPaymentMethodId);
    }

    return paymentMethods?.length ? paymentMethods?.find(method => method.default) || paymentMethods[0] : null;
  }, [paymentMethods]);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(findCurrentPaymentMethod()?.id.toString());

  const onPress = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const selectedButton = options.find(option => option.value === value);
      if (selectedButton) {
        setSelectedPaymentMethod(selectedButton.value);
        dispatch(setCurrentPaymentMethodId(selectedButton.value));
      }
    },
    [setSelectedPaymentMethod],
  );

  return (
    !!paymentMethods.length && (
      <PaymentMethodSelectorContainer>
        <Header>
          <Title>{intl.formatMessage({ id: 'payment_method' })}</Title>
          <ChangeButton onClick={onEditClick}>{intl.formatMessage({ id: 'change' })}</ChangeButton>
        </Header>
        <PaymentMethodsSelector options={options} onPress={onPress} selected={selectedPaymentMethod} />
      </PaymentMethodSelectorContainer>
    )
  );
};
