import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useSearchParams } from 'hooks/use-search-params.hook';
import { useUpdateUrlPath } from 'hooks/use-update-url-path.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { DurationsAndInterval, ItemRef } from 'pages/resource/components/timeline/types';
import {
  BLOCK_WIDTH,
  DEFAULT_INTERVAL,
  DEFAULT_SELECTED_BLOCK_STYLES,
  DRAGGING_SELECTED_BLOCK_STYLES,
  LAST_INVISIBLE_BLOCK_WIDTH,
  SELECTED_BLOCK_HANDLERS,
} from 'shared/consts/timeline';
import { track } from '@hqo/web-tracking';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { customRound } from 'utils/customRound';
import {
  useResizeSelectedBlockWithAutoScroll,
  useUpdateInitialScrollOnThreshold,
} from 'pages/resource/components/selected-timeline-block/hooks/use-timeline-auto-scroll.hook';

interface UseSelectedBlockResizeDragReturnValues {
  handleTouch: (event: React.TouchEvent) => void;
  handleMouseDown: (event: React.MouseEvent) => void;
  selectedBlockRef: React.MutableRefObject<HTMLDivElement>;
  handleDragStart: (event: React.MouseEvent | React.TouchEvent) => void;
  handleDragMove: (event: React.MouseEvent | React.TouchEvent) => void;
  handleDragEnd: (event: React.MouseEvent | React.TouchEvent) => void;
}

interface UseSelectedBlockResizeDragProps {
  itemRefs: React.MutableRefObject<Array<ItemRef>>;
  timelineWrapperRef: React.MutableRefObject<HTMLDivElement>;
  durationsAndInterval: DurationsAndInterval;
}

export const useSelectedBlockResizeDrag = ({
  itemRefs,
  timelineWrapperRef,
  durationsAndInterval,
}: UseSelectedBlockResizeDragProps): UseSelectedBlockResizeDragReturnValues => {
  const querySearchParams = useSearchParams<searchParams>();
  const updateUrlPath = useUpdateUrlPath();
  const selectedBlockRef = useRef<HTMLDivElement>(null);
  const { maxDuration = 0, minDuration = 0, timeInterval = 0 } = durationsAndInterval || {};
  const isIPhoneDevice = navigator.userAgent.includes('iPhone');

  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [isLeftClick, setIsLeftClick] = useState<boolean>(false);
  const [clickedPosition, setClickedPosition] = useState<number>(null);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [pressTimer, setPressTimer] = useState(null);
  const [longPress, setLongPress] = useState<boolean>(false);

  const maxDurationWidth = (maxDuration / DEFAULT_INTERVAL) * BLOCK_WIDTH;
  const minDurationWidth = (minDuration / DEFAULT_INTERVAL) * BLOCK_WIDTH;
  const unavailableItemRefs = itemRefs?.current.filter(item => !item.available);

  const initialWidth = selectedBlockRef?.current?.offsetWidth ?? 0;
  const initialLeft = selectedBlockRef?.current?.offsetLeft ?? 0;
  const initialRight = initialLeft + initialWidth;

  const selectedBlockRefParentElement = selectedBlockRef.current?.parentElement;
  const startTimelineItemRef = itemRefs?.current[0];
  const timelineFirstPosition = startTimelineItemRef?.ref?.offsetLeft;
  const timelineLastPosition = selectedBlockRefParentElement?.offsetWidth - LAST_INVISIBLE_BLOCK_WIDTH;
  let closestUnavailableLeftPosition = 0;

  const closestUnavailableRightPosition = unavailableItemRefs?.find(
    unavailableItem => unavailableItem?.ref?.offsetLeft >= initialRight - 1,
  )?.ref?.offsetLeft;

  const closestUnavailableLeftItem = unavailableItemRefs
    .reverse()
    .find(unavailableItem => unavailableItem?.ref?.offsetLeft <= initialLeft);

  if (closestUnavailableLeftItem) {
    closestUnavailableLeftPosition = closestUnavailableLeftItem?.ref?.offsetLeft + BLOCK_WIDTH;
  }

  const shouldStopResizing = useCallback(
    (updatedLeft, updatedRight, updatedWidth) => {
      const isLeftResizing = isLeftClick;
      const isRightResizing = !isLeftClick;
      const isLessThanTimelineStart = isLeftResizing && updatedLeft < (timelineFirstPosition || 0);
      const isMoreThanTimelineEnd = isRightResizing && updatedRight > (timelineLastPosition || 0);
      const isLessThanMinDuration = updatedWidth < minDurationWidth;
      const isMoreThanMaxDuration = maxDurationWidth && updatedWidth > maxDurationWidth;

      const isTouchingUnavailableSlot =
        (isLeftResizing && closestUnavailableLeftPosition && updatedLeft < closestUnavailableLeftPosition) ||
        (isRightResizing && closestUnavailableRightPosition && updatedRight > closestUnavailableRightPosition);

      return (
        isLessThanTimelineStart ||
        isMoreThanTimelineEnd ||
        isTouchingUnavailableSlot ||
        isMoreThanMaxDuration ||
        isLessThanMinDuration
      );
    },
    [
      isLeftClick,
      timelineFirstPosition,
      timelineLastPosition,
      closestUnavailableLeftPosition,
      closestUnavailableRightPosition,
      minDurationWidth,
      maxDurationWidth,
    ],
  );

  const updateInitialScrollLOnThreshold = useUpdateInitialScrollOnThreshold({
    selectedBlockRef,
    timelineWrapperRef,
  });

  const {
    resizeWithAutoScroll,
    resizeOppositeDirectionAfterAutoScrolling,
    scrollLeftThreshold,
    scrollRightThreshold,
    resizeAutoScrollRef,
    isAutoScrolling,
    stopAutoScrolling,
    setIsAutoScrolling,
  } = useResizeSelectedBlockWithAutoScroll({
    selectedBlockRef,
    timelineWrapperRef,
    isLeftClick,
    timelineFirstPosition,
    timelineLastPosition,
    initialLeft,
    initialWidth,
    shouldStopResizing,
    maxDurationWidth,
  });

  useEffect(() => {
    if (!isResizing) {
      stopAutoScrolling();
    }
  }, [isResizing, stopAutoScrolling]);

  const calculateStartAndEndTimesFromRefs = useCallback(() => {
    const updatedLeft = selectedBlockRef?.current?.offsetLeft ?? 0;
    const updatedWidth = selectedBlockRef?.current?.offsetWidth ?? 0;
    const updatedRight = updatedLeft + updatedWidth;

    const startTimeRef = itemRefs?.current?.find(item => item.ref?.offsetLeft === updatedLeft);
    const endTimeRef = itemRefs?.current?.find(item => item.ref?.offsetLeft === updatedRight);
    const startTime = startTimeRef?.key ?? querySearchParams?.startTime;
    const endTime = endTimeRef?.key ?? querySearchParams?.endTime;

    return { startTime, endTime };
  }, [selectedBlockRef, itemRefs, querySearchParams]);

  const updateDimensionToClosestPosition = useCallback(() => {
    const currentLeft = selectedBlockRef?.current?.offsetLeft;
    const currentWidth = selectedBlockRef?.current?.offsetWidth;
    const updatedBlockWidth = isResizing ? (timeInterval / DEFAULT_INTERVAL) * BLOCK_WIDTH : BLOCK_WIDTH;
    const shiftLeft = Math.round(currentLeft / updatedBlockWidth) * updatedBlockWidth;
    const shiftWidth = customRound(currentWidth / updatedBlockWidth) * updatedBlockWidth;

    selectedBlockRef.current.style.width = `${shiftWidth}px`;
    if (isLeftClick || isDragging) {
      selectedBlockRef.current.style.left = `${shiftLeft}px`;
    }

    setIsLeftClick(false);
    setIsResizing(false);

    const { startTime, endTime } = calculateStartAndEndTimesFromRefs();
    updateUrlPath(startTime, endTime);
  }, [isResizing, timeInterval, isLeftClick, isDragging, calculateStartAndEndTimesFromRefs, updateUrlPath]);

  const resizeSelectedBlockForNonAutoScrolling = useCallback(
    (updatedLeft: number, updatedWidth: number) => {
      if (!maxDurationWidth || updatedWidth <= maxDurationWidth) {
        selectedBlockRef.current.style.width = `${updatedWidth}px`;

        if (isLeftClick) {
          selectedBlockRef.current.style.left = `${updatedLeft}px`;
        }
      }
    },
    [isLeftClick, maxDurationWidth],
  );

  const resizeSelectedBlock = useCallback(
    currentPosition => {
      const updatedLeft = initialLeft + Number(isLeftClick ? currentPosition - clickedPosition : 0);
      const updatedWidth =
        initialWidth + Number(isLeftClick ? clickedPosition - currentPosition : currentPosition - clickedPosition);
      const updatedRight = updatedLeft + updatedWidth;

      if (!isAutoScrolling) {
        if (shouldStopResizing(updatedLeft, updatedRight, updatedWidth)) {
          return;
        }
        resizeSelectedBlockForNonAutoScrolling(updatedLeft, updatedWidth);
      }
      const { offsetLeft: selectedBlockOffsetLeft, offsetWidth: selectedBlockOffsetWidth } = selectedBlockRef.current;
      const { scrollLeft: timelineWrapperScrollLeft } = timelineWrapperRef.current;
      const selectedBlockRightOffset = selectedBlockOffsetLeft + selectedBlockOffsetWidth;

      const isSelectedBlockAtTimelineEnd = selectedBlockRightOffset >= timelineLastPosition;
      const isSelectedBlockAtTimelineStart = selectedBlockOffsetLeft <= timelineFirstPosition;

      const hitRightThreshold = currentPosition >= scrollRightThreshold;
      const hitLeftThreshold = currentPosition <= scrollLeftThreshold;

      const shouldStartAutoScrolling = isLeftClick
        ? hitLeftThreshold && !isSelectedBlockAtTimelineStart
        : hitRightThreshold && !isSelectedBlockAtTimelineEnd;

      const shouldSwitchDirectionAfterAutoScrolling = isLeftClick ? !hitLeftThreshold : !hitRightThreshold;

      if (shouldStartAutoScrolling) {
        if (shouldStopResizing(selectedBlockOffsetLeft, selectedBlockRightOffset, selectedBlockOffsetWidth)) {
          return;
        }
        setIsAutoScrolling(true);

        /* To ensure smooth scrolling during updates to scrollLeft in the resizeWithAutoScroll:
         - iPhones perform better with 'overflow: hidden'
         - It appears that non-iPhones mobile devices require 'overflow: auto'
         */
        if (!isIPhoneDevice && timelineWrapperRef.current.style.overflow !== 'auto') {
          timelineWrapperRef.current.style.overflow = 'auto';
        }

        if (!resizeAutoScrollRef.current) {
          resizeWithAutoScroll(currentPosition);
          return () => {
            cancelAnimationFrame(resizeAutoScrollRef.current);
          };
        }
      } else if (isAutoScrolling && shouldSwitchDirectionAfterAutoScrolling) {
        resizeOppositeDirectionAfterAutoScrolling(currentPosition, timelineWrapperScrollLeft);
      }
    },
    [
      initialLeft,
      isLeftClick,
      clickedPosition,
      initialWidth,
      isAutoScrolling,
      timelineWrapperRef,
      timelineLastPosition,
      timelineFirstPosition,
      scrollRightThreshold,
      scrollLeftThreshold,
      shouldStopResizing,
      resizeSelectedBlockForNonAutoScrolling,
      setIsAutoScrolling,
      isIPhoneDevice,
      resizeAutoScrollRef,
      resizeWithAutoScroll,
      resizeOppositeDirectionAfterAutoScrolling,
    ],
  );

  const updateDraggableSelectedBlockDimension = useCallback(
    currentPosition => {
      const updatedLeft = initialLeft + Number(currentPosition - clickedPosition);
      const updatedWidth = initialWidth + Number(currentPosition - clickedPosition);
      const updatedRight = initialLeft + updatedWidth;

      const isLessThanTimelineStart = updatedLeft < timelineFirstPosition;
      const isMoreThanTimelineEnd = updatedRight > timelineLastPosition;

      if (isMoreThanTimelineEnd || isLessThanTimelineStart) {
        return;
      }
      selectedBlockRef.current.style.left = `${updatedLeft}px`;
    },
    [clickedPosition, timelineLastPosition, initialLeft, initialWidth, timelineFirstPosition],
  );

  const setSelectedBlockStyles = useCallback(
    (isDefaultStyles = false): void => {
      const { top, height, boxShadow } = isDefaultStyles
        ? DEFAULT_SELECTED_BLOCK_STYLES
        : DRAGGING_SELECTED_BLOCK_STYLES;

      selectedBlockRef.current.style.top = `${top}px`;
      selectedBlockRef.current.style.height = `${height}px`;
      selectedBlockRef.current.style.boxShadow = boxShadow;
    },
    [selectedBlockRef],
  );

  const startPressTimer = useCallback(() => {
    setPressTimer(
      setTimeout(() => {
        setLongPress(true);
        setSelectedBlockStyles();
      }, 500),
    );
  }, [setSelectedBlockStyles]);

  const stopPressTimer = useCallback(() => {
    clearTimeout(pressTimer);
  }, [pressTimer]);

  const handleDragStart = useCallback(
    event => {
      event.preventDefault();
      const isTouch = event.type?.includes('touch');
      const dragEvent = isTouch ? event.changedTouches[0] : event;

      setIsDragging(!isTouch);
      setClickedPosition(dragEvent.clientX);

      if (isTouch) {
        setLongPress(false);
        startPressTimer();
      } else {
        setSelectedBlockStyles();
      }
    },
    [setSelectedBlockStyles, startPressTimer],
  );

  const handleDragMove = useCallback(
    event => {
      event.preventDefault();

      const isTouch = event.type?.includes('touch');
      const dragEvent = isTouch ? event.changedTouches[0] : event;

      if (!isDragging && longPress && isTouch) {
        // When initiating dragging on a mobile device, set the overflow style to 'hidden'
        // to prevent unintended scrolling while moving your finger on the selected block.
        timelineWrapperRef.current.style.overflow = 'hidden';
        setIsDragging(true);
      }

      if (isDragging) {
        updateDraggableSelectedBlockDimension(dragEvent.clientX);

        if (isTouch) {
          setSelectedBlockStyles();
        }
      }
    },
    [isDragging, longPress, timelineWrapperRef, updateDraggableSelectedBlockDimension, setSelectedBlockStyles],
  );

  const sentTrackEvent = (): void => {
    track(
      TRACK_EVENT_NAMES.RESOURCE_DETAIL_PAGE_TIMELINE_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
      },
      { sendToHqoTracking: true },
    );
  };

  const handleDragEnd = useCallback(
    event => {
      const isTouch = event.type?.includes('touch');
      event.preventDefault();

      if (isDragging) {
        setSelectedBlockStyles(true);
        timelineWrapperRef.current.style.overflow = 'auto';
        updateDimensionToClosestPosition();
        setIsDragging(false);
        stopAutoScrolling();
        sentTrackEvent();

        if (isTouch) {
          setLongPress(false);
          stopPressTimer();
        }
      }
    },
    [
      isDragging,
      setSelectedBlockStyles,
      timelineWrapperRef,
      updateDimensionToClosestPosition,
      stopAutoScrolling,
      stopPressTimer,
    ],
  );

  const handleBarDownStartEvent = useCallback(
    event => {
      event.preventDefault();

      const dataValue = event.target.getAttribute('data-value');
      const isMobileTouch = Boolean(event.changedTouches);
      const eventItem = isMobileTouch ? event.changedTouches[0] : event;

      updateInitialScrollLOnThreshold(dataValue === SELECTED_BLOCK_HANDLERS.LEFT);
      setIsResizing(true);
      setIsLeftClick(dataValue === SELECTED_BLOCK_HANDLERS.LEFT);
      setClickedPosition(eventItem.clientX);

      if (isMobileTouch) {
        // When initiating resizing on a mobile device, set the overflow style to 'hidden'
        // to prevent unintended scrolling when moving your finger on the resize handle.
        timelineWrapperRef.current.style.overflow = 'hidden';
      }
    },
    [timelineWrapperRef, updateInitialScrollLOnThreshold],
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      handleBarDownStartEvent(event);
    },
    [handleBarDownStartEvent],
  );

  const handleTouch = useCallback(
    (event: React.TouchEvent) => {
      handleBarDownStartEvent(event);
    },
    [handleBarDownStartEvent],
  );

  const handleTouchMove = useCallback(
    (event: TouchEvent) => {
      event.preventDefault();
      if (isResizing) {
        const touch = event.changedTouches[0];
        resizeSelectedBlock(touch.clientX);
      }
    },
    [isResizing, resizeSelectedBlock],
  );

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (isResizing) {
        resizeSelectedBlock(event.clientX);
      } else if (isDragging) {
        handleDragMove(event);
      }
    },
    [isResizing, resizeSelectedBlock, isDragging, handleDragMove],
  );

  const handleTouchEnd = useCallback(
    event => {
      stopPressTimer();
      setSelectedBlockStyles(true);
      if (isResizing) {
        stopAutoScrolling();
        timelineWrapperRef.current.style.overflow = 'auto';
        updateDimensionToClosestPosition();
        sentTrackEvent();
      } else if (isDragging) {
        handleDragEnd(event);
      }
    },
    [
      stopPressTimer,
      setSelectedBlockStyles,
      stopAutoScrolling,
      isResizing,
      isDragging,
      timelineWrapperRef,
      updateDimensionToClosestPosition,
      handleDragEnd,
    ],
  );

  const handleMouseUp = useCallback(
    event => {
      if (isResizing) {
        stopAutoScrolling();
        updateDimensionToClosestPosition();
        timelineWrapperRef.current.style.overflow = 'auto';

        sentTrackEvent();
      } else if (isDragging) {
        handleDragEnd(event);
      }
    },
    [isResizing, isDragging, stopAutoScrolling, updateDimensionToClosestPosition, timelineWrapperRef, handleDragEnd],
  );

  // When the mouse leaves the app, we should call handleMouseUp. Caveat, this only works when testing the miniapp via TenantWeb.
  const handleMouseLeave = useCallback(
    event => {
      handleMouseUp(event);
    },
    [handleMouseUp],
  );

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mouseleave', handleMouseLeave);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mouseleave', handleMouseLeave);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleMouseLeave, handleMouseMove, handleMouseUp, handleTouchEnd, handleTouchMove]);

  return {
    handleMouseDown,
    handleTouch,
    selectedBlockRef,
    handleDragStart,
    handleDragEnd,
    handleDragMove,
  };
};
