import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import { TimeRange } from 'store/resource-time-ranges/types';
import { MinDurationAndHours, TimeRangeByDuration } from 'components/schedule-select/interface';
import { getResourceTimeIndices } from 'utils/getResourceTimeIndices';
import { getResourceTimeRanges } from 'store/resource-time-ranges/actions';
import { getTimeRangeByDuration } from 'utils/getTimeRangeByDuration';
import moment from 'moment';
import { push } from 'store/router/actions';
import { resourceState } from 'store/bookings/selectors';
import { searchParams } from 'pages/date-select-page/interface';
import { selectResourceTimeRanges } from 'store/resource-time-ranges/selectors';
import { useLocation } from 'react-router-dom';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useDateScheduleModalNavigation } from 'hooks/use-date-schedule-modal-navigation.hook';
import qs from 'qs';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { useLocale } from 'hooks/use-locale.hook';
import { useAddOnsEnabled } from 'hooks/use-add-ons-enabled.hook';
import { resolveGetResourceTimeRangesParams } from 'utils';
import { useTimeInterval } from 'hooks/use-time-interval.hook';
import { transformToSortedDatesList } from 'utils/formatDate';
import { selectAllUnitCodes } from 'store/unit-codes/selectors';

interface UseSelectModalsReturnValues {
  reverseAnimation: boolean;
  onClose: VoidFunction;
  onViewAvailabilityClick: VoidFunction;
  onNextClick: VoidFunction;
  onDateTimeSaveClick: VoidFunction;
  onCtaButtonClick: VoidFunction;
  onTimeLinkClick: VoidFunction;
  onDateLinkClick: VoidFunction;
  timeRangeByDuration: TimeRangeByDuration;
  isDateStep: boolean;
  onPressSave: VoidFunction;
  onPressBack: VoidFunction;
  backToDateStep: boolean;
  isModalWithoutSlideAnimation: boolean;
  reverseHorizontalAnimation: boolean;
}

export const useSelectModals = (onSubmitBookingRequest: VoidFunction): UseSelectModalsReturnValues => {
  const { resource } = useSelector(resourceState);
  const { ownerType, ownerUuid } = useOwnerParams();
  const resourceTimeRanges = useSelector(selectResourceTimeRanges);
  const addOnsEnabled = useAddOnsEnabled();
  const locale = useLocale();
  const { isTimeIntervalFromTimeRanges, timeInterval } = useTimeInterval();

  const { search, pathname } = useLocation();
  const dispatch = useDispatch();
  const { startDate, startTime, endTime, duration, isExact, startDates, ...restQueryParams } =
    useSearchParams<searchParams>();

  const sortedDates = !!startDates && transformToSortedDatesList(startDates);
  const date = startDate || sortedDates?.[0];
  const isDatesExist = Boolean(date);
  const areTimeRangesForSelectedDate = resourceTimeRanges?.length && date === resourceTimeRanges[0]?.date;
  const shouldDispatchGetResourceTimeRanges: boolean = isDatesExist && resource?.id && !areTimeRangesForSelectedDate;
  const isUserPromptsExist = resource?.user_prompts?.length > 0;
  const unitCodes = useSelector(selectAllUnitCodes);

  const userHasMultipleUnitCodes = useMemo(() => {
    if (!!unitCodes) {
      return unitCodes.length > 1;
    }
    return false;
  }, [unitCodes]);

  const {
    reverseHorizontalAnimation,
    isModalWithoutSlideAnimation,
    onPressSave,
    reverseAnimation,
    toggleModal,
    onNextClick,
    onTimeLinkClick,
    onDateLinkClick,
    isDateStep,
    backToDateStep,
    onPressBack,
    toggleReverseAnimation,
    toggleModalSlideAnimation,
    onDateTimeSaveClick,
  } = useDateScheduleModalNavigation();

  const queryString = useMemo(
    () => ({
      ...restQueryParams,
      startDate: startDate || undefined,
      startDates: startDates || undefined,
      startTime: startTime || undefined,
      endTime: endTime || undefined,
      duration: duration || undefined,
      isExact: isExact || undefined,
    }),
    [duration, endTime, isExact, restQueryParams, startDate, startTime, startDates],
  );

  const onViewAvailabilityClick = useCallback(() => {
    toggleReverseAnimation();
    if (!isModalWithoutSlideAnimation) {
      toggleModalSlideAnimation();
    }
    const params = qs.stringify({ ...queryString, step: 0 });
    dispatch(push(`${pathname}/date-schedule-select?${params}`));
  }, [
    toggleReverseAnimation,
    toggleModalSlideAnimation,
    queryString,
    dispatch,
    pathname,
    isModalWithoutSlideAnimation,
  ]);

  const onCtaButtonClick = useCallback(() => {
    const isBookingTimeRangeExist = Boolean(startTime) && Boolean(endTime);

    if (
      isDatesExist &&
      isBookingTimeRangeExist &&
      (isUserPromptsExist || userHasMultipleUnitCodes) &&
      isExact &&
      isExact !== 'false'
    ) {
      toggleReverseAnimation();
      dispatch(push(`${pathname}/additional-information${search}`));
    }
    if ((isDatesExist && (!startTime || !endTime)) || ((isExact === 'false' || !isExact) && isDatesExist)) {
      toggleReverseAnimation();
      toggleModalSlideAnimation();
      const params = qs.stringify({ ...queryString, step: 1 });
      dispatch(push(`${pathname}/date-schedule-select?${params}`));
    }
    if (!(startDate || startDates)) {
      toggleReverseAnimation();
      toggleModalSlideAnimation();
      const params = qs.stringify({ ...queryString, step: 0 });
      dispatch(push(`${pathname}/date-schedule-select?${params}`));
    }
    if (!!startDate && isBookingTimeRangeExist && !isUserPromptsExist && resource.room_layouts?.length) {
      dispatch(push(`${pathname}/room-layouts${search}`));
    } else if (
      !!startDate &&
      isBookingTimeRangeExist &&
      !isUserPromptsExist &&
      !resource.room_layouts?.length &&
      resource.add_ons?.length &&
      addOnsEnabled
    ) {
      dispatch(push(`${pathname}/add-ons${search}`));
    } else if (isDatesExist && isBookingTimeRangeExist && !isUserPromptsExist && !userHasMultipleUnitCodes) {
      onSubmitBookingRequest();
    }
  }, [
    startTime,
    endTime,
    isDatesExist,
    isUserPromptsExist,
    isExact,
    startDate,
    startDates,
    resource,
    addOnsEnabled,
    toggleReverseAnimation,
    dispatch,
    pathname,
    search,
    toggleModalSlideAnimation,
    queryString,
    onSubmitBookingRequest,
    userHasMultipleUnitCodes,
  ]);

  const minDurationAndHours = useMemo<MinDurationAndHours>(() => {
    if (startDate) {
      const { minimumDuration, hours, maximumDuration } =
        getResourceTimeIndices({
          resource,
          startDate,
          resourceTimeRanges,
          locale,
        }) || {};
      return { minimumDuration, hours, maximumDuration };
    }

    return null;
  }, [startDate, resource, resourceTimeRanges, locale]);

  const filteredTimeRanges = useMemo(() => {
    if (startDate) {
      const { startResourceTimeIndex, endResourceTimeIndex, timeRangesWithLastTimeSlot } =
        getResourceTimeIndices({
          resource,
          startDate,
          resourceTimeRanges,
          locale,
        }) || {};
      return timeRangesWithLastTimeSlot?.slice(startResourceTimeIndex, endResourceTimeIndex + 1);
    }

    return resourceTimeRanges;
  }, [startDate, resourceTimeRanges, resource, locale]);

  const indexOfStartTime = useMemo<number>(
    () => filteredTimeRanges?.indexOf(filteredTimeRanges.find(el => el.time === startTime)),
    [filteredTimeRanges, startTime],
  );

  const indexOfEndTime = useMemo<number>(
    () => filteredTimeRanges?.indexOf(filteredTimeRanges.find(el => el.time === endTime)),
    [endTime, filteredTimeRanges],
  );

  const selectedTimeRange = useMemo<TimeRange[]>(() => {
    return filteredTimeRanges
      ?.slice(indexOfStartTime, indexOfEndTime + 1)
      ?.filter(filteredTimeRange => filteredTimeRange?.available);
  }, [filteredTimeRanges, indexOfEndTime, indexOfStartTime]);

  const timeRangeByDuration = useMemo<TimeRangeByDuration>(
    () =>
      getTimeRangeByDuration({
        duration: {
          max: minDurationAndHours?.maximumDuration,
          min: minDurationAndHours?.minimumDuration,
          currentDuration:
            +duration || (startTime && endTime && bookingTimeHandler({ startTime, endTime }).getTimeDiff()),
        },
        resourceTimeRanges: selectedTimeRange,
        filteredTimeRanges,
        hours: minDurationAndHours?.hours,
        timeInterval,
        isTimeIntervalFromTimeRanges,
      }),
    [
      minDurationAndHours?.maximumDuration,
      minDurationAndHours?.minimumDuration,
      minDurationAndHours?.hours,
      duration,
      startTime,
      endTime,
      selectedTimeRange,
      filteredTimeRanges,
      resource,
    ],
  );

  useEffect(() => {
    if (shouldDispatchGetResourceTimeRanges) {
      const getResourceTimeRangesParams = resolveGetResourceTimeRangesParams({
        ownerType: ownerType as OwnerTypesEnum,
        resourceId: resource.id,
        start: date,
        end: moment(date).add(1, 'days').format('YYYY-MM-DD'),
        ...(startDates && { startDates }),
      });

      dispatch(getResourceTimeRanges.request(getResourceTimeRangesParams));
    }
  }, [dispatch, startDate, ownerType, ownerUuid, resource, startDates, shouldDispatchGetResourceTimeRanges]);

  return {
    reverseHorizontalAnimation,
    isModalWithoutSlideAnimation,
    onPressSave,
    reverseAnimation,
    onClose: toggleModal,
    onViewAvailabilityClick,
    onNextClick,
    onDateTimeSaveClick,
    onCtaButtonClick,
    onTimeLinkClick,
    onDateLinkClick,
    timeRangeByDuration,
    isDateStep,
    backToDateStep,
    onPressBack,
  };
};
