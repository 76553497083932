import { Button } from '@hqo/react-components-library';
import styled from 'styled-components';

interface ButtonProps {
  isSelectedDateTimeFilterTextOnly?: boolean;
}

export const StyledButton = styled(Button)<ButtonProps>`
  color: ${({ theme }) => theme.colors.admin.greenAlt2};
  text-decoration: underline;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  min-width: 0;

  ${({ isSelectedDateTimeFilterTextOnly }) =>
    isSelectedDateTimeFilterTextOnly &&
    `
    text-decoration: none;
    :hover {
      cursor: default;
    }
    pointer-events: none;
  `};

  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 22px;
  }
`;
