import { StyledModalWrapper, ModalContainer } from './styles';
import React from 'react';
import { QuickCheckoutContent } from './quick-checkout-content';
import { useQuickCheckoutHeight } from 'components/payment/quick-checkout/useQuickCheckoutHeight.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'shared/types/search-params';

import { MultidayCheckoutContainer } from 'components/payment/quick-checkout/components/multiday-checkout-content/multidayCheckoutContainer';
interface QuickCheckoutProps {
  closeQuickCheckoutHandler: VoidFunction;
  reverseAnimation: boolean;
  openCheckout?: VoidFunction;
  overflow?: string;
}

export const QuickCheckout = ({
  closeQuickCheckoutHandler,
  reverseAnimation,
  openCheckout,
  overflow,
}: QuickCheckoutProps) => {
  const quickCheckoutHeight = useQuickCheckoutHeight();
  const { startDates } = useSearchParams<searchParams>();
  const isMultiDayContent = !!startDates?.length;

  const buildContent = (): JSX.Element => (
    <ModalContainer>
      <QuickCheckoutContent closeQuickCheckoutHandler={closeQuickCheckoutHandler} openCheckout={openCheckout} />
    </ModalContainer>
  );

  return isMultiDayContent ? (
    <MultidayCheckoutContainer
      closeQuickCheckoutHandler={closeQuickCheckoutHandler}
      reverseAnimation={reverseAnimation}
    />
  ) : (
    <StyledModalWrapper
      reverseAnimation={reverseAnimation}
      quickCheckoutHeight={quickCheckoutHeight}
      content={buildContent()}
      overflow={overflow}
    />
  );
};
