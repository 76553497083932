import { ButtonContainer, ContentContainer, ModalContainer, TextContainer, TitleContainer } from './styles';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';

import { ACTION_STATUSES } from 'shared/consts';
import { CancellationPolicy } from './cancellation-policy';
import { Container } from 'hocs/shared-styles';
import { PaymentMethods } from 'components/payment-methods';
import { Order } from 'store/cart/types';
import React from 'react';
import { SavedPaymentMethod } from 'store/payment/types';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { useIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CheckoutPaymentDetails } from './checkout-payment-details';
import { CheckoutButton } from 'components/checkout-button';
import { APPLE_PAY_PAYMENT_TYPE_ID } from 'shared/consts/payment-method-types';
import { faApplePay } from '@fortawesome/free-brands-svg-icons';
import { StyledNativePaymentIcon } from 'components/payment/quick-checkout/components/styles';

export interface Props {
  cart: Order;
  paymentMethods: Array<SavedPaymentMethod>;
  currentPaymentMethodId: string;
  submittedPaymentMethodId?: number;
  onRemovePaymentMethod: VoidFunction;
  onAddPaymentMethodClick: VoidFunction;
  onPayClick: VoidFunction;
  submitCartStatus: ACTION_STATUSES;
  isCartFulfilled: boolean;
  showAddCardForm: boolean;
  currentPaymentError?: boolean;
  cartId: string;
  onCancelAddCardClick: VoidFunction;
  isAddCardButtonDisabled: boolean;
  setIsAddCardButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  pendingNativePayment: boolean;
  isNativePaymentError: boolean;
}

export const CheckoutMobile = ({
  cart,
  onAddPaymentMethodClick,
  paymentMethods,
  currentPaymentMethodId,
  submittedPaymentMethodId,
  onRemovePaymentMethod,
  onPayClick,
  submitCartStatus,
  isCartFulfilled,
  showAddCardForm,
  currentPaymentError,
  cartId,
  onCancelAddCardClick,
  isAddCardButtonDisabled,
  setIsAddCardButtonDisabled,
  pendingNativePayment,
  isNativePaymentError,
}: Props): JSX.Element => {
  const intl = useIntl();
  const { total } = formatSummaryValues(cart?.total_summary);
  const { showMiniappPaymentsNavigation } = useFlags();
  const isApplePay = currentPaymentMethodId === APPLE_PAY_PAYMENT_TYPE_ID;

  return (
    <ModalContainer>
      {!cart || !paymentMethods ? (
        <Container isLoading>
          <Spinner color={defaultTheme.colors.$greyLight} size="10em" />
        </Container>
      ) : (
        <ContentContainer>
          <TitleContainer>
            <TitleBlock title={intl.formatMessage({ id: 'checkout' })} />
          </TitleContainer>
          {isCartFulfilled && <CheckoutPaymentDetails cart={cart} />}
          <PaymentMethods
            cart={cart}
            showAddCardForm={showAddCardForm}
            onCancelAddCardClick={onCancelAddCardClick}
            isSwipeModalContent={showMiniappPaymentsNavigation}
            enabledPaymentMethods={cart.ui_enablements}
            paymentMethods={paymentMethods}
            selectedPaymentMethodId={currentPaymentMethodId}
            submittedPaymentMethodId={submittedPaymentMethodId}
            onRemovePaymentMethod={onRemovePaymentMethod}
            onAddNewCard={onAddPaymentMethodClick}
            currentPaymentError={currentPaymentError}
            cartId={cartId}
            total={total}
            formTitle={intl.formatMessage({ id: 'payment' })}
            isAddCardButtonDisabled={isAddCardButtonDisabled}
            setIsAddCardButtonDisabled={setIsAddCardButtonDisabled}
            isNativePaymentError={isNativePaymentError}
          />
          {cart.items[0].display_info?.cancellation_description && (
            <TextContainer showAddCardForm={showAddCardForm}>
              <CancellationPolicy cart={cart} />
            </TextContainer>
          )}
          <ButtonContainer>
            <CheckoutButton
              text={
                isApplePay
                  ? intl.formatMessage({ id: 'check_out_with' })
                  : intl.formatMessage({ id: 'confirm_and_book' })
              }
              isDisabled={
                !currentPaymentMethodId ||
                submitCartStatus === ACTION_STATUSES.PENDING ||
                showAddCardForm ||
                pendingNativePayment
              }
              isLoading={submitCartStatus === ACTION_STATUSES.PENDING || pendingNativePayment}
              onClick={onPayClick}
              icon={isApplePay && <StyledNativePaymentIcon icon={faApplePay} size="2x" />}
              isNativePayment={isApplePay}
            />
          </ButtonContainer>
        </ContentContainer>
      )}
    </ModalContainer>
  );
};
