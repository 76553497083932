import React, { useMemo } from 'react';
import { StyledFilterPicker, FloorPlanDrawerContentContainer, FloorPlanButton } from './styles';
import { FloorPlanListView } from 'components/floor-plan-list-view';
import { FloorPlanButtonIcon } from 'components/floor-plan-footer/styles';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { FloorPlanResourceInfo } from 'components/floor-plan-list-view/floor-plan-list.interface';

interface FloorPlanDrawerContentParamsInterface {
  resourceListViewAvailability: FloorPlanResourceInfo[];
  toggleReverseAnimation?: VoidFunction;
  toggleModalSlideAnimation?: VoidFunction;
  modalRef?: React.MutableRefObject<any>;
}
export const FloorPlanDrawerContent = ({
  resourceListViewAvailability,
  toggleReverseAnimation,
  toggleModalSlideAnimation,
  modalRef,
}: FloorPlanDrawerContentParamsInterface): JSX.Element => {
  const isMobileDevice = useIsSmallViewportWidth();

  const onClick = useMemo(() => {
    return modalRef?.current?.closeModal;
  }, [modalRef, modalRef?.current]);

  return (
    <FloorPlanDrawerContentContainer>
      <StyledFilterPicker />
      <FloorPlanListView
        resourceListViewAvailability={resourceListViewAvailability}
        toggleModalSlideAnimation={toggleModalSlideAnimation}
        toggleReverseAnimation={toggleReverseAnimation}
      />
      {isMobileDevice && (
        <FloorPlanButton
          size="floorPlan"
          data-testid="floor-plan-button"
          text="Floor Plan"
          variant="floorPlan"
          multiline={false}
          iconDirection="left"
          Icon={<FloorPlanButtonIcon variant="white" />}
          onClick={onClick}
        />
      )}
    </FloorPlanDrawerContentContainer>
  );
};
