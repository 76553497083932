import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { PresetWindows } from 'store/resources/types';
import { TimeRange } from 'store/resource-time-ranges/types';
import { useIntl } from 'react-intl';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';

interface UseDateSelectTimelineReturnValues {
  formattedDates: string;
  datePickerValue: string | Array<string>;
  timePickerValue?: string;
  startTimeLine: string;
  endTimeLine: string;
}

export const useDateSelectTimeline = (filteredTimeRanges: Array<TimeRange>): UseDateSelectTimelineReturnValues => {
  const intl = useIntl();
  const locale = useLocale();
  const { startDate, startDates, startTime, endTime, presetWindows } = useSearchParams<searchParams>();
  const timeRange = { startTime: startTime, endTime: endTime, locale };
  const { getFormatTime, getDatesRangeString } = bookingTimeHandler(timeRange);

  const datePickerValue = startDate || startDates?.split(',');
  const formattedDates = getDatesRangeString(datePickerValue);

  const timeRangeString =
    !startTime || !endTime
      ? undefined
      : `${getFormatTime(startTime, locale, { toUpperCase: true })} - ${getFormatTime(endTime, locale, {
          toUpperCase: true,
        })}`;

  const timePickerValue =
    presetWindows === PresetWindows.FULL_DAY ? intl.formatMessage({ id: 'all_day' }) : timeRangeString;

  const startTimeLine =
    filteredTimeRanges?.[0]?.time && getFormatTime(filteredTimeRanges[0].time, locale, { toUpperCase: true });

  const endTimeLine =
    filteredTimeRanges?.[filteredTimeRanges?.length - 1]?.time &&
    getFormatTime(filteredTimeRanges?.[filteredTimeRanges?.length - 1]?.time, locale, { toUpperCase: true });

  return {
    formattedDates,
    datePickerValue,
    timePickerValue,
    startTimeLine,
    endTimeLine,
  };
};
