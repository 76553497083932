import { TimeRangeByDuration } from 'components/schedule-select/interface';
import { createContext } from 'react';

const defaultTimeRangeByDurationContext = {
  index: 0,
  height: 0,
  startTimeByDuration: '',
  endTimeByDuration: '',
};

export const TimeRangeByDurationContext = createContext<TimeRangeByDuration>(defaultTimeRangeByDurationContext);
