import styled from 'styled-components';
import { Modal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { DIMENSIONS } from '@hqo/react-components-library';

export const StyledModal = styled(Modal)`
  overflow: auto;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 348px;
    max-height: 683px;
    max-width: 963px;
    padding: 24px 32px;
    margin: auto;
    overflow: initial;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 24px 56px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Content = styled.div<{ backToDateStep: boolean }>`
  overflow-y: auto;
  width: 100%;
  margin-bottom: 10px;
  ${({ backToDateStep }) => !backToDateStep && 'margin-top: 10px;'};
  flex-grow: 1;
`;

export const Footer = styled.div`
  width: 100%;
`;

export const Title = styled.div<{ backToDateStep: boolean }>`
  padding: ${({ backToDateStep }) => (backToDateStep ? '20px 0 8px 0' : '20px 0')};
  width: 100%;
  font: 700 28px/33px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  box-sizing: border-box;
  justify-content: flex-start;
`;

export const FooterContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
`;
