import { DIMENSIONS } from '@hqo/react-components-library';

import styled from 'styled-components';

interface Props {
  backgroundColor?: string;
}

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  z-index: 13;
`;

export const Container = styled.div<Props>`
  background: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.$white};
  margin: auto;
  box-shadow: ${({ theme }) => `0px 0px 4px ${theme.colors.greys.adminBlack}33`};
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: inherit;
  z-index: 14;
  height: inherit;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    transform: translate(0%, 0%);
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    height: auto;
    width: auto;
  }
`;

export const Content = styled.div<Props>`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;
