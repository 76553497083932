import { OverviewContainer, StyledStatusIconTitle, Subtitle, TitleContainer } from './styles';
import { formatDate } from 'utils/formatDate';
import { BoldText } from 'hocs/bold-text';
import { IconContentProps } from '@hqo/react-components-library';
import React, { useMemo } from 'react';
import { TransactionDetails } from 'store/transactions/types';
import { getReceiptIcon, getReceiptIconFromCart } from 'utils/getReceiptIcon';
import { getReceiptSubtitle } from 'utils/getReceiptSubtitle';
import { getReceiptTitle } from 'utils/getReceiptTitle';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectPatchTransactionsStatus, selectTypeOfPatchTransaction } from 'store/transactions/selectors';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useSelector } from 'react-redux';
import { BookingStatus } from 'store/bookings/types';
import { ACTION_STATUSES } from 'shared/consts';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'shared/types/search-params';
import { selectCart } from 'store/cart/selectors';
import { formatStartDatesQueryParams } from 'components/payment/quick-checkout/components/multiday-checkout-content/utils/formatStartDatesQueryParam';
import { joinArrayItemsWithOxfordComma } from 'components/payment/quick-checkout/components/multiday-checkout-content/utils/joinItemsWithOxfordComma';
import { isBookingsForOneResource } from 'components/payment/receipt/components/utils/isBookingsForOneResource';

interface ReceiptOverviewProps {
  transaction: TransactionDetails;
  isSwipeModalContent?: boolean;
}

export const ReceiptOverview = ({ transaction, isSwipeModalContent = false }: ReceiptOverviewProps): JSX.Element => {
  const intl = useIntl();
  const locale = useLocale();
  const patchTransactionStatus = useSelector(selectPatchTransactionsStatus);
  const typeOfPatchTransaction = useSelector(selectTypeOfPatchTransaction);
  const patchTransactionData = useMemo(
    () => ({
      status: patchTransactionStatus,
      type: typeOfPatchTransaction,
    }),
    [patchTransactionStatus, typeOfPatchTransaction],
  );
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const cart = useSelector(selectCart);

  const { startDates } = useSearchParams<searchParams>();

  const multidayBookingInfo = useMemo<{ resourceName: string; status: string } | null>(() => {
    const isMultidayBooking = isBookingsForOneResource(cart?.items);

    return !!startDates?.length && isMultidayBooking
      ? { resourceName: cart?.items[0].resource_booking.name, status: cart.items[0].status }
      : null;
  }, [cart?.items, startDates?.length]);

  const { eventWeekDay, eventDateMonthLong, eventDateDay } = formatDate(
    transaction?.details.start_at,
    locale as string,
    buildingTimeZone,
  );

  const icon = useMemo<JSX.Element>(
    () =>
      multidayBookingInfo
        ? getReceiptIconFromCart(cart.items)
        : getReceiptIcon(transaction?.details.status as string, patchTransactionData),
    [multidayBookingInfo, cart?.items, transaction?.details.status, patchTransactionData],
  );

  const hideIcon = useMemo<boolean>(
    () =>
      multidayBookingInfo
        ? multidayBookingInfo.status === BookingStatus.PENDING
        : transaction?.details.status === BookingStatus.PENDING && patchTransactionStatus !== ACTION_STATUSES.FULFILLED,
    [multidayBookingInfo, transaction?.details.status, patchTransactionStatus],
  );

  const formattedDatesString = formatStartDatesQueryParams(startDates, locale);
  const andSeparator = intl.formatMessage({ id: 'and' });

  const formattedDatesStringWithSeparator = joinArrayItemsWithOxfordComma(formattedDatesString, andSeparator);

  const getOverviewTitle = () => {
    const booking_status = multidayBookingInfo ? multidayBookingInfo.status : transaction?.details.status;

    return {
      title: getReceiptTitle(booking_status as string, patchTransactionData),
      subtitle: multidayBookingInfo
        ? intl.formatMessage(
            { id: getReceiptSubtitle(booking_status as string) },
            {
              resourceName: <BoldText>{multidayBookingInfo.resourceName}</BoldText>,
              bookingTime: <BoldText>{formattedDatesStringWithSeparator}</BoldText>,
            },
          )
        : intl.formatMessage(
            {
              id: getReceiptSubtitle(booking_status as string, patchTransactionData),
            },
            {
              resourceName: <BoldText>{transaction?.details.name}</BoldText>,
              bookingTime: <BoldText>{`${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}`}</BoldText>,
            },
          ),
    };
  };

  return (
    <OverviewContainer isSwipeModalContent={isSwipeModalContent} data-testid="receipt-overview">
      <TitleContainer>
        <StyledStatusIconTitle
          title={intl.formatMessage({ id: getOverviewTitle().title })}
          icon={icon as unknown as React.FC<IconContentProps>}
          hideIcon={hideIcon}
        />
      </TitleContainer>
      {/* @ts-ignore */}
      <Subtitle>{getOverviewTitle().subtitle}</Subtitle>
    </OverviewContainer>
  );
};
