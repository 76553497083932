import { DetailsModalContentContainer, Description, StyledImage, StyledCounter, ResourceImageSkeleton } from './styles';
import React from 'react';
import { useToggleNativeHeader } from 'hooks/use-toggle-native-header/use-toggle-native-header.hook';
import { useToggleSwipeGestures } from 'hooks/use-toggle-swipe-gestures/use-toggle-swipe-gestures.hook';
import { AddOn } from 'store/resource/types';
import { useIntl } from 'react-intl';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { useModalContent } from './use-modal-content.hook';

interface DetailsModalContentProps {
  selectedAddOn: AddOn;
  quantity: number;
  increaseHandler: VoidFunction;
  decreaseHandler: VoidFunction;
  onModalClose: VoidFunction;
}

export const DetailsModalContent = ({
  selectedAddOn,
  quantity,
  increaseHandler,
  decreaseHandler,
  onModalClose,
}: DetailsModalContentProps): JSX.Element => {
  useToggleNativeHeader();
  useToggleSwipeGestures();
  const intl = useIntl();
  const { isDecreaseDisabled, description, image_url, onRemoveClick } = useModalContent(
    selectedAddOn,
    onModalClose,
    quantity,
  );

  return (
    <DetailsModalContentContainer>
      {image_url && <StyledImage src={image_url} alt="add-on-image" skeleton={<ResourceImageSkeleton />} />}
      {description && <Description>{description}</Description>}
      <StyledCounter
        value={quantity}
        increaseHandler={increaseHandler}
        decreaseHandler={decreaseHandler}
        isDecreaseDisabled={isDecreaseDisabled}
      />
      <Button
        variant="primaryLink"
        onClick={onRemoveClick}
        text={intl.formatMessage({ id: 'remove' })}
        multiline={false}
      />
    </DetailsModalContentContainer>
  );
};
