import { Map, Button } from '@hqo/react-components-library';
import styled from 'styled-components';

export const Footer = styled.div`
  left: calc(50% - 76px);
  text-align: center;
  position: fixed;
  bottom: 50px;
`;

export const FloorPlanButton = styled(Button)`
  margin: 0 auto;
`;

export const FloorPlanButtonIcon = styled(Map)`
  margin-right: 4px;
`;
