import { ACTION_STATUSES, FontLocation } from 'shared/consts';
import { ThemeLocationFont, useThemeFont } from 'hooks/use-theme-font.hook';
import { getResources, getResourcesTypes, resetInfiniteResourcesState } from 'store/resources/actions';
import { resourcesState } from 'store/resources/selectors';
import {
  selectAppInstanceConfigsStatus,
  selectCurrentAppInstanceConfigUuid,
  selectCurrentLocationId,
} from 'store/app-instance-configs/selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FilterParams } from 'store/resources/types';
import { LocaleEnum } from 'store/cart/types';
import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import { Resource } from 'store/resource/types';
import { getAppInstanceConfigs } from 'store/app-instance-configs/actions';
import { getFilterParams } from 'utils';
import { useExchangeAdapterHandling } from 'hooks/use-exchange-adapter-handling.hook';
import { useLocale } from 'hooks/use-locale.hook';
import { useLocation } from 'react-router-dom';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useResourceLoadingHandler } from './index';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { useTermsAndConditionsVisibility } from 'hooks/use-terms-and-conditions-visibility.hook';
import { getFloors } from 'store/spaceManager/actions';
import { useResetResourceState } from 'pages/resources/hooks/useResetResourceState.hook';

interface UseResourcesPageReturnValues {
  resources: Resource[];
  isLoadingState: boolean;
  themeFontTitle: ThemeLocationFont;
  isCompactUiEnabled: boolean;
  location: {
    pathname: string;
    search: string;
  };
}

export const useResourcesPage = (): UseResourcesPageReturnValues => {
  useTermsAndConditionsVisibility();
  const dispatch = useDispatch();
  const locale = useLocale();
  const timeZone = useTimeZone();
  const { pathname, search } = useLocation();
  const { ownerType, ownerUuid } = useOwnerParams();
  const { ...queryParams } = useSearchParams();

  const { filterByType, startDate, startTime, endTime, duration, capacity, presetWindows, filterByFloor, startDates } =
    queryParams;

  const { resources } = useSelector(resourcesState);
  const themeFontTitle = useThemeFont(FontLocation.TITLE);
  const locationId = useSelector(selectCurrentLocationId);
  const appInstanceConfigsStatus = useSelector(selectAppInstanceConfigsStatus);
  const isFilteringEnabled = useFilteringEnabled();
  const { isCompactUiEnabled } = useExchangeAdapterHandling();
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);
  const client = useMiniappSdk();

  const offsetElements = Math.round((window.innerHeight - 109 - 20) / 84);

  useEffect(() => {
    client?.header.setHeader('', HEADER_BUTTON_MODE.HISTORY);
    client?.swipe.enableBackSwipe();
  }, [client]);

  useResetResourceState();

  useEffect(() => {
    const searchParams = {
      types: filterByType,
      startDate,
      startTime,
      endTime,
      duration,
      capacity,
      presetWindows,
      startDates,
      floor: filterByFloor,
    } as FilterParams;

    const filterParams = getFilterParams({ ...searchParams });

    if (
      (pathname === '/resource-booking/resources' || pathname === '/resource-booking/resources/filter-by') &&
      ownerType &&
      ownerUuid &&
      appInstanceConfigsStatus === ACTION_STATUSES.FULFILLED &&
      locationId
    ) {
      if (isCompactUiEnabled) {
        dispatch(resetInfiniteResourcesState());
        dispatch(
          getResources.request({ ownerType, ownerUuid, filterParams: { ...filterParams, limit: offsetElements } }),
        );
      } else {
        dispatch(getResources.request({ ownerType, ownerUuid, filterParams: { ...filterParams } }));
      }
      if (isFilteringEnabled) {
        dispatch(getResourcesTypes.request({ ownerUuid }));

        dispatch(getFloors.request({ buildingUuid: ownerUuid }));
      }
    }
  }, [
    ownerType,
    filterByType,
    startDate,
    startTime,
    endTime,
    duration,
    capacity,
    timeZone,
    dispatch,
    appInstanceConfigsStatus,
    isFilteringEnabled,
    locationId,
    isCompactUiEnabled,
    pathname,
    ownerUuid,
    presetWindows,
    filterByFloor,
    startDates,
  ]);

  useEffect(() => {
    if (ownerUuid) {
      if (!currentAppInstanceConfigUuid) {
        dispatch(
          getAppInstanceConfigs.request({
            lang: locale as LocaleEnum,
            owners: [
              {
                uuid: ownerUuid,
                type: ownerType.toUpperCase() as OwnerTypesEnum,
              },
            ],
          }),
        );
      }
    }
  }, [dispatch, ownerUuid, ownerType, locale, currentAppInstanceConfigUuid, locationId]);

  return {
    resources,
    isLoadingState,
    themeFontTitle,
    isCompactUiEnabled,
    location: {
      pathname,
      search,
    },
  };
};
