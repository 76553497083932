import { ChipsContainer, TypesFilterContainer, TypesFilterTitle, ViewMoreButton } from './styles';
import React, { useCallback } from 'react';

import { MAX_DISPLAYED_TYPES_COUNT } from 'shared/consts';
import { TypesComponent } from 'components/types-component';
import { selectResourcesTypes } from 'store/resources/selectors';
import { useBooleanState } from '@hqo/react-components-library';
import { useIntl } from 'react-intl';
import { useIsAppInstanceConfigNexudus } from 'hooks/use-app-instance-config.hook';
import { useSelector } from 'react-redux';
import { useTypesFilter } from './use-types-filter.hook';

export const TypesFilter = (): JSX.Element => {
  const { value: isChipContainerExpanded, toggle: toggleIsChipContainerExpanded } = useBooleanState(false);
  const resourceTypes = useSelector(selectResourcesTypes);
  const { selectedTypes, onTypeChipClickHandler } = useTypesFilter();
  const isCurrentConfigNexudus = useIsAppInstanceConfigNexudus();

  const intl = useIntl();

  const handleToggleIsChipContainerExpanded = useCallback(() => {
    toggleIsChipContainerExpanded();
  }, [isChipContainerExpanded, toggleIsChipContainerExpanded]);

  return (
    <TypesFilterContainer>
      <TypesFilterTitle>{intl.formatMessage({ id: 'types' })}</TypesFilterTitle>
      <ChipsContainer isExpanded={isChipContainerExpanded}>
        <TypesComponent
          resourceTypes={resourceTypes}
          selectedTypes={selectedTypes}
          onTypeChipClickHandler={onTypeChipClickHandler}
          isCurrentConfigNexudus={isCurrentConfigNexudus}
        />
      </ChipsContainer>
      {resourceTypes.length > MAX_DISPLAYED_TYPES_COUNT && (
        <ViewMoreButton
          text={intl.formatMessage({ id: isChipContainerExpanded ? 'hideTypes' : 'viewMore' })}
          variant="closeButton"
          onClick={handleToggleIsChipContainerExpanded}
          multiline={false}
        />
      )}
    </TypesFilterContainer>
  );
};
