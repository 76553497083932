import { useCallback } from 'react';

import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useUpdateUrlPath } from 'hooks/use-update-url-path.hook';
import { searchParams } from 'pages/date-select-page/interface';
import {
  calculateAvailableWindowDuration,
  calculateStartTime,
  generateTimeSlots,
  getIsUnavailableSlotIncluded,
} from 'pages/resource/components/selected-timeline-block/utils/selected-timeline-block.utils';
import { ItemRef } from 'pages/resource/components/timeline/types';
import { isEndTimeBeforeStartTime } from 'pages/resource/components/timeline/utils/isEndTimeBeforeStartTime';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { getEndTimeByDuration } from 'utils/getEndTimeByDuration';
import { track } from '@hqo/web-tracking';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';

export const useTimelineEvents = ({ itemRefs }: { itemRefs: React.MutableRefObject<Array<ItemRef>> }) => {
  const locale = useLocale();
  const { startTime, endTime } = useSearchParams<searchParams>();
  const updateUrlPath = useUpdateUrlPath();

  const handleTimelineBlockClick = useCallback(
    blockIndex => {
      track(
        TRACK_EVENT_NAMES.RESOURCE_DETAIL_PAGE_TIMELINE_CLICK,
        {
          type: TRACK_EVENT_TYPES.ACTION,
        },
        { sendToHqoTracking: true },
      );

      const selectedDuration = bookingTimeHandler({
        startTime: startTime?.toString(),
        endTime: endTime?.toString(),
      }).getTimeDiff();

      const calculatedEndTime = getEndTimeByDuration(
        new Date(),
        itemRefs.current[blockIndex].key,
        selectedDuration,
        locale,
      );
      const currentStartTime = itemRefs.current[blockIndex].key;

      const isSelectedBlockOutsideWorkingHours =
        !itemRefs.current.some(timeSlot => timeSlot.key === calculatedEndTime) ||
        isEndTimeBeforeStartTime(currentStartTime, calculatedEndTime);

      if (isSelectedBlockOutsideWorkingHours) {
        const updatedStartTime = calculateStartTime(
          itemRefs.current[itemRefs.current.length - 1].key,
          selectedDuration,
        );
        updateUrlPath(updatedStartTime, itemRefs.current[itemRefs.current.length - 1].key);

        return;
      }

      const timeSlots = generateTimeSlots(currentStartTime, calculatedEndTime);
      const isUnavailableTimeSlotIncluded = getIsUnavailableSlotIncluded<ItemRef>(
        itemRefs.current,
        timeSlots,
        'key',
        'available',
      );

      const { durationMinutes, endTime: calculatedAvailableEndTime } = calculateAvailableWindowDuration(
        itemRefs,
        itemRefs.current[blockIndex].key,
      );

      const shouldBlockShiftNeareastAvailableTime =
        isUnavailableTimeSlotIncluded && durationMinutes >= selectedDuration;

      if (shouldBlockShiftNeareastAvailableTime) {
        const updatedStartTime = calculateStartTime(calculatedAvailableEndTime, selectedDuration);
        updateUrlPath(updatedStartTime, calculatedAvailableEndTime);

        return;
      }

      updateUrlPath(itemRefs.current[blockIndex].key, calculatedEndTime);
    },
    [endTime, itemRefs, locale, startTime, updateUrlPath],
  );

  return {
    handleTimelineBlockClick,
  };
};
