export const DEFAULT_HEIGHT = 66;
export const RADIX = 10;
export const DEFAULT_TIME_INTERVAL = 15;
export const ONE_HOUR = 60;
export const MIDNIGHT_HOUR = 24;
export const DEFAULT_CONTAINER_PADDING = 10;
export const MIDNIGHT_HOURS = '00:00';
export const MIDNIGHT_HOURS_24H = '24:00';
export const MIDNIGHT_HOURS_24H_SAFARI = '00:00';
export const EMPTY_STRING = '';
