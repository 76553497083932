import React from 'react';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { useResourceLoadingHandler } from '../hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FilterBar } from '../filter-bar';

export const FilterPicker = ({ className }: { className?: string }): JSX.Element => {
  const isFilteringEnabled = useFilteringEnabled();
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);
  const { showResourceBookingMiniappFiltering: isResourceFilteringVisible } = useFlags();

  if (!isResourceFilteringVisible) return null;

  return <FilterBar className={className} isVisible={!isLoadingState} />;
};
