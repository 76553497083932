import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getAddOnLabels } from 'utils/getAddOnLabels';
import { DEFAULT_ITEM_QUANTITY, ZERO_ITEM_QUANTITY } from 'shared/consts';
import { AddOn } from 'store/resource/types';
import { useSelector } from 'react-redux';
import { selectBuildingCurrencyType } from 'store/building/selectors';
import { StyledAddOnTile } from './styles';
import { selectAllAddOns } from 'store/add-ons/selectors';
import { AddOnDto } from '../../../store/add-ons/types';
import { useLocale } from 'hooks/use-locale.hook';

interface AddOnComponentProps {
  addOn: AddOn;
  onAddOnClick: (id: string) => void;
}

export const AddOnComponent = ({ addOn, onAddOnClick }: AddOnComponentProps): JSX.Element => {
  const intl = useIntl();
  const selectedAddOns = useSelector(selectAllAddOns);
  const currencyType = useSelector(selectBuildingCurrencyType);
  const locale = useLocale();
  const { id, image_url, required, name, description, price, request_quantity } = addOn || {};
  const onClick = useCallback(() => onAddOnClick(id), [id, onAddOnClick]);
  const currentAddOn = useMemo<AddOnDto>(
    () => selectedAddOns?.find(selectedAddOn => selectedAddOn.id === id),
    [id, selectedAddOns],
  );
  const isAddOnSelected = useMemo<boolean>(() => currentAddOn && !currentAddOn.required, [currentAddOn]);
  const itemQuantity = useMemo<number>(
    () => (currentAddOn && request_quantity ? currentAddOn.quantity : ZERO_ITEM_QUANTITY),
    [currentAddOn, request_quantity],
  );

  return (
    <StyledAddOnTile
      onClick={onClick}
      key={id}
      isSelected={isAddOnSelected}
      imageSrc={image_url}
      isRequired={required}
      itemQuantity={required ? DEFAULT_ITEM_QUANTITY : itemQuantity}
      labels={getAddOnLabels(name, description, price, intl, currencyType, locale)}
    />
  );
};
