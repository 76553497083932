import { useSelector } from 'react-redux';
import { selectAppInstanceConfigsStatus } from 'store/app-instance-configs/selectors';
import { useResourcesTypes } from 'hooks/useResourcesTypes.hook';
import { ACTION_STATUSES } from 'shared/consts';
import { selectTransactionsLoading } from 'store/transactions/selectors';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';

export const useLoadingHandler = (): boolean => {
  const isFilteringEnabled = useFilteringEnabled();
  const isAppInstanceConfigsLoading = useSelector(selectAppInstanceConfigsStatus) === ACTION_STATUSES.PENDING;
  const { isResourceTypesLoading } = useResourcesTypes();
  const isTransactionsLoading = useSelector(selectTransactionsLoading);

  return isFilteringEnabled
    ? isAppInstanceConfigsLoading || isResourceTypesLoading || isTransactionsLoading
    : isAppInstanceConfigsLoading || isTransactionsLoading;
};
