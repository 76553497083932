import { ModalContainer, ContentContainer, Text, Title, TextContainer } from './styles';
import React from 'react';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { marked } from 'marked';
import { DEFAULT_MARKED_OPTIONS, DEFAULT_LINK_ATTRIBUTES } from 'shared/consts';
import { addAttributesToLinks } from 'utils/addAttributeToLinks';

interface ModalContentProps {
  title?: string;
}

export const ModalContent = ({ title }: ModalContentProps) => {
  const { termsAndConditionsValues } = useAppInstanceConfigFeature();
  const { termsAndConditionsText } = termsAndConditionsValues || {};
  const formattedText = marked.parse(termsAndConditionsText.replace(/\n(?=\n)/g, '<br/>\n'), DEFAULT_MARKED_OPTIONS);
  const modifiedHtmlString = addAttributesToLinks(formattedText, DEFAULT_LINK_ATTRIBUTES);

  return (
    <ModalContainer>
      <ContentContainer>
        {title && <Title>{title}</Title>}
        <TextContainer>
          <Text
            dangerouslySetInnerHTML={{
              __html: `${modifiedHtmlString}`,
            }}
          />
        </TextContainer>
      </ContentContainer>
    </ModalContainer>
  );
};
