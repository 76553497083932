import { createEpicMiddleware } from 'redux-observable';
import { RootAction } from 'store/actions';
import rootEpic from './epics';
import rootReducer, { RootState } from './reducer';
import { rootDependencies, RootDependencies } from 'store/dependencies';
import { createReduxEnhancer } from '@sentry/react';
import { configureStore, Tuple } from '@reduxjs/toolkit';
import { sentryStateTransformer } from './utils/sentry-state-transformer.util';

const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: sentryStateTransformer,
});

export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, RootDependencies>({
  dependencies: rootDependencies,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: () => new Tuple(epicMiddleware),
  enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
});

epicMiddleware.run(rootEpic);

export default store;
