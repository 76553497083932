import { TimelineBlock } from 'components/timeline-block';
import React from 'react';
import { CurrentTimeIndicator, NotAvailableText, TimelineContainer, TimelineWrapper } from './styles';
import { SelectedTimelineBlock } from 'pages/resource/components/selected-timeline-block/selected-timeline-block';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useIntl } from 'react-intl';
import { TimelineProps } from './types';
import { useTimeline } from './hooks/use-timeline.hook';
import { useTimelineEvents } from 'pages/resource/components/timeline/hooks/use-timeline-events.hook';
import useCurrentTimeIndicator from 'pages/resource/components/timeline/hooks/use-current-time-indicator.hook';

export const Timeline = ({ startTimeLine, endTimeLine, filteredTimeRanges }: TimelineProps) => {
  const intl = useIntl();
  const isMobileDevice = useIsSmallViewportWidth();
  const {
    itemRefs,
    timelineWrapperRef,
    initialWidthSelectedBlock,
    initialStartTimeOffsetSelectedBlock,
    durationsAndInterval,
  } = useTimeline(filteredTimeRanges);

  const { handleTimelineBlockClick } = useTimelineEvents({ itemRefs });
  const { showCurrentTimeIndicator, leftOffsetInPx } = useCurrentTimeIndicator({
    timeRanges: filteredTimeRanges,
  });

  return (
    <TimelineWrapper ref={timelineWrapperRef} isMobileDevice={isMobileDevice} data-testid="timeline-wrapper">
      <NotAvailableText>
        {intl.formatMessage({ id: 'notAvailable' }, { availability: 'before', time: startTimeLine })}
      </NotAvailableText>
      <TimelineContainer>
        {showCurrentTimeIndicator && (
          <CurrentTimeIndicator data-testid="current-time-indicator" left={leftOffsetInPx} />
        )}
        {!!itemRefs?.current.length && (
          <SelectedTimelineBlock
            itemRefs={itemRefs}
            timelineWrapperRef={timelineWrapperRef}
            initialWidth={initialWidthSelectedBlock}
            initialStartTimeOffset={initialStartTimeOffsetSelectedBlock}
            durationsAndInterval={durationsAndInterval}
          />
        )}
        {filteredTimeRanges?.map((timeRange, index) => (
          <TimelineBlock
            key={timeRange.time}
            timeRange={timeRange}
            lastTimeRangeIndex={filteredTimeRanges.length - 1}
            index={index}
            itemRefs={itemRefs}
            handleTimelineBlockClick={handleTimelineBlockClick}
            timelineWrapperRef={timelineWrapperRef}
          />
        ))}
      </TimelineContainer>
      <NotAvailableText>
        {intl.formatMessage({ id: 'notAvailable' }, { availability: 'after', time: endTimeLine })}
      </NotAvailableText>
    </TimelineWrapper>
  );
};
