import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
`;

export const FloorName = styled.div`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme?.colors.greys.adminBlack};
  margin: 0 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledDropDownIcon = styled(FontAwesomeIcon)`
  margin-top: -6px;
  color: ${({ theme }) => theme?.colors.greys.adminGreyDark};
  margin-left: 10px;
  margin-right: 10px;
`;
