import { BackLinkWrapper, Container } from './styles';
import { Route, Routes, useParams } from 'react-router-dom';
import { ACTION_STATUSES, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts/';

import { AdditionalInformationPage } from 'pages/additional-information';
import { ArrowBackLink } from 'components/arrow-back-link';
import { PaymentIFrame } from 'components/paymentIFrame';
import React, { Dispatch, useEffect } from 'react';
import { resourceState } from 'store/bookings/selectors';
import { useAddtitionalInformation } from 'hooks/additional-information/useAdditionalInformation.hook';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';
import { goBack } from 'store/router/actions';
import { useHqoTracking } from 'hooks/use-hqo-tracking.hook';
import { usePaymentModal } from 'hooks/payment-hook/use-payment-modal.hook';
import { useDispatch, useSelector } from 'react-redux';
import { SwipePaymentIFrame } from 'components/swipePaymentIFrame';
import { DateTimeSelectModal } from 'pages/resource/date-time-select-modal';
import { useDateScheduleModalNavigation } from 'hooks/use-date-schedule-modal-navigation.hook';
import { getResourceTrackInfo } from 'utils/getResourceTrackInfo';
import { useQuickCheckout } from 'hooks/use-quick-checkout.hook';
import { useCheckoutScreen } from 'hooks/payment-content/use-checkout-screen.hook';
import { PaymentContent } from 'components/payment-content';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';
import { selectGetResourceStatus } from 'store/resource/selectors';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { getResource } from 'store/resource/actions';
import { ModalContainer } from 'pages/resource/components/terms-and-conditions/components/modal-container';

interface Props {
  onSubmit: VoidFunction;
  selectCode?: Dispatch<React.SetStateAction<string>>;
  selectedUnitCode?: string;
}

export const AdditionalInformationModal = ({ onSubmit, selectCode, selectedUnitCode }: Props): JSX.Element => {
  const { setUserResponse, userResponse } = useAddtitionalInformation();
  const {
    onTimeLinkClick,
    onDateLinkClick,
    onNextClick,
    backToDateStep,
    isDateStep,
    onPressBack,
    onPressSave,
    isModalWithoutSlideAnimation,
    reverseHorizontalAnimation,
    toggleModal,
    onDateClick,
  } = useDateScheduleModalNavigation();
  const isBackButtonVisible = useBackButtonVisibility();
  const getResourceStatus = useSelector(selectGetResourceStatus);
  const { ownerType, ownerUuid } = useOwnerParams();
  const { resourceUuid } = useParams();
  const dispatch = useDispatch();
  const { closeQuickCheckoutHandler, openQuickCheckoutHandler } = useQuickCheckout();
  const { reverseAnimation } = usePaymentModal(openQuickCheckoutHandler);
  const { resource } = useSelector(resourceState);
  const { onCloseCheckout, openCheckout } = useCheckoutScreen();
  const {
    isCancelBookingStep,
    setIsCancelBookingStep,
    onCloseReceipt,
    onPressBack: onPressBackReceipt,
    openReceipt,
  } = useReceiptScreen();

  useHqoTracking({
    isLoading: !resourceUuid || getResourceStatus !== ACTION_STATUSES.FULFILLED,
    trackEventName: TRACK_EVENT_NAMES.ADDITIONAL_INFO_IMPRESSION,
    metadata: {
      type: TRACK_EVENT_TYPES.IMPRESSION,
      resource: getResourceTrackInfo(resource),
    },
    trackConfig: { sendToHqoTracking: true },
  });

  useEffect(() => {
    if (getResourceStatus !== ACTION_STATUSES.FULFILLED && resourceUuid) {
      dispatch(getResource.request({ ownerUuid, ownerType, resourceUuid }));
    }
  }, [dispatch, ownerType, resourceUuid, getResourceStatus]);

  return (
    <Container>
      {isBackButtonVisible && (
        <BackLinkWrapper>
          <ArrowBackLink onClick={goBack} />
        </BackLinkWrapper>
      )}
      <AdditionalInformationPage
        userResponse={userResponse}
        setUserResponse={setUserResponse}
        onBooking={onSubmit}
        onDateLinkClick={onDateClick}
        onTimeLinkClick={onTimeLinkClick}
        selectCode={selectCode}
        selectedUnitCode={selectedUnitCode}
      />
      <PaymentContent
        openReceipt={openReceipt}
        reverseAnimation={reverseAnimation}
        closeQuickCheckoutHandler={closeQuickCheckoutHandler}
        isCancelBookingStep={isCancelBookingStep}
        setIsCancelBookingStep={setIsCancelBookingStep}
        onCloseReceipt={onCloseReceipt}
        onPressBack={onPressBackReceipt}
        onCloseCheckout={onCloseCheckout}
        openCheckout={openCheckout}
      />
      <Routes>
        <Route
          path="date-schedule-select"
          element={
            <DateTimeSelectModal
              reverseHorizontalAnimation={reverseHorizontalAnimation}
              isModalWithoutSlideAnimation={isModalWithoutSlideAnimation}
              onPressSave={onPressSave}
              onClose={toggleModal}
              isDateStep={isDateStep}
              onNextClick={onNextClick}
              onDateLinkClick={onDateLinkClick}
              onTimeLinkClick={onTimeLinkClick}
              onPressBack={onPressBack}
              backToDateStep={backToDateStep}
            />
          }
        />
        <Route path="payment" element={<PaymentIFrame reverseAnimation={reverseAnimation} />} />
        <Route path="swipe-payment" element={<SwipePaymentIFrame />} />
        <Route path="quick-checkout/terms-and-conditions" element={<ModalContainer />} />
      </Routes>
    </Container>
  );
};
