import React, { useEffect } from 'react';
import { ModalPortal } from 'components/modals/modal-portal/modal-portal';
import { useIntl } from 'react-intl';
import { BuildingGroupItem, FeatureValue, Floor, FloorItem } from 'store/spaceManager/types';
import {
  Content,
  HeaderContainer,
  StyledModal,
  Container,
  FooterContainer,
  Title,
} from 'components/floor-select/desktop/styles';
import { ActionButton } from 'components/floor-plan-popup/styles';
import { FloorSelectModalDesktopContent } from 'components/floor-select/desktop/floor-select-desktop.modal.content';
import { BuildingGroupSelectModalDesktopContent } from 'components/building-group-select/desktop/building-group-select-desktop.modal.content';

interface FloorSelectModalContentProps {
  pickedFloor: Floor;
  options: FloorItem[];
  onFloorSelect: React.Dispatch<React.SetStateAction<string>>;
  onSaveClick: VoidFunction;
  onClose: VoidFunction;
  pickedBuildingGroup: FeatureValue | null;
  buildingGroupOptions: BuildingGroupItem[];
  onWheelChangeBuildingGroup: React.Dispatch<React.SetStateAction<string>>;
}

export const FloorSelectModalDesktop = ({
  pickedFloor,
  options,
  onFloorSelect,
  onSaveClick,
  onClose,
  pickedBuildingGroup,
  buildingGroupOptions,
  onWheelChangeBuildingGroup,
}: FloorSelectModalContentProps): JSX.Element => {
  const intl = useIntl();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);
  return (
    <ModalPortal>
      <StyledModal
        opened
        onClose={onClose}
        dataTestId="modal-window"
        data-cy="modal-window"
        data-testid="floor-picker-desktop-close"
      >
        <Container className="floor-picker-desktop" data-testid="floor-picker-desktop">
          <HeaderContainer>
            <Title>{intl.formatMessage({ id: 'building_and_floor' })}</Title>
          </HeaderContainer>
          <Content>
            {buildingGroupOptions?.length > 1 && (
              <BuildingGroupSelectModalDesktopContent
                pickedBuildingGroup={pickedBuildingGroup}
                buildingGroupOptions={buildingGroupOptions}
                onWheelChangeBuildingGroup={onWheelChangeBuildingGroup}
              />
            )}
            <FloorSelectModalDesktopContent pickedFloor={pickedFloor} options={options} onFloorSelect={onFloorSelect} />
          </Content>
          <FooterContainer>
            <ActionButton
              variant="primary"
              text={intl.formatMessage({ id: 'save' })}
              size="xxs"
              onClick={onSaveClick}
            />
          </FooterContainer>
        </Container>
      </StyledModal>
    </ModalPortal>
  );
};
