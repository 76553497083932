import React from 'react';
import { FlexContainer, SkeletonBlock } from '../styles';
import { AmenityBlock } from './amenity-block';

export const ResourceAmenities = (): JSX.Element => {
  return (
    <FlexContainer gap="23" isColumnDirection top="10" left={16}>
      <SkeletonBlock width="171" />
      <FlexContainer gap="117">
        <FlexContainer gap="23" isColumnDirection>
          <AmenityBlock />
          <AmenityBlock />
        </FlexContainer>
        <FlexContainer gap="23" isColumnDirection>
          <AmenityBlock />
          <AmenityBlock />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
