import { IconBlockContainer } from '../styles';
import { useIntl } from 'react-intl';
import { IconBlock } from '@hqo/react-components-library/dist/molecules/iconBlock';
import React, { useMemo } from 'react';
import { useCurrentConfig } from 'hooks/use-current-config.hook';
import { useSelector } from 'react-redux';
import { selectResource } from 'store/resource/selectors';

export const ResourceAddress = (): JSX.Element => {
  const intl = useIntl();
  const resource = useSelector(selectResource);
  const currentAppInstanceConfig = useCurrentConfig();
  const formattedAddress = useMemo<string | null>(
    () => resource?.address?.formatted_address ?? null,
    [currentAppInstanceConfig, resource],
  );

  if (!formattedAddress) {
    return null;
  }

  return (
    <IconBlockContainer data-testid="resource-address-container">
      <IconBlock
        icon="map-marker-alt"
        iconType="fal"
        title={formattedAddress}
        linkRef={`https://www.google.com/maps?q=${encodeURI(formattedAddress)}`}
        subtitle={resource.directions}
        linkText={intl.formatMessage({ id: 'open_in_maps' })}
        shouldOpenInNewTab={!/mobi/i.test(navigator.userAgent)}
      />
    </IconBlockContainer>
  );
};
