import { useLocale } from 'hooks/use-locale.hook';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PATCH_STATUSES } from 'shared/consts';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { selectCart } from 'store/cart/selectors';
import { patchTransaction } from 'store/transactions/actions';
import { selectPatchTransactionsStatus, selectTypeOfPatchTransaction } from 'store/transactions/selectors';
import { formatDate } from 'utils/formatDate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { TransactionDetails, PatchTransaction } from 'store/transactions/types';

export interface UseCancelBookingReturnValues {
  onBack: VoidFunction;
  onCancelButtonClick: VoidFunction;
  formattedDate: { [arg: string]: string };
  vendorName: string;
  showMiniappPaymentsNavigation: boolean;
  isMobileDevice: boolean;
  patchTransactionData: Pick<PatchTransaction, 'status' | 'type'>;
}

interface UseCancelBookingProps {
  transaction?: TransactionDetails;
  setIsCancelBookingStep?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useCancelBooking = ({
  transaction,
  setIsCancelBookingStep,
}: UseCancelBookingProps): UseCancelBookingReturnValues => {
  const locale = useLocale();
  const dispatch = useDispatch();
  const { showMiniappPaymentsNavigation } = useFlags();
  const isMobileDevice = useIsSmallViewportWidth();
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const cart = useSelector(selectCart);
  const formattedDate = formatDate(
    cart ? cart?.items[0].resource_booking?.start_at : transaction?.details.start_at,
    locale as string,
    buildingTimeZone,
  );
  const patchTransactionStatus = useSelector(selectPatchTransactionsStatus);
  const typeOfPatchTransaction = useSelector(selectTypeOfPatchTransaction);
  const patchTransactionData = {
    status: patchTransactionStatus,
    type: typeOfPatchTransaction,
  };
  const vendorName = cart ? cart?.items[0].display_info.title : transaction?.details.name;

  const onCancelButtonClick = useCallback(() => {
    dispatch(
      patchTransaction.request({
        transactionId: cart ? cart?.transaction_id?.toString() : transaction?.id.toString(),
        status: PATCH_STATUSES.CANCELLED,
      }),
    );
    setIsCancelBookingStep(false);
  }, [dispatch, cart, transaction?.id, setIsCancelBookingStep]);

  const onBack = useCallback(() => {
    setIsCancelBookingStep(false);
  }, [setIsCancelBookingStep]);

  return {
    onBack,
    onCancelButtonClick,
    formattedDate,
    vendorName,
    showMiniappPaymentsNavigation,
    isMobileDevice,
    patchTransactionData,
  };
};
