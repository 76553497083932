import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library';
import { HANDLE_WIDTH } from 'shared/consts/timeline';

export const HandleContainer = styled.div`
  height: 100%;
  width: ${HANDLE_WIDTH}px;
  cursor: ew-resize;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 30px;
    margin: 0 -8px;
  }
`;

export const Handle = styled.div`
  width: 4px;
  height: 24px;
  background: ${({ theme }) => theme.colors.universal.white};
  border-radius: 2px;
`;
