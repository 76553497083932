import { Button } from '@hqo/react-components-library';
import { FilterPicker } from 'pages/resources/components/filter-picker';
import styled from 'styled-components';

export const StyledFilterPicker = styled(FilterPicker)`
  display: block;
  margin: 0 8px;

  .filter-search-bar-container {
    gap: 18px;
  }

  .filter-icon {
    display: none;
  }

  .touch-search-bar-container {
    background: ${({ theme }) => theme.colors.greys.adminGreyMedium};
  }

  .touch-search-bar-wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
  }
`;

export const FloorPlanDrawerContentContainer = styled.div``;

export const FloorPlanButton = styled(Button)`
  margin: 0 auto;
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translateX(-50%);
`;
