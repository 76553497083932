import { TimeRange } from 'store/resource-time-ranges/types';

export interface ResourceAPIResponse {
  data: ResourceResponse;
}

interface ResourceResponse {
  resource: Resource;
}

export interface ResourceState {
  resource: Resource;
  getResource: AsyncActionTypes;
  imageIsLoaded?: boolean;
  initialQueryString?: string;
}

export type FormResponseType = 'Text' | 'Number' | 'Yes/No';

export enum BookingWindowType {
  AVAILABLE = 'Available',
  UNAVAILABLE = 'Unavailable',
}

export interface BookingWindow {
  id: number;
  uuid: string;
  resource_id: number;
  rrule: string;
  buffer_before?: number;
  buffer_after?: number;
  buffer_between?: number;
  bookable_interval_close?: number;
  bookable_interval_open?: number;
  minimum_duration: number;
  maximum_duration: number;
  time_interval?: number;
  type: BookingWindowType;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
  pricing_rules?: Array<PricingRule>;
  cancellation_rules?: Array<CancellationRule>;
}

interface PricingRule {
  id: number;
  uuid: string;
  booking_window_id: number;
  name: string;
  min_interval_price: number;
  min_interval_duration_minutes: number;
  active_at: Date;
  expires_at: Date;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

interface CancellationRule {
  id: number;
  uuid: string;
  booking_window_id: number;
  name?: string;
  description?: string;
  description_uuid?: string;
  cancellation_window_end_minutes: number;
  created_at: Date;
  updated_at: Date;
  deleted_at?: Date;
}

interface Address {
  formatted_address?: string;
}

export enum CTATypeEnum {
  URL = 'URL',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum DefaultTypeEnum {
  TV = 'TV',
  WIFI = 'WIFI',
  PHONE = 'PHONE',
}

export interface Feature {
  id: number;
  uuid: string;
  resource_id: number;
  default_type: DefaultTypeEnum;
  name: string;
  name_uuid?: string;
  description?: string;
  description_uuid?: string;
  icon?: string;
  cta_type?: CTATypeEnum;
  cta_value?: string;
  active?: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export interface Resource {
  id: number;
  uuid: string;
  has_other_amenity?: boolean;
  address?: Address;
  timezone: string;
  booking_windows: Array<BookingWindow>;
  created_at: Date | string;
  updated_at: Date | string;
  deleted_at?: Date;
  name: string;
  subtitle?: string;
  description?: string;
  type?: string;
  directions?: string;
  image_url?: string;
  capacity: string | number;
  manager_email: string;
  wifi?: boolean;
  tv_projector?: boolean;
  phone?: boolean;
  other_amenity?: string;
  other_amenity_icon?: string;
  other_amenity_url?: string;
  user_prompts?: Array<UserPrompt>;
  features?: Array<Feature>;
  available_now?: AvailableNow;
  minimum_price: number;
  add_ons?: Array<AddOn>;
  room_layouts?: Array<RoomLayout>;
  terms_and_conditions?: string;
  preset_booking_windows: string;
  show_free?: boolean;
  supports_multi_day_bookings?: boolean;
  enable_capacity_booking?: boolean;
  allowed_visitors?: number;
  closest_availability?: ClosestAvailability;
}

export interface ClosestAvailability {
  start_at?: string;
  end_at?: string;
  closest_day_time_ranges: Array<TimeRange>;
}

export interface AvailableNow {
  available_minutes: number;
  start_at: string;
  end_at: string;
}

export interface UserPrompt {
  id: number;
  uuid: string;
  resource_id: number;
  response_type: FormResponseType;
  prompt?: string;
  prompt_uuid?: string;
  is_required: boolean;
  created_at: Date | string;
  updated_at: Date | string;
  deleted_at?: Date | string;
}

export interface AddOn {
  id: string;
  name: string;
  price: number;
  description?: string;
  image_url?: string;
  request_quantity: boolean;
  required: boolean;
}

export interface RoomLayout {
  id: string;
  name: string;
  description?: string;
  image_url?: string;
}

export interface RoomLayoutState {
  room_layout: RoomLayout;
}
