export const addAttributesToLinks = (htmlString: string, attributes: Record<string, string>): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const linkElements = doc.querySelectorAll('a');

  linkElements.forEach(linkElement => {
    Object.entries(attributes).forEach(([name, value]) => linkElement.setAttribute(name, value));

    const url = new URL(linkElement.href);
    url.searchParams.set('hqoExternalLink', 'true');
    const newHref = url.toString();
    linkElement.setAttribute('href', newHref);
  });

  return doc.documentElement.outerHTML;
};
