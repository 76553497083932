import { ACTION_STATUSES, BOOKING_STATUSES, PATCH_STATUSES } from 'shared/consts';
import { PatchTransaction } from 'store/transactions/types';

export const getReceiptTitle = (bookingStatus: string, { status, type }: Pick<PatchTransaction, 'status' | 'type'>) => {
  if (status === ACTION_STATUSES.FULFILLED && type === PATCH_STATUSES.CANCELLED) {
    return 'resource_cancelled';
  }
  if (bookingStatus === BOOKING_STATUSES.ACCEPTED) {
    return 'booking_confirmed';
  }
  if (bookingStatus === BOOKING_STATUSES.PENDING) {
    return 'resource_requested';
  }

  return 'default_receipt';
};
