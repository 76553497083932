import { TimeRange } from 'store/resource-time-ranges/types';
import { DEFAULT_TIME_INTERVAL, ONE_HOUR } from 'components/schedule-select/const';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';

interface ClosestHourAvailability {
  startTime: string;
  endTime: string;
}

export const findClosestHourAvailability = (availableTimeSlots: Array<TimeRange>): ClosestHourAvailability | null => {
  if (!availableTimeSlots?.length) return null;
  const firstTimeSlot = availableTimeSlots[0];
  const minDuration = firstTimeSlot.minimum_duration;
  const maxDuration =
    firstTimeSlot.maximum_duration && firstTimeSlot.maximum_duration < ONE_HOUR
      ? firstTimeSlot.maximum_duration
      : ONE_HOUR;

  let currentDuration = 0;
  const timeSlots = [];

  for (let index = 0; index < availableTimeSlots.length - 1; index++) {
    const startTime = availableTimeSlots[index].time;
    const endTime = availableTimeSlots[index + 1].time;

    if (bookingTimeHandler({ startTime, endTime }).getTimeDiff() === DEFAULT_TIME_INTERVAL) {
      timeSlots.push(availableTimeSlots[index], availableTimeSlots[index + 1]);
      currentDuration += DEFAULT_TIME_INTERVAL;
    }

    if (
      timeSlots.length &&
      bookingTimeHandler({ startTime, endTime }).getTimeDiff() !== DEFAULT_TIME_INTERVAL &&
      currentDuration < minDuration
    ) {
      currentDuration = 0;
      timeSlots.length = 0;
    }

    if (
      (timeSlots.length && bookingTimeHandler({ startTime, endTime }).getTimeDiff() !== DEFAULT_TIME_INTERVAL) ||
      currentDuration === maxDuration
    ) {
      break;
    }
  }

  const filteredTimeSlots = [...new Set(timeSlots)];
  const startTime = filteredTimeSlots[0]?.time;
  const endTime = filteredTimeSlots[filteredTimeSlots.length - 1]?.time;

  return filteredTimeSlots.length ? { startTime, endTime } : null;
};
