import { Duration } from 'shared/consts/duration';
import { formatTimeOptions } from 'utils/bookingTimeHandler/types';

const MS_PER_HOUR = 60_000;

interface TimeHandlerInterface {
  getDisabledDurations: (defaultDurations: Array<Duration>) => Array<Duration>;
}

export class BookingTimeHandler implements TimeHandlerInterface {
  constructor(private timeRange: { startTime?: string; endTime?: string; locale?: string }) {}

  getTimeDiff(): number {
    const [startHour, startMinutes] = this.getResolvedTimeOfDay(this.timeRange.startTime).split(':');
    const [endHour, endMinutes] = this.getResolvedTimeOfDay(this.timeRange.endTime).split(':');

    const start = new Date().setHours(+startHour, +startMinutes);
    const end = new Date().setHours(+endHour, +endMinutes);

    return Math.floor((end - start) / MS_PER_HOUR);
  }

  private get getTimeDiff24h(): number {
    const [startHour, startMinutes] = this.timeRange.startTime.split(':');
    const [endHour, endMinutes] = this.timeRange.endTime.split(':');

    const start = new Date().setHours(+startHour, +startMinutes);
    const end = new Date().setHours(+endHour, +endMinutes);

    return Math.floor((end - start) / MS_PER_HOUR);
  }

  private getResolvedTimeOfDay(time: string): string {
    const [hour, minutes] = time?.split(':');

    if (+hour === 24 && +minutes > 0) {
      const resolvedTime = `00:${minutes}`;

      return resolvedTime;
    }

    return time;
  }

  getFormatTime(time: string, locale: string, options?: formatTimeOptions): string {
    const dateNow = new Date();
    const [hours, minutes] = time.split(':');
    dateNow.setHours(+hours, +minutes);

    if (options?.removeOClockMinute && minutes === '00' && options?.toUpperCase) {
      return dateNow.toLocaleTimeString(locale, { hour: 'numeric' }).toLowerCase().replace(' h', '').toUpperCase();
    } else if (options?.removeOClockMinute && minutes === '00') {
      return dateNow.toLocaleTimeString(locale, { hour: 'numeric' }).toLowerCase().replace(' h', '');
    }

    if (options?.toUpperCase) {
      return dateNow.toLocaleTimeString(locale, { timeStyle: 'short' }).toUpperCase();
    }

    return dateNow.toLocaleTimeString(locale, { timeStyle: 'short' }).toLowerCase();
  }

  getDisabledDurations(defaultDurations: Array<Duration>, isBasedOnStartTime?: boolean): Array<Duration> {
    const timeDiff = isBasedOnStartTime ? this.getTimeDiff24h : this.getTimeDiff();
    const closestDurationTotimeDiff = defaultDurations.reduce((prev, curr) => {
      return Math.abs(curr.value - timeDiff) < Math.abs(prev.value - timeDiff) ? curr : prev;
    });
    const lastDuration = defaultDurations[defaultDurations.length - 1];

    const index = defaultDurations.indexOf(closestDurationTotimeDiff);

    if (closestDurationTotimeDiff.value > timeDiff) {
      return defaultDurations.slice(-(defaultDurations.length - index));
    }

    if (lastDuration.value <= timeDiff) {
      return [];
    }

    return defaultDurations.slice(-(defaultDurations.length - index - 1));
  }

  getDateRangeString(startDate: string): string {
    const { locale } = this.timeRange;
    if (startDate) {
      return new Date(startDate.replace(/-/g, '/')).toLocaleDateString(locale, {
        month: 'short',
        day: 'numeric',
      });
    }

    return '';
  }

  getDatesRangeString = (datesInput: Array<string> | string): string => {
    if (!Array.isArray(datesInput)) {
      return this.getDateRangeString(datesInput);
    }
    const MILLISECONDS_IN_DAY = 86_400_000;
    const dates = datesInput.map(dateString => new Date(dateString).getTime());

    dates.sort((a, b) => a - b);

    const [firstGroupDate] = dates;
    let outputArray: Array<Array<number>> = [];
    let currentGroup = [firstGroupDate];

    for (let dateIterator = 1; dateIterator < dates.length; dateIterator++) {
      const diffInDays = (dates[dateIterator] - dates[dateIterator - 1]) / MILLISECONDS_IN_DAY;
      if (diffInDays === 1) {
        currentGroup.push(dates[dateIterator]);
      } else {
        outputArray.push(currentGroup);
        currentGroup = [dates[dateIterator]];
      }
    }

    outputArray.push(currentGroup);

    return this.formatDatesRangesString(outputArray);
  };

  /**
   * Converts the datesArray param into a string of formatted dates with respect to locale
   * @param datesArray - An ordered array of arrays of timestamps
   * @returns a string of formatted dates and/or date ranges separated by a comma
   *          example for en-US locale: Jan 1 - 5, Jan 30 - Feb 2, Feb 5
   *          example for en-GB locale: 1 - 5 Jan, 30 Jan - 2 Feb, 5 Feb
   */
  private formatDatesRangesString = (datesArray: Array<Array<number>>): string => {
    const { locale } = this.timeRange;
    const monthAndDayOptions: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC',
    };
    const monthOptions: Intl.DateTimeFormatOptions = {
      month: 'short',
      timeZone: 'UTC',
    };

    return datesArray.reduce((formattedDateStrings, currentDates, index) => {
      const [firstElementInGroup] = currentDates;
      const lastElementInGroup = currentDates[currentDates.length - 1];
      const isSingleDay = currentDates.length === 1;
      const formattedStartDateWithMonth = new Date(firstElementInGroup).toLocaleString(locale, monthAndDayOptions);
      const formattedEndDateWithMonth = new Date(lastElementInGroup).toLocaleString(locale, monthAndDayOptions);
      const startDay = new Date(firstElementInGroup).getUTCDate();
      const endDay = new Date(lastElementInGroup).getUTCDate();

      // determines if the current locale formats dates with the month or the day first
      const isDayFirst = formattedStartDateWithMonth.split(' ').indexOf(startDay.toString()) === 0;

      const startMonth = new Date(firstElementInGroup).toLocaleString(locale, monthOptions);
      const endMonth = new Date(lastElementInGroup).toLocaleString(locale, monthOptions);

      // determines if a comma is needed based on if the iterator is on the first value
      const separator = index === 0 ? '' : ', ';

      // returns string of date range if the start/end months in the range are different
      if (startMonth !== endMonth && !isSingleDay) {
        return (formattedDateStrings += `${separator}${formattedStartDateWithMonth} - ${formattedEndDateWithMonth}`);
      }

      if (isSingleDay) {
        return (formattedDateStrings += `${separator}${formattedStartDateWithMonth}`);
      }
      const formattedDateRange = isDayFirst
        ? `${separator}${startDay} - ${formattedEndDateWithMonth}`
        : `${separator}${formattedStartDateWithMonth} - ${endDay}`;

      return (formattedDateStrings += formattedDateRange);
    }, '');
  };

  getTimeRangeString(): string {
    const { startTime, endTime, locale } = this.timeRange;
    if (startTime && endTime) {
      let formattedTime = `${this.getFormatTime(startTime, locale, {
        removeOClockMinute: true,
      })} - ${this.getFormatTime(endTime, locale, { removeOClockMinute: true })}`;

      if (startTime < '12:00' && endTime < '12:00') {
        formattedTime = formattedTime.replace(' am', '');
      }
      if (startTime >= '12:00' && endTime >= '12:00' && endTime < '24:00') {
        formattedTime = formattedTime.replace(' pm', '');
      }
      return formattedTime;
    }
    if (startTime && !endTime) {
      return `${this.getFormatTime(startTime, locale, { removeOClockMinute: true })}`;
    }
    if (!startTime && endTime) {
      return `${this.getFormatTime(endTime, locale, { removeOClockMinute: true })}`;
    }

    return '';
  }

  getFormatDate = (date: Date, format: string): string => {
    if (!date) {
      return '';
    }
    const { locale } = this.timeRange;
    const mm = date.toLocaleDateString(locale, { month: '2-digit' });
    const dd = date.toLocaleDateString(locale, { day: '2-digit' });
    const yy = date.getFullYear();
    if (format === 'YYYY-MM-DD') {
      return `${yy}-${mm}-${dd}`;
    }
    if (format === 'MMMM YYYY') {
      return date.toLocaleString(locale, { month: 'long', year: 'numeric' });
    }

    return '';
  };
}
