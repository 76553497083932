import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import { selectAllAddOns } from 'store/add-ons/selectors';
import { AddOn } from 'store/resource/types';
import { DEFAULT_ITEM_QUANTITY } from 'shared/consts';
import { addAddOn, removeAddOn } from 'store/add-ons/actions';

interface UseAddOnModalReturnValues {
  selectedAddOn: AddOn;
  isModalVisible: boolean;
  onModalClose: VoidFunction;
  onAddOnClick: (id: string) => void;
}

export const useAddOnModal = (addOns: AddOn[]): UseAddOnModalReturnValues => {
  const dispatch = useDispatch();
  const selectedAddOns = useSelector(selectAllAddOns);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedAddOn, setSelectedAddOn] = useState<AddOn>(null);

  const onModalClose = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const onAddOnClick = useCallback(
    (id: string) => {
      const currentAddOn = addOns?.find(addOn => addOn.id === id);
      const currentSelectedAddOn = selectedAddOns?.find(addOn => addOn.id === id);
      if (currentAddOn?.required) {
        return;
      }
      if (currentAddOn?.request_quantity) {
        setSelectedAddOn(currentAddOn);
        setIsModalVisible(true);
      } else if (currentSelectedAddOn) {
        dispatch(removeAddOn(id));
      } else {
        dispatch(
          addAddOn({
            id: currentAddOn?.id,
            name: currentAddOn?.name,
            price: currentAddOn?.price,
            description: currentAddOn?.description,
            image_url: currentAddOn?.image_url,
            quantity: DEFAULT_ITEM_QUANTITY,
          }),
        );
      }
    },
    [addOns, dispatch, selectedAddOns],
  );

  return {
    isModalVisible,
    selectedAddOn,
    onModalClose,
    onAddOnClick,
  };
};
