import appInstanceConfigsReducer from './app-instance-configs/reducer';
import floorplanAppInstanceConfigReducer from './floorplan-app-instance-config/reducer';
import floorplanResourcesReducer from './floorplan-resources/reducer';
import bookingsReducer from 'store/bookings/reducer';
import buildingReducer from 'store/building/reducer';
import cartReducer from 'store/cart/reducer';
import configReducer from 'store/config/reducer';
import errorsReducer from 'store/errors/reducer';
import featureFlagsReducer from 'store/featureFlags/reducer';
import priceReducer from './price/reducer';
import resourceAvailableDatesReducer from './resource-available-dates/reducer';
import resourceReducer from 'store/resource/reducer';
import resourceTimeRangesReducer from './resource-time-ranges/reducer';
import resourcesReducer from 'store/resources/reducer';
import themeReducer from 'store/theme/reducer';
import transactionsReducer from 'store/transactions/reducer';
import userReducer from 'store/user/reducer';
import uiMetadataReducer from 'store/ui-metadata/reducer';
import floorReducer from 'store/spaceManager/reducer';
import spreedlyReducer from 'store/add-card/reducer';
import paymentReducer from 'store/payment/reducer';
import roomLayoutReducer from 'store/room-layout/reducer';
import addOnsReducer from 'store/add-ons/reducer';
import headerReducer from 'store/header-state/reducer';
import { combineReducers } from '@reduxjs/toolkit';
import unitCodesReducer from 'store/unit-codes/reducers';

const rootReducer = combineReducers({
  config: configReducer,
  user: userReducer,
  resource: resourceReducer,
  resources: resourcesReducer,
  transactions: transactionsReducer,
  bookings: bookingsReducer,
  theme: themeReducer,
  cart: cartReducer,
  errors: errorsReducer,
  building: buildingReducer,
  price: priceReducer,
  featureFlags: featureFlagsReducer,
  appInstanceConfigs: appInstanceConfigsReducer,
  floorplanAppInstanceConfig: floorplanAppInstanceConfigReducer,
  floorplanResources: floorplanResourcesReducer,
  resourceAvailableDates: resourceAvailableDatesReducer,
  resourceTimeRanges: resourceTimeRangesReducer,
  floor: floorReducer,
  uiMetaData: uiMetadataReducer,
  spreedly: spreedlyReducer,
  payment: paymentReducer,
  roomLayout: roomLayoutReducer,
  addOns: addOnsReducer,
  headerState: headerReducer,
  unitCodes: unitCodesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
