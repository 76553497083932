import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';

import { InteractionEvent } from 'floorplan/pages/floorplan/types';

import { BuildingGroupSelectModalContent } from 'components/building-group-select/mobile/building-group-select.modal.content';
import { FloorPickerHeader } from 'components/floor-picker-header';
import { FloorSelectModalContent } from 'components/floor-select/mobile/floor-select.modal.content';
import { FloorSelectModalDesktop } from './desktop/floor-select-desktop.modal';
import { SelectLocationModalFooter } from 'components/select-location-modal/select-location-modal-footer';
import { useFloorSelectModal } from 'floorplan/pages/floorplan/hooks/use-floor-select-modal.hook';

interface FloorSelectionProps {
  floorName: string;
  buildingName: string;
  isMobileDevice: boolean;
  setClickedResource: React.Dispatch<React.SetStateAction<InteractionEvent>>;
}

export const FloorSelection = ({
  floorName,
  buildingName,
  isMobileDevice,
  setClickedResource,
}: FloorSelectionProps): JSX.Element => {
  const intl = useIntl();

  const {
    floors,
    pickedFloor,
    onWheelChange,
    isFloorPickerWheelShown,
    toggleFloorPickerWheel,
    buildingGroups,
    pickedBuildingGroup,
    onWheelChangeBuildingGroup,
    isBuildingGroupPickerWheelShown,
    toggleBuildingGroupPickerWheel,
    canOpenModal,
    onFloorPickerClick,
    onSaveClick,
    handleOpenFloorPickerModal,
    onModalClose,
  } = useFloorSelectModal(setClickedResource);

  return (
    <>
      <FloorPickerHeader
        floorName={floorName}
        buildingName={buildingName}
        onFloorPickerClick={onFloorPickerClick}
        canOpenModal={canOpenModal}
        handleOpenFloorPickerModal={handleOpenFloorPickerModal}
      />
      <Routes>
        <Route
          path="select-floor-modal"
          element={
            isMobileDevice ? (
              <SwipeModal
                data-testid="select-floor-modal"
                reverseAnimation={false}
                title={intl.formatMessage({ id: 'building_and_floor' })}
                className="select-floor-modal"
                onClose={onModalClose}
                content={
                  <>
                    {buildingGroups?.length > 1 && (
                      <BuildingGroupSelectModalContent
                        data-testid="building-group"
                        pickedBuildingGroup={pickedBuildingGroup}
                        options={buildingGroups}
                        onWheelChange={onWheelChangeBuildingGroup}
                        isBuildingGroupPickerWheelShown={isBuildingGroupPickerWheelShown}
                        toggleBuildingGroupPickerWheel={toggleBuildingGroupPickerWheel}
                      />
                    )}
                    <FloorSelectModalContent
                      pickedFloor={pickedFloor}
                      options={floors}
                      onWheelChange={onWheelChange}
                      isFloorPickerWheelShown={isFloorPickerWheelShown}
                      toggleFloorPickerWheel={toggleFloorPickerWheel}
                    />
                  </>
                }
                footerContent={<SelectLocationModalFooter handleClick={onSaveClick} />}
              />
            ) : (
              <FloorSelectModalDesktop
                pickedFloor={pickedFloor}
                options={floors}
                onClose={onModalClose}
                onFloorSelect={onWheelChange}
                onSaveClick={onSaveClick}
                pickedBuildingGroup={pickedBuildingGroup}
                buildingGroupOptions={buildingGroups}
                onWheelChangeBuildingGroup={onWheelChangeBuildingGroup}
              />
            )
          }
        />
      </Routes>
    </>
  );
};
