import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 30px;
  width: 940px;
  flex-grow: 1;
  overflow: hidden;
`;

export const Header = styled.div``;

export const BackLinkWrapper = styled.div`
  margin-bottom: 34px;
`;

export const Content = styled.div`
  max-width: 650px;
  flex-grow: 1;
  gap: 48px;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;
