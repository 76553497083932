import styled from 'styled-components';
import { DIMENSIONS, Button } from '@hqo/react-components-library';

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 265px;
  border-radius: 8px 8px 0 0;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 350px;
  }
`;

export const ModalTitle = styled.div`
  text-align: center;
  font: 600 28px/33px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminBlack};
`;

export const Text = styled.div`
  font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-inline-box;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    text-align: center;
  }
`;

export const TextContainer = styled.div`
  padding: 32px 32px 0 32px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    align-items: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  flex-direction: row;
  padding: 0 32px;
  margin-bottom: 32px;
  gap: 10px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
  }
`;

export const AgreeButton = styled(Button)`
  width: 83px;
  height: 32px;
  background: ${({ theme }) => theme.colors.admin.blue};
  padding: 0;
  font-size: 16px;
  font-weight: 500;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 48px;
  }
`;

export const DeclineButton = styled(Button)`
  width: 83px;
  height: 32px;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  padding: 0;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyDark};
  }
`;

export const LinkText = styled.strong`
  font: 500 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$primary};
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
`;

export const LocationNameText = styled.strong`
  font: 400 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;
