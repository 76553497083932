import { SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library/dist/device';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import styled from 'styled-components';

export const DATE_TIME_INPUT_GAP_WIDTH = '12px';

export const DateSelectTimelineContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    align-items: center;
  }
`;

export const DateTimeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    align-items: center;
  }
`;

export const StyledDateInputWrapper = styled.div`
  display: flex;
  width: 150px;

  .date-picker-select {
    height: 40px;
  }

  @media only screen and (max-width: ${DIMENSIONS.SMALL}) {
    width: 40%;
  }
`;

export const StyledDateTimeWrapper = styled.div`
  display: flex;
  width: 60%;
  gap: ${DATE_TIME_INPUT_GAP_WIDTH};

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100%;
  }
`;
