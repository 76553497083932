import React, { useEffect, useMemo } from 'react';
import { QuickCheckout } from 'components/payment/quick-checkout/quick-checkout';
import { ReceiptBooking } from 'components/receipt-booking';
import { Route, Routes } from 'react-router-dom';
import { useMiniappSdk } from '../miniapp-sdk-provider/miniapp-sdk.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { Receipt } from '../payment/receipt';
import { Checkout } from '../payment/checkout';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { selectPatchTransactionsStatus } from 'store/transactions/selectors';
import { ACTION_STATUSES } from 'shared/consts';

interface PaymentContentProps {
  bookingId?: number | string;
  reverseAnimation: boolean;
  closeQuickCheckoutHandler: VoidFunction;
  isCancelBookingStep: boolean;
  setIsCancelBookingStep: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseReceipt: VoidFunction;
  onPressBack: VoidFunction;
  onCloseCheckout: VoidFunction;
  openCheckout: VoidFunction;
  openReceipt: VoidFunction;
  overflow?: string;
  showNativeMobileHeader?: boolean;
}

export const PaymentContent = ({
  bookingId,
  reverseAnimation,
  closeQuickCheckoutHandler,
  isCancelBookingStep,
  setIsCancelBookingStep,
  onPressBack,
  onCloseReceipt,
  openCheckout,
  onCloseCheckout,
  openReceipt,
  overflow,
  showNativeMobileHeader = true,
}: PaymentContentProps): JSX.Element => {
  const isMobileDevice = useIsSmallViewportWidth();
  const client = useMiniappSdk();
  const { pathname } = useLocation();
  const patchTransactionsStatus = useSelector(selectPatchTransactionsStatus);
  const hideHeader = useMemo<boolean>(
    () => (pathname.includes('checkout') || pathname.includes('receipt')) && isMobileDevice,
    [pathname, isMobileDevice],
  );

  useEffect(() => {
    if (hideHeader) {
      client?.header.hideHeader();
      client?.swipe.disableBackSwipe();
    }

    return () => {
      if (patchTransactionsStatus !== ACTION_STATUSES.PENDING && showNativeMobileHeader) {
        client?.header.showHeader();
        client?.swipe.enableBackSwipe();
      }
    };
  }, [client, isMobileDevice, hideHeader, patchTransactionsStatus]);

  return (
    <>
      <Routes>
        <Route
          path="quick-checkout"
          element={
            <QuickCheckout
              closeQuickCheckoutHandler={closeQuickCheckoutHandler}
              reverseAnimation={reverseAnimation}
              openCheckout={openCheckout}
              overflow={overflow}
            />
          }
        />
        <Route
          path="checkout"
          element={
            <ReceiptBooking
              content={<Checkout openReceipt={openReceipt} onCloseCheckout={onCloseCheckout} />}
              onClose={onCloseCheckout}
              withLeftArrowButton={isCancelBookingStep}
              onPressBack={onPressBack}
            />
          }
        />
        <Route
          path="receipt"
          element={
            <ReceiptBooking
              content={
                <Receipt
                  transactionId={bookingId}
                  onCloseReceipt={onCloseReceipt}
                  isCancelBookingStep={isCancelBookingStep}
                  setIsCancelBookingStep={setIsCancelBookingStep}
                />
              }
              onClose={onCloseReceipt}
              withLeftArrowButton={isCancelBookingStep}
              onPressBack={onPressBack}
            />
          }
        />
      </Routes>
    </>
  );
};
