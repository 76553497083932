import React, { useMemo } from 'react';

import { FloorplanListViewContainer } from './styles';
import { FloorPlanListViewResources } from './floor-plan-list-view-resources';
import { FloorPlanResourceInfo } from 'components/floor-plan-list-view/floor-plan-list.interface';

interface FloorPlanListViewParamsInterface {
  resourceListViewAvailability: FloorPlanResourceInfo[];
  toggleReverseAnimation?: VoidFunction;
  toggleModalSlideAnimation?: VoidFunction;
}

export const FloorPlanListView = ({
  resourceListViewAvailability,
  toggleReverseAnimation,
  toggleModalSlideAnimation,
}: FloorPlanListViewParamsInterface): JSX.Element => {
  const sortResources = (resources: FloorPlanResourceInfo[]): FloorPlanResourceInfo[] => {
    return resources.sort((resourceA, resourceB) => {
      return resourceA.resource.name.localeCompare(resourceB.resource.name);
    });
  };

  const sortedAvailableResources = useMemo<FloorPlanResourceInfo[]>(() => {
    const availableResource = resourceListViewAvailability.filter(info => info.isAvailable);
    return sortResources(availableResource);
  }, [resourceListViewAvailability]);

  const sortedUnavailableResources = useMemo<FloorPlanResourceInfo[]>(() => {
    const unavailableResource = resourceListViewAvailability.filter(info => !info.isAvailable);
    return sortResources(unavailableResource);
  }, [resourceListViewAvailability]);

  return (
    <FloorplanListViewContainer data-testid="floor-plan-list-view-container">
      {!!sortedAvailableResources.length && (
        <FloorPlanListViewResources
          resources={sortedAvailableResources}
          translationLabelId="available_floor_plan_resources"
          isAvailableList
          toggleReverseAnimation={toggleReverseAnimation}
          toggleModalSlideAnimation={toggleModalSlideAnimation}
        />
      )}
      {!!sortedUnavailableResources.length && (
        <FloorPlanListViewResources
          resources={sortedUnavailableResources}
          translationLabelId="unavailable_floor_plan_resources"
          isAvailableList={false}
          toggleReverseAnimation={toggleReverseAnimation}
          toggleModalSlideAnimation={toggleModalSlideAnimation}
        />
      )}
    </FloorplanListViewContainer>
  );
};
