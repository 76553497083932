import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAppInstanceConfigs, selectCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/selectors';

export const useAddOnsEnabled = (): boolean => {
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);

  return useMemo<boolean>(() => {
    const currentAppInstanceConfig = appInstanceConfigs?.find(config => config.uuid === currentAppInstanceConfigUuid);

    return currentAppInstanceConfig?.config?.add_ons_enabled;
  }, [appInstanceConfigs, currentAppInstanceConfigUuid]);
};
