import { FloorName, Wrapper, StyledDropDownIcon } from './styles';

import React, { useCallback } from 'react';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { StyledDot } from 'pages/resource/styles';

interface FloorPickerHeaderProps {
  floorName: string;
  buildingName: string;
  onFloorPickerClick: VoidFunction;
  canOpenModal: boolean;
  handleOpenFloorPickerModal: VoidFunction;
}

export const FloorPickerHeader = ({
  floorName,
  buildingName,
  onFloorPickerClick,
  canOpenModal,
  handleOpenFloorPickerModal,
}: FloorPickerHeaderProps): JSX.Element => {
  const handleFloorPickerClick = useCallback(() => {
    if (canOpenModal) {
      handleOpenFloorPickerModal();
      onFloorPickerClick();
    }
  }, [handleOpenFloorPickerModal, onFloorPickerClick, canOpenModal]);
  return (
    <Wrapper data-testid="floor-picker-component" onClick={handleFloorPickerClick}>
      <FloorName>
        {floorName}
        <StyledDot />
        {buildingName}
      </FloorName>
      <StyledDropDownIcon icon={faSortDown} size="1x" />
    </Wrapper>
  );
};
