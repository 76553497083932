import styled from 'styled-components';
import { DIMENSIONS, ZINDEX } from '@hqo/react-components-library';

export const MobileContentContainer = styled.div`
  width: 100%;
  height: 100%;
  word-break: break-word;
  text-align: justify;
`;

export const DesktopContentContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const Text = styled.div`
  font: 400 16px/19px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const ScrollTextContainer = styled.div`
  width: 100%;
  height: 400px;
  overflow: auto;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  transition: height 1s;

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    transition: none;
    width: 100%;
    align-items: flex-start;
    height: 734px;
  }
`;

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  z-index: ${ZINDEX.MODAL_BACKGROUND};
`;

export const DesktopContainer = styled.div`
  flex-direction: column;
  background: ${({ theme }) => theme.colors.$white};
  margin: auto;
  box-shadow: ${({ theme }) => `0px 0px 4px ${theme.colorsRgba.black_25}`};
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${ZINDEX.MODAL_CONTAINER};
  height: 538px;
  width: 872px;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 24px;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const Title = styled.div`
  padding: 10px 0;
  width: 100%;
  font: 700 28px/41px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  box-sizing: border-box;
  margin-top: 9px;
`;
