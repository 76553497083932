import { EXPDATE_LENGTH, SPREEDLY_CARD_ID, SPREEDLY_CVV_ID } from 'shared/consts';
import {
  AddCardContainer,
  CancelButton,
  CardIcon,
  CommonWrapper,
  Label,
  RequiredIcon,
  SaveButton,
  SaveCancelButtonContainer,
  SpaceBetween,
  SpreedlyCVVWrapper,
  SpreedlyCardWrapper,
  SpreedlyErrorLabel,
  SpreedlyIFrameContainer,
  StyledCountryLabel,
  StyledFormField,
  StyledSelect,
  AddPaymentMethodError,
  AddPaymentMethodErrorWrapper,
} from './styles';
import { Country } from 'store/add-card/types';
import React from 'react';
import { FormFieldType, SelectItem } from '@hqo/react-components-library';
import { useIntl } from 'react-intl';
import { faCreditCardAlt } from '@fortawesome/pro-regular-svg-icons';
import { useAddCard } from './hooks/use-add-card.hook';
import countries from 'assets/countries.json';
import { useSelector } from 'react-redux';
import { selectSpreedlyValidation } from 'store/add-card/selectors';

export interface AddCardProps {
  spreedlyEnvKey: string;
  onCancel: VoidFunction;
  cartId: string;
  setIsAddCardButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const isExpDateValid = (formattedExpDate: string): boolean => {
  if (formattedExpDate.length === EXPDATE_LENGTH) {
    const [expMonth, expYear] = formattedExpDate.split('/');
    const expMonthNumber = Number(expMonth);
    const expYearNumber = Number(expYear);
    const currentUserLocaleMonth = Number(
      Intl.DateTimeFormat()
        .formatToParts()
        .find(part => part.type === 'month').value,
    );
    const currentUserLocaleYear =
      Number(
        Intl.DateTimeFormat()
          .formatToParts()
          .find(part => part.type === 'year').value,
      ) % 100;

    return (
      expYearNumber > currentUserLocaleYear ||
      (expYearNumber === currentUserLocaleYear && expMonthNumber >= currentUserLocaleMonth)
    );
  }
  return false;
};

export const AddCard = ({ spreedlyEnvKey, onCancel, cartId, setIsAddCardButtonDisabled }: AddCardProps) => {
  const intl = useIntl();
  const countryData: Array<Country> = countries;
  const spreedlyValidation = useSelector(selectSpreedlyValidation);
  const expDateErrorMessage = intl.formatMessage({ id: 'min_exp_date_length' });
  const userNameErrorMessage = intl.formatMessage({ id: 'min_one_char' });
  const zipCodeErrorMessage = intl.formatMessage({ id: 'min_five_char' });
  const {
    firstName,
    lastName,
    expDate,
    zipCode,
    country,
    triggerErrorFirstName,
    triggerErrorLastName,
    triggerErrorZipCode,
    triggerErrorExpDate,
    displayAddPaymentMethodErrorMessage,
    isUserNameValid,
    isZipCodeValid,
    handleOnChangeExpDate,
    handleOnChangeFirstName,
    handleOnChangeLastName,
    handleOnChangeZipCode,
    handleOnClickSave,
    handleSpreedlyCardOnMouseEnter,
    handleSpreedlyCvvOnMouseEnter,
    handleSpreedlyCardOnMouseLeave,
    handleSpreedlyCvvOnMouseLeave,
    handleKeyboardEnter,
    handleFirstNameOnBlur,
    handleLastNameOnBlur,
    handleZipCodeOnBlur,
    handleExpDateOnBlur,
    handleOnChangeCountry,
    defaultCountry,
    spreedlyCvvElementRef,
    spreedlyCardElementRef,
    isLoading,
    dirtySpreedlyCardField,
    dirtySpreedlyCvvField,
  } = useAddCard({ spreedlyEnvKey, cartId, setIsAddCardButtonDisabled });

  return (
    <AddCardContainer>
      <CommonWrapper>
        <StyledFormField
          autoCapitalize="words"
          data-testid="first-name-field"
          errorText={!isUserNameValid(firstName) && triggerErrorFirstName && userNameErrorMessage}
          fieldType={FormFieldType.INPUT}
          label={intl.formatMessage({ id: 'first_name' })}
          onBlur={handleFirstNameOnBlur}
          onChange={handleOnChangeFirstName}
          onKeyDown={handleKeyboardEnter}
          placeholder={intl.formatMessage({ id: 'first_name_placeholder' })}
          value={firstName}
          variant="master"
          required
        />
        <SpaceBetween />
        <StyledFormField
          autoCapitalize="words"
          data-testid="last-name-field"
          errorText={!isUserNameValid(lastName) && triggerErrorLastName && userNameErrorMessage}
          fieldType={FormFieldType.INPUT}
          label={intl.formatMessage({ id: 'last_name' })}
          onBlur={handleLastNameOnBlur}
          onChange={handleOnChangeLastName}
          onKeyDown={handleKeyboardEnter}
          placeholder={intl.formatMessage({ id: 'last_name_placeholder' })}
          value={lastName}
          variant="master"
          required
        />
      </CommonWrapper>
      <Label>
        {intl.formatMessage({ id: 'card_number' })}
        <RequiredIcon>*</RequiredIcon>
      </Label>
      <SpreedlyCardWrapper>
        <CardIcon icon={faCreditCardAlt} />
        <SpreedlyIFrameContainer
          data-testid="spreedly-card-field"
          ref={spreedlyCardElementRef}
          id={SPREEDLY_CARD_ID}
          onMouseEnter={handleSpreedlyCardOnMouseEnter}
          onMouseLeave={handleSpreedlyCardOnMouseLeave}
        />
      </SpreedlyCardWrapper>
      {!spreedlyValidation.validNumber && dirtySpreedlyCardField && (
        <SpreedlyErrorLabel>{intl.formatMessage({ id: 'invalid_card_number' })}</SpreedlyErrorLabel>
      )}
      <CommonWrapper>
        <SpreedlyCVVWrapper>
          <Label>
            {intl.formatMessage({ id: 'cvv' })}
            <RequiredIcon>*</RequiredIcon>
          </Label>
          <SpreedlyIFrameContainer
            data-testid="spreedly-cvv-field"
            ref={spreedlyCvvElementRef}
            id={SPREEDLY_CVV_ID}
            onMouseEnter={handleSpreedlyCvvOnMouseEnter}
            onMouseLeave={handleSpreedlyCvvOnMouseLeave}
          />
          {!spreedlyValidation.validCvv && dirtySpreedlyCvvField && (
            <SpreedlyErrorLabel>{intl.formatMessage({ id: 'invalid_cvv' })}</SpreedlyErrorLabel>
          )}
        </SpreedlyCVVWrapper>
        <SpaceBetween />
        <StyledFormField
          data-testid="exp-date-field"
          errorText={!isExpDateValid(expDate) && triggerErrorExpDate && expDateErrorMessage}
          fieldType={FormFieldType.INPUT}
          inputMode="numeric"
          label={intl.formatMessage({ id: 'exp_date' })}
          onBlur={handleExpDateOnBlur}
          onChange={handleOnChangeExpDate}
          onKeyDown={handleKeyboardEnter}
          placeholder={intl.formatMessage({ id: 'exp_date_placeholder' })}
          value={expDate}
          variant="master"
          required
        />
      </CommonWrapper>
      <CommonWrapper>
        <StyledFormField
          data-testid="zip-code-field"
          errorText={!isZipCodeValid(zipCode) && triggerErrorZipCode && zipCodeErrorMessage}
          fieldType={FormFieldType.INPUT}
          label={intl.formatMessage({ id: 'billing_zip_code' })}
          onBlur={handleZipCodeOnBlur}
          onChange={handleOnChangeZipCode}
          onKeyDown={handleKeyboardEnter}
          placeholder={intl.formatMessage({ id: 'zip_code' })}
          value={zipCode}
          variant="master"
          required
        />
        <SpaceBetween />
        <StyledSelect
          data-testid="country-select-field"
          onChange={handleOnChangeCountry}
          selectedValueLabel={country?.name || defaultCountry.name}
          titleComponent={
            <StyledCountryLabel>
              {intl.formatMessage({ id: 'country_or_region' })}
              <RequiredIcon>*</RequiredIcon>
            </StyledCountryLabel>
          }
          value={country?.code || defaultCountry.code}
        >
          {!country && (
            <SelectItem key={defaultCountry.code} renderedValue={defaultCountry.name} value={defaultCountry.code} />
          )}
          {countryData.map((countryEntry: Country) => (
            <SelectItem key={countryEntry.code} renderedValue={countryEntry.name} value={countryEntry.code} />
          ))}
        </StyledSelect>
      </CommonWrapper>
      {displayAddPaymentMethodErrorMessage && (
        <AddPaymentMethodErrorWrapper data-testid="add-payment-method-error-wrapper">
          <AddPaymentMethodError>{intl.formatMessage({ id: 'add_payment_method_error' })}</AddPaymentMethodError>
        </AddPaymentMethodErrorWrapper>
      )}
      <SaveCancelButtonContainer>
        <CancelButton
          data-testid="cancel-button"
          loading={isLoading}
          onClick={onCancel}
          text={intl.formatMessage({ id: 'cancel' })}
          variant="cancelButton"
        />
        <SaveButton
          data-testid="save-button"
          disabled={
            !isUserNameValid(firstName) ||
            !isUserNameValid(lastName) ||
            !isExpDateValid(expDate) ||
            !isZipCodeValid(zipCode) ||
            !spreedlyValidation.validCvv ||
            !spreedlyValidation.validNumber ||
            isLoading
          }
          loading={isLoading}
          onClick={handleOnClickSave}
          text={intl.formatMessage({ id: 'save' })}
          variant="secondary"
        />
      </SaveCancelButtonContainer>
    </AddCardContainer>
  );
};
