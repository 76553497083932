import { ModalButtonContainer, ShowResultsButton } from './filter-modal-content/styles';

import { FilterModalContent } from './filter-modal-content';
import { Modal } from 'components/modals/modal-portal/modal';
import React, { useCallback, useMemo, useRef } from 'react';
import { useBooleanState } from '@hqo/react-components-library';
import { useFilterModal } from 'hooks/use-filter-modal.hook';
import { useIntl } from 'react-intl';
import { useToggleNativeHeader } from 'hooks/use-toggle-native-header/use-toggle-native-header.hook';
import { useToggleSwipeGestures } from 'hooks/use-toggle-swipe-gestures/use-toggle-swipe-gestures.hook';
import { StyledClearButton, StyledSwipeModal } from 'pages/date-time-select-page/styles';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';

interface FilterModalProps {
  onCloseModal: VoidFunction;
  onCTAClick: VoidFunction;
}

export const FilterModal = ({ onCloseModal, onCTAClick }: FilterModalProps): JSX.Element => {
  const intl = useIntl();
  const { startDate, filterByType, capacity, filterByFloor, startDates } = useSearchParams<searchParams>();
  const { resourcesAmount, isLoadingResources, isMobileDevice } = useFilterModal();
  const { value: isScrollableContent, toggle: toggleScrollableContent } = useBooleanState(true);
  const isActive = useMemo<boolean>(
    () => !!(startDate || filterByType || capacity || filterByFloor || startDates),
    [startDate, filterByType, capacity, filterByFloor, startDates],
  );
  const onResetRef = useRef(null);
  const onReset = useCallback(() => {
    onResetRef.current.onClearAll();
  }, []);
  useToggleNativeHeader();
  useToggleSwipeGestures();

  const clearButton: JSX.Element = (
    <StyledClearButton
      isActive={isActive}
      size="xxs"
      text={intl.formatMessage({ id: 'clearAll' })}
      variant="closeButton"
      onClick={onReset}
      multiline={false}
      disabled={!isActive}
    />
  );

  return isMobileDevice ? (
    <StyledSwipeModal
      isScrollableContent={isScrollableContent}
      reverseAnimation={false}
      footerContent={
        <ModalButtonContainer>
          <ShowResultsButton
            data-testid="show-results-button"
            text={intl.formatMessage({ id: 'showResults' }, { resourcesAmount })}
            loading={isLoadingResources}
            disabled={isLoadingResources}
            onClick={onCTAClick}
            multiline={false}
          />
        </ModalButtonContainer>
      }
      title={intl.formatMessage({ id: 'filterBy' })}
      onClose={onCloseModal}
      content={<FilterModalContent ref={onResetRef} toggleScrollableContent={toggleScrollableContent} />}
      rightIcon={clearButton}
    />
  ) : (
    <Modal onClose={onCloseModal}>
      <FilterModalContent ref={onResetRef} onCTAClick={onCTAClick} toggleScrollableContent={toggleScrollableContent} />
    </Modal>
  );
};
