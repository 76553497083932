import { AvailableNowSubtitles, useAvailableNow } from 'hooks/use-available-now.hook';

import { AvailableNowContainer } from './styles';
import { AvailableNowItem } from 'components/available-now-item';
import { Carousel } from '@hqo/react-components-library/dist/molecules/carousel';
import { EmptyAvailableBlock } from './empty-available-block';
import React from 'react';
import { resourcesState } from 'store/resources/selectors';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AvailableNowSkeleton } from '../skeleton/components/availableNowSkeleton';
import { getUniqueImageUrl } from 'utils/getUniqueImageUrl';
import { useIsUniqueImageUrl } from 'hooks/use-resource-image.hook';

export const AvailableNowBlockComponent = (): JSX.Element => {
  const intl = useIntl();

  const { resources } = useSelector(resourcesState);
  const isUniqueImageUrl = useIsUniqueImageUrl();

  const { hasAvailableResources, renderAvailabilitySubtitleText, onAvailableNowBlockClick, isGetResourcesLoading } =
    useAvailableNow();

  const availableNowSubtitles: AvailableNowSubtitles = {
    availableAllDay: intl.formatMessage({ id: 'available_all_day' }),
    availableFor: intl.formatMessage({ id: 'available_for' }),
    hours: intl.formatMessage({ id: 'hrs' }),
    minutes: intl.formatMessage({ id: 'min' }),
  };

  return isGetResourcesLoading ? (
    <AvailableNowSkeleton />
  ) : (
    <AvailableNowContainer isEmpty={!hasAvailableResources}>
      {hasAvailableResources ? (
        <Carousel title={intl.formatMessage({ id: 'available_now' })} orientation="horizontal" height={750} width={239}>
          {resources?.map(resource => (
            <AvailableNowItem
              key={resource.uuid}
              uuid={resource.uuid}
              title={resource.name}
              subtitle={renderAvailabilitySubtitleText(resource.available_now, availableNowSubtitles)}
              resourceType={resource.type}
              bookingWindows={resource.booking_windows}
              availableNow={resource.available_now}
              capacity={resource.capacity as number}
              image_url={getUniqueImageUrl(resource.image_url, isUniqueImageUrl)}
              onClick={onAvailableNowBlockClick}
            />
          ))}
        </Carousel>
      ) : (
        <EmptyAvailableBlock />
      )}
    </AvailableNowContainer>
  );
};
