import React from 'react';
import { Container, FlexContainer } from 'components/resource-skeleton/styles';
import { RoomLayoutTitle, RoomLayoutOption } from './styles';

export const Skeleton = (): JSX.Element => {
  return (
    <Container data-testid="skeleton">
      <FlexContainer gap="38" isWidth isColumnDirection className="desktop">
        <RoomLayoutTitle />
        <RoomLayoutOption />
        <RoomLayoutOption />
        <RoomLayoutOption />
      </FlexContainer>
      <FlexContainer gap="38" isWidth isColumnDirection className="mobile">
        <RoomLayoutTitle />
        <FlexContainer isWidth isColumnDirection className="mobile">
          <RoomLayoutOption />
          <RoomLayoutOption />
          <RoomLayoutOption />
        </FlexContainer>
      </FlexContainer>
    </Container>
  );
};
