import { TIMELINE_BLOCK_HEIGHT, TIMELINE_TOP_HEIGHT } from 'shared/consts/timeline';
import styled from 'styled-components';

export const NotAvailableText = styled.div`
  margin-top: ${TIMELINE_TOP_HEIGHT}px;
  user-select: none;
  font: 400 12px/14px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  align-self: center;
  margin-right: 25px;
  min-width: 90px;
`;

export const TimelineContainer = styled.div`
  display: flex;
  position: relative;
`;

export const TimelineWrapper = styled.div<{ isMobileDevice: boolean }>`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  flex-wrap: nowrap;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 25px;
  position: relative;
  margin-top: 20px;
  border-right-style: solid;
  border-left-style: solid;
  border-left-color: ${({ theme }) => theme.colors.greys.adminGreyMedium};
  border-right-color: ${({ theme }) => theme.colors.greys.adminGreyMedium};
  border-left-width: ${({ isMobileDevice }) => (isMobileDevice ? 0 : '1px')};
  border-right-width: ${({ isMobileDevice }) => (isMobileDevice ? 0 : '1px')};
  user-select: none;

  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

export const CurrentTimeIndicator = styled.div<{ left: number }>`
  position: absolute;
  left: ${({ left }) => left}px;
  top: ${TIMELINE_TOP_HEIGHT}px;
  height: ${TIMELINE_BLOCK_HEIGHT}px;
  bottom: 0;
  width: 2px;
  background-color: ${({ theme }) => theme.colors.admin.pink};

  ::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.admin.pink};
    border-radius: 50%;
  }
`;
