import { useCallback, useEffect, useState } from 'react';
import { replace } from 'store/router/actions';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useIsAppInstanceConfigNexudus } from 'hooks/use-app-instance-config.hook';
import { useLocation } from 'react-router';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { selectResourcesTypes } from 'store/resources/selectors';
import { DEFAULT_TYPE_COMMA_DELIMITER, DEFAULT_TYPE_DELIMITER } from 'shared/consts';

interface UseFilterBarReturnValues {
  isCurrentConfigNexudus: boolean;
  filterByTypeEncoded: string;
  dateRangeEncoded: string;
  startTimeEncoded: string;
  endTimeEncoded: string;
  onCancelClick: (value: string) => void;
  chips: string[];
}

export const getSelectedTypes = (filterByTypesString: string, resourcesTypes: Array<string>): Array<string> => {
  const selectedTypes: Array<string> = [];
  let currentType = '';
  for (let i = 0; i < filterByTypesString.length; i += 1) {
    if (filterByTypesString[i] !== ',') {
      currentType += filterByTypesString[i];
      if (i === filterByTypesString.length - 1 && resourcesTypes.includes(currentType)) {
        selectedTypes.push(currentType);
        currentType = '';
      }
    }
    if (filterByTypesString[i] === ',') {
      if (!resourcesTypes.includes(currentType)) {
        currentType += filterByTypesString[i];
      } else {
        selectedTypes.push(currentType);
        currentType = '';
      }
    }
  }

  return selectedTypes;
};

export const useFilterBar = (): UseFilterBarReturnValues => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [chips, setChips] = useState<Array<string>>([]);
  const {
    filterByType: filterByTypeEncoded,
    startDate: dateRangeEncoded,
    startTime: startTimeEncoded,
    endTime: endTimeEncoded,
  } = useSearchParams();

  const isCurrentConfigNexudus = useIsAppInstanceConfigNexudus();

  const onCancelClick = useCallback(
    (value: string) => {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

      const { filterByType, ...clearQueryParams } = queryParams;
      const filterChipsToString = chips
        ?.filter(chip => chip !== value)
        ?.map(chip => chip.replace(',', DEFAULT_TYPE_COMMA_DELIMITER))
        .join(',');

      const queryString = qs.stringify(
        chips.length === 1 && !!filterByType ? clearQueryParams : { ...queryParams, filterByType: filterChipsToString },
      );
      setChips(chips.filter(chip => chip !== value));
      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [dispatch, location, setChips, chips],
  );

  const resourcesTypes = useSelector(selectResourcesTypes) as Array<string>;

  useEffect(() => {
    if (typeof filterByTypeEncoded === 'string' && filterByTypeEncoded.length > 0) {
      const selectedTypes = getSelectedTypes(filterByTypeEncoded.replace(DEFAULT_TYPE_DELIMITER, ''), resourcesTypes);
      setChips([...new Set(selectedTypes)]);
    } else {
      setChips([]);
    }
  }, [filterByTypeEncoded, resourcesTypes, getSelectedTypes]);

  return {
    isCurrentConfigNexudus,
    filterByTypeEncoded: filterByTypeEncoded as string,
    dateRangeEncoded: dateRangeEncoded as string,
    startTimeEncoded: startTimeEncoded as string,
    endTimeEncoded: endTimeEncoded as string,
    onCancelClick,
    chips,
  };
};
