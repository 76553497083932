import { CHECKOUT_BREAK_POINT } from 'shared/consts';
import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library';
import IframeModalWrapper from 'components/modals/iframeWrapper';
import { reverseSlide, slide } from 'hocs/shared-styles';

export const QuickCheckoutWrapper = styled.div`
  background: ${({ theme }) => theme.colors.universal.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 14;
  width: 100%;

  @media screen and (max-width: ${CHECKOUT_BREAK_POINT}px) {
    width: 100vw;
    border-radius: 8px 8px 0 0;
    transform: translate(0%, 0%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  z-index: 13;
`;

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 360px;
  border-radius: 8px 8px 0 0;

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    align-items: flex-start;
  }
`;

export const StyledModalWrapper = styled(IframeModalWrapper)<{
  reverseAnimation: boolean;
  quickCheckoutHeight: number;
}>`
  width: 543px;
  transition: width 1s;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: ${props => props.quickCheckoutHeight}px;

    .iframe-wrapper {
      height: ${props => props.quickCheckoutHeight}px;
      border-radius: 8px 8px 0 0;
      top: auto;
      animation: ${props => (props.reverseAnimation ? reverseSlide : slide)} 0.5s;
    }

    .iframe-modal-content {
      border-radius: 8px 8px 0 0;
      height: auto;
    }

    .iframe-modal-content > div {
      border-radius: 8px 8px 0 0;
      height: 100%;
      inset: 0;
    }
  }

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    width: 100%;
  }
`;
