export function isEndTimeBeforeStartTime(startTime: string, endTime: string) {
  const [startHours, startMinutes] = startTime.split(':').map(Number);
  const [endHours, endMinutes] = endTime.split(':').map(Number);

  if (endHours < startHours) {
    return true;
  } else if (endHours === startHours) {
    return endMinutes < startMinutes;
  }

  return false;
}
