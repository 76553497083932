import React, { useCallback } from 'react';
import { ResourceItem } from 'components/resources-list-item';
import { useSelector, useDispatch } from 'react-redux';
import { resourcesState } from 'store/resources/selectors';
import { RESOURCE_PATH } from 'shared/consts';
import { push } from 'store/router/actions';
import { useLocation } from 'react-router-dom';
import { getUniqueImageUrl } from 'utils/getUniqueImageUrl';
import { useIsUniqueImageUrl } from 'hooks/use-resource-image.hook';
import { resetResource } from 'store/resource/actions';

export const FlexContent = (): JSX.Element => {
  const { resources } = useSelector(resourcesState);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const isUniqueImageUrl = useIsUniqueImageUrl();

  const onResourceClick = useCallback(
    (resourceUuid: any) => {
      dispatch(resetResource());
      dispatch(push(`${RESOURCE_PATH}/${resourceUuid}${search}`));
    },
    [dispatch, search],
  );

  return (
    <>
      {resources?.map(resource => (
        <ResourceItem
          key={resource.uuid}
          uuid={resource.uuid}
          title={resource.name}
          subtitle={resource.subtitle}
          capacity={+resource.capacity}
          image_url={getUniqueImageUrl(resource.image_url, isUniqueImageUrl)}
          onClick={onResourceClick}
        />
      ))}
    </>
  );
};
