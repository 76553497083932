import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectAllAddOns, selectAllAddonsPrice } from 'store/add-ons/selectors';
import { AddOn } from 'store/resource/types';
import { DEFAULT_ITEM_QUANTITY } from 'shared/consts';
import { addAddOn } from 'store/add-ons/actions';
import { useIntl } from 'react-intl';
import { selectBuildingCurrencyType } from 'store/building/selectors';
import { AddOnDto } from 'store/add-ons/types';
import { formatCurrency } from 'utils/formatCurrency';
import { useLocale } from 'hooks/use-locale.hook';

interface UseAddOnDetailsReturnValues {
  quantity: number;
  name: string;
  ctaButtonText: string;
  onClose: VoidFunction;
  onButtonClick: VoidFunction;
  increaseHandler: VoidFunction;
  decreaseHandler: VoidFunction;
}

export const useAddOnDetails = (selectedAddOn: AddOn, onModalClose: VoidFunction): UseAddOnDetailsReturnValues => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedAddOns = useSelector(selectAllAddOns);
  const { name, price, description, image_url, id } = selectedAddOn || {};
  const currencyType = useSelector(selectBuildingCurrencyType);
  const locale = useLocale();
  const addOnsPrice = useSelector(selectAllAddonsPrice);
  const currentSelectedAddOn = useMemo<AddOnDto>(
    () => selectedAddOns?.find(addOn => addOn.id === selectedAddOn?.id),
    [selectedAddOn?.id, selectedAddOns],
  );
  const filterAddOns = useMemo<AddOnDto[]>(
    () => selectedAddOns?.filter(addOn => addOn?.id !== currentSelectedAddOn?.id),
    [currentSelectedAddOn?.id, selectedAddOns],
  );
  const filterAddOnsPrice = useMemo<number>(
    () => filterAddOns?.reduce((partialSum, addOn) => partialSum + addOn.price * addOn.quantity, 0),
    [filterAddOns],
  );
  const defaultQuantity = useMemo<number>(
    () => currentSelectedAddOn?.quantity || DEFAULT_ITEM_QUANTITY,
    [currentSelectedAddOn],
  );
  const [quantity, setQuantity] = useState<number>(defaultQuantity);
  const totalPrice = useMemo<string>(
    () => formatCurrency(price * quantity, currencyType, locale, true, false),
    [locale, currencyType, price, quantity],
  );
  const ctaButtonText = useMemo<string>(
    () =>
      intl.formatMessage({ id: 'update_item' }, { price: totalPrice, type: currentSelectedAddOn ? 'update' : 'add' }),
    [currentSelectedAddOn, intl, totalPrice],
  );

  useEffect(() => {
    setQuantity(defaultQuantity);
  }, [defaultQuantity, dispatch, price]);

  const onClose = useCallback(() => {
    onModalClose();
    setQuantity(defaultQuantity);
  }, [defaultQuantity, onModalClose]);

  const onButtonClick = useCallback(() => {
    dispatch(addAddOn({ id, name, price, description, image_url, quantity }));
    onModalClose();
  }, [
    filterAddOnsPrice,
    dispatch,
    addOnsPrice,
    id,
    name,
    price,
    description,
    image_url,
    quantity,
    currentSelectedAddOn,
    onModalClose,
  ]);

  const increaseHandler = useCallback(() => {
    setQuantity(quantity + 1);
  }, [quantity, setQuantity]);

  const decreaseHandler = useCallback(() => {
    if (quantity === 1) {
      return;
    }
    setQuantity(quantity - 1);
  }, [quantity, setQuantity]);

  return {
    quantity,
    name,
    ctaButtonText,
    onClose,
    onButtonClick,
    increaseHandler,
    decreaseHandler,
  };
};
