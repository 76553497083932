import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library';

export const CalendarViewContainer = styled.div`
  padding-bottom: 30px;

  @media only screen and (max-width: ${DIMENSIONS.SMALL}) {
    overflow-x: hidden;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    flex-direction: column;
  }
`;
