import { AppliedCreditsMethod } from 'components/payment/quick-checkout/components/applied-credits-method';
import {
  ErrorLabel,
  ErrorWrapper,
  StyledErrorIcon,
  StyledPaymentBlock,
  StyledPaymentMethod,
  NativeErrorLabel,
  StyledAfterPaymentMethod,
} from 'components/payment/quick-checkout/components/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PaymentMethodTypes } from '@hqo/react-components-library/dist/payment/payment-method';
import { getCartErrorMessage } from 'utils/getCartErrorMessage';
import { Order } from 'store/cart/types';
import { PaymentError } from 'shared/consts';
import { SavedPaymentMethod } from 'store/payment/types';
import { resolvePaymentMethodText } from 'utils/resolvePaymentMethodText';
import { PaymentMethodManager } from 'components/payment/quick-checkout/components/payment-methods-selector';
import { AFTER_PAYMENT, INVOICE } from 'shared/consts/payment-method-types';
import { useSelector } from 'react-redux';
import { selectPaymentMethods } from 'store/payment/selectors';
import { InfoCircle } from '@hqo/react-components-library/dist/icons';

export interface PaymentMethodsSectionProps {
  onEditClick: VoidFunction;
  cart: Order;
  isProcessPaymentFailed: boolean;
  isVisiblePaymentMethodBlock: boolean;
  currentPaymentMethodId: string;
  paymentMethod: SavedPaymentMethod;
  cartError: PaymentError;
  isNativePaymentError: boolean;
  isAllowedVisitorsExist?: boolean;
}

export const PaymentMethodsSection = ({
  onEditClick,
  cart,
  isProcessPaymentFailed,
  isVisiblePaymentMethodBlock,
  currentPaymentMethodId,
  paymentMethod,
  cartError,
  isNativePaymentError,
  isAllowedVisitorsExist = false,
}: PaymentMethodsSectionProps) => {
  const { enableHqoMiniappApplePay } = useFlags();
  const intl = useIntl();
  const paymentMethods = useSelector(selectPaymentMethods);
  const paymentMethodText = resolvePaymentMethodText(paymentMethod, intl);
  const isPaymentErrorVisible = useMemo<boolean>(
    () => isProcessPaymentFailed || isNativePaymentError,
    [isProcessPaymentFailed, isNativePaymentError],
  );

  return (enableHqoMiniappApplePay &&
    navigator.userAgent.includes('iPhone') &&
    ![INVOICE, AFTER_PAYMENT].includes(paymentMethod?.payment_method_type)) ||
    paymentMethods?.length > 1 ? (
    <>
      {isVisiblePaymentMethodBlock && <PaymentMethodManager onEditClick={onEditClick} />}
      {isPaymentErrorVisible && (
        <ErrorWrapper>
          <NativeErrorLabel>{intl.formatMessage({ id: 'payment_error' })}</NativeErrorLabel>
        </ErrorWrapper>
      )}
    </>
  ) : (
    <StyledPaymentBlock isAllowedVisitorsExist={isAllowedVisitorsExist}>
      {isVisiblePaymentMethodBlock && (
        <StyledPaymentMethod
          methodText={currentPaymentMethodId && paymentMethodText}
          paymentMethod={paymentMethod?.payment_method_type as PaymentMethodTypes}
          editButtonText={intl.formatMessage({ id: 'edit' })}
          onEditClick={onEditClick}
        />
      )}
      {paymentMethod?.payment_method_type === AFTER_PAYMENT && (
        <StyledAfterPaymentMethod data-testid="after-payment-method">
          <InfoCircle />
          {intl.formatMessage({ id: 'after_payment_info' })}
        </StyledAfterPaymentMethod>
      )}
      <AppliedCreditsMethod cart={cart} />
      {isProcessPaymentFailed && (
        <ErrorWrapper>
          <ErrorLabel>{intl.formatMessage({ id: getCartErrorMessage(cartError) })}</ErrorLabel>
          <StyledErrorIcon size="sm" variant="pink" />
        </ErrorWrapper>
      )}
    </StyledPaymentBlock>
  );
};
