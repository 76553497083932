import { FloorPlan, Space } from 'floorplan/pages/floorplan/types';
import { useMemo } from 'react';

interface useArchilogicFloorPlanValues {
  archilogicSpaces: Space[];
}

export const useArchilogicFloorPlan = (floorPlan: FloorPlan | null): useArchilogicFloorPlanValues => {
  const archilogicSpaces: Space[] = useMemo(() => {
    if (floorPlan?.resources) {
      const spaces = [...floorPlan.resources.spaces.concat(floorPlan.resources.assets)];
      return spaces;
    }
    return [];
  }, [floorPlan?.resources?.assets?.length, floorPlan?.resources?.spaces?.length]);

  return {
    archilogicSpaces,
  };
};
