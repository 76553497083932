import * as actions from './actions';

import { catchError, filter, map, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { PaymentError } from 'shared/consts/payment-error-types';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of, concat } from 'rxjs';
import { getPaymentMethods } from 'store/payment/actions';

export const createCartEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.createCart.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .createCart(payload)
        .pipe(
          mergeMap(data => {
            return concat(
              of(actions.createCart.success(data.response.data)),
              of(getPaymentMethods.request(data.response.data.cart_id)),
            );
          }),
          catchError((error: Error) => of(actions.createCart.failure({ error }))),
        );
    }),
  );

export const getCartEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getCart.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getCart(payload)
        .pipe(
          map(data => {
            return actions.getCart.success(data.response.data.cart);
          }),
          catchError((error: Error) => of(actions.getCart.failure({ error }))),
        );
    }),
  );

export const submitCartEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.submitCart.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .submitCart(payload)
        .pipe(
          map(data => {
            return actions.submitCart.success(data.response.data.order);
          }),
          catchError((error: PaymentError) => {
            return of(actions.submitCart.failure(error));
          }),
        );
    }),
  );

export const complete3DSCartEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.complete3DSCart.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .complete3DSCart(payload)
        .pipe(
          map(data => {
            return actions.complete3DSCart.success(data.response.data.transaction);
          }),
          catchError((error: Error) => of(actions.complete3DSCart.failure({ error }))),
        );
    }),
  );
