import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { AddOn } from 'store/resource/types';
import { removeAddOn } from 'store/add-ons/actions';
import { DEFAULT_ITEM_QUANTITY } from 'shared/consts';
import { selectAllAddOns } from 'store/add-ons/selectors';
import { AddOnDto } from 'store/add-ons/types';

interface UseModalContentReturnValues {
  isDecreaseDisabled: boolean;
  description: string;
  image_url: string;
  onRemoveClick: VoidFunction;
}

export const useModalContent = (
  selectedAddOn: AddOn,
  onModalClose: VoidFunction,
  quantity: number,
): UseModalContentReturnValues => {
  const selectedAddOns = useSelector(selectAllAddOns);
  const dispatch = useDispatch();
  const isDecreaseDisabled = useMemo<boolean>(() => quantity === DEFAULT_ITEM_QUANTITY, [quantity]);
  const { description, image_url, id, price } = selectedAddOn || {};
  const currentAddOn = useMemo<AddOnDto>(() => selectedAddOns?.find(addOn => addOn?.id === id), [id, selectedAddOns]);

  const onRemoveClick = useCallback(() => {
    if (currentAddOn) {
      dispatch(removeAddOn(id));
    }
    onModalClose();
  }, [dispatch, id, currentAddOn, onModalClose, price]);

  return {
    isDecreaseDisabled,
    description,
    image_url,
    onRemoveClick,
  };
};
