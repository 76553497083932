import { FooterContainer, StyledButton } from './styles';
import React from 'react';

interface SelectLocationModalFooterProps {
  handleClick: VoidFunction;
  ctaButtonText: string;
}

export const DetailsModalFooter = ({ handleClick, ctaButtonText }: SelectLocationModalFooterProps): JSX.Element => (
  <FooterContainer>
    <StyledButton
      variant="primary"
      data-testid="details-modal-button"
      onClick={handleClick}
      text={ctaButtonText}
      multiline={false}
    />
  </FooterContainer>
);
