import { BackLinkWrapper, Container, Content, Header, Wrapper } from './styles';
import { ArrowBackLink } from '../arrow-back-link';
import React, { useMemo } from 'react';
import { useDateScheduleModalNavigation } from 'hooks/use-date-schedule-modal-navigation.hook';
import { useIntl } from 'react-intl';
import { Footer } from '../footer';
import { isButtonLoading } from 'utils/isButtonLoading';
import { useSelector } from 'react-redux';
import { selectCreateCartStatus } from 'store/cart/selectors';

interface FlexDesktopWrapperProps {
  children: React.ReactNode;
  onBackLinkClick: VoidFunction;
  onContinueClick: VoidFunction;
  userResponse: Array<FormResponse>;
}

export const FlexDesktopWrapper = ({
  children,
  onBackLinkClick,
  onContinueClick,
  userResponse,
}: FlexDesktopWrapperProps): JSX.Element => {
  const intl = useIntl();
  const createCartStatus = useSelector(selectCreateCartStatus);
  const { onTimeLinkClick, onDateClick } = useDateScheduleModalNavigation();
  const isEmptyRequiredFieldsExist = useMemo<boolean>(
    () => userResponse?.some(question => question.required && !question.answer),
    [userResponse],
  );
  const isButtonDisabled = useMemo<boolean>(
    () => isEmptyRequiredFieldsExist || isButtonLoading(createCartStatus),
    [createCartStatus, isEmptyRequiredFieldsExist],
  );

  return (
    <Wrapper>
      <Container>
        <Header>
          <BackLinkWrapper>
            <ArrowBackLink onClick={onBackLinkClick} />
          </BackLinkWrapper>
        </Header>
        <Content>{children}</Content>
      </Container>
      <Footer
        isAdditionalInformationPage
        onTimeLinkClick={onTimeLinkClick}
        onDateLinkClick={onDateClick}
        onCtaButtonClick={onContinueClick}
        isButtonDisabled={isButtonDisabled}
        isButtonLoading={isButtonLoading(createCartStatus)}
        ctaButtonText={intl.formatMessage({ id: 'continue' })}
        isErrorTextVisible={isEmptyRequiredFieldsExist}
        errorText={intl.formatMessage({ id: 'fill_required_fields' })}
      />
    </Wrapper>
  );
};
