import { ACTION_STATUSES, BOOKING_STATUSES, PATCH_STATUSES } from 'shared/consts';
import { PatchTransaction } from 'store/transactions/types';

export const getReceiptSubtitle = (
  bookingStatus: string,
  { status, type }: Partial<Pick<PatchTransaction, 'status' | 'type'>> = {},
): string => {
  if (status === ACTION_STATUSES.FULFILLED && type === PATCH_STATUSES.CANCELLED) {
    return 'cancelled_text';
  }
  if (bookingStatus === BOOKING_STATUSES.ACCEPTED) {
    return 'confirmation_text';
  }
  if (bookingStatus === BOOKING_STATUSES.PENDING) {
    return 'requested_subtitle';
  }

  return 'default_receipt';
};
