import React, { useCallback, useRef } from 'react';

import { DropdownOption } from './types';
import { DropdownOptions } from './dropdown-options/dropdown-options';
import { useBooleanState, useOnClickOutside, AccessTime } from '@hqo/react-components-library';
import { Container, ValueContainer, Value, Placeholder, ValueTimeContainer, CaretDown, CaretUp } from './styles';

export interface DropdownProps {
  onSelect: (option: DropdownOption) => void;
  options: Array<DropdownOption>;
  placeholder?: string;
  value?: string;
  fieldClass?: string;
  disabled?: boolean;
  defaultValueIndex?: number;
  trackEvent?: VoidFunction;
}

export const Dropdown: React.FC<DropdownProps> = ({
  onSelect,
  options,
  placeholder,
  value,
  fieldClass,
  defaultValueIndex,
  disabled,
  trackEvent,
}: DropdownProps): JSX.Element => {
  const { value: isActive, toggle: toggleActive, setFalse } = useBooleanState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  const Caret = isActive ? CaretUp : CaretDown;

  useOnClickOutside(() => setFalse(), [ref]);

  const onClick = useCallback(() => {
    toggleActive();

    if (trackEvent) {
      trackEvent();
    }
  }, [trackEvent, toggleActive]);

  return (
    <Container data-testid="resource-booking-dropdown" isActive={isActive}>
      <ValueContainer
        ref={ref}
        active={isActive.toString()}
        onClick={!disabled ? onClick : undefined}
        data-testid="resource-booking-dropdown-value-container"
        data-cy="resource-booking-dropdown-value-container"
        className={fieldClass}
        disabled={disabled}
      >
        <ValueTimeContainer>
          <AccessTime
            variant="adminGreyDark"
            data-testid="resource-booking-time-picker-icon"
            className="time-picker-icon"
          />
          {value && <Value>{value}</Value>}
          {!value && placeholder && <Placeholder disabled={disabled}>{placeholder}</Placeholder>}
        </ValueTimeContainer>
        <Caret
          size="xs"
          variant="adminGreyDark"
          data-testid={`caret-${isActive ? 'up' : 'down'}-sharp`}
          dataCy={`caret-${isActive ? 'up' : 'down'}-sharp`}
        />
      </ValueContainer>
      {isActive && (
        <DropdownOptions
          onSelect={onSelect}
          options={options}
          value={value}
          dropRef={dropRef}
          defaultValueIndex={defaultValueIndex}
        />
      )}
    </Container>
  );
};
