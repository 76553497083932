import { AppThemeProvider, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { ErrorBoundary, init as initSentry } from '@sentry/react';

import { App } from 'components/app';
import { ErrorPage } from 'pages/error';
import { Integrations } from '@sentry/tracing';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { IntlProvider } from '../intl-provider';
import { LaunchDarklyInitializer } from 'components/launch-darkly';
import { MiniappSdkProvider } from 'components/miniapp-sdk-provider';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RootStyles } from './root.styles';
import { history } from 'store/router/history';
import store from 'store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleUp, faCalendarAlt, faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck, faExclamationTriangle, faPhone, faTv, faWifi } from '@fortawesome/pro-regular-svg-icons';
import { faTrash, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';

library.add(
  faTrash,
  faMapMarkerAlt,
  faExclamationTriangle,
  faAngleUp,
  faAngleDown,
  faCalendarAlt,
  faLocationDot,
  faWifi,
  faPhone,
  faTv,
  faCircleCheck,
);

initSentry({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  autoSessionTracking: true,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

export const Root: React.FC = (): JSX.Element => (
  <ReduxProvider store={store}>
    <MiniappSdkProvider>
      <LaunchDarklyInitializer>
        <AppThemeProvider theme={defaultTheme}>
          <Router history={history}>
            <IntlProvider>
              <ErrorBoundary fallback={<ErrorPage />}>
                <RootStyles />
                <App />
              </ErrorBoundary>
            </IntlProvider>
          </Router>
        </AppThemeProvider>
      </LaunchDarklyInitializer>
    </MiniappSdkProvider>
  </ReduxProvider>
);
