import React, { ChangeEvent } from 'react';
import { Switch } from '@hqo/react-components-library';
import { useIntl } from 'react-intl';
import { SwitchLabel, SwitchContainer } from './styles';

interface SwitchBlockProps {
  checked: boolean;
  onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SwitchBlock = ({ checked, onChangeHandler }: SwitchBlockProps): JSX.Element => {
  const intl = useIntl();

  return (
    <SwitchContainer>
      <SwitchLabel>{intl.formatMessage({ id: 'all_day_lower' })}</SwitchLabel>
      <Switch data-testid="all-day-switch" onChange={onChangeHandler} checked={checked} />
    </SwitchContainer>
  );
};
