import { OwnerParams } from 'shared/types/index';
import { PaginationParams, PaginatedResponse } from 'shared/types/pagination';
import { Resource } from 'store/resource/types';

export interface ResourcesAPIResponse {
  data: ResourcesResponse;
  total: number;
  paging: PaginatedResponse;
}

export interface ResourcesResponse {
  resources: Array<Resource>;
  indexOfSet?: number;
  total: number;
}

export interface ResourcesState {
  resources: Array<Resource>;
  resourcesTypes: Array<ResourceType>;
  getResourcesTypes: AsyncActionTypes;
  infiniteResources: { [key: number]: Array<Resource> };
  getInfiniteResources: AsyncActionTypes;
  total: number;
  loading: boolean;
  error?: Error;
}

export interface GetResourcesTypesPayload {
  ownerUuid: string;
  queryParams?: GetResourceTypeParams;
}

export interface ResourcesTypes {
  data: { types: Array<ResourceType> };
}

interface GetResourceTypeParams {
  include?: string;
}

export enum ResourceType {
  OTHER = 'OTHER',
  LARGE_CONFERENCE_ROOM = 'LARGE_CONFERENCE_ROOM',
  SMALL_CONFERENCE_ROOM = 'SMALL_CONFERENCE_ROOM',
  PRIVATE_OFFICE = 'PRIVATE_OFFICE',
  AUDITORIUM = 'AUDITORIUM',
  CLASSROOM = 'CLASSROOM',
  QUIET_SPACE = 'QUIET_SPACE',
  BREAK_ROOM = 'BREAK_ROOM',
  PHONE_BOOTH = 'PHONE_BOOTH',
  MOTHER_ROOM = 'MOTHER_ROOM',
  DESK = 'DESK',
  CAFETERIA = 'CAFETERIA',
  KITCHEN = 'KITCHEN',
  LOBBY = 'LOBBY',
  LOUNGE = 'LOUNGE',
  ROOF_DECK = 'ROOF_DECK',
  OUTDOOR_SPACE = 'OUTDOOR_SPACE',
  FITNESS_EQUIPMENT = 'FITNESS_EQUIPMENT',
  RECREATION_GAME_ROOM = 'RECREATION_GAME_ROOM',
  RESTAURANT_BAR = 'RESTAURANT_BAR',
  SLEEP_POD = 'SLEEP_POD',
  EVENT_SPACE = 'EVENT_SPACE',
  MEDIA_LAB = 'MEDIA_LAB',
  PARKING_SPOT = 'PARKING_SPOT',
  CO_WORKING_SPACE = 'CO_WORKING_SPACE',
}

export enum PresetWindows {
  HALF_DAY_1 = 'HALF_DAY_1',
  HALF_DAY_2 = 'HALF_DAY_2',
  FULL_DAY = 'FULL_DAY',
}

export interface FilterParams extends PaginationParams {
  types?: string;
  startDate?: string;
  startTime?: string;
  endTime?: string;
  availableNow?: string;
  duration?: string;
  capacity?: number | string;
  presetWindows?: string;
  resourceIds?: string;
  appInstanceConfigUuid?: string;
  endDate?: string;
  floor?: string;
  offset?: number;
  startDates?: string;
}

export interface GetResourcesParams extends OwnerParams {
  filterParams?: FilterParams;
  indexOfSet?: number;
  offset?: number;
}
