import { QuickCheckoutWrapper } from './styles';
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { select3DSecure, selectCartId, selectComplete3DSCartError } from 'store/cart/selectors';

import { Container } from './components/container';
import { DefaultLoader } from 'components/default-loader';
import { ThreeDSModal } from 'components/modals/3dsModal/component';
import { useBooleanState } from '@hqo/react-components-library';
import { useCart } from 'hooks/use-cart.hook';
import { usePaymentMethods } from 'hooks/use-payment-methods.hook';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/resource/date-select-modal/interface';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { Container as SpinnerContainer } from 'hocs/shared-styles';
import { selectCurrentPaymentMethodId } from 'store/payment/selectors';

const renderLoading = (isCartLoading: boolean, isPaymentMethodsLoading: boolean) =>
  (isCartLoading || isPaymentMethodsLoading) && <DefaultLoader isModal />;

interface QuickCheckoutContentProps {
  closeQuickCheckoutHandler: VoidFunction;
  openCheckout: VoidFunction;
}

export const QuickCheckoutContent = ({ closeQuickCheckoutHandler, openCheckout }: QuickCheckoutContentProps) => {
  const { cartId: currentCartId } = useSearchParams<searchParams>();
  const cartId = useSelector(selectCartId) || currentCartId;
  const { isCartLoading, isCartFulfilled, cart } = useCart(cartId);
  const { isPaymentMethodsLoading, paymentMethods, isPaymentMethodsFullfiled } = usePaymentMethods();
  const { is3DSecureSupported } = useSelector(select3DSecure);
  const complete3DSCartError = useSelector(selectComplete3DSCartError);
  const currentPaymentMethodId = useSelector(selectCurrentPaymentMethodId);

  const { value: isProcessPaymentFailed, setTrue: setProcessPaymentFailed } = useBooleanState();
  const [show3DS, setShow3DS] = useState<boolean>(false);

  useEffect(() => {
    if (complete3DSCartError) {
      setProcessPaymentFailed();
    }
  }, [complete3DSCartError, setProcessPaymentFailed]);

  const setPaymentError = useCallback(
    (value: boolean) => {
      if (value) {
        setProcessPaymentFailed();
      }
    },
    [setProcessPaymentFailed],
  );

  const paymentMethod = useMemo(() => {
    if (!!currentPaymentMethodId) {
      return paymentMethods?.find(({ id }) => `${id}` === currentPaymentMethodId);
    }

    return paymentMethods?.length ? paymentMethods?.find(method => method.default) || paymentMethods[0] : null;
  }, [paymentMethods, currentPaymentMethodId]);

  const [currentOffsetHeight, setOffsetHeight] = useState<number>(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setOffsetHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef]);

  return (
    <>
      {!show3DS ? (
        <>
          <QuickCheckoutWrapper data-testid="quick-checkout-swipe-modal" ref={contentRef}>
            {renderLoading(isCartLoading, isPaymentMethodsLoading)}
            {isCartFulfilled && isPaymentMethodsFullfiled && (
              <Container
                openCheckout={openCheckout}
                closeQuickCheckoutHandler={closeQuickCheckoutHandler}
                cart={cart}
                isProcessPaymentFailed={isProcessPaymentFailed}
                setProcessPaymentFailed={setProcessPaymentFailed}
                paymentMethods={paymentMethods}
                currentPaymentMethodId={paymentMethod?.id?.toString()}
              />
            )}
          </QuickCheckoutWrapper>
        </>
      ) : (
        <SpinnerContainer data-testid="loading-container" isLoading isModal>
          <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
        </SpinnerContainer>
      )}
      {is3DSecureSupported && (
        <ThreeDSModal
          setPaymentError={setPaymentError}
          setIs3DSVisible={setShow3DS}
          transactionToken={cart?.transaction?.token}
          spreedlyToken={cart?.ui_enablements.spreedly_env_token}
          currentOffsetHeight={currentOffsetHeight}
          isQuickCheckout
        />
      )}
    </>
  );
};
