import { AddOnTile, Button, Counter, DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';
import { Image } from 'components/image';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';
import { ResourceImage } from 'components/resource-skeleton/styles';
import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';

export const StyledButton = styled(Button)`
  width: 110px;
  height: 36px;
  padding: 16px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    height: 48px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    justify-content: center;
  }
`;

export const DetailsModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div`
  margin-top: 24px;
  font: 400 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.universal.black};
  padding: 0 16px;
`;

export const StyledImage = styled(Image)`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: auto;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;

export const ResourceImageSkeleton = styled(ResourceImage)`
  display: block;
  width: 100%;
  height: 300px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    border-radius: 0;
    margin-left: 50%;
    transform: translateX(-50%);
  }
`;

export const StyledCounter = styled(Counter)`
  display: flex;
  align-self: center;
  margin: 32px 0 12px 0;
`;

export const StyledAddOnTile = styled(AddOnTile)`
  margin: 24px 0;
  cursor: ${({ isRequired }) => (isRequired ? 'initial' : 'pointer')};

  .icon-container {
    margin-top: 4px;
  }

  .subtitle {
    color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  }
`;

export const StyledSwipeModal = styled(SwipeModal)`
  .modal-title {
    padding: 0 16px;
  }
`;
