import { Text, Title } from './styles';
import { Order } from 'store/cart/types';
import React from 'react';
import { useIntl } from 'react-intl';

export interface CancellationPolicyProps {
  cart: Order;
}

export const CancellationPolicy = ({ cart }: CancellationPolicyProps) => {
  const intl = useIntl();

  return (
    <>
      <Title>{intl.formatMessage({ id: 'cancellation_title' })}</Title>
      <Text
        dangerouslySetInnerHTML={{
          __html: `${cart?.items[0]?.display_info?.cancellation_description?.replace(/\n/g, '<br/>')}`,
        }}
      />
    </>
  );
};
