import styled from 'styled-components';
import { Modal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { DIMENSIONS } from '@hqo/react-components-library';

export const StyledModal = styled(Modal)`
  overflow: auto;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 348px;
    max-height: 683px;
    max-width: 963px;
    padding: 24px 32px;
    margin: auto;
    overflow: initial;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 24px 56px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
`;

export const Header = styled.div`
  width: 90%;
  padding-right: 15px;
`;

export const Title = styled.div`
  padding: 20px 20px 20px 0;
  width: 100%;
  font: 700 28px/33px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  box-sizing: border-box;
  justify-content: flex-start;
`;

export const FooterContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
`;

export const Content = styled.div`
  overflow-y: auto;
  width: 90%;
  margin-bottom: 15px;
  margin-top: 10px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.greys.sharedMediumGrey};
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.universal.white};
  }
`;
