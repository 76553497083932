import {
  AdditionalInfoRequiredText,
  FooterContainer,
  CtaButton,
  ButtonContainer,
  ButtonWrapper,
  ErrorText,
  DateTimeContainer,
} from './styles';
import React from 'react';
import { SelectedDateTimeFilter } from 'components/selected-date-time-filter/selected-date-time-filter';
import { resourceState } from 'store/bookings/selectors';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useSelector } from 'react-redux';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';

interface FooterProps {
  onDateLinkClick?: VoidFunction;
  onTimeLinkClick?: VoidFunction;
  onCtaButtonClick: VoidFunction;
  ctaButtonText: string;
  isSkipCtaButton?: boolean;
  isButtonDisabled?: boolean;
  isErrorTextVisible?: boolean;
  errorText?: string;
  isAdditionalInformationTextVisible?: boolean;
  isAdditionalInformationPage?: boolean;
  isSelectedDateTimeFilterTextOnly?: boolean;
  isButtonLoading?: boolean;
  resourceContainerRef?: React.MutableRefObject<HTMLDivElement>;
}

export type searchParams = { [key: string]: string };

export const Footer = ({
  onDateLinkClick,
  onTimeLinkClick,
  onCtaButtonClick,
  ctaButtonText,
  isSkipCtaButton = false,
  isButtonDisabled = false,
  isErrorTextVisible = false,
  errorText,
  isAdditionalInformationTextVisible = false,
  isAdditionalInformationPage = false,
  isSelectedDateTimeFilterTextOnly,
  isButtonLoading = false,
  resourceContainerRef,
}: FooterProps): JSX.Element => {
  const intl = useIntl();
  const isMobileDevice = useIsSmallViewportWidth();
  const { resource } = useSelector(resourceState);

  const { startDate } = useSearchParams<searchParams>();
  const isPaidResource = !!(
    resource.minimum_price || resource.booking_windows.some(({ pricing_rules }) => pricing_rules?.length)
  );
  const buttonVariant = isSkipCtaButton ? 'skip' : 'primary';

  return (
    <FooterContainer isMobileDevice={isMobileDevice}>
      <ButtonContainer>
        <ButtonWrapper
          startDate={!!startDate}
          isPaidResource={isPaidResource}
          isAdditionalInformationPage={isAdditionalInformationPage}
        >
          {isAdditionalInformationTextVisible && (
            <AdditionalInfoRequiredText>
              {intl.formatMessage({ id: 'additional_information_required' })}
            </AdditionalInfoRequiredText>
          )}
          {isErrorTextVisible && <ErrorText data-testid="error-text">{errorText}</ErrorText>}
          <DateTimeContainer>
            <SelectedDateTimeFilter
              resourceContainerRef={resourceContainerRef}
              isSelectedDateTimeFilterTextOnly={isSelectedDateTimeFilterTextOnly}
              onDateClick={onDateLinkClick}
              onTimeClick={onTimeLinkClick}
            />
            <CtaButton
              loading={isButtonLoading}
              text={ctaButtonText}
              onClick={onCtaButtonClick}
              data-testid="check-button"
              startDate={!!startDate}
              isPaidResource={isPaidResource}
              disabled={isButtonDisabled}
              multiline={false}
              variant={buttonVariant}
            />
          </DateTimeContainer>
        </ButtonWrapper>
      </ButtonContainer>
    </FooterContainer>
  );
};
