import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { replace } from 'store/router/actions';
import moment, { Moment } from 'moment';
import { BUTTON_VARIANT, UserDialogButton } from '@hqo/react-components-library';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { getQueryString } from 'pages/filter-modal/date-filter/utils/getQueryString';
import { BookingTimeHandler } from 'utils/bookingTimeHandler/bookingTimeHandler';
import { BOOKING_DAYS_LIMIT } from 'shared/consts';
import { DEFAULT_DATE_FORMAT } from 'pages/resource/constants';

type searchParams = { [key: string]: string };

interface UseDateFilterReturnValues {
  value: Moment;
  startDate: string;
  setValue: React.Dispatch<Moment>;
  onSave: VoidFunction;
  onClear: VoidFunction;
  onChangeHandler: (date: Moment) => void;
  startDatesValue: Array<Moment>;
}

export const useDateFilter = (setIsOpenedFalse: VoidFunction): UseDateFilterReturnValues => {
  const { isMultiDayBookingEnabled } = useAppInstanceConfigFeature();
  const [startDateValue, setStartDateValue] = useState<Moment>(null);
  const [startDatesValue, setStartDatesValue] = useState<Array<Moment>>(null);

  const { startDate, startDates, ...restQueryParams } = useSearchParams<searchParams>();
  const dispatch = useDispatch();
  const formattedStartDates = startDates?.split(',') || [];
  const isCartOverloaded = formattedStartDates.length >= BOOKING_DAYS_LIMIT;

  useEffect(() => {
    setStartDateValue(startDate?.length ? moment(startDate) : null);
    if (isMultiDayBookingEnabled) {
      setStartDatesValue(startDates?.length ? startDates.split(',').map(date => moment(date)) : null);
    }
  }, [startDate, startDates, setStartDateValue, setStartDatesValue, isMultiDayBookingEnabled]);

  const onSave = useCallback(() => {
    setIsOpenedFalse();
  }, [setIsOpenedFalse]);

  const onChangeHandler = useCallback(
    (date: Moment) => {
      const queryString = getQueryString(date, restQueryParams, isMultiDayBookingEnabled && { startDate, startDates });
      const formattedDateValue = moment(date).format(DEFAULT_DATE_FORMAT);
      if (isCartOverloaded && !formattedStartDates.includes(formattedDateValue)) return;

      dispatch(replace(`${location.pathname}?${queryString}`));

      if (!isMultiDayBookingEnabled) {
        setIsOpenedFalse();
      }
    },
    [
      restQueryParams,
      isMultiDayBookingEnabled,
      startDate,
      startDates,
      dispatch,
      setIsOpenedFalse,
      isCartOverloaded,
      formattedStartDates,
    ],
  );

  const onClear = useCallback(() => {
    const queryString = (startDateValue || startDatesValue) && qs.stringify(restQueryParams);
    if (queryString) {
      dispatch(replace(`${location.pathname}?${queryString}`));
    }
    setStartDateValue(null);
    setStartDatesValue(null);
  }, [dispatch, restQueryParams, startDateValue, startDatesValue]);

  return {
    value: startDateValue,
    startDate,
    onSave,
    onClear,
    setValue: setStartDateValue,
    onChangeHandler,
    startDatesValue,
  };
};

interface UseDatePickerReturnValues {
  value: Moment;
  formattedDate: string;
  minDate: Moment;
  buttons: UserDialogButton[];
  locale: string;
  onChangeHandler: (date: Moment) => void;
  startDatesValue: Array<Moment>;
}

interface UseDatePickerProps {
  setIsOpenedFalse: VoidFunction;
}

export const useDatePicker = ({ setIsOpenedFalse }: UseDatePickerProps): UseDatePickerReturnValues => {
  const locale = useLocale();
  const { value, startDate, onSave, onClear, onChangeHandler, startDatesValue } = useDateFilter(setIsOpenedFalse);
  const minDate = useMemo(() => moment().startOf('day'), []);
  const formattedDate = useMemo(
    () =>
      (startDate && moment(startDate).format('MMMM DD, YYYY')) ??
      (startDatesValue &&
        new BookingTimeHandler({ locale }).getDatesRangeString(
          startDatesValue.map(dates => dates.format('YYYY-MM-DD')),
        )),
    [locale, startDate, startDatesValue],
  );
  const intl = useIntl();

  const buttons: UserDialogButton[] = useMemo(
    () => [
      { title: intl.formatMessage({ id: 'save' }), size: 'sm', handler: onSave },
      {
        title: intl.formatMessage({ id: 'clear' }),
        variant: BUTTON_VARIANT.PRIMARY_LINK,
        size: 'sm',
        handler: onClear,
      },
    ],
    [intl, onSave, onClear],
  );

  return { value, formattedDate, minDate, buttons, locale, onChangeHandler, startDatesValue };
};
