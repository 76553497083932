import { Grid, Section, SectionHeader } from '../styles';
import React from 'react';

import { Feature } from 'store/resource/types';
import { IconTextBlock } from '@hqo/react-components-library/dist/molecules/iconTextBlock';
import { formatLink } from 'utils/FormatLink';
import { useIntl } from 'react-intl';
import { useIconValues } from 'hooks/use-icon-values';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';

interface AmenityProps {
  feature: Feature;
}

interface AmenitiesProps {
  features: Array<Feature>;
}

const Amenity = ({ feature }: AmenityProps) => {
  const { id, name, icon, cta_value, cta_type } = feature;

  const { getIconValues } = useIconValues();
  const { iconName } = icon ? getIconValues(icon) : { iconName: faCircleCheck.iconName };

  const intl = useIntl();

  return (
    <IconTextBlock
      key={id}
      icon={iconName}
      iconType={'far'}
      text={name ? intl.formatMessage({ id: name }) : ''}
      linkRef={formatLink(cta_type, cta_value)}
      wrapText
      shouldOpenInNewTab={!/mobi/i.test(navigator.userAgent)}
    />
  );
};

export const Amenities = ({ features }: AmenitiesProps) => {
  const intl = useIntl();
  const activeFeatures = features?.filter(feature => feature.active);

  return activeFeatures?.length ? (
    <Section>
      <SectionHeader>{intl.formatMessage({ id: 'amenities' })}</SectionHeader>
      <Grid>
        {activeFeatures.map(feature => (
          <Amenity key={feature.id} feature={feature} />
        ))}
      </Grid>
    </Section>
  ) : null;
};
