import { Route, Routes } from 'react-router-dom';
import { DateTimeSelectModal } from '../date-time-select-modal';
import React, { useMemo } from 'react';
import { TimeRangeByDurationContext } from 'utils/timeRangeByDurationContext';
import { useSelectModals } from 'pages/resource/cta-button/use-select-modals.hook';
import { Footer } from 'components/footer';
import { useSelector } from 'react-redux';
import { selectPriceStatus } from 'store/price/selectors';
import { resourceState } from 'store/bookings/selectors';
import { selectCreateCartStatus } from 'store/cart/selectors';
import { isButtonLoading } from 'utils/isButtonLoading';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface CtaButtonProps {
  onSubmitBookingRequest: VoidFunction;
  ctaButtonValue: string;
  resourceContainerRef: React.MutableRefObject<HTMLDivElement>;
  disabled?: boolean;
  unitCodes?: string[];
}

export type searchParams = { [key: string]: string };

export const CtaButton = ({
  onSubmitBookingRequest,
  ctaButtonValue,
  resourceContainerRef,
  disabled,
}: CtaButtonProps): JSX.Element => {
  const {
    onClose,
    isDateStep,
    onNextClick,
    onCtaButtonClick,
    onViewAvailabilityClick,
    onTimeLinkClick,
    onDateLinkClick,
    timeRangeByDuration,
    onPressBack,
    backToDateStep,
    onPressSave,
    isModalWithoutSlideAnimation,
    reverseHorizontalAnimation,
    onDateTimeSaveClick,
  } = useSelectModals(onSubmitBookingRequest);
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { resource } = useSelector(resourceState);
  const getPriceStatus = useSelector(selectPriceStatus);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const isButtonDisabled = isButtonLoading(getPriceStatus) || isButtonLoading(createCartStatus) || disabled;
  const isAdditionalInformationTextVisible = useMemo(() => resource?.user_prompts?.length > 0, [resource]);

  return (
    <TimeRangeByDurationContext.Provider value={timeRangeByDuration}>
      <Footer
        resourceContainerRef={resourceContainerRef}
        onDateLinkClick={onViewAvailabilityClick}
        onTimeLinkClick={onTimeLinkClick}
        onCtaButtonClick={onCtaButtonClick}
        ctaButtonText={ctaButtonValue}
        isButtonDisabled={isButtonDisabled}
        isButtonLoading={isButtonLoading(createCartStatus)}
        isAdditionalInformationTextVisible={isAdditionalInformationTextVisible}
      />
      <Routes>
        <Route
          path="date-schedule-select"
          element={
            <DateTimeSelectModal
              reverseHorizontalAnimation={reverseHorizontalAnimation}
              isModalWithoutSlideAnimation={isModalWithoutSlideAnimation}
              onPressSave={onPressSave}
              onClose={onClose}
              isDateStep={isDateStep}
              onNextClick={onNextClick}
              onDateLinkClick={onDateLinkClick}
              onTimeLinkClick={onTimeLinkClick}
              onPressBack={onPressBack}
              backToDateStep={backToDateStep}
              showNativeMobileHeader={!showResourceDetailsDateTimeInputs}
              onDateTimeSaveClick={onDateTimeSaveClick}
            />
          }
        />
        <Route
          path="date-schedule-select/*"
          element={
            <DateTimeSelectModal
              reverseHorizontalAnimation={reverseHorizontalAnimation}
              isModalWithoutSlideAnimation={isModalWithoutSlideAnimation}
              onPressSave={onPressSave}
              onClose={onClose}
              isDateStep={isDateStep}
              onNextClick={onNextClick}
              onDateLinkClick={onDateLinkClick}
              onTimeLinkClick={onTimeLinkClick}
              onPressBack={onPressBack}
              backToDateStep={backToDateStep}
              showNativeMobileHeader={!showResourceDetailsDateTimeInputs}
              onDateTimeSaveClick={onDateTimeSaveClick}
            />
          }
        />
      </Routes>
    </TimeRangeByDurationContext.Provider>
  );
};
