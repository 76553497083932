import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { replace } from 'store/router/actions';

import { ArrowLeftChevron } from '@hqo/react-components-library/dist/icons';
import {
  Container,
  ListViewContainer,
  FloorPlanContainer,
  FloorPlanPageContainer,
  Header,
  HeaderSpan,
  FloorPlanListViewContainer,
} from './styles';
import { ArchilogicEvent, FloorPlan, InteractionEvent, Space } from './types';
import { useArchilogicConfiguration } from 'floorplan/pages/floorplan/hooks/use-archilogic-configuration.hook';
import { useFloorPlanPage } from 'floorplan/pages/floorplan/hooks/use-floorplan-page.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useFloorPlanAvailability } from 'floorplan/pages/floorplan/hooks/use-floorplan-availability.hook';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  getTargetSpace,
  getLinkedSpaceBySpace,
  mapAvailableSpaces,
  isAvailable,
  highlightSpaces,
  colorResource,
  stripQueryParams,
} from 'floorplan/utils/archilogic.helpers';
import { FLOORPLAN_COLORS } from 'floorplan/const';
import { FloorSelection } from 'components/floor-select/floor-select';
import { FloorPlanDrawer } from 'components/floor-plan-drawer';
import { useResourceAdapterConfiguration } from 'floorplan/pages/floorplan/hooks/use-resource-adapter-configuration.hook';
import { PaymentContent } from 'components/payment-content';
import { usePaymentModal } from 'hooks/payment-hook/use-payment-modal.hook';
import { useQuickCheckout } from 'hooks/use-quick-checkout.hook';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';
import { useCheckoutScreen } from 'hooks/payment-content/use-checkout-screen.hook';
import { useUpdateAppInstanceConfigOnFloorplanClick } from 'floorplan/pages/floorplan/hooks/use-resource-fetching.hook';
import { DateTimeSelectModal } from 'pages/resource/date-time-select-modal';
import { useDateScheduleModalNavigation } from 'hooks/use-date-schedule-modal-navigation.hook';
import { FloorPlanDrawerContent } from 'components/floor-plan-drawer/floor-plan-drawer-content';
import { FloorplanPopup } from 'components/floor-plan-popup';
import { useResourceTile } from 'hooks/use-resource-tile.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useLinkedSpacesFiltering } from './hooks/use-linkedspaces-filtering.hook';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useListView } from 'floorplan/pages/floorplan/hooks/use-list-view.hook';
import { useArchilogicFloorPlan } from 'floorplan/pages/floorplan/hooks/use-archilogic-floor-plan.hook';
import { ModalContainer } from 'pages/resource/components/terms-and-conditions/components/modal-container';

export const FloorPlanPage = (): JSX.Element => {
  const { floor, buildingName, publishableToken, linkedSpaces } = useFloorPlanPage();
  const dispatch = useDispatch();
  const isMobileDevice = useIsSmallViewportWidth();
  const [clickedResource, setClickedResource] = useState<InteractionEvent>(null);
  const { availableResources, allResources, isAvailabilityEnabled, isAvailabilityLoading } = useFloorPlanAvailability();
  const filteredLinkedSpaces = useLinkedSpacesFiltering(linkedSpaces, allResources);
  const archilogicConfiguration = useArchilogicConfiguration(filteredLinkedSpaces);
  const { search, pathname } = useLocation();
  const [floorPlan, setFloorPlan] = useState<FloorPlan>(null);
  const hoveredResources: Set<Space> = new Set();
  const archilogicClickedResource: Array<InteractionEvent> = [];
  const [floorplanEnabled, setFloorplanEnabled] = useState<boolean>(false);
  const [areSpacesLoaded, setAreSpacesLoaded] = useState<boolean>(false);
  const { hasCompactUISupport } = useResourceAdapterConfiguration();
  const { closeQuickCheckoutHandler, openQuickCheckoutHandler } = useQuickCheckout();
  const { reverseAnimation } = usePaymentModal(openQuickCheckoutHandler);
  const client = useMiniappSdk();
  const {
    isCancelBookingStep,
    setIsCancelBookingStep,
    onCloseReceipt,
    onPressBack: onPressBackReceipt,
    openReceipt,
  } = useReceiptScreen();
  const { onCloseCheckout, openCheckout } = useCheckoutScreen();
  const searchParams = useSearchParams();
  const useListViewInfo = useListView(filteredLinkedSpaces, allResources, availableResources);
  const { archilogicSpaces } = useArchilogicFloorPlan(floorPlan);

  const {
    reverseHorizontalAnimation,
    isModalWithoutSlideAnimation,
    onPressSave,
    toggleModal,
    onNextClick,
    onTimeLinkClick,
    onDateLinkClick,
    isDateStep,
    backToDateStep,
    onPressBack,
    toggleModalSlideAnimation,
    toggleReverseAnimation,
  } = useDateScheduleModalNavigation();

  useUpdateAppInstanceConfigOnFloorplanClick(clickedResource, filteredLinkedSpaces);

  const onFloorplanPressBack = useCallback(() => {
    setFloorPlan(null);
    dispatch(
      replace(
        `/?${stripQueryParams(search, [
          'startDate',
          'startTime',
          'endTime',
          'endDate',
          'duration',
          'isExact',
          'startDates',
        ])}`,
      ),
    );
  }, [floorPlan]);

  useEffect(() => {
    let fpWrapperEl = document.getElementById('resource-booking-floorplan-wrapper');
    let floorplanEngineEl: HTMLDivElement;
    if (!fpWrapperEl) {
      fpWrapperEl = document.createElement('div');
      fpWrapperEl.setAttribute('id', 'resource-booking-floorplan-wrapper');
      document.body.appendChild(fpWrapperEl);

      floorplanEngineEl = document.createElement('div');
      floorplanEngineEl.setAttribute('id', 'resource-booking-floorplan-engine');
      floorplanEngineEl.style.height = '100%';
      fpWrapperEl.appendChild(floorplanEngineEl);
    } else {
      floorplanEngineEl = document.getElementById('resource-booking-floorplan-engine') as HTMLDivElement;
    }
    document
      .getElementById('resource-booking-floorplan')
      .appendChild(document.getElementById('resource-booking-floorplan-engine'));

    return () => {
      fpWrapperEl.appendChild(floorplanEngineEl);
      fpWrapperEl.style.display = 'none';

      document.getElementById('resource-booking-floorplan-engine').style.display = 'none';
    };
  }, []);

  const spacesAvailabilityInfo = useMemo(() => {
    if (floorPlan && filteredLinkedSpaces?.size && areSpacesLoaded) {
      return mapAvailableSpaces(filteredLinkedSpaces, availableResources, archilogicSpaces);
    }

    return {};
  }, [archilogicSpaces, filteredLinkedSpaces?.size, availableResources, areSpacesLoaded]);

  const { onButtonClick } = useResourceTile({
    toggleReverseAnimation,
    toggleModalSlideAnimation,
    isAvailable:
      !!spacesAvailabilityInfo[clickedResource?.archilogicUuid]?.availableResource && !searchParams.presetWindows,
  });

  const handleMouseOver = (floorPlanEngine: FloorPlan, evt: ArchilogicEvent) => {
    const resource = getTargetSpace(floorPlanEngine, evt, filteredLinkedSpaces);
    const hoveredResourcesList = [...hoveredResources];
    hoveredResourcesList.forEach(element => {
      if (element.id !== resource?.id) {
        isAvailable(element, spacesAvailabilityInfo)
          ? colorResource(element, FLOORPLAN_COLORS.green, 0.6)
          : colorResource(element, FLOORPLAN_COLORS.unavailable_unselected, 1);
      }
    });

    hoveredResources.clear();

    if (resource && archilogicClickedResource[0]?.space?.id !== resource.id) {
      hoveredResources.add(resource);
      if (isAvailable(resource, spacesAvailabilityInfo)) {
        colorResource(resource, FLOORPLAN_COLORS.green, 0.4);
      } else {
        colorResource(resource, FLOORPLAN_COLORS.unavailable_unselected, 0.6);
      }
    }
  };

  const handleClickOnFloorplan = (floorPlanEngine: FloorPlan, event: ArchilogicEvent) => {
    const targetSpace = getTargetSpace(floorPlanEngine, event, filteredLinkedSpaces);
    const foundResource = getLinkedSpaceBySpace(filteredLinkedSpaces, targetSpace);

    const previousInteraction: InteractionEvent = archilogicClickedResource[0];
    if (previousInteraction) {
      if (spacesAvailabilityInfo[previousInteraction.archilogicUuid]?.availableResource) {
        colorResource(previousInteraction.space, FLOORPLAN_COLORS.green, 0.6);
      } else {
        colorResource(previousInteraction.space, FLOORPLAN_COLORS.unavailable_unselected, 1);
      }
    }

    archilogicClickedResource.pop();

    if (foundResource) {
      const currentClickInteraction: InteractionEvent = {
        name: foundResource.name,
        uuid: foundResource.resource_booking_id,
        archilogicUuid: foundResource.archilogic_id,
        space: targetSpace,
      };
      colorResource(targetSpace, FLOORPLAN_COLORS.blue, 1);
      archilogicClickedResource.push(currentClickInteraction);
      setClickedResource(currentClickInteraction);
    } else {
      setClickedResource(null);
    }
  };

  useEffect(() => {
    if (!floorplanEnabled && floor?.spaces) {
      setFloorplanEnabled(true);

      if (window.ArchilogFloorPlanEngine === undefined) {
        const floorplanEngineEl: HTMLDivElement = document.getElementById(
          'resource-booking-floorplan-engine',
        ) as HTMLDivElement;

        const ArchilogFloorPlanEngine = new window.FloorPlanEngine(floorplanEngineEl, archilogicConfiguration);
        window.ArchilogFloorPlanEngine = ArchilogFloorPlanEngine;
        setFloorPlan(ArchilogFloorPlanEngine);
      } else {
        setFloorPlan(window.ArchilogFloorPlanEngine);
      }
    }
  }, [floorplanEnabled, floor?.spaces]);

  useEffect(() => {
    if (window.ArchilogFloorPlanEngine !== undefined) {
      window.ArchilogFloorPlanEngine.set(archilogicConfiguration);
    }
  }, [archilogicConfiguration?.spaceLabelMapping]);

  useEffect(() => {
    setAreSpacesLoaded(false);
  }, [floor?.uuid]);

  useEffect(() => {
    if (floorPlan && floor?.apps.length > 0) {
      floorPlan
        .loadScene(floor.apps[0].external_resource_id, {
          publishableToken,
        })
        .then(() => {
          document.getElementById('resource-booking-floorplan-engine').style.display = 'block';
          setAreSpacesLoaded(true);
        });
    }
  }, [floorPlan, floor?.uuid, floor?.apps]);

  useEffect(() => {
    let handleClickOnFloorplanEvent: () => void = null;
    let handleMouseMoveFloorplanEvent: () => void = null;

    if (!areSpacesLoaded || !floorPlan || isAvailabilityLoading) {
      return;
    }

    handleClickOnFloorplanEvent = handleClickOnFloorplan.bind(null, floorPlan);
    handleMouseMoveFloorplanEvent = handleMouseOver.bind(null, floorPlan);

    floorPlan.on('click', handleClickOnFloorplanEvent);

    if (isAvailabilityEnabled) {
      floorPlan.on('mousemove', handleMouseMoveFloorplanEvent);
    }

    return () => {
      if (floorPlan) {
        floorPlan.off('click', handleClickOnFloorplanEvent);
        floorPlan.off('mousemove', handleMouseMoveFloorplanEvent);
      }
    };
  }, [floorPlan, areSpacesLoaded, filteredLinkedSpaces, spacesAvailabilityInfo, isAvailabilityLoading]);

  useEffect(() => {
    if (isAvailabilityEnabled && areSpacesLoaded && archilogicSpaces && filteredLinkedSpaces?.size) {
      highlightSpaces(spacesAvailabilityInfo, isAvailabilityLoading);
    }
  }, [
    isAvailabilityEnabled,
    areSpacesLoaded,
    archilogicSpaces,
    filteredLinkedSpaces,
    spacesAvailabilityInfo,
    isAvailabilityLoading,
  ]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    client?.header.hideHeader();
  }, [document.body.style.overflow]);

  return (
    <FloorPlanPageContainer>
      <Container isMobile={isMobileDevice}>
        {floor && (
          <Header zIndex={pathname.includes('/select-floor-modal') ? 12 : 16}>
            <ArrowLeftChevron data-testid="arrowLeft" variant="adminBlack" size="md" onClick={onFloorplanPressBack} />
            <FloorSelection
              floorName={floor.name}
              buildingName={buildingName}
              isMobileDevice={isMobileDevice}
              setClickedResource={setClickedResource}
            />
            <HeaderSpan />
          </Header>
        )}
        <FloorPlanListViewContainer>
          {isAvailabilityEnabled && !isMobileDevice && (
            <ListViewContainer data-testid="floor-plan-list-view-large-screen-container">
              <FloorPlanDrawerContent
                resourceListViewAvailability={useListViewInfo}
                toggleModalSlideAnimation={toggleModalSlideAnimation}
                toggleReverseAnimation={toggleReverseAnimation}
              />
            </ListViewContainer>
          )}
          <FloorPlanContainer data-testid="floorplan-page" id="resource-booking-floorplan">
            {clickedResource?.name && (
              <FloorplanPopup
                resourceUuid={clickedResource?.uuid}
                resourceName={clickedResource?.name}
                capacity={allResources.find(resource => resource.uuid === clickedResource.uuid)?.capacity}
                isAvailable={!!spacesAvailabilityInfo[clickedResource?.archilogicUuid]?.availableResource}
                onClick={onButtonClick}
                isCompactUI={hasCompactUISupport(spacesAvailabilityInfo[clickedResource?.archilogicUuid])}
                toggleReverseAnimation={toggleReverseAnimation}
                toggleModalSlideAnimation={toggleModalSlideAnimation}
              />
            )}
          </FloorPlanContainer>
        </FloorPlanListViewContainer>
      </Container>
      <PaymentContent
        openReceipt={openReceipt}
        reverseAnimation={reverseAnimation}
        closeQuickCheckoutHandler={closeQuickCheckoutHandler}
        isCancelBookingStep={isCancelBookingStep}
        setIsCancelBookingStep={setIsCancelBookingStep}
        onCloseReceipt={onCloseReceipt}
        onPressBack={onPressBackReceipt}
        onCloseCheckout={onCloseCheckout}
        openCheckout={openCheckout}
      />
      {isAvailabilityEnabled && isMobileDevice && (
        <FloorPlanDrawer
          toggleModalSlideAnimation={toggleModalSlideAnimation}
          toggleReverseAnimation={toggleReverseAnimation}
          resourceListViewAvailability={useListViewInfo}
        />
      )}
      <Routes>
        <Route
          path="date-schedule-select"
          element={
            <DateTimeSelectModal
              reverseHorizontalAnimation={reverseHorizontalAnimation}
              isModalWithoutSlideAnimation={isModalWithoutSlideAnimation}
              onPressSave={onPressSave}
              onClose={toggleModal}
              isDateStep={isDateStep}
              onNextClick={onNextClick}
              onDateLinkClick={onDateLinkClick}
              onTimeLinkClick={onTimeLinkClick}
              onPressBack={onPressBack}
              backToDateStep={backToDateStep}
            />
          }
        />
        <Route path="quick-checkout/terms-and-conditions" element={<ModalContainer />} />
      </Routes>
    </FloorPlanPageContainer>
  );
};
