import { Image } from 'components/image';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';
import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library';
import { CONTENT_BORDER_RADIUS, RESOURCE_SECTIONS_Z_INDEXES } from 'pages/resource/constants';

export const HoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledMobileHeaderWrapper = styled.div`
  z-index: ${RESOURCE_SECTIONS_Z_INDEXES.HEADER_AND_FOOTER};
`;

export const Container = styled.div<{ showResourceDetailsDateTimeInputs?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
  padding: 34px 52px;
  overflow-y: auto;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    ${({ showResourceDetailsDateTimeInputs }) =>
      showResourceDetailsDateTimeInputs ? 'padding: 0;' : 'padding: 0 20px;'}
  }
`;

export const Title = styled.h1`
  margin: 30px 0 0 0;
  font: 700 36px/36px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    color: ${({ theme }) => theme.colors.fontColor};
    font: 700 24px/24px ${({ theme }) => theme.fonts.join()};
  }
`;

export const Subtitle = styled.h2`
  font: 500 14px/14px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const SectionHeader = styled.div`
  display: block;
  font: 600 16px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin: 0 0 16px 0;
`;

export const Section = styled.div<{ hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  margin: 30px 0;
`;

export const AvailabilityDay = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const DayText = styled.div`
  width: 60px;
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const HoursRangeText = styled.div`
  font: 500 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const IconBlockContainer = styled.div`
  margin: 30px 0;
`;

export const StyledImage = styled(Image)<{ showResourceDetailsDateTimeInputs?: boolean }>`
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: auto;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    ${({ showResourceDetailsDateTimeInputs }) =>
      showResourceDetailsDateTimeInputs &&
      `
      position: fixed;
      z-index: ${RESOURCE_SECTIONS_Z_INDEXES.IMAGE_SECTION};
    `}
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 15px 15px;
`;

export const ResourceContainer = styled.div<{ showResourceDetailsDateTimeInputs?: boolean }>`
  width: min(700px, 100%);
  padding-bottom: 90px;

  &:last-child {
    margin-bottom: 50px;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding-bottom: 90px;
    ${({ showResourceDetailsDateTimeInputs }) => showResourceDetailsDateTimeInputs && 'position: relative;'}
  }
`;

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    height: calc(100vh - 70px);
  }
`;

export const Content = styled.p`
  color: ${({ theme }) => theme.colors.fontColor};
  font-size: 14px;
`;

export const BackLinkWrapper = styled.div`
  margin-bottom: 34px;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    margin: 11px 0;
  }
`;

export const StyledDot = styled.div<{ showResourceDetailsDateTimeInputs?: boolean }>`
  margin: 10px 4px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${({ theme, showResourceDetailsDateTimeInputs }) =>
    showResourceDetailsDateTimeInputs ? theme.colors.palette.systemBlack : theme.colors.$secondary};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ScrollableTrayContainer = styled.div<{ marginTop: number; isMobileDevice: boolean }>`
  position: relative;
  padding: 20px;
  z-index: ${({ isMobileDevice }) =>
    isMobileDevice ? RESOURCE_SECTIONS_Z_INDEXES.SCROLLABLE_CONTENT_SECTION : `unset`};
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background: ${({ theme }) => theme.colors.$white};
  ${({ marginTop, isMobileDevice }) => isMobileDevice && `margin-top: ${marginTop - CONTENT_BORDER_RADIUS}px;`}

  ${({ isMobileDevice }) =>
    isMobileDevice &&
    `
      border-top-left-radius: ${CONTENT_BORDER_RADIUS}px;
      border-top-right-radius: ${CONTENT_BORDER_RADIUS}px;
    `}
`;
