import { useDispatch, useSelector } from 'react-redux';
import { setSelectedFloor } from 'store/spaceManager/actions';
import { selectFloor, selectFloors, selectGetFloorsStatus } from 'store/spaceManager/selectors';
import { push, replace } from 'store/router/actions';
import { useEffect, useMemo } from 'react';
import { ACTION_STATUSES } from 'shared/consts';
import { selectFloorPlanAppInstanceConfig } from 'store/floorplan-app-instance-config/selectors';
import { AppInstanceConfig } from 'store/app-instance-configs/types';
import { Floor } from 'store/spaceManager/types';
import { useTimeZone } from 'hooks/use-timezone.hook';

import qs from 'qs';
import moment from 'moment-timezone';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { FLOORPLAN_SELECT_FLOOR_STORAGE_KEY } from 'floorplan/const';
import { useLocation } from 'react-router-dom';
import { DEFAULT_DATE_FORMAT } from 'pages/resource/constants';

export const useSelectAvailableFloor = () => {
  const timezone = useTimeZone();
  const appInstanceConfig: AppInstanceConfig = useSelector(selectFloorPlanAppInstanceConfig);
  const floors: Floor[] = useSelector(selectFloors);
  const selectedFloor: Floor = useSelector(selectFloor);
  const searchParams = useSearchParams();
  const { pathname } = useLocation();

  const updatedSearchParams = useMemo(() => {
    if (!(searchParams.startDate || searchParams.startDates)) {
      const currentDateTz = moment().tz(timezone);
      const remainder = 15 - (currentDateTz.minute() % 15);

      return qs.stringify({
        ...searchParams,
        duration: 60,
        isExact: true,
        startDate: currentDateTz.format(DEFAULT_DATE_FORMAT),
        startTime: currentDateTz.clone().add(remainder, 'minutes').format('HH:mm'),
        endTime: currentDateTz
          .clone()
          .add(remainder + 60, 'minutes')
          .format('HH:mm'),
      });
    }

    return qs.stringify(searchParams);
  }, [searchParams, timezone]);

  const dispatch = useDispatch();
  const floorsStatus = useSelector(selectGetFloorsStatus);

  const selectableFloors = useMemo((): Floor[] => {
    return floors.filter(
      floor => floor.apps.filter(app => app.app_instance_config_uuid === appInstanceConfig?.uuid).length === 1,
    );
  }, [floors, appInstanceConfig]);

  useEffect(() => {
    if (!selectedFloor && selectableFloors?.length) {
      const storedSelectedFloorUuid =
        localStorage.getItem(FLOORPLAN_SELECT_FLOOR_STORAGE_KEY) ?? selectableFloors[0].uuid;
      const storedSelectedFloor =
        selectableFloors.find(floor => floor.uuid === storedSelectedFloorUuid) ?? selectableFloors[0];
      dispatch(setSelectedFloor(storedSelectedFloor));
      dispatch(replace(`/floorplan/${storedSelectedFloor.uuid}?${updatedSearchParams}`));
    }
  }, [selectableFloors, selectedFloor, updatedSearchParams]);

  useEffect(() => {
    if (selectedFloor && /^\/floorplan\/*[a-f0-9-]*$/.test(pathname)) {
      localStorage.setItem(FLOORPLAN_SELECT_FLOOR_STORAGE_KEY, selectedFloor.uuid);
      dispatch(replace(`/floorplan/${selectedFloor.uuid}?${updatedSearchParams}`));
    }
  }, [selectedFloor, updatedSearchParams, pathname]);

  useEffect(() => {
    if (floorsStatus === ACTION_STATUSES.FULFILLED && selectableFloors?.length === 0) {
      dispatch(push('/error'));
    }
  }, [floorsStatus, selectableFloors]);
};
