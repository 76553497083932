import styled, { css } from 'styled-components';
import { CaretUpSharp, CaretDownSharp } from '@hqo/react-components-library';

interface ContainerProps {
  isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 4px;
  width: 175px;

  position: ${({ isActive }) => (isActive ? 'relative' : 'static')};
`;

interface ContainerProps {
  isActive: boolean;
}

interface ValueContainerProps {
  active: string;
  disabled?: boolean;
}

const activeStyles: Record<NonNullable<ValueContainerProps['active']>, ReturnType<typeof css>> = {
  true: css`
    border: 1px solid ${({ theme }) => theme.colors.primary};
  `,
  false: css`
    border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
  `,
};

const disabledStyled: ReturnType<typeof css> = css`
  &.field {
    background-color: ${({ theme }) => theme.colors.greys.adminGreyLight};
    color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
    pointer-events: none;
  }
  cursor: auto;
`;

export const ValueContainer = styled.div<ValueContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 12px 12px 12px 16px;
  cursor: pointer;
  border-radius: 4px;
  ${({ active }) => activeStyles[active]}
  ${({ disabled }) => disabled && disabledStyled}
`;

export const Value = styled.p`
  margin: 0 10px;
  font-family: ${({ theme }) => theme.fonts.join()};
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.fontColor};
  max-width: 95%;
  max-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Placeholder = styled(Value)<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.colors.greys.adminGreyMediumAlt : theme.colors.fontColor)};
  margin-left: 10px;
`;

export const ValueTimeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CaretUp = styled(CaretUpSharp)`
  pointer-events: none;
`;

export const CaretDown = styled(CaretDownSharp)`
  pointer-events: none;
`;
