import { HandleContainer, Handle } from './styles';
import React from 'react';

interface HandleBlockProps {
  dataValue: string;
  dataCy?: string;
  onMouseDown: (event: React.MouseEvent) => void;
  onTouchStart: (event: React.TouchEvent) => void;
}

export const HandleBlock = ({ dataValue, onMouseDown, onTouchStart, dataCy }: HandleBlockProps): JSX.Element => {
  return (
    <HandleContainer
      data-cy={dataCy}
      data-testid="handle-block"
      data-value={dataValue}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
    >
      <Handle data-value={dataValue} />
    </HandleContainer>
  );
};
