import { DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';

export const Container = styled.div<{ isLoading?: boolean }>`
  padding: 34px 52px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  ${({ isLoading }) => isLoading && 'padding: 0;'}

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 32px 16px;
    ${({ isLoading }) => isLoading && 'padding: 0;'}
  }

  @media only screen and (max-width: ${DIMENSIONS.SMALL}) {
    padding: 28px 13px;
    ${({ isLoading }) => isLoading && 'padding: 0;'}
  }
`;

export const Grid = styled.div<{ isNoResources: boolean; isCompactUiEnabled: boolean }>`
  display: grid;
  ${({ isCompactUiEnabled }) => (isCompactUiEnabled ? 'gap: 24px 20px;' : 'gap: 32px 24px;')};
  ${({ isCompactUiEnabled }) =>
    isCompactUiEnabled
      ? 'grid-template-columns: repeat(auto-fit, 220px);'
      : 'grid-template-columns: repeat(auto-fill, minmax(min(428px, 100%), 1fr));'};
  ${({ isNoResources }) =>
    isNoResources &&
    `grid-template-columns: none;
    height: 100%;
  `};

  &:last-child {
    padding-bottom: 24px;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    ${({ isCompactUiEnabled }) => isCompactUiEnabled && 'gap: 16px 24px;'}
    ${({ isCompactUiEnabled }) =>
      isCompactUiEnabled && 'grid-template-columns: repeat(auto-fill, minmax(min(428px, 100%), 1fr));'}
  }
`;

export const ResourcesCounter = styled.div`
  font: normal 16px/19px ${({ theme }) => theme.fonts.join()};
  line-height: 19px;
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: 27px;
`;

export const StyledBackLink = styled.div`
  margin-bottom: 18px;
`;
