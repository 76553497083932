import { useFlags } from 'launchdarkly-react-client-sdk';
import { TermsAndConditions } from '../terms-and-conditions';
import { useLocale } from 'hooks/use-locale.hook';
import { Amenities } from 'pages/resource/components/amenities';
import { DateSelectTimeline } from 'pages/resource/components/date-select-timeline/date-select-timeline';
import { ResourceAddress } from 'pages/resource/components/resource-address';
import { renderOpeningHours } from 'pages/resource/resource';
import { TitleContainer, Title, Subtitle, Section, SectionHeader, Content } from 'pages/resource/styles';
import { useResourcePage } from 'pages/resource/use-resource-page.hook';
import React from 'react';
import { useIntl } from 'react-intl';
import { Resource } from 'store/resource/types';
import { TimeRange } from 'store/resource-time-ranges/types';

export interface ContentProps {
  resource: Resource;
  isTermsAndConditionsVisible: boolean;
  filteredTimeRanges?: Array<TimeRange>;
}

export const ResourceContent = ({
  resource,
  isTermsAndConditionsVisible,
  filteredTimeRanges,
}: ContentProps): JSX.Element => {
  const intl = useIntl();
  const locale = useLocale();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { onDateInputClick, onTimeInputClick } = useResourcePage();

  return (
    <>
      <TitleContainer>
        <Title>{resource.name}</Title>
      </TitleContainer>
      <Subtitle>{resource.subtitle}</Subtitle>
      {showResourceDetailsDateTimeInputs && (
        <DateSelectTimeline
          filteredTimeRanges={filteredTimeRanges}
          onDateInputClick={onDateInputClick}
          onTimeInputClick={onTimeInputClick}
        />
      )}
      <ResourceAddress />
      {isTermsAndConditionsVisible && <TermsAndConditions />}
      <Amenities features={resource.features} />
      {!!resource.description && (
        <Section>
          <SectionHeader>{intl.formatMessage({ id: 'description' })}</SectionHeader>
          <Content dangerouslySetInnerHTML={{ __html: `${resource.description.replace(/\n/g, '<br/>')}` }} />
        </Section>
      )}
      <Section>
        <SectionHeader>{intl.formatMessage({ id: 'opening_hours' })}</SectionHeader>
        {renderOpeningHours(resource.booking_windows, intl, locale, resource.timezone)}
      </Section>
    </>
  );
};
