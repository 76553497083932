import { useCallback, useEffect, useMemo } from 'react';
import { useBooleanState } from '@hqo/react-components-library';

import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useCurrentConfig } from 'hooks/use-current-config.hook';
import { useLocale } from 'hooks/use-locale.hook';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { ACTION_STATUSES, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { selectCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/selectors';
import { resetHeaderState } from 'store/header-state/actions';
import { useResetAddOns } from 'hooks/use-reset-add-ons.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { searchParams } from 'pages/date-select-page/interface';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { OwnerTypesEnum, ResourceBookingEnum } from 'store/app-instance-configs/enums';
import { resetBookingRequest } from 'store/bookings/actions';
import { getResourceAvailableDates } from 'store/resource-available-dates/actions';
import { selectResourceAvailableDates } from 'store/resource-available-dates/selectors';
import { getResourceTimeRanges } from 'store/resource-time-ranges/actions';
import {
  selectPresetBookableWindows,
  selectResourceTimeRanges,
  selectResourceTimeRangesStatus,
} from 'store/resource-time-ranges/selectors';
import { TimeRange } from 'store/resource-time-ranges/types';
import { getResource } from 'store/resource/actions';
import { selectGetResourceStatus, selectResource } from 'store/resource/selectors';
import { Resource } from 'store/resource/types';
import { PresetWindows } from 'store/resources/types';
import { replace } from 'store/router/actions';
import { resolveGetResourceTimeRangesParams } from 'utils';
import { getEndTimeByDuration } from 'utils/getEndTimeByDuration';
import { getResourceTimeIndices } from 'utils/getResourceTimeIndices';

import { DEFAULT_DATE_FORMAT, REFRESH_INTERVAL_MS } from './constants';
import { useClosestAvailability } from './hooks/use-closest-availability.hook';
import { getResourceAvailableDatesPayload } from './utils/getResourceAvailableDatesPayload';
import { track } from '@hqo/web-tracking';

interface UseResourcePageReturnValues {
  showCheckAvailabilityModal: boolean;
  ownerType: string;
  ownerUuid: string;
  resourceUuid: string;
  toggleCheckAvailabilityModal: VoidFunction;
  resource: Resource;
  locale: string;
  isResourceLoading: boolean;
  relativePathname: string;
  search: string;
  isTermsAndConditionsVisible: boolean;
  shouldDisplayMobileHeader: boolean;
  isValidBooking: boolean;
  onTimeInputClick: VoidFunction;
  onDateInputClick: VoidFunction;
  filteredTimeRanges: Array<TimeRange>;
}

export const useResourcePage = (): UseResourcePageReturnValues => {
  const { ownerType, ownerUuid } = useOwnerParams();
  const { resourceUuid } = useParams();
  const { pathname, search } = useLocation();
  const { enableResourceDetailsDateTimeAutoselect, showResourceDetailsDateTimeInputs } = useFlags();
  const locale = useLocale();
  const { startDate, startTime, endTime, presetWindows, startDates, ...restQueryParams } =
    useSearchParams<searchParams>();
  const appInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const relativePathname = pathname.substring(0, pathname.indexOf('/additional-information'));
  const resource = useSelector(selectResource);
  const getResourceStatus = useSelector(selectGetResourceStatus);
  const currentConfig = useCurrentConfig();
  const isStartDateEmpty = !(startDate || startDates);
  const isTimeEmpty = !(startTime && endTime);
  const isTermsAndConditionsVisible: boolean =
    currentConfig?.vertical_adapter?.name === ResourceBookingEnum.PrismAdapter && !!resource.terms_and_conditions;
  const presetBookableWindows = useSelector(selectPresetBookableWindows);
  const { date, time, duration } = presetBookableWindows?.[0] || {};
  const selectedEndTime = getEndTimeByDuration(date, time, duration, locale);
  const isGetTimeRangesStatusFulfilled = useSelector(selectResourceTimeRangesStatus) === ACTION_STATUSES.FULFILLED;
  const resourceTimeRanges = useSelector(selectResourceTimeRanges);
  const formattedStartDates = startDates ? startDates.split(',') : [];
  const selectedDate = startDate || formattedStartDates?.[0];
  const updatedStartDate = selectedDate ?? moment(new Date()).format(DEFAULT_DATE_FORMAT);
  const isDatesExist = Boolean(selectedDate);

  useResetAddOns();
  useClosestAvailability();
  const dispatch = useDispatch();

  const filteredTimeRanges = useMemo<Array<TimeRange>>(() => {
    const { startResourceTimeIndex, endResourceTimeIndex, timeRangesWithLastTimeSlot } =
      getResourceTimeIndices({
        resource,
        startDate: updatedStartDate,
        startDates: formattedStartDates,
        resourceTimeRanges,
        locale,
        options: { isMultiday: Boolean(formattedStartDates.length) },
      }) || {};

    return timeRangesWithLastTimeSlot?.slice(startResourceTimeIndex, endResourceTimeIndex + 1);
  }, [resource, updatedStartDate, resourceTimeRanges, locale, formattedStartDates]);

  const routeArray = pathname.split('/');
  const isShowingDetailsPage = routeArray[routeArray.length - 1] === resourceUuid;
  const shouldDisplayMobileHeader = showResourceDetailsDateTimeInputs && isShowingDetailsPage;

  const isResourceLoading = useMemo<boolean>(() => {
    return getResourceStatus !== ACTION_STATUSES.FULFILLED;
  }, [enableResourceDetailsDateTimeAutoselect, getResourceStatus, isStartDateEmpty, isTimeEmpty]);

  const client = useMiniappSdk();

  const resourceAvailableDates = useSelector(selectResourceAvailableDates);
  const shouldDispatchGetResourceAvailableDates = !Object.keys(resourceAvailableDates)?.length && Boolean(resource?.id);

  const isValidBooking = useMemo(() => {
    if (resourceTimeRanges?.length) {
      const startTimeIndex = resourceTimeRanges.findIndex(timeRange => timeRange.time === startTime);
      const endTimeIndex = resourceTimeRanges.findIndex(timeRange => timeRange.time === endTime);

      // The last time range is at 23:45. We can have an endTime at 24:00 that is not in the timeRange
      const sliceEndTimeIndex = endTimeIndex === -1 ? resourceTimeRanges.length : endTimeIndex;
      const chosenResourceTimeRanges = resourceTimeRanges.slice(startTimeIndex, sliceEndTimeIndex);

      return chosenResourceTimeRanges.every(({ available }) => available === true);
    }

    return false;
  }, [endTime, resourceTimeRanges, startTime]);

  useEffect(() => {
    client?.header.setHeader('');
  }, [client]);

  const onDateInputClick = useCallback(() => {
    dispatch(replace(`${pathname}/date-schedule-select${search}&step=0`));
    track(
      TRACK_EVENT_NAMES.RESOURCE_DETAIL_PAGE_DATE_INPUT_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
      },
      { sendToHqoTracking: true },
    );
  }, [dispatch, pathname, search]);

  useEffect(() => {
    if (appInstanceConfigUuid && !resource?.id) {
      dispatch(
        getResource.request({
          ownerUuid,
          ownerType,
          resourceUuid,
          ...(enableResourceDetailsDateTimeAutoselect && {
            getResourceOptions: { closestAvailability: isStartDateEmpty },
          }),
        }),
      );
    }
  }, [
    resource,
    dispatch,
    ownerType,
    resourceUuid,
    appInstanceConfigUuid,
    enableResourceDetailsDateTimeAutoselect,
    isStartDateEmpty,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const getResourceTimeRangesParams = resolveGetResourceTimeRangesParams({
        ownerType: ownerType as OwnerTypesEnum,
        resourceId: resource.id,
        start: startDate,
        end: moment(date).add(1, 'days').format('YYYY-MM-DD'),
        ...(startDates && { startDates }),
      });
      dispatch(getResourceTimeRanges.request(getResourceTimeRangesParams));
    }, REFRESH_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [ownerType, resource.id, startDate, date, startDates, dispatch, getResourceTimeRanges, REFRESH_INTERVAL_MS]);

  useEffect(() => {
    if (
      isDatesExist &&
      !startTime &&
      !endTime &&
      presetWindows === PresetWindows.FULL_DAY &&
      isGetTimeRangesStatusFulfilled
    ) {
      const queryParams = {
        ...restQueryParams,
        ...(startDate && { startDate }),
        ...(startDates && { startDates }),
        presetWindows,
        startTime: time,
        endTime: selectedEndTime,
        isExact: true,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [
    dispatch,
    startTime,
    startDates,
    endTime,
    presetWindows,
    restQueryParams,
    time,
    selectedEndTime,
    startDate,
    isDatesExist,
    isGetTimeRangesStatusFulfilled,
  ]);

  useEffect(() => {
    if (shouldDispatchGetResourceAvailableDates) {
      dispatch(
        getResourceAvailableDates.request({
          ownerType: OwnerTypesEnum.BUILDING,
          ownerUuid,
          resourceId: resource?.id,
          indexOfMonth: 0,
          ...getResourceAvailableDatesPayload(0, locale),
        }),
      );
      dispatch(
        getResourceAvailableDates.request({
          ownerType: OwnerTypesEnum.BUILDING,
          ownerUuid,
          resourceId: resource?.id,
          indexOfMonth: 1,
          ...getResourceAvailableDatesPayload(1, locale),
        }),
      );
    }
    // empty array means trigger useEffect hook once before render stage of the component's lifecycle
  }, []);

  const { value: showCheckAvailabilityModal, toggle: toggleCheckAvailabilityModal } = useBooleanState(false);

  useEffect(() => {
    if (!showCheckAvailabilityModal) {
      dispatch(resetBookingRequest());
    }
  }, [showCheckAvailabilityModal]);

  useEffect(() => {
    dispatch(resetHeaderState());
  }, []);

  const onTimeInputClick = useCallback(() => {
    dispatch(replace(`${pathname}/date-schedule-select${search}&step=1`));
  }, [dispatch, pathname, search]);

  return {
    showCheckAvailabilityModal,
    ownerType,
    ownerUuid,
    resourceUuid,
    toggleCheckAvailabilityModal,
    resource,
    locale,
    isResourceLoading,
    relativePathname,
    search,
    isTermsAndConditionsVisible,
    onDateInputClick,
    onTimeInputClick,
    shouldDisplayMobileHeader,
    isValidBooking,
    filteredTimeRanges,
  };
};
