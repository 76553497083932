import { AmenityContainer, AmenityDescription, AmenityLink } from '../styles';

import React from 'react';
import { ResourceFeature } from 'store/transactions/types';
import { formatLink } from 'utils/FormatLink';

interface UrlAmenityProps {
  amenity: ResourceFeature;
}

export const UrlAmenity = ({ amenity }: UrlAmenityProps): JSX.Element => {
  const { cta_value, cta_type, name, description } = amenity;

  return (
    <AmenityContainer>
      <AmenityLink
        href={formatLink(cta_type, cta_value)}
        target={!/mobi/i.test(navigator.userAgent) ? '_blank' : '_parent'}
      >
        {name}
      </AmenityLink>
      <AmenityDescription>{description}</AmenityDescription>
    </AmenityContainer>
  );
};
