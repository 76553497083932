import {
  DraggableSection,
  StyledSelectedTimelineBlock,
} from 'pages/resource/components/selected-timeline-block/styles';
import { DurationsAndInterval, ItemRef } from '../timeline/types';
import { useSelectedBlockResizeDrag } from './hooks/use-selected-block-resize-drag.hook';
import { SELECTED_BLOCK_HANDLERS } from 'shared/consts/timeline';
import React, { useState, useEffect, useMemo } from 'react';
import { searchParams } from 'pages/resource/cta-button/cta-button';
import { SelectedTimelineBlockVariants } from 'pages/resource/components/selected-timeline-block/selected-timeline-block.enum';
import { useSearchParams } from 'hooks/use-search-params.hook';
import {
  generateTimeSlots,
  getIsUnavailableSlotIncluded,
} from 'pages/resource/components/selected-timeline-block/utils/selected-timeline-block.utils';
import { useSelector } from 'react-redux';
import { selectResourceTimeRanges } from 'store/resource-time-ranges/selectors';
import { HandleBlock } from './components/handle-block';
import { PresetWindows } from 'store/resources/types';

interface SelectedTimeLineBlockProps {
  initialStartTimeOffset?: number;
  initialWidth?: number;
  durationsAndInterval: DurationsAndInterval;
  itemRefs: React.MutableRefObject<Array<ItemRef>>;
  timelineWrapperRef: React.MutableRefObject<HTMLDivElement>;
}

export const SelectedTimelineBlock = ({
  initialStartTimeOffset,
  initialWidth,
  durationsAndInterval,
  itemRefs,
  timelineWrapperRef,
}: SelectedTimeLineBlockProps): JSX.Element => {
  const [isUnavailableSlotIncluded, setIsUnavailableSlotIncluded] = useState<boolean>(false);
  const { startTime, endTime, presetWindows } = useSearchParams<searchParams>();
  const timeRanges = useSelector(selectResourceTimeRanges);

  const isDraggingAndResizingAvailable = presetWindows !== PresetWindows.FULL_DAY;

  const variant = useMemo<SelectedTimelineBlockVariants>(() => {
    if (isUnavailableSlotIncluded) {
      return SelectedTimelineBlockVariants.UNAVAILABLE;
    }

    if (presetWindows === PresetWindows.FULL_DAY) {
      return SelectedTimelineBlockVariants.FULL_DAY;
    }

    return SelectedTimelineBlockVariants.AVAILABLE;
  }, [presetWindows, isUnavailableSlotIncluded]);

  useEffect(() => {
    const selectedTimeSlots: Array<string> = generateTimeSlots(startTime, endTime);
    setIsUnavailableSlotIncluded(
      getIsUnavailableSlotIncluded<ItemRef>(itemRefs.current, selectedTimeSlots, 'key', 'available'),
    );
  }, [startTime, endTime, timeRanges]);

  const { handleTouch, handleMouseDown, selectedBlockRef, handleDragMove, handleDragStart, handleDragEnd } =
    useSelectedBlockResizeDrag({
      durationsAndInterval,
      itemRefs,
      timelineWrapperRef,
    });

  return (
    <StyledSelectedTimelineBlock
      key={initialStartTimeOffset}
      ref={selectedBlockRef}
      width={initialWidth}
      startTimeOffset={initialStartTimeOffset}
      data-testid="selected-timeline-block"
      variant={variant}
    >
      {isDraggingAndResizingAvailable && (
        <HandleBlock
          dataCy="handle-block-left-cy"
          dataValue={SELECTED_BLOCK_HANDLERS.LEFT}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouch}
        />
      )}
      {isDraggingAndResizingAvailable && (
        <DraggableSection
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDragMove}
          onTouchStart={handleDragStart}
          onTouchMove={handleDragMove}
          onTouchEnd={handleDragEnd}
        />
      )}
      {isDraggingAndResizingAvailable && (
        <HandleBlock
          dataCy="handle-block-right-cy"
          dataValue={SELECTED_BLOCK_HANDLERS.RIGHT}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouch}
        />
      )}
    </StyledSelectedTimelineBlock>
  );
};
