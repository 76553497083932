import { LocationName, CaretDownIcon, LocationPickerContainer } from './styles';
import React from 'react';
import { useLocationPicker } from './use-location-picker.hook';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface LocationPickerProps {
  onClick: VoidFunction;
  locationName: string;
  isResourcesPage?: boolean;
}

export const LocationPicker = ({ onClick, locationName, isResourcesPage }: LocationPickerProps): JSX.Element => {
  useLocationPicker(locationName);
  return (
    <LocationPickerContainer
      data-testid="location-picker-component"
      onClick={onClick}
      isResourcesPage={isResourcesPage}
    >
      <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" />
      <LocationName>{locationName}</LocationName>
      <CaretDownIcon variant="adminBlack" className="caret" />
    </LocationPickerContainer>
  );
};
