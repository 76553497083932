/* eslint-disable consistent-return */
import * as actions from './actions';

import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { getAppInstanceConfigs } from 'store/app-instance-configs/actions';
import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import { ACTION_STATUSES } from 'shared/consts';
import { LocaleEnum } from 'store/cart/types';

export const getBuildingEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getBuilding.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]): Observable<RootAction> => {
      return apiClient(state)
        .getBuilding(payload)
        .pipe(
          switchMap(xhrPayload => of(actions.getBuilding.success(xhrPayload.response.data.building))),
          catchError(error => of(actions.getBuilding.failure({ error }))),
        );
    }),
  );

export const appInstanceConfigEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(actions.getBuilding.success)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      if (state.appInstanceConfigs.getAppInstanceConfigs.status !== ACTION_STATUSES.PENDING) {
        return of(
          getAppInstanceConfigs.request({
            lang: (state.config?.locale as LocaleEnum) ?? (payload.locale as LocaleEnum),
            owners: [
              {
                uuid: payload.uuid,
                type: 'BUILDING' as OwnerTypesEnum,
              },
            ],
          }),
        );
      }

      return EMPTY;
    }),
  );
