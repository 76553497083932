import React from 'react';
import { CapacityContainer, CapacityNumberContainer } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-light-svg-icons';

interface CapacityBlockProps {
  capacity: number;
  title: string;
}

export const CapacityBlock: React.FC<CapacityBlockProps> = ({ capacity, title }: CapacityBlockProps): JSX.Element => {
  return (
    <CapacityContainer data-testid={`${title}-capacity`}>
      <FontAwesomeIcon icon={faUsers} key={`${capacity}`} />
      <CapacityNumberContainer>{capacity}</CapacityNumberContainer>
    </CapacityContainer>
  );
};
