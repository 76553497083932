import styled from 'styled-components';
import { skeletonAnimation } from 'components/skeleton/styles';

export const RoomLayoutTitle = styled.div`
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  height: 36px;
  width: 150px;
  margin-top: 40px;
  margin-left: 20px;
  border-radius: 4px;
  ${skeletonAnimation};
`;
export const RoomLayoutOption = styled.div`
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  height: 36px;
  width: 150px;
  margin-top: 40px;
  margin-left: 20px;
  border-radius: 4px;
  ${skeletonAnimation};
  width: 90%;
  height: 100px;
`;
