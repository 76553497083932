import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllAddOns, selectAllAddonsPrice } from 'store/add-ons/selectors';
import { resetAddOnsState } from 'store/add-ons/actions';

export const useResetAddOns = (): void => {
  const dispatch = useDispatch();
  const selectedAddOns = useSelector(selectAllAddOns);
  const addOnsPrice = useSelector(selectAllAddonsPrice);

  useEffect(() => {
    if (selectedAddOns?.length) {
      dispatch(resetAddOnsState());
    }
  }, [dispatch, selectedAddOns, addOnsPrice]);
};
