import styled from 'styled-components';

import { RadioButton } from '@hqo/react-components-library/dist/molecules/radio-button';
import { RadioButtonGroup } from '@hqo/react-components-library';

export const StyledRadioButton = styled(RadioButton)`
  padding: 2px;
`;

export const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  margin-bottom: 15px;
`;
