import { Button, DIMENSIONS, ExclamationMark } from '@hqo/react-components-library';
import { PaymentMethod } from '@hqo/react-components-library/dist/payment/payment-method';
import { QUICK_CHECKOUT_BREAK_POINT } from 'shared/consts';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface QuickCheckoutButtonProps {
  isNext?: boolean;
  isFreeCart?: boolean;
}

export const ContentContainer = styled.div`
  margin: 52px 32px 30px 32px;
  display: grid;
  @media only screen and (max-width: ${QUICK_CHECKOUT_BREAK_POINT}px) {
    margin: 39px 16px 20px 16px;
  }
`;

export const QuickCheckoutContent = styled.div`
  @media only screen and (max-width: ${DIMENSIONS.SMALL}) {
    .text-container {
      flex-basis: 75%;
      flex-grow: 0;
    }

    .subtitle-text {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-inline-box;
    }
  }
`;

export const ButtonContainer = styled.div<QuickCheckoutButtonProps>`
  margin-top: ${({ isNext, isFreeCart }) => (isNext || isFreeCart ? '40px' : '0px')};

  @media only screen and (min-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 0;
  }

  @media only screen and (max-width: ${QUICK_CHECKOUT_BREAK_POINT}px) {
    background: ${({ theme }) => theme.colors.$white};
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 15px 10px 15px;
  }
`;

export const StyledButton = styled(Button)<{ isNativePayment: boolean }>`
  width: 215px;
  height: 48px;
  border-color: ${({ theme }) => theme.colors.greys.darkGrey6};
  align-self: flex-end;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  ${({ isNativePayment }) =>
    isNativePayment &&
    `background-color: black;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      opacity: 0.9;
      background-color: black;
      border-color: black;
  }
`}

  ${({ isNativePayment }) =>
    isNativePayment &&
    `
    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      opacity: 0.9;
      background-color: black;
      border-color: black;
  }
`}

  @media only screen and (max-width: ${QUICK_CHECKOUT_BREAK_POINT}px) {
    width: 100%;
    height: 100%;
    margin-top: 0;
    height: 48px;
  }
`;

export const StyledTitleBlock = styled(TitleBlock)``;

export const StyledPriceBlock = styled.div`
  margin-top: 15px;
`;

export const StyledPaymentBlock = styled.div<{ isAllowedVisitorsExist: boolean }>`
  height: ${({ isAllowedVisitorsExist }) => (isAllowedVisitorsExist ? 'initial' : '60px')};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
`;

export const StyledPaymentMethod = styled(PaymentMethod)`
  margin-bottom: 5px;
  width: 100%;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ErrorLabel = styled.div`
  font: italic 12px/17px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$adminPink};
`;

export const NativeErrorLabel = styled.div`
  font: italic 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$adminPink};
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const StyledErrorIcon = styled(ExclamationMark)`
  margin: 0 5px;
  flex-shrink: 0;
`;

export const StyledNativePaymentIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.$white};
  margin-top: -4px;
`;

export const EditButton = styled.div`
  font-weight: 500;
  padding: 5px;
  flex: 0 1 auto;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledAfterPaymentMethod = styled.div`
  font: italic 14px/22px ${({ theme }) => theme.fonts.join()};

  svg {
    vertical-align: middle;
    margin-right: 4px;
  }
`;
