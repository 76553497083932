import { useEffect, useMemo, useState } from 'react';

import { REFRESH_INTERVAL_MS } from 'pages/resource/constants';
import { BLOCK_WIDTH } from 'shared/consts/timeline';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { TimeRange } from 'store/resource-time-ranges/types';
import { getStartDatetimeFromTimeRange, isDateInBetweenInclusive } from 'utils/date';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTES } from 'shared/consts';

export interface UseCurrentTimeIndicatorProps {
  timeRanges: TimeRange[];
}

export interface UseCurrentTimeIndicatorResult {
  showCurrentTimeIndicator: boolean;
  leftOffsetInPx: number;
}

export default function useCurrentTimeIndicator({
  timeRanges,
}: UseCurrentTimeIndicatorProps): UseCurrentTimeIndicatorResult {
  const buildingTimezone = useTimeZone();
  const [leftOffsetInPx, setLeftOffsetInPx] = useState(0);
  const [showCurrentTimeIndicator, setShowCurrentTimeIndicator] = useState(false);

  const { minDatetime, maxDatetime, sizeInPxTimeline, intervalSizeInMilliseconds } = useMemo(() => {
    if (!timeRanges || timeRanges.length === 0) {
      return {
        sizeInPxTimeline: 0,
        minDatetime: undefined,
        maxDatetime: undefined,
        intervalSizeInMilliseconds: 0,
      };
    }
    const firstTimeRange = timeRanges[0];
    const minDatetimeOfTimerange = getStartDatetimeFromTimeRange(firstTimeRange, buildingTimezone);
    const lastStartTimeRange = getStartDatetimeFromTimeRange(timeRanges[timeRanges.length - 1], buildingTimezone);
    const maxDatetimeOfTimerange = new Date(
      lastStartTimeRange.getTime() + firstTimeRange.time_interval * SECONDS_IN_MINUTES * MILLISECONDS_IN_SECOND,
    );
    return {
      maxDatetime: maxDatetimeOfTimerange,
      minDatetime: minDatetimeOfTimerange,
      intervalSizeInMilliseconds: maxDatetimeOfTimerange.getTime() - minDatetimeOfTimerange.getTime(),
      sizeInPxTimeline: (timeRanges.length - 1) * BLOCK_WIDTH,
    };
  }, [timeRanges, buildingTimezone]);

  const refresh = () => {
    const now = new Date(Date.now());
    const isIndicatorVisible =
      intervalSizeInMilliseconds > 0 && isDateInBetweenInclusive(now, minDatetime, maxDatetime);
    if (!isIndicatorVisible) {
      setLeftOffsetInPx(0);
      setShowCurrentTimeIndicator(false);
      return;
    }

    const positionInMillisecondsInInterval = Math.abs(now.getTime() - minDatetime.getTime());
    const left = Math.floor((positionInMillisecondsInInterval / intervalSizeInMilliseconds) * sizeInPxTimeline);

    setLeftOffsetInPx(left);
    setShowCurrentTimeIndicator(true);
  };

  useEffect(() => {
    refresh();
  }, [minDatetime, maxDatetime, sizeInPxTimeline, intervalSizeInMilliseconds]);

  useEffect(() => {
    const intervalId = setInterval(refresh, REFRESH_INTERVAL_MS);
    return () => clearInterval(intervalId);
  }, []);

  return {
    showCurrentTimeIndicator,
    leftOffsetInPx,
  };
}
