import React from 'react';
import {
  PageContainer,
  TitleContainer,
  Title,
  RadioButtonContainer,
  RadioButtonImage,
  StyledRadioButton,
  RadioButtonSkeleton,
} from './styles';
import { useIntl } from 'react-intl';
import { Footer } from 'components/footer/footer';
import { useRoomLayout } from './use-room-layout.hook';
import { PaymentContent } from 'components/payment-content/payment-content';
import { usePaymentModal } from 'hooks/payment-hook/use-payment-modal.hook';
import { useQuickCheckout } from 'hooks/use-quick-checkout.hook';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';
import { useCheckoutScreen } from 'hooks/payment-content/use-checkout-screen.hook';
import { Skeleton } from './skeleton/room-layout-skeleton';
import { useSelector } from 'react-redux';
import { selectCreateCartStatus } from 'store/cart/selectors';
import { isButtonLoading } from 'utils/isButtonLoading';

export const RoomLayoutPage = (): JSX.Element => {
  const { closeQuickCheckoutHandler, openQuickCheckoutHandler } = useQuickCheckout();
  const { onCloseCheckout, openCheckout } = useCheckoutScreen();
  const { reverseAnimation } = usePaymentModal(openQuickCheckoutHandler);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const intl = useIntl();
  const {
    onContinue,
    roomLayouts,
    currentRoomLayout,
    handleRadioButtonChange,
    handleRadioButtonDeselect,
    isSkipButtonShown,
    isResourceLoading,
    buttonText,
  } = useRoomLayout();

  const { isCancelBookingStep, setIsCancelBookingStep, onCloseReceipt, onPressBack, openReceipt } = useReceiptScreen();

  if (isResourceLoading) {
    return <Skeleton />;
  }

  return (
    <PageContainer>
      <TitleContainer>
        <Title>{intl.formatMessage({ id: 'room_layout' })}</Title>
      </TitleContainer>
      <RadioButtonContainer>
        {roomLayouts.map(roomLayout => (
          <StyledRadioButton
            onClick={handleRadioButtonDeselect}
            value={roomLayout?.name}
            key={roomLayout?.id}
            selectedValue={currentRoomLayout?.name}
            onChange={handleRadioButtonChange}
            subLabel={roomLayout?.description}
            content={
              roomLayout?.image_url && (
                <RadioButtonImage isRoomLayoutImage src={roomLayout.image_url} skeleton={<RadioButtonSkeleton />} />
              )
            }
            isActive={roomLayout?.name === currentRoomLayout?.name}
          />
        ))}
      </RadioButtonContainer>
      <Footer
        isSkipCtaButton={isSkipButtonShown}
        isSelectedDateTimeFilterTextOnly
        onCtaButtonClick={onContinue}
        ctaButtonText={intl.formatMessage({ id: `${buttonText()}` })}
        isButtonDisabled={isButtonLoading(createCartStatus)}
        isButtonLoading={isButtonLoading(createCartStatus)}
      />
      <PaymentContent
        reverseAnimation={reverseAnimation}
        closeQuickCheckoutHandler={closeQuickCheckoutHandler}
        isCancelBookingStep={isCancelBookingStep}
        setIsCancelBookingStep={setIsCancelBookingStep}
        onCloseReceipt={onCloseReceipt}
        onPressBack={onPressBack}
        onCloseCheckout={onCloseCheckout}
        openCheckout={openCheckout}
        openReceipt={openReceipt}
      />
    </PageContainer>
  );
};
