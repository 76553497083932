import React, { useCallback, useRef } from 'react';
import { Container, StyledCalendar } from './styles';
import { UserDialogButton } from '@hqo/react-components-library';
import { Moment } from 'moment';
import { DatePickerInput } from './date-picker-input';
import { CalendarPopover } from './calendar-popover';
import { MultidayWarningMessage } from 'components/multiday-warning-message/multiday-warning-message';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';

interface DatePickerProps {
  value: Moment | null;
  onChange: (value: Moment) => void;
  minDate: Moment | null;
  locale: string;
  buttons: Array<UserDialogButton>;
  isOpened: boolean;
  setIsOpenedTrue: VoidFunction;
  setIsOpenedFalse: VoidFunction;
  dateRef?: React.MutableRefObject<HTMLDivElement>;
  activeDays?: Array<Moment>;
  formattedDate: string;
}

export const DatePicker = ({
  value,
  onChange,
  minDate,
  locale,
  buttons,
  isOpened,
  setIsOpenedFalse,
  setIsOpenedTrue,
  dateRef,
  activeDays,
  formattedDate,
}: DatePickerProps): JSX.Element => {
  const onCloseHandler = useCallback(() => {
    setIsOpenedFalse();
  }, [setIsOpenedFalse]);
  const inputRef = useRef(null);
  const { isMultiDayBookingEnabled } = useAppInstanceConfigFeature();

  return (
    <Container ref={dateRef}>
      <DatePickerInput
        value={value}
        setIsOpenedTrue={setIsOpenedTrue}
        inputRef={inputRef}
        formattedDate={formattedDate}
      />
      <CalendarPopover isOpened={isOpened} toggleIsOpened={onCloseHandler}>
        <StyledCalendar
          value={value}
          className="calendar-view"
          onChange={onChange}
          minDate={minDate}
          locale={locale}
          buttons={buttons}
          size="sm"
          showCurrentDate
          isFlexStyle
          activeDays={activeDays}
          message={isMultiDayBookingEnabled && <MultidayWarningMessage />}
        />
      </CalendarPopover>
    </Container>
  );
};
