import styled from 'styled-components';
import IframeModalWrapper from 'components/modals/iframeWrapper';
import { QUICK_CHECKOUT_BREAK_POINT } from 'shared/consts';
import { DIMENSIONS } from '@hqo/react-components-library';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import { Button } from 'components/payment/quick-checkout/components/button/button';

export const Container = styled.div`
  margin-top: -6px;
`;

export const Subtitle = styled.div`
  font: 400 14px/24px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const BookingContainer = styled.div<{ isDesktop?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ isDesktop }) => (isDesktop ? '6px' : '16px')};
  margin: 34px 0 34px;
  flex-grow: 1;
  overflow: auto;
`;

export const BoldText = styled.strong`
  font-weight: 500;
`;

export const Title = styled.div<{ isDesktop?: boolean }>`
  font: 500 16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: ${({ isDesktop }) => (isDesktop ? '10px' : 0)};
`;

export const StyledModalWrapper = styled(IframeModalWrapper)<{
  quickCheckoutHeight: number;
}>`
  width: 543px;
  height: ${props => props.quickCheckoutHeight}px;

  .iframe-wrapper {
    max-height: 80vh;
  }
`;

export const ContentContainer = styled.div`
  margin: 0 32px;
  display: grid;
  height: 100%;

  @media only screen and (max-width: ${QUICK_CHECKOUT_BREAK_POINT}px) {
    margin: 39px 16px 20px 16px;
  }
`;

export const QuickCheckoutContent = styled.div`
  display: flex;
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  overflow: auto;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 329px;
  border-radius: 8px 8px 0 0;

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    align-items: flex-start;
  }
`;

export const StyledTitleBlock = styled(TitleBlock)`
  position: sticky;
  top: 0;
  padding-top: 52px;
`;

export const StyledButton = styled(Button)`
  position: sticky;
  bottom: 0;
  padding: 10px 0 20px 0;
`;
