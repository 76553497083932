import { Route, Routes } from 'react-router-dom';

import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { ErrorPage } from 'pages/error';
import { PrivateRoute } from 'components/private-route';
import { ROOT_PATH, ERROR_PATH } from 'shared/consts/const';
import React from 'react';
import { ResourcePage } from 'pages/resource';
import { ResourcesPage } from 'pages/resources';
import { LandingPage } from 'pages/landing';
import { AdditionalInformationFlex } from 'pages/additional-information-flex';
import { DefaultRedirect } from './default-redirect';
import { FloorPlanPage } from '../../floorplan/pages/floorplan';
import { useFloorPlanConfiguration } from '../../floorplan/pages/floorplan/hooks/use-floorplan-configuration.hook';
import { RoomLayoutPage } from 'pages/room-layout/room-layout';
import { AddOnsPage } from 'pages/add-ons';
import { RedirectPage } from 'components/payment/redirect';
import { DeepLinkHandlePage } from 'pages/deeplink/deeplink';
import { ErrorHandler } from 'hocs/errorHandler';

export const Router: React.FC = (): JSX.Element => {
  const floorPlanEnabled = !!useFloorPlanConfiguration();

  return (
    <AppContentWrapper>
      <ErrorHandler>
        <Routes>
          <Route path="/*" element={<LandingPage />} />
          <Route
            path="resource-booking/resource/:resourceUuid/*"
            element={
              <PrivateRoute>
                <ResourcePage />
              </PrivateRoute>
            }
          />
          <Route
            path="resource-booking/resource/:resourceUuid/additional-information/*"
            element={
              <PrivateRoute>
                <AdditionalInformationFlex />
              </PrivateRoute>
            }
          />
          <Route
            path="resource-booking/resource/:resourceUuid/room-layouts/*"
            element={
              <PrivateRoute>
                <RoomLayoutPage />
              </PrivateRoute>
            }
          />
          <Route
            path="resource-booking/resource/:resourceUuid/add-ons/*"
            element={
              <PrivateRoute>
                <AddOnsPage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROOT_PATH}
            element={
              <PrivateRoute>
                <ResourcesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="floorplan/*"
            element={
              floorPlanEnabled ? (
                <PrivateRoute>
                  <FloorPlanPage />
                </PrivateRoute>
              ) : (
                <DefaultRedirect />
              )
            }
          />
          <Route
            path="floorplan/:floorUuid/*"
            element={
              floorPlanEnabled ? (
                <PrivateRoute>
                  <FloorPlanPage />
                </PrivateRoute>
              ) : (
                <DefaultRedirect />
              )
            }
          />
          <Route path="deeplink/:payload" element={<DeepLinkHandlePage />} />
          <Route path="redirect" element={<RedirectPage />} />
          <Route path={ERROR_PATH} element={<ErrorPage />} />
          <Route path="*" element={<DefaultRedirect />} />
        </Routes>
      </ErrorHandler>
    </AppContentWrapper>
  );
};
