import React from 'react';
import { TitleBlock } from '@hqo/react-components-library/dist/molecules/titleBlock';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const StyledTitleBlock = styled(TitleBlock)`
  .title {
    font-weight: 700;
  }
`;

const AdditionalInformation = () => {
  const intl = useIntl();

  return (
    <StyledTitleBlock
      title={intl.formatMessage({ id: 'additional_information' })}
      subtitle={intl.formatMessage({ id: 'additional_information_description' })}
    />
  );
};

export default AdditionalInformation;
