import React from 'react';
import { FlexContainer, SkeletonBlock } from '../styles';

export const ResourceDescription = (): JSX.Element => {
  return (
    <FlexContainer gap="14" isColumnDirection top="25" left={16}>
      <SkeletonBlock width="650" />
      <SkeletonBlock width="650" />
      <SkeletonBlock width="281" />
    </FlexContainer>
  );
};
