import styled from 'styled-components';
import { DIMENSIONS, PaymentMethodsManager } from '@hqo/react-components-library';

export const Container = styled.div<{ isSwipeModalContent: boolean }>`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 16px;
  }
`;

export const StyledPaymentMethodsManager = styled(PaymentMethodsManager)`
  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 0;
  }
`;

export const ErrorLabel = styled.div`
  font: italic 14px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.$adminPink};
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 20px 0;
`;
