import { BuildingGroupSelectModalDesktopContent } from 'components/building-group-select/desktop/building-group-select-desktop.modal.content';
import { BuildingGroupSelectModalContent } from 'components/building-group-select/mobile/building-group-select.modal.content';
import { FloorSelectModalDesktopContent } from 'components/floor-select/desktop/floor-select-desktop.modal.content';
import { FloorSelectModalContent } from 'components/floor-select/mobile/floor-select.modal.content';
import { useFloorFilter } from 'pages/filter-modal/floor-filter/use-floor-filter';
import React, { forwardRef, useImperativeHandle } from 'react';

export interface FloorFilterProps {
  isMobileDevice: boolean;
  ref?: React.MutableRefObject<HTMLDivElement>;
}

export const FloorFilter = forwardRef(({ isMobileDevice }: { isMobileDevice: boolean }, ref): JSX.Element => {
  const {
    floors,
    onFloorChange,
    pickedFloor,
    isFloorPickerWheelShown,
    toggleFloorPickerWheel,
    buildingGroups,
    pickedBuildingGroup,
    onWheelChangeBuildingGroup,
    isBuildingGroupPickerWheelShown,
    toggleBuildingGroupPickerWheel,
    setPickedBuildingGroup,
    setPickedFloor,
  } = useFloorFilter();

  const onResetAll = () => {
    onWheelChangeBuildingGroup(buildingGroups[0].value);
    setPickedFloor(null);
    setPickedBuildingGroup(null);
  };

  useImperativeHandle(ref, () => ({
    onResetAll,
  }));
  return (
    <>
      {isMobileDevice && floors.length > 0 ? (
        <>
          {buildingGroups?.length > 1 && (
            <BuildingGroupSelectModalContent
              pickedBuildingGroup={pickedBuildingGroup}
              options={buildingGroups}
              onWheelChange={onWheelChangeBuildingGroup}
              isBuildingGroupPickerWheelShown={isBuildingGroupPickerWheelShown}
              toggleBuildingGroupPickerWheel={toggleBuildingGroupPickerWheel}
            />
          )}
          <FloorSelectModalContent
            pickedFloor={pickedFloor}
            options={floors}
            onWheelChange={onFloorChange}
            isFloorPickerWheelShown={isFloorPickerWheelShown}
            toggleFloorPickerWheel={toggleFloorPickerWheel}
          />
        </>
      ) : (
        <>
          {buildingGroups?.length > 1 && (
            <BuildingGroupSelectModalDesktopContent
              pickedBuildingGroup={pickedBuildingGroup}
              buildingGroupOptions={buildingGroups}
              onWheelChangeBuildingGroup={onWheelChangeBuildingGroup}
            />
          )}
          <FloorSelectModalDesktopContent pickedFloor={pickedFloor} options={floors} onFloorSelect={onFloorChange} />
        </>
      )}
    </>
  );
});
