import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { getSelectedTypes } from 'pages/resources/filter-bar/use-filter-bar.hook';
import { selectResourcesTypes } from 'store/resources/selectors';
import { DEFAULT_TYPE_COMMA_DELIMITER, DEFAULT_TYPE_DELIMITER } from 'shared/consts';

const removeTypeFilterParams = (
  value: string,
  filterValues: string[],
  restQueryParams: { [key: string]: string },
): string => {
  const newFilterValues = filterValues.filter(type => type !== value);
  const filterValuesWithTypeDelimiter = newFilterValues.map(filterValue =>
    filterValue.replace(',', DEFAULT_TYPE_COMMA_DELIMITER),
  );

  return qs.stringify(
    filterValues.length === 1
      ? restQueryParams
      : { ...restQueryParams, filterByType: filterValuesWithTypeDelimiter.join(',') },
  );
};

const addTypeFilterParams = (
  value: string,
  filterValues: string[],
  restQueryParams: { [key: string]: string },
): string => {
  const newFilterValues = [...filterValues, value];
  const filterValuesWithTypeDelimiter = newFilterValues.map(filterValue =>
    filterValue.replace(',', DEFAULT_TYPE_COMMA_DELIMITER),
  );

  return qs.stringify({ ...restQueryParams, filterByType: filterValuesWithTypeDelimiter.join(',') });
};

interface UseTypesFilterReturnValues {
  selectedTypes: string[];
  onTypeChipClickHandler: (value: string) => void;
}

export const useTypesFilter = (): UseTypesFilterReturnValues => {
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const { filterByType, ...restQueryParams } = useSearchParams();
  const dispatch = useDispatch();
  const resourcesTypes = useSelector(selectResourcesTypes) as Array<string>;

  useEffect(() => {
    const filterByTypeToString: string = filterByType
      ? filterByType.toString().replace(DEFAULT_TYPE_DELIMITER, '')
      : '';
    const selected = getSelectedTypes(filterByTypeToString, resourcesTypes);
    setSelectedTypes(filterByType?.length ? [...new Set(selected)] : []);
  }, [filterByType]);

  const onTypeChipClickHandler = useCallback(
    (value: string) => {
      const queryString = selectedTypes.includes(value)
        ? removeTypeFilterParams(value, selectedTypes, restQueryParams as any)
        : addTypeFilterParams(value, selectedTypes, restQueryParams as any);

      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [selectedTypes, restQueryParams],
  );

  return { selectedTypes, onTypeChipClickHandler };
};
