import { useSelectAvailableFloor } from 'floorplan/pages/floorplan/hooks/use-select-available-floor.hook';
import { useFloorPlanConfiguration } from 'floorplan/pages/floorplan/hooks/use-floorplan-configuration.hook';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBuilding } from 'store/building/selectors';
import { getFloors, getSpaces } from 'store/spaceManager/actions';
import { selectFloor, selectFloorBuilding } from 'store/spaceManager/selectors';
import { LinkedSpace } from 'store/spaceManager/types';
import { selectAppInstanceConfigs } from 'store/app-instance-configs/selectors';

export const useFloorPlanPage = () => {
  const config = useFloorPlanConfiguration();
  const building = useSelector(selectBuilding);
  const floorBuildingUuid = useSelector(selectFloorBuilding);
  const dispatch = useDispatch();
  const floor = useSelector(selectFloor);
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  useSelectAvailableFloor();

  useEffect(() => {
    if (building && building.uuid !== floorBuildingUuid) {
      dispatch(getFloors.request({ buildingUuid: building.uuid }));
    }
  }, [building, floorBuildingUuid]);
  const linkedSpaces = useMemo(() => {
    const mappedArchilogicIds = new Map<string, LinkedSpace>();
    if (floor?.spaces && appInstanceConfigs?.length) {
      for (const space of floor.spaces) {
        for (const app of space.apps) {
          const foundAppInstanceConfig = appInstanceConfigs.find(
            appInstanceConfig => appInstanceConfig.uuid === app.app_instance_config_uuid,
          );

          if (foundAppInstanceConfig) {
            mappedArchilogicIds.set(app.external_resource_id, {
              name: space.name,
              app_instance_config_uuid: foundAppInstanceConfig?.uuid,
              resource_booking_id: app.external_resource_id,
              archilogic_id: space.apps.find(arrayApp => arrayApp !== app).external_resource_id,
            });
          }
        }
      }
    }

    return mappedArchilogicIds;
  }, [floor?.spaces, appInstanceConfigs]);

  useEffect(() => {
    if (floor?.uuid && floor?.spaces === undefined) {
      dispatch(
        getSpaces.request({
          floor_uuid: floor.uuid,
          vertical_adapter_uuid: appInstanceConfigs[0]?.vertical_adapter.uuid,
          no_pagination: true,
        }),
      );
    }
  }, [floor?.uuid, floor?.spaces, appInstanceConfigs]);

  return {
    floor,
    publishableToken: config?.external_id,
    buildingName: building?.name,
    linkedSpaces,
  };
};
