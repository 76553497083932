import { AccessTime } from '@hqo/react-components-library/dist/icons';
import { DIMENSIONS } from '@hqo/react-components-library/dist/utils';
import { DATE_TIME_INPUT_GAP_WIDTH } from 'pages/resource/components/date-select-timeline/styles';
import styled from 'styled-components';

export const Container = styled.button`
  padding-right: 16px;
  height: 40px;
  width: 210px;
  background-color: ${({ theme }) => theme.colors.universal.white};
  border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;

  @media only screen and (max-width: ${DIMENSIONS.SMALL}) {
    width: calc(60% - ${DATE_TIME_INPUT_GAP_WIDTH});
  }
`;

export const StyledIcon = styled(AccessTime)`
  margin-left: 4px;
`;

export const StyledText = styled.div`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.fontColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
