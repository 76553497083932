import styled from 'styled-components';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { DIMENSIONS } from '@hqo/react-components-library';

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  height: 36px;
  width: 110px;
  padding: 16px;
  border-radius: 4px;
  font-size: 16px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 48px;
    width: 100%;
  }
`;

export const TimeFilterContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  flex-grow: 1;
  position: relative;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    overflow-y: unset;
    padding-bottom: 82px;
  }
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  backdrop-filter: blur(0);
`;
