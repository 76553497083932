import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { useOnSubmitBookingRequest } from 'pages/schedule-select-page/components/use-on-submit-booking-request.hook';
import { selectAllAddOns } from 'store/add-ons/selectors';
import { selectCurrentRoomLayout } from 'store/room-layout/selectors';
import { selectAddOns, selectGetResourceStatus } from 'store/resource/selectors';
import { AddOn } from 'store/resource/types';
import { ACTION_STATUSES, DEFAULT_ITEM_QUANTITY } from 'shared/consts';
import { getResource } from 'store/resource/actions';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useParams } from 'react-router-dom';
import { addAddOn } from 'store/add-ons/actions';

interface UseAddOnsReturnValues {
  onButtonClick: VoidFunction;
  buttonText: string;
  isSkipButton: boolean;
  addOns: AddOn[];
  isResourceLoading: boolean;
}

export const useAddOns = (): UseAddOnsReturnValues => {
  const dispatch = useDispatch();
  const onSubmitBooking = useOnSubmitBookingRequest();
  const selectedAddOns = useSelector(selectAllAddOns);
  const addOns = useSelector(selectAddOns);
  const { ownerType, ownerUuid } = useOwnerParams();
  const { resourceUuid } = useParams();
  const getResourceStatus = useSelector(selectGetResourceStatus);
  const { room_layout: currentRoomLayout } = useSelector(selectCurrentRoomLayout);
  const isResourceLoading = useMemo<boolean>(
    () => getResourceStatus !== ACTION_STATUSES.FULFILLED,
    [getResourceStatus],
  );
  const isSkipButton = useMemo<boolean>(() => !selectedAddOns?.length, [selectedAddOns]);
  const buttonText = useMemo<string>(() => {
    if (!selectedAddOns?.length) {
      return 'skip';
    }

    return 'complete_booking';
  }, [selectedAddOns]);
  const requiredAddOns = useMemo<AddOn[]>(() => addOns?.filter(addOn => addOn.required), [addOns]);

  useEffect(() => {
    if (!selectedAddOns?.length) {
      requiredAddOns?.forEach(requiredAddOn => {
        dispatch(
          addAddOn({
            id: requiredAddOn?.id,
            name: requiredAddOn?.name,
            price: requiredAddOn?.price,
            description: requiredAddOn?.description,
            image_url: requiredAddOn?.image_url,
            quantity: DEFAULT_ITEM_QUANTITY,
            required: requiredAddOn?.required,
          }),
        );
      });
    }
  }, [dispatch, requiredAddOns, selectedAddOns?.length]);

  useEffect(() => {
    if (getResourceStatus !== ACTION_STATUSES.FULFILLED && resourceUuid) {
      dispatch(getResource.request({ ownerUuid, ownerType, resourceUuid }));
    }
  }, [dispatch, ownerType, resourceUuid, getResourceStatus, ownerUuid]);

  const onButtonClick = useCallback(() => {
    onSubmitBooking({ currentRoomLayout, selectedAddOns });
  }, [currentRoomLayout, selectedAddOns]);

  return {
    onButtonClick,
    buttonText,
    isSkipButton,
    addOns,
    isResourceLoading,
  };
};
