import qs from 'qs';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { replace } from 'store/router/actions';

export const useUpdateUrlPath = () => {
  const dispatch = useDispatch();
  const querySearchParams = useSearchParams<searchParams>();

  const updateStartEndTimeQueryParams = useCallback(
    (startTimeParam: string, endTimeParam: string) => {
      const queryParams = {
        ...querySearchParams,
        startTime: startTimeParam,
        endTime: endTimeParam,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [dispatch, querySearchParams],
  );

  return updateStartEndTimeQueryParams;
};
