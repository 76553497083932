import { AvailableNow, BookingWindow } from 'store/resource/types';
import { ACTION_STATUSES, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ONE_HOUR } from 'components/schedule-select/const';
import { push } from 'store/router/actions';
import qs from 'qs';

import { resourcesState, selectGetResourcesLoadingStatus } from 'store/resources/selectors';
import { searchParams } from 'pages/date-select-page/interface';
import { track } from '@hqo/web-tracking';
import { useBooleanState } from '@hqo/react-components-library';
import { useLocale } from './use-locale.hook';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useSearchParams } from './use-search-params.hook';
import { useExchangeAdapterHandling } from './use-exchange-adapter-handling.hook';
import { selectPriceStatus } from 'store/price/selectors';
import { getPrice, resetPrice } from 'store/price/actions';
import moment from 'moment-timezone';
import { GetPriceParams } from 'store/price/types';
import { getResource } from 'store/resource/actions';
import { resourceState, selectGetResourceStatus } from 'store/resource/selectors';
import { useOnSubmitBookingRequest } from 'pages/schedule-select-page/components/use-on-submit-booking-request.hook';
import { useParams } from 'react-router-dom';
import { selectCurrentRoomLayout } from 'store/room-layout/selectors';
import { selectAllAddOns } from 'store/add-ons/selectors';

export interface AvailableNowSubtitles {
  availableAllDay: string;
  availableFor: string;
  hours: string;
  minutes: string;
}

export interface AvailableNowIcons {
  paidResourceIcon: JSX.Element;
  freeResourceIcon: JSX.Element;
  capacityIcon: JSX.Element;
}

interface UseAvailableNowReturnValues {
  hasAvailableResources: boolean;
  isGetResourcesLoading: boolean;
  renderAvailabilitySubtitleText: (availableNow: AvailableNow, subtitles: AvailableNowSubtitles) => string;
  calculateAvailableNowEndTime: (
    availableNow: AvailableNow,
    minimumDuration: number,
    maximumDuration: number,
  ) => string;
  buildAvailableNowIcons: (
    windows: BookingWindow[],
    resourceCapacity: number,
    availableNowIcons: AvailableNowIcons,
  ) => JSX.Element[];
  onAvailableNowBlockClick: (
    uuid: string,
    availableNow: AvailableNow,
    minimumDuration: number,
    maximumDuration: number,
    resourceType: string,
  ) => void;
}

export const useAvailableNow = (): UseAvailableNowReturnValues => {
  const { search } = useLocation();
  const locale = useLocale();
  const dispatch = useDispatch();
  const { ownerType, ownerUuid } = useOwnerParams();
  const queryParams = useSearchParams<searchParams>();
  const onSubmitBookingRequest = useOnSubmitBookingRequest();
  const { isCompactUiEnabled } = useExchangeAdapterHandling();
  const { resourceUuid } = useParams();

  const { resources } = useSelector(resourcesState);
  const isGetResourcesLoading = useSelector(selectGetResourcesLoadingStatus) as boolean;
  const { value: hasAvailableResources, toggle: toggleHasAvailableResources } = useBooleanState(false);
  const [isAvailableBlockClicked, setIsAvailableBlockClicked] = useState<boolean>(false);
  const { resource } = useSelector(resourceState);
  const hasAdditionalInfo = useMemo(() => !!resource?.user_prompts?.length, [resource]);
  const [startTime, setStartTime] = useState<string>(null);
  const [endTime, setEndTime] = useState<string>(null);
  const [startDate, setStartDate] = useState<string>(null);
  const getPriceStatus = useSelector(selectPriceStatus);
  const getResourceStatus = useSelector(selectGetResourceStatus);
  const isPaidResource = useMemo<boolean>(
    () => !!(resource?.booking_windows?.some(({ pricing_rules }) => pricing_rules?.length) || resource?.minimum_price),
    [resource?.booking_windows, resource?.minimum_price],
  );
  const { room_layout: currentRoomLayout } = useSelector(selectCurrentRoomLayout);
  const selectedAddOns = useSelector(selectAllAddOns);

  useEffect(() => {
    if (resources?.some(selectedResource => selectedResource.available_now) && !hasAvailableResources) {
      toggleHasAvailableResources();
    }
  }, [resources]);

  useEffect(() => {
    if (isPaidResource && startTime && endTime && startDate && getPriceStatus !== ACTION_STATUSES.FULFILLED) {
      dispatch(
        getPrice.request({
          ownerType,
          ownerUuid,
          resourceId:
            resource?.id ?? resources.find(reduxResource => reduxResource.uuid === resourceUuid).id.toString(),
          from: moment(`${startDate} ${startTime}`).tz(resource?.timezone, true)?.utc().format(),
          to: moment(`${startDate} ${endTime}`).tz(resource?.timezone, true)?.utc().format(),
        } as unknown as GetPriceParams),
      );
    }
  }, [
    dispatch,
    endTime,
    getPriceStatus,
    isPaidResource,
    ownerType,
    ownerUuid,
    resource?.id,
    resource?.timezone,
    startDate,
    startTime,
    resources,
  ]);

  const [isSubmitBookingEnabled, setIsSubmitBookingEnabled] = useState(false);

  useEffect(() => {
    if (isSubmitBookingEnabled) {
      onSubmitBookingRequest({
        availableNowStartDate: startDate,
        availableNowStartTime: startTime,
        availableNowEndTime: endTime,
        availableNowResource: resource,
        currentRoomLayout,
        selectedAddOns,
      });
      setIsSubmitBookingEnabled(false);
      dispatch(resetPrice());
    }
  }, [
    isSubmitBookingEnabled,
    setIsSubmitBookingEnabled,
    onSubmitBookingRequest,
    dispatch,
    resetPrice,
    startDate,
    startTime,
    endTime,
    resource,
    currentRoomLayout,
    selectedAddOns,
  ]);

  useEffect(() => {
    if (getResourceStatus === ACTION_STATUSES.FULFILLED && isAvailableBlockClicked) {
      const params = {
        ...queryParams,
        startDate,
        startTime,
        endTime,
        isExact: true,
      };
      const queryString = qs.stringify(params);
      if (hasAdditionalInfo) {
        setIsAvailableBlockClicked(false);
        dispatch(push(`resource-booking/resource/${resource.uuid}/additional-information?${queryString}`));
      } else if (
        startDate &&
        startTime &&
        endTime &&
        ((isPaidResource && getPriceStatus === ACTION_STATUSES.FULFILLED) || !isPaidResource)
      ) {
        setIsAvailableBlockClicked(false);
        setIsSubmitBookingEnabled(true);
      }
    }
  }, [
    dispatch,
    endTime,
    getPriceStatus,
    getResourceStatus,
    hasAdditionalInfo,
    isAvailableBlockClicked,
    isPaidResource,
    onSubmitBookingRequest,
    resource?.uuid,
    search,
    startDate,
    startTime,
  ]);

  const renderAvailabilitySubtitleText = useCallback(
    (availableNow: AvailableNow, subtitles: AvailableNowSubtitles) => {
      const currentDate = new Date();
      const resourceAvailabilityEndDate = new Date(availableNow?.end_at);

      if (
        resourceAvailabilityEndDate > currentDate &&
        resourceAvailabilityEndDate.getHours() === 0 &&
        resourceAvailabilityEndDate.getMinutes() === 0 &&
        resourceAvailabilityEndDate.getSeconds() === 0
      ) {
        return subtitles.availableAllDay;
      }

      const resourceAvailabilityText =
        availableNow?.available_minutes >= 60
          ? `${Math.floor(availableNow?.available_minutes / 60)} ${subtitles.hours}`
          : `${availableNow?.available_minutes} ${subtitles.minutes}`;

      return `${subtitles.availableFor} ${resourceAvailabilityText}`;
    },
    [resources],
  );

  const calculateAvailableNowEndTime = useCallback(
    (availableNow: AvailableNow, minimumDuration: number, maximumDuration: number) => {
      const currentAvailableStartDate = new Date(availableNow.start_at.replace(/-/g, '/'));

      if (minimumDuration && ONE_HOUR <= minimumDuration) {
        currentAvailableStartDate.setMinutes(currentAvailableStartDate.getMinutes() + minimumDuration);
      } else if (maximumDuration && ONE_HOUR >= maximumDuration) {
        currentAvailableStartDate.setMinutes(currentAvailableStartDate.getMinutes() + maximumDuration);
      } else if (ONE_HOUR >= availableNow?.available_minutes) {
        currentAvailableStartDate.setMinutes(currentAvailableStartDate.getMinutes() + availableNow?.available_minutes);
      } else {
        currentAvailableStartDate.setHours(currentAvailableStartDate.getHours() + 1);
      }

      const midnightFormatOptions =
        currentAvailableStartDate.getHours() === 0 && currentAvailableStartDate.getMinutes() === 0
          ? { hour12: false }
          : {};

      return currentAvailableStartDate.toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
        ...midnightFormatOptions,
      });
    },
    [resources],
  );

  const buildAvailableNowIcons = useCallback(
    (windows: BookingWindow[], resourceCapacity: number, availableNowIcons: AvailableNowIcons) => {
      const pricing = {
        free: windows.some(window => window.pricing_rules && window.pricing_rules[0]?.min_interval_price === 0),
        paid: windows.some(window => window.pricing_rules && !!window.pricing_rules[0]?.min_interval_price),
      };

      const activeAvailableNowIcons: Array<JSX.Element> = [];

      // Populate with pricing pills first
      if (pricing.free && pricing.paid) {
        activeAvailableNowIcons.push(availableNowIcons.paidResourceIcon, availableNowIcons.freeResourceIcon);
      } else if (pricing.paid && !pricing.free) {
        activeAvailableNowIcons.push(availableNowIcons.paidResourceIcon);
      } else if (pricing.free && !pricing.paid) {
        activeAvailableNowIcons.push(availableNowIcons.freeResourceIcon);
      }

      // Add capacity icon
      if (resourceCapacity) {
        activeAvailableNowIcons.push(availableNowIcons.capacityIcon);
      }

      return activeAvailableNowIcons;
    },
    [resources],
  );

  const onAvailableNowBlockClick = useCallback(
    (
      uuid: string,
      availableNow: AvailableNow,
      minimumDuration: number,
      maximumDuration: number,
      resourceType: string,
    ) => {
      if (isCompactUiEnabled) {
        dispatch(
          getResource.request({
            ownerUuid,
            ownerType,
            resourceUuid: uuid ?? resources.find(reduxResource => reduxResource.uuid === resourceUuid).id.toString(),
          }),
        );
      }

      const selectedAvailableNowResource = resources?.find(({ uuid: uuidFromState }) => uuidFromState === uuid);

      track(
        TRACK_EVENT_NAMES.AVAILABLE_NOW_RESOURCE_CLICK,
        {
          type: TRACK_EVENT_TYPES.ACTION,
          resource: {
            type: resourceType,
            uuid,
            resource_id: selectedAvailableNowResource?.id?.toString(),
            name: selectedAvailableNowResource?.name,
            capacity: selectedAvailableNowResource?.capacity,
          },
        },
        { sendToHqoTracking: true },
      );
      const availableEndTime = !availableNow.available_minutes
        ? null
        : calculateAvailableNowEndTime(availableNow, minimumDuration, maximumDuration);
      const currentAvailableStartArray = availableNow.start_at.split(' ');
      setEndTime(availableEndTime);
      setStartTime(currentAvailableStartArray[1]);
      setStartDate(currentAvailableStartArray[0]);

      const availableNowFilterParams = {
        ...queryParams,
        startDate: currentAvailableStartArray[0],
        startTime: currentAvailableStartArray[1],
        endTime: availableEndTime,
        isExact: true,
      };
      const queryString = qs.stringify(availableNowFilterParams);
      if (isCompactUiEnabled) {
        setIsAvailableBlockClicked(true);
      } else {
        dispatch(push(`/resource-booking/resource/${uuid}?${queryString}`));
      }
    },
    [
      isCompactUiEnabled,
      calculateAvailableNowEndTime,
      queryParams,
      dispatch,
      ownerUuid,
      ownerType,
      resources,
      resource,
    ],
  );

  return {
    hasAvailableResources,
    renderAvailabilitySubtitleText,
    calculateAvailableNowEndTime,
    buildAvailableNowIcons,
    onAvailableNowBlockClick,
    isGetResourcesLoading,
  };
};
