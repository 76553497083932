import { TIME_RANGES_INTERVAL } from 'shared/consts';
import { TimeRange } from 'store/resource-time-ranges/types';

/**
 * Checks if a series of time within a specified time ranges block are available.
 *
 * This function determines whether all times within the specified time ranges block, starting at the given index
 * and spanning a specified number of time steps, are marked as available.
 *
 * @param {TimeRange[]} timeRanges - An array of time range to check.
 * @param {number} timeSteps - The number of consecutive time steps to consider within the block.
 * @param {number} index - The starting index within the `timeRanges` array for the block.
 * @returns {boolean} - `true` if all times within the block are available, otherwise `false`.
 */
const checkAreTimesInTimeBlockAvailable = (timeRanges: TimeRange[], timeSteps: number, index: number): boolean => {
  const minDurationTimeRanges = timeRanges.slice(index, index + timeSteps);
  const areAllTimesAvailable = minDurationTimeRanges.every(({ available }) => available);
  return minDurationTimeRanges.length === timeSteps && areAllTimesAvailable;
};

/**
 * Retrieves a list of available start times based on the provided time ranges.
 *
 * This function filters the given array of time range to identify those with an available status,
 * and for each available time range, it checks if the times within the minimum duration block are all available.
 * The available start times are then extracted from these filtered time ranges and returned as an array.
 *
 * @param {TimeRange[]} timeRanges - An array of time range to filter.
 * @returns {string[]} - An array of time strings in the format 'HH:mm' representing available start times.
 */
export const getAvailableStartTimes = (timeRanges: TimeRange[]): string[] => {
  const availableStartTimes = timeRanges.filter((timeRange, index) => {
    if (timeRange.available) {
      const updatedMinDuration = Math.max(timeRange.time_interval, timeRange.minimum_duration);
      // return true if the times within the min duration block are all available
      const minDurationSteps = updatedMinDuration / TIME_RANGES_INTERVAL;
      return checkAreTimesInTimeBlockAvailable(timeRanges, minDurationSteps, index);
    }
    return false;
  });

  return availableStartTimes.map(({ time }) => time);
};

/**
 * Calculates and returns the end time of a time block based on the given parameters.
 *
 * This function computes the end time of a time block by adding the specified timeRanges interval to the
 * time specified in the time range at the given index. The resulting end time is formatted as 'HH:mm'.
 *
 * @param {TimeRange[]} timeRanges - An array of time range.
 * @param {number} count - The index within `timeRanges` representing the start of the time range.
 * @param {number} timeSteps - The number of time steps to add to the start time.
 * @returns {string} - A time string in the format 'HH:mm' representing the calculated end time.
 */
const getEndTime = (timeRanges: TimeRange[], count: number, timeSteps: number): string => {
  const date = new Date();
  const [hours, minutes] = timeRanges[count + timeSteps - 1].time.split(':');
  date.setHours(+hours, +minutes);
  date.setMinutes(date.getMinutes() + TIME_RANGES_INTERVAL);
  // format the time to be 'HH:mm'
  return date.toLocaleTimeString(undefined, { hourCycle: 'h24', timeStyle: 'short' });
};

/**
 * Retrieves an array of available end times based on a specified start time and time ranges.
 *
 * This function calculates the available end times within the specified time ranges starting from the given start time.
 * The first available end time must be the start time plus minimum duration. Subsequent available end times must be the previous available end time plus time interval.
 * All the times from the start time to the end times must be available.
 * Furthermore, the end time from the start time cannot exceed the the maximum duration.
 *
 * @param {TimeRange[]} timeRanges - An array of time range.
 * @param {string} startTime - a time string in the format 'HH:mm'.
 * @returns {string[]} - An array of time strings representing available end times in the format 'HH:mm'.
 *                      If no valid end times are found, an empty array is returned.
 */
export const getAvailableEndTimesFromStartTime = (timeRanges: TimeRange[], startTime: string): string[] => {
  const startTimeIndex = timeRanges.findIndex(({ time }) => time === startTime);

  if (startTimeIndex === -1) {
    return []; // Return an empty array if startTime is not found in timeRanges.
  }

  const {
    minimum_duration: minDuration,
    time_interval: timeInterval,
    maximum_duration: maxDuration,
  } = timeRanges[startTimeIndex];

  const updatedMinDuration = Math.max(timeInterval, minDuration);
  const minDurationSteps = updatedMinDuration / TIME_RANGES_INTERVAL;
  const timeIntervalSteps = timeInterval / TIME_RANGES_INTERVAL;
  let isAvailable = true;
  let count = startTimeIndex;
  const maxDurationIndex = startTimeIndex + maxDuration / TIME_RANGES_INTERVAL;
  const lastPossibleIndex = maxDuration ? maxDurationIndex : timeRanges.length;
  const availableEndTimes = [];

  while (isAvailable && count + timeIntervalSteps - 1 < lastPossibleIndex) {
    const timeSteps = count === startTimeIndex ? minDurationSteps : timeIntervalSteps;
    isAvailable = checkAreTimesInTimeBlockAvailable(timeRanges, timeSteps, count);

    if (isAvailable) {
      const newTime = getEndTime(timeRanges, count, timeSteps);
      availableEndTimes.push(newTime);
      count = count + timeSteps;
    }
  }

  return availableEndTimes;
};
