import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { FloorPlanResourcesState } from 'store/floorplan-resources/types';
import { ACTION_STATUSES } from 'shared/consts';

export const floorplanResourcesState = (state: RootState): FloorPlanResourcesState => state.floorplanResources;

export const selectAvailableResources = createSelector([floorplanResourcesState], config => {
  return (
    Object.values(config.availableResources)
      .filter(x => x !== null)
      .flatMap(x => x) ?? []
  );
});

export const selectIsAvailabilityLoading = createSelector([floorplanResourcesState], config => {
  const requestsInfo = Object.values(config.getAvailableResources).concat(Object.values(config.getAllResources));
  const isLoading =
    requestsInfo.length === 0 ||
    !!requestsInfo.filter(requestStatus => requestStatus.status === ACTION_STATUSES.PENDING).length;
  return isLoading;
});

export const selectAllResources = createSelector([floorplanResourcesState], config => {
  return (
    Object.values(config.allResources)
      .filter(x => x !== null)
      .flatMap(x => x) ?? []
  );
});
