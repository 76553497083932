import { BookingStatus, MAP_STATUSES } from 'store/bookings/types';
import React, { useCallback, useEffect } from 'react';
import { bookingDate, getFormattedEventDuration } from 'utils/formatDate';
import { colors, useBooleanState } from '@hqo/react-components-library';

import { Carousel } from '@hqo/react-components-library/dist/molecules/carousel';
import { CHECK_IN_CLOSED_STATUSES, CHECK_IN_STATUSES, PAYMENT_MINIAPP_POST_MESSAGES } from 'shared/consts';
import { UpcomingBooking } from '@hqo/react-components-library/dist/molecules/upcomingBookingBlock';
import { UpcomingBookingsContainer } from './styles';
import { getTransactions } from 'store/transactions/actions';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { useTimeZone } from 'hooks/use-timezone.hook';
import { useUpcomingBookings } from './hooks/useUpcomingBookings';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { push } from 'store/router/actions';

interface UpcomingBookingsProps {
  setBookingId: React.Dispatch<React.SetStateAction<number | string>>;
  openReceipt: VoidFunction;
}

export const UpcomingBookings = ({ setBookingId, openReceipt }: UpcomingBookingsProps) => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const timezone = useTimeZone();
  const intl = useIntl();
  const { value: showPaymentModal, toggle: togglePaymentModal } = useBooleanState(false);

  const { search } = useLocation();
  const { showMiniappPaymentsNavigation, enableMiniappPaymentsMigration } = useFlags();
  const isMobileDevice = useIsSmallViewportWidth();
  const isSwipeModalContent: boolean = showMiniappPaymentsNavigation && isMobileDevice;

  const onMessage = useCallback(
    (event: MessageEvent): void => {
      if (event.data === PAYMENT_MINIAPP_POST_MESSAGES.PAYMENT_MINIAPP_CLOSE && showPaymentModal) {
        setTimeout(() => {
          togglePaymentModal();
        }, 400);
        setTimeout(() => {
          dispatch(getTransactions.request({}));
        }, 400);
      }
    },
    [dispatch, showPaymentModal, togglePaymentModal],
  );

  const { formatTransactions: bookings } = useUpcomingBookings();
  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const onClick = useCallback(
    (id: any) => {
      setBookingId(id);
      if (enableMiniappPaymentsMigration) {
        openReceipt();
      } else if (isSwipeModalContent) {
        dispatch(push(`swipe-payment${search}`));
      } else {
        dispatch(push(`payment${search}`));
      }
      togglePaymentModal();
    },
    [
      openReceipt,
      dispatch,
      enableMiniappPaymentsMigration,
      isSwipeModalContent,
      search,
      setBookingId,
      togglePaymentModal,
    ],
  );

  const renderEventDurationAndLocationString = useCallback(
    (startAt: string, endAt: string, locationName: string): string => {
      if (locationName) {
        return `${getFormattedEventDuration(startAt, endAt, locale, timezone)} / ${locationName}`;
      }
      return getFormattedEventDuration(startAt, endAt, locale, timezone);
    },
    [locale, timezone],
  );

  return (
    <UpcomingBookingsContainer isEmpty={bookings?.length === 0}>
      {bookings.length > 0 && (
        <Carousel title={intl.formatMessage({ id: 'upcoming' })} orientation="horizontal" height={750} width={239}>
          {bookings.map(booking => {
            const isCheckedIn = CHECK_IN_CLOSED_STATUSES.includes(
              booking.details.check_in?.status?.toLowerCase() as CHECK_IN_STATUSES,
            );

            return (
              <UpcomingBooking
                dataTestId={booking.details.name}
                id={booking.details.id}
                key={booking.uuid}
                title={booking.details.name}
                date={bookingDate(booking.details.start_at, locale, timezone)}
                time={renderEventDurationAndLocationString(
                  booking.details.start_at,
                  booking.details.end_at,
                  booking.details.location_name,
                )}
                onClick={onClick}
                withStatus={booking.details.status !== BookingStatus.ACCEPTED}
                status={intl.formatMessage({ id: MAP_STATUSES[booking.details.status] })}
                statusColor={colors.universal.sharedOrange}
                isCheckInAllowed={!!booking.details.check_in?.status}
                checkInText={intl.formatMessage({ id: isCheckedIn ? 'is_checked_in' : 'check_in_status' })}
                isCheckedIn={isCheckedIn}
              />
            );
          })}
        </Carousel>
      )}
    </UpcomingBookingsContainer>
  );
};
