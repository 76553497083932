import { Button } from '@hqo/react-components-library';
import styled from 'styled-components';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';

export const PopupWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.universal.white};
  width: 100%;
  border: 1px solid #f4f4f4;
  border-radius: 12px;
  box-shadow: 0px 2px 10px rgba(54, 54, 54, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 21px 16px;
`;

export const FixedWrapper = styled.div`
  max-width: 344px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 82px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    bottom: 180px;
  }
`;

export const SimpleWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const NameCapacityWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  text-align: left;
  margin: 2px 0;
`;

export const ActionButton = styled(Button)`
  fontweight: 500;
  fontsize: 16px;
`;
