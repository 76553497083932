import React, { Dispatch, useCallback } from 'react';
import {
  StyledRadioButton,
  StyledRadioButtonGroup,
} from 'components/payment/quick-checkout/components/unit-code-section/styles';
import { useIntl } from 'react-intl';
export interface UnitCodeSectionProps {
  unitCodes: string[];
  selectCode: Dispatch<React.SetStateAction<string>>;
  selectedUnitCode: string;
}

export const UnitCodeSection = ({ unitCodes, selectCode, selectedUnitCode }: UnitCodeSectionProps) => {
  const intl = useIntl();

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      selectCode(event.target.value);
    },
    [selectCode],
  );

  return (
    <StyledRadioButtonGroup
      data-testid={'unit-code-group'}
      groupName="unit-codes"
      label={intl.formatMessage({ id: 'selectApartment' })}
      onChange={onChange}
    >
      {unitCodes.map(unitCode => {
        return (
          <StyledRadioButton
            key={unitCode}
            value={unitCode}
            name="unit-codes"
            data-testid={`unit-code-item-${unitCode}`}
            selectedValue={selectedUnitCode}
            label={unitCode}
          />
        );
      })}
    </StyledRadioButtonGroup>
  );
};
