import { ButtonContainer, CancelButton } from './styles';

import { ACTION_STATUSES, PATCH_STATUSES } from 'shared/consts';
import React from 'react';
import { useIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PatchTransaction } from 'store/transactions/types';

interface CancelButtonBlockProps {
  onCancel: VoidFunction;
  expired?: boolean;
  isReceiptCTA?: boolean;
  patchTransactionData: Pick<PatchTransaction, 'status' | 'type'>;
}

export const CancelButtonBlock = ({
  onCancel,
  patchTransactionData,
  expired,
  isReceiptCTA,
}: CancelButtonBlockProps) => {
  const intl = useIntl();
  const { showMiniappPaymentsNavigation: isSwipeModalContent } = useFlags();
  const resolveButtonText = () => {
    if (expired) return 'cancellation_period_closed';

    if (isReceiptCTA || patchTransactionData.type !== PATCH_STATUSES.CANCELLED) return 'cancel_booking';

    switch (patchTransactionData.status) {
      case ACTION_STATUSES.FULFILLED:
        return 'canceled';
      case ACTION_STATUSES.PENDING:
        return 'cancel_booking';
      case ACTION_STATUSES.REJECTED:
        return 'cancellation_denied';
      default:
        return 'yes_cancel';
    }
  };

  return (
    <ButtonContainer>
      <CancelButton
        isSwipeModalContent={isSwipeModalContent}
        data-testid="cancel-button"
        text={intl.formatMessage({ id: resolveButtonText() })}
        variant={isSwipeModalContent ? 'secondary' : 'primary'}
        onClick={onCancel}
        loading={
          patchTransactionData.status === ACTION_STATUSES.PENDING &&
          patchTransactionData.type === PATCH_STATUSES.CANCELLED
        }
        disabled={expired || !!patchTransactionData.status}
      />
    </ButtonContainer>
  );
};
