import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrice } from 'store/price/actions';
import moment from 'moment-timezone';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { resourceState } from 'store/bookings/selectors';
import { GetPriceParams } from 'store/price/types';

export const usePrice = () => {
  const dispatch = useDispatch();
  const { ownerType, ownerUuid } = useOwnerParams();
  const { startDate, startTime, endTime } = useSearchParams();
  const { resource } = useSelector(resourceState);
  const from = endTime ? moment(`${startDate} ${startTime}`).tz(resource.timezone, true)?.utc().format() : null;
  const to = endTime ? moment(`${startDate} ${endTime}`).tz(resource.timezone, true)?.utc().format() : null;

  useEffect(() => {
    if (from && to && startDate) {
      dispatch(
        getPrice.request({
          ownerType,
          ownerUuid,
          resourceId: resource.id,
          from,
          to,
        } as unknown as GetPriceParams),
      );
    }
  }, [from, to]);
};
