import { FLOORPLAN_COLORS, FLOORPLAN_SPACE_TYPES } from 'floorplan/const';
import {
  ArchilogicEvent,
  AvailabilityInfo,
  FloorPlan,
  Space,
  SpacesAvailabilityInfo,
} from 'floorplan/pages/floorplan/types';
import { Resource } from 'store/resource/types';
import { LinkedSpace } from 'store/spaceManager/types';

export const getAllFloorplanResources = (floorPlan: FloorPlan): Space[] => {
  return floorPlan.resources.spaces.concat(floorPlan.resources.assets);
};

const getResourcesByEvent = (floorPlan: FloorPlan, event: ArchilogicEvent): Array<Space> => {
  const { spaces, assets } = floorPlan.getResourcesFromPosition(event.pos);
  return spaces.concat(assets);
};

const getInteractiveResource = (resources: Array<Space>, linkedSpaces: Map<string, LinkedSpace>): Space | undefined => {
  if (linkedSpaces?.size) {
    return resources.find(resource =>
      Array.from(linkedSpaces.values()).find((linkedSpace: LinkedSpace) => linkedSpace.archilogic_id === resource.id),
    );
  }
};

export const getTargetSpace = (
  floorPlan: FloorPlan,
  event: ArchilogicEvent,
  linkedSpaces: Map<string, LinkedSpace>,
): Space | null => {
  const resources: Array<Space> = getResourcesByEvent(floorPlan, event);
  const interactiveResource = getInteractiveResource(resources, linkedSpaces);
  return interactiveResource ?? null;
};

export const getLinkedSpaceBySpace = (linkedSpaces: Map<string, LinkedSpace>, resource: Space): LinkedSpace | null => {
  if (!resource) {
    return null;
  }
  const matchedLinkedSpace = Array.from(linkedSpaces.entries()).find(
    ([, space]) => space.archilogic_id === resource.id,
  );
  return matchedLinkedSpace ? matchedLinkedSpace[1] : null;
};

export const isAvailable = (resource: Space, spacesAvailableInfo: SpacesAvailabilityInfo): boolean => {
  const availabityInfo: AvailabilityInfo = spacesAvailableInfo[resource.id];
  if (availabityInfo) {
    return !!availabityInfo.availableResource;
  }
  return false;
};

const mapResourcestoLinkedSpaces = (
  spaces: Space[],
  linkedSpaces: Map<string, LinkedSpace>,
): Array<[Space, LinkedSpace]> => {
  const mapped: Array<[Space, LinkedSpace]> = spaces.map(space => [space, getLinkedSpaceBySpace(linkedSpaces, space)]);
  return mapped.filter(([, linkedSpace]) => linkedSpace);
};

export const mapAvailableSpaces = (
  linkedSpaces: Map<string, LinkedSpace>,
  availableResources: Array<Resource>,
  archilogicSpaces: Space[],
): SpacesAvailabilityInfo => {
  if (archilogicSpaces && linkedSpaces) {
    const mapSpacestoLinkedSpaces = mapResourcestoLinkedSpaces(archilogicSpaces, linkedSpaces);

    const spacesDict = Object.assign(
      {},
      ...mapSpacestoLinkedSpaces.map(([space, linkedSpace]) => ({
        [space.id]: {
          availableResource: availableResources.find(
            availableResource => availableResource.uuid === linkedSpace.resource_booking_id,
          ),
          space,
          linkedSpace,
        },
      })),
    );
    return spacesDict;
  }
  return {};
};

export const colorResource = (space: Space, color: Array<number> | undefined, opacity?: number): void => {
  space.node.setHighlight(color ? { fill: color, fillOpacity: opacity } : undefined);
};

export const highlightSpaces = (
  spacesAvailabilityInfo: SpacesAvailabilityInfo,
  isAvailabilityLoading: boolean,
): void => {
  Object.values(spacesAvailabilityInfo).forEach((availabilityInfo: AvailabilityInfo) => {
    if (availabilityInfo.availableResource) {
      colorResource(availabilityInfo.space, FLOORPLAN_COLORS.green, 0.6);
    } else {
      if (!isAvailabilityLoading) {
        colorResource(availabilityInfo.space, FLOORPLAN_COLORS.unavailable_unselected);
      } else {
        colorResource(availabilityInfo.space, undefined);
      }
    }
  });
};

export const getArchilogicSpaceTypesInitialValues = (): { [resourceType: string]: string } => {
  return FLOORPLAN_SPACE_TYPES.reduce((previousState, spaceType) => ({ ...previousState, [spaceType]: '' }), {});
};

export const stripQueryParams = (queryParams: string, paramsToBeStripped: Array<string>): string => {
  const searchParamsUnpacked = new URLSearchParams(queryParams);
  for (const removeKey of paramsToBeStripped) {
    searchParamsUnpacked.delete(removeKey);
  }
  return searchParamsUnpacked.toString();
};
