import { Container, CustomQuestionWrapper, DescriptionWrapper, DoublePane } from './styles';

import AdditionalInformation from './AdditionalInformation';
import AdditionalSelectors from './AdditionalSelectors';
import React, { Dispatch, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Footer } from 'components/footer';
import { useSelector } from 'react-redux';
import { selectCreateCartStatus } from 'store/cart/selectors';
import { isButtonLoading } from 'utils/isButtonLoading';
import { UnitCodeSection } from 'components/payment/quick-checkout/components/unit-code-section';
import { selectAllUnitCodes } from 'store/unit-codes/selectors';

export interface Props {
  onBooking: () => void;
  userResponse: Array<FormResponse>;
  setUserResponse: (arg: Array<FormResponse>) => void;
  onDateLinkClick?: VoidFunction;
  onTimeLinkClick?: VoidFunction;
  selectCode?: Dispatch<React.SetStateAction<string>>;
  selectedUnitCode?: string;
}

export const AdditionalInformationPage = ({
  userResponse,
  setUserResponse,
  onBooking,
  onDateLinkClick,
  onTimeLinkClick,
  selectCode,
  selectedUnitCode,
}: Props) => {
  const unitCodes = useSelector(selectAllUnitCodes);

  const intl = useIntl();

  const createCartStatus = useSelector(selectCreateCartStatus);
  const isEmptyRequiredFieldsExist = useMemo<boolean>(
    () => userResponse?.some(question => question.required && !question.answer),
    [userResponse],
  );
  const isButtonDisabled = useMemo<boolean>(
    () => isEmptyRequiredFieldsExist || isButtonLoading(createCartStatus),
    [createCartStatus, isEmptyRequiredFieldsExist],
  );

  return (
    <Container data-testid="additional-information-page">
      <DoublePane>
        <DescriptionWrapper>
          <AdditionalInformation />
        </DescriptionWrapper>
        <CustomQuestionWrapper>
          {unitCodes?.length > 1 && (
            <UnitCodeSection unitCodes={unitCodes} selectCode={selectCode} selectedUnitCode={selectedUnitCode} />
          )}
          <AdditionalSelectors userResponse={userResponse} setUserResponse={setUserResponse} />
        </CustomQuestionWrapper>
      </DoublePane>
      <Footer
        onTimeLinkClick={onTimeLinkClick}
        onDateLinkClick={onDateLinkClick}
        onCtaButtonClick={onBooking}
        isButtonDisabled={isButtonDisabled}
        isButtonLoading={isButtonLoading(createCartStatus)}
        ctaButtonText={intl.formatMessage({ id: 'continue' })}
        isErrorTextVisible={isEmptyRequiredFieldsExist}
        errorText={intl.formatMessage({ id: 'fill_required_fields' })}
        isAdditionalInformationPage
      />
    </Container>
  );
};
