import { useCallback, useContext, useEffect, useMemo } from 'react';
import { MiniappSdkContext } from 'components/miniapp-sdk-provider/miniapp-sdk.context';
import { Handler, SdkEvents, HqoClientSdk } from '@hqo/hqo-miniapp-client-sdk';

export const useMiniappSdkClient = (appUuid: string): HqoClientSdk =>
  useMemo(
    () =>
      new HqoClientSdk({
        develop: false,
        app_uuid: appUuid,
        init_timeout: 7,
      }),
    [appUuid],
  );

export const useMiniappSdk = (): HqoClientSdk | null => useContext(MiniappSdkContext);

export const useMiniappSdkEventHandler = (
  eventName: SdkEvents,
  handler: Handler,
  handlerDependencies: unknown[],
): void => {
  const client = useMiniappSdk();
  const eventHandler = useCallback(handler, handlerDependencies);

  useEffect(() => {
    client?.on(eventName, eventHandler);
  }, [eventName, eventHandler]);
};
