import React from 'react';
import { SelectValue } from 'components/floor-select/desktop/styles';
import { TitleComponent } from 'components/floor-select/mobile/styles';
import { useCallback } from 'react';
import { Floor, FloorItem } from 'store/spaceManager/types';
import { Select, SelectItem } from '@hqo/react-components-library';
import { useIntl } from 'react-intl';
interface FloorSelectModalContentProps {
  pickedFloor: Floor;
  options: FloorItem[];
  onFloorSelect: React.Dispatch<React.SetStateAction<string>>;
}

export const FloorSelectModalDesktopContent = ({
  pickedFloor,
  options,
  onFloorSelect,
}: FloorSelectModalContentProps): JSX.Element => {
  const intl = useIntl();
  const handleRenderingValue = useCallback(() => {
    const selectedValue = pickedFloor ? pickedFloor.name : intl.formatMessage({ id: 'noFloorSelected' });
    return <SelectValue>{selectedValue}</SelectValue>;
  }, [pickedFloor]);

  const handleFloorSelect = useCallback(
    (e: any) => {
      onFloorSelect(e.target.value);
    },
    [onFloorSelect],
  );

  return (
    <Select
      onChange={handleFloorSelect}
      value={pickedFloor ? pickedFloor.uuid : ''}
      renderValue={handleRenderingValue}
      titleComponent={<TitleComponent>{intl.formatMessage({ id: 'floor' })}</TitleComponent>}
    >
      {options.map(option => {
        return <SelectItem key={option.uuid} value={option.uuid} renderedValue={option.name} />;
      })}
    </Select>
  );
};
