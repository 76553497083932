import { faApplePay } from '@fortawesome/free-brands-svg-icons';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import { APPLE_PAY_PAYMENT_TYPE_ID } from 'shared/consts/payment-method-types';
import { selectCurrentPaymentMethodId } from 'store/payment/selectors';
import { ButtonContainer, StyledButton, StyledNativePaymentIcon } from '../styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ButtonProps {
  isPaymentMethodsAvailable?: boolean | number;
  isFreeCart?: boolean;
  submitCartStatus: string;
  onCTAClick: VoidFunction;
  pendingNativeMethod?: boolean;
  className?: string;
  checkoutDisabled?: boolean;
}

export const Button = ({
  isPaymentMethodsAvailable,
  isFreeCart,
  submitCartStatus,
  onCTAClick,
  pendingNativeMethod,
  className,
  checkoutDisabled,
}: ButtonProps) => {
  const intl = useIntl();
  const { enableHqoMiniappApplePay } = useFlags();

  const paymentMethodId = useSelector(selectCurrentPaymentMethodId);
  const isApplePay = paymentMethodId === APPLE_PAY_PAYMENT_TYPE_ID && enableHqoMiniappApplePay;

  const buttonDisabled =
    checkoutDisabled ||
    submitCartStatus === ACTION_STATUSES.PENDING ||
    submitCartStatus === ACTION_STATUSES.FULFILLED ||
    pendingNativeMethod;

  const checkoutButtonText = useMemo(() => {
    if (!isPaymentMethodsAvailable && !isFreeCart) {
      return 'add_payment_method';
    }

    if (isApplePay && !isFreeCart) {
      return 'check_out_with';
    }

    return 'book';
  }, [isPaymentMethodsAvailable, isFreeCart, isApplePay]);

  return (
    <ButtonContainer isNext={!isPaymentMethodsAvailable} isFreeCart={isFreeCart} className={className}>
      <StyledButton
        data-testid="checkout-button"
        disabled={buttonDisabled}
        onClick={onCTAClick}
        text={intl.formatMessage({ id: checkoutButtonText })}
        Icon={isApplePay && !isFreeCart && <StyledNativePaymentIcon icon={faApplePay} size="2x" />}
        isNativePayment={isApplePay && !isFreeCart}
      />
    </ButtonContainer>
  );
};
