import React, { useMemo } from 'react';
import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { useResourceLoadingHandler } from 'pages/resources/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LocationSelector } from 'pages/landing/components/location-selector';

export const LocationPicker = (): JSX.Element => {
  const isFilteringEnabled = useFilteringEnabled();
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);
  const { showResourceBookingPortfolioNetworkCrossLocation: isLocationFeatureVisible } = useFlags();
  const isLocationPickerVisible = useMemo<boolean>(
    () => !isLoadingState && isLocationFeatureVisible,
    [isLoadingState, isLocationFeatureVisible],
  );

  if (!isLocationPickerVisible) return null;

  return <LocationSelector isResourcesPage />;
};
