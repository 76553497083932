import { ACTION_STATUSES, TRANSACTION_TYPES } from 'shared/consts';
import {
  CancellationExpiredText,
  ReceiptContainer,
  StyledModalWrapper,
  LeftPaneContentContainer,
  StyledTwoColumnModal,
} from '../styles';
import React from 'react';
import { PatchTransaction, TransactionDetails } from 'store/transactions/types';
import { useIntl } from 'react-intl';
import { ReceiptOverview } from './receipt-overview';
import { ReceiptCheckIn } from './receipt-check-in';
import { ReceiptSummary } from './receipt-summary';
import { CancelBooking } from '../../cancel-booking';
import { useSelector } from 'react-redux';
import { selectTransactionStatus } from 'store/transactions/selectors';
import { useBookingObject } from 'components/payment/receipt/components/hooks/useBookingObject.hook';
import { OrderType } from 'store/cart/types';
import { DoneButtonBlock } from 'components/payment/receipt/components/done-button';

interface ReceiptContentProps {
  transaction: TransactionDetails;
  onCloseClick: VoidFunction;
  onCancelBookingClick: VoidFunction;
  patchTransactionData: Pick<PatchTransaction, 'status' | 'type'>;
  isCancellationExpiredState: boolean;
  isCancelBookingStep: boolean;
  setIsCancelBookingStep: React.Dispatch<React.SetStateAction<boolean>>;
  onCheckInClick: VoidFunction;
}

export const ReceiptContent = ({
  onCancelBookingClick,
  onCloseClick,
  patchTransactionData,
  transaction,
  isCancelBookingStep,
  setIsCancelBookingStep,
  onCheckInClick,
}: ReceiptContentProps) => {
  const intl = useIntl();
  const getTransactionStatus = useSelector(selectTransactionStatus);
  const isCheckInAllowed =
    !!transaction?.details?.check_in?.status && patchTransactionData.status !== ACTION_STATUSES.FULFILLED;
  const mappedBookingObject = useBookingObject(transaction);

  return isCancelBookingStep ? (
    <StyledModalWrapper
      isCancelBookingPage
      visible
      content={<CancelBooking setIsCancelBookingStep={setIsCancelBookingStep} transaction={transaction} />}
    />
  ) : (
    <ReceiptContainer data-testid="receipt-page">
      {transaction && (
        <StyledTwoColumnModal
          isContentLoading={getTransactionStatus === ACTION_STATUSES.PENDING}
          isVisible
          onClose={onCloseClick}
          LeftPaneContent={
            <LeftPaneContentContainer>
              <ReceiptOverview transaction={transaction} />
              {isCheckInAllowed && <ReceiptCheckIn onCheckIn={onCheckInClick} />}
            </LeftPaneContentContainer>
          }
          RightPaneContent={<ReceiptSummary onCancelBooking={onCancelBookingClick} transaction={mappedBookingObject} />}
          Button={
            (transaction?.type === TRANSACTION_TYPES.RESOURCES_BOOKING ||
              mappedBookingObject?.type === OrderType.RESOURCE_BOOKING) &&
            !mappedBookingObject.isMultiday && (
              <DoneButtonBlock onClick={onCloseClick} text={intl.formatMessage({ id: 'done' })} />
            )
          }
          ButtonLabel={
            transaction.details?.cancellation_window_end_minutes >= 0 &&
            transaction.details?.cancellation_window_end_minutes !== null && (
              <CancellationExpiredText>
                {intl.formatMessage(
                  { id: 'notice_required' },
                  { hours: transaction.details?.cancellation_window_end_minutes / 60 },
                )}
              </CancellationExpiredText>
            )
          }
        />
      )}
    </ReceiptContainer>
  );
};
