import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts/';
import { goBack, push, replace } from 'store/router/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { selectResourcesAmount } from 'store/resources/selectors';
import { track } from '@hqo/web-tracking';
import { useBooleanState } from '@hqo/react-components-library';
import { useCallback } from 'react';
import { useSearchParams } from 'hooks/use-search-params.hook';

interface UseFilterBarReturnValues {
  reverseAnimation: boolean;
  onOpenModal: VoidFunction;
  onCloseModal: VoidFunction;
  onCTAClick: VoidFunction;
  pathname: string;
}

export const useFilterModalNavigation = (): UseFilterBarReturnValues => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { value: reverseAnimation, toggle: toggleReverseAnimation } = useBooleanState(true);

  const { ...queryParams } = useSearchParams();
  const { filterByType, startDate, startDates, startTime, endTime, duration, filterByFloor } = queryParams;
  const resourceCount = useSelector(selectResourcesAmount);
  const multiDay = !!startDates;

  const filterEntries = Object.entries({
    filterByType,
    startDate,
    multiDay,
    startTime,
    endTime,
    duration,
    filterByFloor,
  });
  const filtersForTracking = filterEntries.map(entry => (entry[1] ? entry[0] : null)).filter(value => value !== null);

  const onOpenModal = useCallback(() => {
    toggleReverseAnimation();
    dispatch(push(`${pathname}/filter-by${search}`));
  }, [dispatch, search, toggleReverseAnimation, pathname]);

  const onCTAClick = useCallback(() => {
    track(
      TRACK_EVENT_NAMES.FILTER_SHOW_RESULTS_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        filters_applied: filtersForTracking,
      },
      { sendToHqoTracking: true },
    );
    toggleReverseAnimation();
    dispatch(replace(`${pathname.replace('/filter-by', '')}${search}`));
  }, [dispatch, search, toggleReverseAnimation, pathname, resourceCount]);

  const onCloseModal = useCallback(() => {
    toggleReverseAnimation();
    dispatch(goBack());
  }, [dispatch, toggleReverseAnimation]);

  return {
    reverseAnimation,
    onOpenModal,
    onCloseModal,
    onCTAClick,
    pathname,
  };
};
