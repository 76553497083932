import React, { useRef } from 'react';
import { Container } from './styles';
import { WheelPickerContainerProps } from './wheel-picker-container.interface';
import { useOnClickOutside } from '@hqo/react-components-library';

export const WheelPickerContainer = ({
  height,
  width,
  onClickOutside,
  outsideClickRef,
  children,
  onItemClick,
}: WheelPickerContainerProps): JSX.Element => {
  const containerRef = useRef(null);

  useOnClickOutside(() => onClickOutside?.(), [outsideClickRef || containerRef]);

  return (
    <Container
      ref={containerRef}
      height={height}
      width={width}
      data-testid="wheel-picker-container"
      onClick={onItemClick}
    >
      {children}
    </Container>
  );
};
