import React from 'react';
import { FlexContainer, SkeletonBlock } from '../styles';

export const AmenityBlock = (): JSX.Element => {
  return (
    <FlexContainer gap="25">
      <SkeletonBlock width="66" />
      <SkeletonBlock width="232" />
    </FlexContainer>
  );
};
