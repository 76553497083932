import styled from 'styled-components';

export const FloorplanListViewContainer = styled.div``;

export const FloorplanListViewLabel = styled.div`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const FloorplanListViewResourcesContainer = styled.div`
  padding: 8px;
  margin-bottom: 5px;
`;
