import { Button, Cancel, Confirmed, DIMENSIONS, PaymentDetails } from '@hqo/react-components-library';
import { StatusIconTitle } from '@hqo/react-components-library/dist/molecules/statusTitle';
import styled from 'styled-components';
import { PAYMENT_CONTENT_WIDTH } from 'shared/consts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OverviewContainer = styled.div<{ isSwipeModalContent: boolean }>`
  display: grid;
  grid-gap: 18px;
  padding: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 0 : '0 16px')};
`;

export const Subtitle = styled.div`
  font: 400 16px/19.2px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const TitleContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-top: 25px;
  }
`;

export const PaymentMethodContainer = styled.div`
  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    padding: 0 12px;
  }
`;

export const SummaryContainer = styled.div`
  display: grid;
  grid-gap: 18px;
  box-sizing: border-box;
  padding-bottom: 140px;
`;

export const PaymentMethodTitle = styled.div`
  font: 500 16px/24px ${({ theme }) => theme.fonts.join()};
  margin-bottom: 23px;
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const CustomIconConfirmed = styled(Confirmed)`
  color: #04623e;
`;

export const CustomIconCancel = styled(Cancel)`
  color: ${({ theme }) => theme.colors.admin.pink};
`;

export const ServiceDetails = styled.div<{ isSwipeModalContent: boolean }>`
  margin: ${({ isSwipeModalContent }) => (isSwipeModalContent ? 0 : '0 16px')};

  button[data-testid='icon-block-action'] {
    color: ${({ theme }) => theme.colors.$primary};
  }

  .icon-block-link {
    color: ${({ theme }) => theme.colors.$primary};
  }

  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}) {
    ${({ theme }) => `border-top: 1px solid ${theme.colors.greys.sharedMediumGrey};`}
    padding-top: 16px;
  }
`;

export const IconBlockContainer = styled.div`
  margin: 16px 0 32px 0;
  display: grid;
  grid-gap: 23px;

  .icon-block-title {
    font-weight: bold;
  }

  & > div:last-child {
    margin-top: 12px;
  }
`;

export const StyledSummarySubtitle = styled.div`
  font: 700 20px/23px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const StyledStatusIconTitle = styled(StatusIconTitle)`
  flex-direction: row-reverse;
  gap: 16px;
`;

export const StyledPaymentDetails = styled(PaymentDetails)`
  margin-top: 0;

  @media only screen and (min-width: ${DIMENSIONS.LARGE}) {
    padding: 0 12px;
  }
`;

export const PaymentDetailsItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin: 2px 0 0 7px;
`;

export const CheckInContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  padding: 16px;
  margin: 32px 16px 16px 16px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
  border-radius: 8px;
`;

export const CheckInTitle = styled.div`
  font: 500 20px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }): string => theme.colors.fontColor};
  text-transform: capitalize;
`;

export const CheckInDescription = styled.div`
  font: 400 14px/21px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const CheckInButtonContainer = styled.div`
  display: flex;
  justify-self: end;
  width: 35%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    justify-self: center;
    width: 100%;
  }
`;

export const CheckInButton = styled(Button)`
  width: 100%;
  height: 48px;
  padding: 0;
`;

export const CheckedInContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  font-style: italic;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};

  svg path {
    fill: ${({ theme }) => theme.colors.greys.adminGreyDark};
  }
`;

export const CheckedInIcon = styled(Confirmed)`
  margin-right: 4px;
`;

export const VisitorsCountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
`;

export const Title = styled.div`
  font: 500 14px / normal ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.darkGrey7};
`;

export const StyledVisitorsIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.palette.systemBlackAlt};
`;
