import React from 'react';

import { FloorplanListViewResourcesContainer, FloorplanListViewLabel } from './styles';
import { FloorplanPopup } from 'components/floor-plan-popup';
import { useIntl } from 'react-intl';
import { useResourceTile } from 'hooks/use-resource-tile.hook';
import { useSelector } from 'react-redux';
import { selectAppInstanceConfigs } from 'store/app-instance-configs/selectors';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { FloorPlanResourceInfo } from 'components/floor-plan-list-view/floor-plan-list.interface';

interface FloorplanListViewResourcesInterface {
  resources: FloorPlanResourceInfo[];
  translationLabelId: string;
  isAvailableList: boolean;
  toggleReverseAnimation?: VoidFunction;
  toggleModalSlideAnimation?: VoidFunction;
}

export const FloorPlanListViewResources = ({
  resources,
  translationLabelId,
  isAvailableList,
  toggleReverseAnimation,
  toggleModalSlideAnimation,
}: FloorplanListViewResourcesInterface): JSX.Element => {
  const searchParams = useSearchParams();
  const intl = useIntl();
  const { onButtonClick } = useResourceTile({
    toggleReverseAnimation,
    toggleModalSlideAnimation,
    isAvailable: isAvailableList && !searchParams.presetWindows,
  });
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);

  return (
    <FloorplanListViewResourcesContainer>
      <FloorplanListViewLabel>
        {intl.formatMessage(
          { id: translationLabelId },
          {
            numberOfResources: resources.length,
          },
        )}
      </FloorplanListViewLabel>
      {resources?.map(resourceInfo => {
        const resourceAppInstanceConfig = resourceInfo.appInstanceConfigUuid;
        const resource = resourceInfo.resource;
        return (
          <FloorplanPopup
            key={resource.id}
            resourceUuid={resource.uuid}
            resourceName={resource.name}
            capacity={resource.capacity}
            isAvailable={isAvailableList}
            isFixed={false}
            onClick={onButtonClick}
            resourceAppInstanceConfig={resourceAppInstanceConfig}
            isCompactUI={
              appInstanceConfigs.find(config => config.uuid === resourceAppInstanceConfig)?.config.compact_ui_enabled
            }
          />
        );
      })}
    </FloorplanListViewResourcesContainer>
  );
};
