import { ChangeEvent, useCallback, useEffect } from 'react';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { searchParams } from 'pages/date-select-page/interface';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useLocale } from 'hooks/use-locale.hook';
import { selectPresetBookableWindows } from 'store/resource-time-ranges/selectors';
import { PresetWindows } from 'store/resources/types';
import { DEFAULT_HEIGHT, DEFAULT_TIME_INTERVAL } from 'components/schedule-select/const';
import { RefObject, TimeRangeByDuration } from 'components/schedule-select/interface';
import { selectResource } from 'store/resource/selectors';
import { getEndTimeByDuration } from 'utils/getEndTimeByDuration';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { formatSelectedDates } from '../utils/formatSelectedDates';

interface UseScheduleSelectHeaderProps {
  refs: Array<RefObject>;
  availableTimeRangeByMinDuration: TimeRangeByDuration;
}

interface UseScheduleSelectHeaderReturnValues {
  scheduleTitle: string;
  checked: boolean;
  onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  isSwitchBlockVisible: boolean;
}

export const useScheduleSelectHeader = ({
  refs,
  availableTimeRangeByMinDuration,
}: UseScheduleSelectHeaderProps): UseScheduleSelectHeaderReturnValues => {
  const { startDate, presetWindows, startTime, endTime, startDates, ...restQueryParams } =
    useSearchParams<searchParams>();
  const dispatch = useDispatch();
  const locale = useLocale();
  const presetBookableWindows = useSelector(selectPresetBookableWindows);
  const { preset_booking_windows } = useSelector(selectResource) || {};
  const { isPresetBookingWindowsEnabled: isPresetBookingWindows } = useAppInstanceConfigFeature();
  const scheduleTitle = formatSelectedDates(startDates, startDate, locale);
  const checked: boolean = presetWindows === PresetWindows.FULL_DAY;
  const { date, time, duration, available } = presetBookableWindows?.[0] || {};
  const selectedEndTime = getEndTimeByDuration(date, time, duration, locale);
  const isSwitchBlockVisible: boolean =
    preset_booking_windows === PresetWindows.FULL_DAY &&
    isPresetBookingWindows &&
    !!presetBookableWindows?.length &&
    available;
  const { startTimeByDuration, endTimeByDuration, height } = availableTimeRangeByMinDuration || {};
  const isDatesExist = Boolean(startDate) || Boolean(startDates);

  useEffect(() => {
    if (isDatesExist && !startTime && !endTime && presetWindows === PresetWindows.FULL_DAY) {
      const queryParams = {
        ...restQueryParams,
        startDate,
        ...(startDates && { startDates }),
        presetWindows,
        startTime: time,
        endTime: selectedEndTime,
        isExact: true,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [
    dispatch,
    startTime,
    endTime,
    presetWindows,
    restQueryParams,
    time,
    selectedEndTime,
    startDate,
    isDatesExist,
    startDates,
  ]);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const resizableBottom = document.getElementById('resizable-bottom');
      const resizableTop = document.getElementById('resizable-top');
      const availableOffsetTop = refs?.find(ref => ref.queryStringTime === time)?.offsetTop;
      const defaultOffsetTop = refs?.find(ref => ref.queryStringTime === startTimeByDuration)?.offsetTop;
      if (event.target.checked) {
        resizableBottom.style.height = `${(duration / DEFAULT_TIME_INTERVAL) * DEFAULT_HEIGHT}px`;
        resizableTop.style.top = `${availableOffsetTop}px`;
      } else {
        resizableBottom.style.height = `${height}px`;
        resizableTop.style.top = `${defaultOffsetTop}px`;
      }
      const presetWindowsValue = event.target.checked ? PresetWindows.FULL_DAY : undefined;
      const startTimeValue = event.target.checked ? time : startTimeByDuration;
      const endTimeValue = event.target.checked ? selectedEndTime : endTimeByDuration;
      const queryParams = {
        ...restQueryParams,
        startDate,
        ...(startDates && { startDates }),
        startTime: startTimeValue,
        endTime: endTimeValue,
        presetWindows: presetWindowsValue,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    },
    [
      refs,
      time,
      startTimeByDuration,
      selectedEndTime,
      endTimeByDuration,
      restQueryParams,
      startDate,
      dispatch,
      duration,
      height,
      isDatesExist,
      startDates,
    ],
  );

  return { scheduleTitle, checked, onChangeHandler, isSwitchBlockVisible };
};
