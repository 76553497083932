import { useLocale } from 'hooks/use-locale.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'shared/types/search-params';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { EMPTY_STRING } from 'components/schedule-select/const';
import { useCallback } from 'react';
import { SLOTS_15_MINUTES } from 'shared/consts/timeline';

interface UseTimelineBlockProps {
  available: boolean;
  time: string;
  index: number;
  lastTimeRangeIndex: number;
  handleTimelineBlockClick: (index: number) => void;
}

interface UseTimelineBlockReturnValues {
  firstLeftBorder: boolean;
  lastRightBorder: boolean;
  timeText: string;
  timelineTickHeight: string;
  handleOnClick: VoidFunction;
}

export const useTimelineBlock = ({
  available,
  time,
  index,
  lastTimeRangeIndex,
  handleTimelineBlockClick,
}: UseTimelineBlockProps): UseTimelineBlockReturnValues => {
  const locale = useLocale();
  const { startTime, endTime } = useSearchParams<searchParams>();
  const bookingTimeHandlerOptions = { startTime: startTime, endTime: endTime };
  const { getFormatTime } = bookingTimeHandler(bookingTimeHandlerOptions);
  const currentTimeRangeMinutes = time.slice(-2);
  const firstLeftBorder = index === 0;
  const lastRightBorder = index === lastTimeRangeIndex - 1;

  const timeText = time.endsWith(SLOTS_15_MINUTES.ZERO_MINUTES)
    ? getFormatTime(time, locale, { removeOClockMinute: true, toUpperCase: true })
    : EMPTY_STRING;

  let timelineTickHeight = '45px';

  switch (currentTimeRangeMinutes) {
    case SLOTS_15_MINUTES.ZERO_MINUTES:
      timelineTickHeight = '100%';
      break;
    case SLOTS_15_MINUTES.FIFTEEN_MINUTES:
    case SLOTS_15_MINUTES.FORTY_FIVE_MINUTES:
      timelineTickHeight = '20px';
      break;
  }

  const handleOnClick = useCallback(() => {
    if (!available || !handleTimelineBlockClick) {
      return;
    }

    handleTimelineBlockClick(index);
  }, [handleTimelineBlockClick, index, available]);

  return {
    firstLeftBorder,
    lastRightBorder,
    timeText,
    timelineTickHeight,
    handleOnClick,
  };
};
