import React from 'react';
import { FlexContainer, SkeletonBlock } from '../styles';

export const ResourceInformation = (): JSX.Element => {
  return (
    <FlexContainer gap="21" isColumnDirection left={16}>
      <SkeletonBlock width="374" />
      <FlexContainer gap="58">
        <SkeletonBlock width="171" />
        <SkeletonBlock width="94" />
      </FlexContainer>
    </FlexContainer>
  );
};
