import { AjaxResponse, ajax } from 'rxjs/ajax';
import {
  BookingsParams,
  CancelBookingParams,
  CreateBookingParams,
  GetResourceOptions,
  OwnerParams,
} from 'shared/types';
import {
  Complete3DSCartApiResponse,
  CompleteCartDto,
  CreateCartAPIResponse,
  CreateCartDto,
  GetCartApiResponse,
  SubmitCartApiResponse,
  SubmitOrderDto,
} from 'store/cart/types';
import { CreateBookingAPIResponse, GetBookingsAPIResponse } from 'store/bookings/types';
import { CurrentUserResponse, GetCurrentUserPayload } from 'store/user/types';
import { FilterParams, GetResourcesTypesPayload, ResourcesAPIResponse, ResourcesTypes } from 'store/resources/types';
import {
  FilterSpacesQueryParams,
  FloorsAPIResponse,
  GetFloorsQueryParams,
  SpaceAPIResponse,
} from 'store/spaceManager/types';
import {
  FloorPlanAppInstanceConfigResponse,
  GetFloorPlanAppInstanceConfigParams,
} from 'store/floorplan-app-instance-config/types';
import {
  GetAppInstanceConfigsAPIResponse,
  GetAppInstanceConfigsParams,
  OnboardUseParams,
} from '../app-instance-configs/types';
import { GetPriceAPIResponse, GetPriceParams } from 'store/price/types';
import {
  GetResourceAvailableDatesAPIResponse,
  GetResourceAvailableDatesParams,
} from '../resource-available-dates/types';
import { GetResourceTimeRangesAPIResponse, GetResourceTimeRangesParams } from '../resource-time-ranges/types';
import {
  GetTransactionResponse,
  GetTransactionsAPIResponse,
  PatchTransactionDto,
  RedirectTransactionResponse,
  UpdateCheckInAPIResponse,
} from 'store/transactions/types';
import QueryString, { stringify } from 'qs';
import { SavePaymentMethodResponse, SavedPaymentMethod, UpdatePaymentMethodRequest } from 'store/payment/types';

import { BuildingResponse } from 'store/building/types';
import { BuildingTheme } from 'store/theme/types';
import { CHECK_IN_STATUSES } from 'shared/consts';
import { Observable } from 'rxjs';
import { RESOURCE_BOOKING } from 'shared/consts/vertical-name';
import { ResourceAPIResponse } from 'store/resource/types';
import { SavePaymentMethodRequest } from '../payment/types';
import { UiMetadata } from 'store/ui-metadata/types';

export class ApiClient {
  constructor(
    private baseUrl: string,
    private authToken?: string | null,
    private buildingUuid?: string | null,
    private appBrand?: string | null,
    private appInstanceConfigUuid?: string | null,
    private locationId?: string | null,
    private locale?: string | null,
  ) {}

  private get DefaultHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authToken,
      'Hqo-Building-UUID': this.buildingUuid,
      'Hqo-App-Brand': this.appBrand,
    };
  }

  private get DefaultParams(): Record<string, string> {
    return {
      appInstanceConfigUuid: this.appInstanceConfigUuid,
      locationId: this.locationId,
      lang: this.locale,
    };
  }

  private get DefaultParamsOptions(): QueryString.IStringifyOptions {
    return {
      skipNulls: true,
    };
  }

  getCurrentUser({
    authToken,
    appBrand,
    buildingUuid,
  }: GetCurrentUserPayload): Observable<AjaxResponse<CurrentUserResponse>> {
    return ajax.get(`${this.baseUrl}/api/auth/current?${stringify(this.DefaultParams, this.DefaultParamsOptions)}`, {
      'Content-Type': 'application/json',
      Authorization: authToken ?? this.authToken,
      'Hqo-Building-UUID': buildingUuid ?? this.buildingUuid,
      'Hqo-App-Brand': appBrand ?? this.appBrand,
    });
  }

  getResource(
    ownerType: string,
    _ownerUuid: string,
    resourceUuid: string,
    getResourceOptions?: GetResourceOptions,
  ): Observable<AjaxResponse<ResourceAPIResponse>> {
    const { closestAvailability } = getResourceOptions || {};
    const requestParams = { ...this.DefaultParams, closestAvailability };

    return ajax.get(
      `${this.baseUrl}/api/resource-booking/v2/${ownerType}/${this.buildingUuid}/resources/${resourceUuid}?${stringify(
        requestParams,
        this.DefaultParamsOptions,
      )}`,
      this.DefaultHeaders,
    );
  }

  getResources(
    ownerType: string,
    _ownerUuid: string,
    filterParams: FilterParams,
  ): Observable<AjaxResponse<ResourcesAPIResponse>> {
    const requestParams = { ...this.DefaultParams, ...filterParams };
    const paramString = stringify(requestParams, this.DefaultParamsOptions);

    return ajax.get(
      `${this.baseUrl}/api/resource-booking/v2/${ownerType}/${this.buildingUuid}/resources?${paramString}`,
      this.DefaultHeaders,
    );
  }

  createCart(createCartDto: CreateCartDto): Observable<AjaxResponse<CreateCartAPIResponse>> {
    return ajax.post(
      `${this.baseUrl}/api/payments/v1/carts?${stringify(this.DefaultParams, this.DefaultParamsOptions)}`,
      createCartDto,
      this.DefaultHeaders,
    );
  }

  getBookings({
    ownerType,
    ownerUuid,
    resourceUuid,
    permission,
    ...queryParams
  }: BookingsParams): Observable<AjaxResponse<GetBookingsAPIResponse>> {
    const params = {
      ...this.DefaultParams,
      start_time: queryParams.start_date,
      end_time: queryParams.end_date,
      limit: queryParams.limit,
      offset: queryParams.offset,
    };

    return ajax.get(
      `${
        this.baseUrl
      }/api/resource-booking/v2/${ownerType}/${ownerUuid}/resources/${resourceUuid}/bookings/${permission}?${stringify(
        params,
        this.DefaultParamsOptions,
      )}`,
      this.DefaultHeaders,
    );
  }

  getTransactions(): Observable<AjaxResponse<GetTransactionsAPIResponse>> {
    return ajax.get(
      `${this.baseUrl}/api/tenant/v1/users/current/transactions?types=resources_booking&is_upcoming=true&${stringify(
        this.DefaultParams,
        this.DefaultParamsOptions,
      )}`,
      this.DefaultHeaders,
    );
  }

  patchTransaction(patchTransactionDto: PatchTransactionDto) {
    const { transactionId, ...body } = patchTransactionDto;

    return ajax.patch(`${this.baseUrl}/api/transactions/${transactionId}`, body, this.DefaultHeaders);
  }

  checkIn(transactionUuid: string): Observable<AjaxResponse<UpdateCheckInAPIResponse>> {
    const body = { status: CHECK_IN_STATUSES.CONFIRMED };

    return ajax.patch(`${this.baseUrl}/api/check-in/${transactionUuid}`, body, this.DefaultHeaders);
  }

  getPrice({
    ownerType,
    resourceId,
    lang,
    ...queryParams
  }: GetPriceParams): Observable<AjaxResponse<GetPriceAPIResponse>> {
    const params = {
      ...this.DefaultParams,
      from: queryParams.from,
      to: queryParams.to,
      lang,
    };

    return ajax.get(
      `${this.baseUrl}/api/resource-booking/v2/${ownerType}/${
        this.buildingUuid
      }/resources/${resourceId}/price?${stringify(params, this.DefaultParamsOptions)}`,
      this.DefaultHeaders,
    );
  }

  getResourceAvailableDates({
    ownerType,
    resourceId,
    from,
    to,
  }: GetResourceAvailableDatesParams): Observable<AjaxResponse<GetResourceAvailableDatesAPIResponse>> {
    const params = stringify({ from: from.replace(/\./g, ''), to: to.replace(/\./g, ''), ...this.DefaultParams });

    return ajax.get(
      `${this.baseUrl}/api/resource-booking/v2/${ownerType}/${this.buildingUuid}/resources/${resourceId}/available-dates?${params}`,
      this.DefaultHeaders,
    );
  }

  getAppInstanceConfigs({
    owners,
    lang,
    appInstanceConfigUuid,
  }: GetAppInstanceConfigsParams): Observable<AjaxResponse<GetAppInstanceConfigsAPIResponse>> {
    const params = stringify(appInstanceConfigUuid ? { owners, appInstanceConfigUuid, lang } : { owners, lang });

    return ajax.get(`${this.baseUrl}/api/resource-booking/v2/app-instance-configs?${params}`, this.DefaultHeaders);
  }

  getFloorPlanAppInstanceConfig({
    building_uuid,
  }: GetFloorPlanAppInstanceConfigParams): Observable<AjaxResponse<FloorPlanAppInstanceConfigResponse>> {
    return ajax.get(
      `${this.baseUrl}/api/floorplan/v1/BUILDING/${building_uuid}/app-instance-config`,
      this.DefaultHeaders,
    );
  }

  onboardUser({ appInstanceConfigUuid }: OnboardUseParams) {
    return ajax.post(
      `${this.baseUrl}/api/resource-booking/v2/app-instance-configs/${appInstanceConfigUuid}/onboard-user`,
      { accept_terms_and_conditions: true },
      this.DefaultHeaders,
    );
  }

  createBooking({
    ownerType,
    ownerUuid,
    resourceUuid,
    booking,
  }: CreateBookingParams): Observable<AjaxResponse<CreateBookingAPIResponse>> {
    const url = `${
      this.baseUrl
    }/api/resource-booking/v2/${ownerType}/${ownerUuid}/resources/${resourceUuid}/bookings?${stringify(
      this.DefaultParams,
      this.DefaultParamsOptions,
    )}`;
    const body = {
      form_responses: booking.form_responses,
      contacted: booking.contacted,
      description: booking.description,
      name: booking.name,
      start_at: booking.startAt,
      end_at: booking.endAt,
    };

    return ajax.post(url, { booking: body }, this.DefaultHeaders);
  }

  cancelBooking({
    ownerType,
    ownerUuid,
    resourceUuid,
    bookingUuid,
  }: CancelBookingParams): Observable<AjaxResponse<CancelBookingParams>> {
    const url = `${
      this.baseUrl
    }/api/resource-booking/v2/${ownerType}/${ownerUuid}/resources/${resourceUuid}/bookings/${bookingUuid}/mine?${stringify(
      this.DefaultParams,
      this.DefaultParamsOptions,
    )}`;

    return ajax.delete(url, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(
      `${this.baseUrl}/api/buildings/${buildingUuid}/theme?${stringify(this.DefaultParams, this.DefaultParamsOptions)}`,
      this.DefaultHeaders,
    );
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(
      `${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}?${stringify(
        this.DefaultParams,
        this.DefaultParamsOptions,
      )}`,
      this.DefaultHeaders,
    );
  }

  getResourcesTypes({ queryParams }: GetResourcesTypesPayload): Observable<AjaxResponse<ResourcesTypes>> {
    const params = stringify(queryParams ?? { include: 'configured', ...this.DefaultParams });
    const url = `${this.baseUrl}/api/resource-booking/v2/building/${this.buildingUuid}/resources/types?${params}`;

    return ajax.get(url, this.DefaultHeaders);
  }

  getResourceTimeRanges({
    ownerType,
    resourceId,
    ...restParams
  }: GetResourceTimeRangesParams): Observable<AjaxResponse<GetResourceTimeRangesAPIResponse>> {
    let params;
    if ('startDates' in restParams) {
      const { startDates, ...rest } = restParams;
      params = stringify({ dates: startDates, ...rest, ...this.DefaultParams });
    } else {
      params = stringify({ ...restParams, ...this.DefaultParams });
    }

    return ajax.get(
      `${this.baseUrl}/api/resource-booking/v2/${ownerType}/${this.buildingUuid}/resources/${resourceId}/time-ranges?${params}`,
      this.DefaultHeaders,
    );
  }

  getFloors({ buildingUuid, floors_sorting, page }: GetFloorsQueryParams): Observable<AjaxResponse<FloorsAPIResponse>> {
    return ajax.get(
      `${this.baseUrl}/api/space-manager/floors?${stringify({
        building_uuid: buildingUuid,
        sorting: floors_sorting,
        page,
      })}`,
      this.DefaultHeaders,
    );
  }

  getSpacesByFloor({
    floor_uuid,
    vertical_names,
    vertical_adapter_uuid,
    no_pagination,
  }: FilterSpacesQueryParams): Observable<AjaxResponse<SpaceAPIResponse>> {
    const params = stringify({ floor_uuid, vertical_names, vertical_adapter_uuid, no_pagination });
    return ajax.get(`${this.baseUrl}/api/space-manager/spaces?${params}`, this.DefaultHeaders);
  }

  fetchUiMetadata({ ownerType, ownerUuid }: OwnerParams): Observable<AjaxResponse<UiMetadata>> {
    return ajax.get(
      `${this.baseUrl}/api/verticals/${RESOURCE_BOOKING}/${ownerType.toUpperCase()}/${ownerUuid}/ui-metadata`,
      this.DefaultHeaders,
    );
  }

  getCart(cartId: string): Observable<AjaxResponse<GetCartApiResponse>> {
    return ajax.get(`${this.baseUrl}/api/payments/v1/carts/${cartId}`, this.DefaultHeaders);
  }

  submitCart(submitCartDto: SubmitOrderDto): Observable<AjaxResponse<SubmitCartApiResponse>> {
    const params = stringify(
      submitCartDto.locationId
        ? { ...this.DefaultParams, locationId: submitCartDto.locationId }
        : { ...this.DefaultParams },
    );

    return ajax.post(
      `${this.baseUrl}/api/payments/v1/carts/${submitCartDto.cart_id}?${params}`,
      { ...submitCartDto, cart_id: undefined, locationId: undefined },
      this.DefaultHeaders,
    );
  }

  getTransaction(transactionUuid: string): Observable<AjaxResponse<GetTransactionResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/users/current/transactions/${transactionUuid}`, this.DefaultHeaders);
  }

  getRedirectTransaction(transactionUuid: string): Observable<AjaxResponse<RedirectTransactionResponse>> {
    return ajax.get(`${this.baseUrl}/api/payments/transaction/${transactionUuid}`, this.DefaultHeaders);
  }

  complete3DSCart(payload: CompleteCartDto): Observable<AjaxResponse<Complete3DSCartApiResponse>> {
    return ajax.post(`${this.baseUrl}/api/payments/complete-3ds`, payload, this.DefaultHeaders);
  }

  getPaymentMethods(cartId: string): Observable<AjaxResponse<Array<SavedPaymentMethod>>> {
    const params = stringify({ cartId, locationId: this.locationId });

    return ajax.get(`${this.baseUrl}/api/payment-methods?${params}`, this.DefaultHeaders);
  }

  savePaymentMethod(
    savePaymentMethodRequest: SavePaymentMethodRequest,
  ): Observable<AjaxResponse<SavePaymentMethodResponse>> {
    return ajax.post(`${this.baseUrl}/api/payment-methods`, savePaymentMethodRequest, this.DefaultHeaders);
  }

  deletePaymentMethod(paymentMethodId: number): Observable<AjaxResponse<[undefined, undefined]>> {
    return ajax.delete(`${this.baseUrl}/api/payment-methods/${paymentMethodId}`, this.DefaultHeaders);
  }

  updatePaymentMethod(
    updatePaymentMethodRequest: UpdatePaymentMethodRequest,
  ): Observable<AjaxResponse<SavePaymentMethodResponse>> {
    return ajax.patch(
      `${this.baseUrl}/api/payment-methods/${updatePaymentMethodRequest.paymentMethodId}`,
      { default: updatePaymentMethodRequest.default },
      this.DefaultHeaders,
    );
  }
}
