import React from 'react';
import { AddOn } from 'store/resource/types';
import { DetailsModalFooter } from './details-modal-footer';
import { DetailsModalContent } from './details-modal-content';
import { useAddOnDetails } from './use-add-on-details.hook';
import { StyledSwipeModal } from './styles';

interface AddOnDetailsProps {
  isModalVisible: boolean;
  selectedAddOn: AddOn;
  onModalClose: VoidFunction;
}

export const AddOnDetails = ({ isModalVisible, selectedAddOn, onModalClose }: AddOnDetailsProps): JSX.Element => {
  const { quantity, name, ctaButtonText, onClose, onButtonClick, increaseHandler, decreaseHandler } = useAddOnDetails(
    selectedAddOn,
    onModalClose,
  );

  return (
    isModalVisible && (
      <StyledSwipeModal
        data-testid="add-on-details-modal"
        reverseAnimation={false}
        title={name}
        className="add-on-details-modal"
        onClose={onClose}
        noContentWrapperPadding
        content={
          <DetailsModalContent
            selectedAddOn={selectedAddOn}
            quantity={quantity}
            increaseHandler={increaseHandler}
            decreaseHandler={decreaseHandler}
            onModalClose={onModalClose}
          />
        }
        footerContent={<DetailsModalFooter handleClick={onButtonClick} ctaButtonText={ctaButtonText} />}
      />
    )
  );
};
