import { useSelector } from 'react-redux';
import {
  resourcesState,
  selectGetResourcesLoadingStatus,
  selectResourceActionStatus,
  selectResourcesTypes,
} from 'store/resources/selectors';
import { ACTION_STATUSES } from 'shared/consts';
import { bookingsState } from 'store/bookings/selectors';

export const useResourceLoadingHandler = (isFilteringEnabled: boolean): boolean => {
  const isResourcesLoading = useSelector(selectGetResourcesLoadingStatus);
  const isResourceTypesLoading = useSelector(selectResourceActionStatus) !== ACTION_STATUSES.FULFILLED;

  const upcomingBookings = useSelector(bookingsState);
  const resourcesTypes = useSelector(selectResourcesTypes);
  const { loading: isUpcomingBookingLoading } = useSelector(bookingsState);
  const { resources } = useSelector(resourcesState);

  const isContentExist = !upcomingBookings || !resourcesTypes || !resources;

  const isLoadingState = isFilteringEnabled
    ? isResourcesLoading || isResourceTypesLoading || isUpcomingBookingLoading
    : isResourcesLoading || isUpcomingBookingLoading;

  return isLoadingState || isContentExist;
};
