import styled from 'styled-components';
import { CaretDown, SMALL_DEVICE_MAX_WIDTH } from '@hqo/react-components-library';

interface LocationPickerContainerProps {
  isResourcesPage?: boolean;
}

export const LocationPickerContainer = styled.div<LocationPickerContainerProps>`
  display: flex;
  gap: 12px;
  padding: 12px 16px;
  align-items: center;
  width: 423px;
  max-width: 423px;
  height: 36px;
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.fonts.join()};
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.universal.white};
  color: ${({ theme }) => theme?.colors.greys.adminBlack};
  border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
  border-radius: 4px;
  cursor: pointer;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100%;
  }
`;

export const LocationName = styled.div`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  margin-right: auto;
  margin-left: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CaretDownIcon = styled(CaretDown)`
  width: 10px;
  height: 5px;
`;
