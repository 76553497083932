import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import { DEFAULT_DATE_FORMAT, MONTH_OFFSET } from '../constants';

interface GetResourceAvailableDatesPayloadReturnValues {
  from: string;
  to: string;
}

const formatTime = (date: Date, locale: string): string => {
  return date.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric' });
};

export const getResourceAvailableDatesPayload = (
  indexOfMonth: number,
  locale: string,
): GetResourceAvailableDatesPayloadReturnValues => {
  const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + indexOfMonth, 1);
  const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + MONTH_OFFSET + indexOfMonth, 0);
  const firstDayTime = formatTime(firstDayOfMonth, locale);
  const lastDayTime = formatTime(lastDayOfMonth, locale);
  const timeRange = { startTime: firstDayTime, endTime: lastDayTime, locale };

  const formattedFirstDay = bookingTimeHandler(timeRange)
    .getFormatDate(firstDayOfMonth, DEFAULT_DATE_FORMAT)
    .replace(/\./g, '');
  const formattedLastDay = bookingTimeHandler(timeRange)
    .getFormatDate(lastDayOfMonth, DEFAULT_DATE_FORMAT)
    .replace(/\./g, '');

  return { from: formattedFirstDay, to: formattedLastDay };
};
