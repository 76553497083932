import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library';

export const DateTimeFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  gap: 5px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    margin-bottom: 13px;
  }
`;

export const DateTimeFilterContainer = styled.div<{
  areDateTimeLinksVisible: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: ${({ areDateTimeLinksVisible }) => (areDateTimeLinksVisible ? 'flex-start' : 'center')};
  align-items: flex-start;
  flex-direction: column-reverse;
  align-self: flex-start;
  user-select: none;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    justify-content: ${({ areDateTimeLinksVisible }) => (areDateTimeLinksVisible ? 'space-between' : 'flex-end')};
    align-items: center;
    flex-direction: row;
  }
`;
