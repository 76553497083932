import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'components/time-selector/time-selector.interface';
import { PresetWindows } from 'store/resources/types';
import { ChangeEvent, useCallback, useMemo } from 'react';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation } from 'react-router-dom';
import { useLocale } from 'hooks/use-locale.hook';
import { useBooleanState } from '@hqo/react-components-library';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { selectPresetBookableWindows, selectResourceTimeRanges } from 'store/resource-time-ranges/selectors';
import { selectResource } from 'store/resource/selectors';
import { getEndTimeByDuration } from 'utils/getEndTimeByDuration';
import { TimeRange } from 'store/resource-time-ranges/types';
import { findClosestHourAvailability } from 'pages/resource/utils/findClosestHourAvailability';

interface UseTimeSelectPageContentReturnValues {
  isPresetWindowFilterVisible: boolean;
  isOverlayVisible: boolean;
  toggleOverlay: VoidFunction;
  onChangeToggle: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const useTimeSelectPageContent = (): UseTimeSelectPageContentReturnValues => {
  const dispatch = useDispatch();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const { pathname } = useLocation();
  const locale = useLocale();
  const { presetWindows, startTime, ...restQueryParams } = useSearchParams<searchParams>();
  const { value: isOverlayVisible, toggle: toggleOverlay } = useBooleanState(false);
  const { isPresetBookingWindowsEnabled } = useAppInstanceConfigFeature();
  const presetBookableWindows = useSelector(selectPresetBookableWindows);
  const { preset_booking_windows } = useSelector(selectResource) || {};
  const resourceTimeRanges = useSelector(selectResourceTimeRanges);
  const { isPresetBookingWindowsEnabled: isPresetBookingWindows } = useAppInstanceConfigFeature();
  const { date, time, duration, available } = presetBookableWindows?.[0] || {};
  const selectedEndTime = getEndTimeByDuration(date, time, duration, locale);

  const availableTimeRanges = useMemo<Array<TimeRange>>(() => {
    const filteredTimeRanges = resourceTimeRanges?.filter(timeRange => timeRange.available);

    if (startTime) {
      const startTimeIndex = filteredTimeRanges?.findIndex(timeRange => timeRange.time === startTime);

      return filteredTimeRanges?.slice(startTimeIndex);
    }

    return filteredTimeRanges;
  }, [resourceTimeRanges, startTime]);

  const { startTime: startTimeByDuration, endTime: endTimeByDuration } =
    findClosestHourAvailability(availableTimeRanges) || {};

  const isPresetBookingWindowAvailable = useMemo<boolean>(
    () =>
      preset_booking_windows === PresetWindows.FULL_DAY &&
      isPresetBookingWindows &&
      !!presetBookableWindows?.length &&
      available,
    [preset_booking_windows, isPresetBookingWindows, presetBookableWindows, available],
  );

  const isWhenIsBookingPath = pathname === '/date-time-select';
  const isPresetWindowFilterVisible =
    showResourceDetailsDateTimeInputs &&
    !(pathname.includes('resources') || pathname.includes('floorplan') || isWhenIsBookingPath)
      ? isPresetBookingWindowAvailable
      : isPresetBookingWindowsEnabled;

  const onChangeToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const presetWindowsValue = event.target.checked ? PresetWindows.FULL_DAY : undefined;
      const startTimeValue = event.target.checked ? time : startTimeByDuration;
      const endTimeValue = event.target.checked ? selectedEndTime : endTimeByDuration;
      const queryParams = {
        ...restQueryParams,
        startTime: startTimeValue,
        endTime: endTimeValue,
        presetWindows: presetWindowsValue,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${pathname}?${queryString}`));
    },
    [time, selectedEndTime, startTimeByDuration, endTimeByDuration, restQueryParams, dispatch, pathname],
  );

  return { isPresetWindowFilterVisible, onChangeToggle, isOverlayVisible, toggleOverlay };
};
