import { Order, OrderType } from 'store/cart/types';

import { formatCurrency } from './formatCurrency';
import { formatSummaryValues } from './formatSummaryValues';

export const getPrice = (isFreeCart: boolean, cart: Order, freeText: string, locale: string): string => {
  const { currencyType, total } = formatSummaryValues(cart?.total_summary);

  return !isFreeCart && currencyType
    ? formatCurrency(total, currencyType, locale)
    : cart.type !== OrderType.RESOURCE_BOOKING && freeText;
};
