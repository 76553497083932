import {
  ChipContainer,
  FilterBarContainer,
  FilterSearchBarContainer,
  FilterSearchBarWrapper,
  StyledChipComponent,
} from './styles';

import { CapacityPill } from './capacity-pill/capacity-pill';
import { DurationPill } from './duration-pill';
import { FilterIcon } from 'components/filter-icon/component';
import { FilterModal } from 'pages/filter-modal';
import React from 'react';
import { TouchSearchBarComponent } from 'components/touch-search-bar';
import { getResourceTypeString } from 'utils/formatResourceType';
import { useFilterBar } from './use-filter-bar.hook';
import { Route, Routes } from 'react-router-dom';
import { useFilterModalNavigation } from './use-filter-modal-navigation.hook';
import { useIntl } from 'react-intl';
import { FloorPill } from 'pages/resources/filter-bar/floor-pills';

export interface FilterBarProps {
  isVisible: boolean;
  className?: string;
}

export const FilterBar = ({ isVisible, className }: FilterBarProps): JSX.Element => {
  const intl = useIntl();
  const {
    isCurrentConfigNexudus,
    filterByTypeEncoded,
    dateRangeEncoded,
    startTimeEncoded,
    endTimeEncoded,
    onCancelClick,
    chips,
  } = useFilterBar();
  const { onOpenModal, onCloseModal, onCTAClick } = useFilterModalNavigation();

  return (
    <FilterBarContainer className={className} data-testid="filter-bar-container" isVisible={isVisible}>
      <FilterSearchBarWrapper>
        <FilterSearchBarContainer className="filter-search-bar-container">
          <TouchSearchBarComponent isResourcesPage />
          <FilterIcon
            isActive={!!filterByTypeEncoded || !!dateRangeEncoded || !!startTimeEncoded || !!endTimeEncoded}
            onClick={onOpenModal}
          />
        </FilterSearchBarContainer>
        <ChipContainer>
          <DurationPill />
          <CapacityPill />
          <FloorPill />
          {chips.length > 0 &&
            chips.map(type => (
              <StyledChipComponent
                key={type.toString()}
                label={getResourceTypeString(isCurrentConfigNexudus, type, intl)}
                value={type}
                isActive
                cancelEnabled
                onCancel={onCancelClick}
              />
            ))}
        </ChipContainer>
      </FilterSearchBarWrapper>
      <Routes>
        <Route path="filter-by" element={<FilterModal onCTAClick={onCTAClick} onCloseModal={onCloseModal} />} />
      </Routes>
    </FilterBarContainer>
  );
};
