import { ItemRef } from 'pages/resource/components/timeline/types';
import { MILLISECONDS_IN_SECOND, MINUTES_IN_HOUR } from 'shared/consts';
import { DEFAULT_INTERVAL } from 'shared/consts/timeline';

export const getIsUnavailableSlotIncluded = <T>(
  items: Array<T>,
  timeSlots: Array<string>,
  timeKeyword: keyof T,
  checkKeyword: keyof T,
): boolean => {
  return timeSlots.some(timeSlot => {
    const matchingSlot = items.find(item => item[timeKeyword].toString() === timeSlot);

    return matchingSlot && !matchingSlot[checkKeyword];
  });
};

export const generateTimeSlots = (startTime: string, endTime: string) => {
  const timeSlots = [];
  if (!(startTime && endTime)) return [];

  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);

  let currentTime = new Date();
  currentTime.setHours(startHour, startMinute, 0, 0);

  const endTimeObj = new Date();
  endTimeObj.setHours(endHour, endMinute, 0, 0);

  while (currentTime < endTimeObj) {
    const formattedTime = currentTime
      .toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
      })
      .slice(0, 5);
    timeSlots.push(formattedTime);
    currentTime.setMinutes(currentTime.getMinutes() + DEFAULT_INTERVAL);
  }

  return timeSlots;
};

export const calculateStartTime = (endTime: string, duration: number) => {
  const [endHours, endMinutes] = endTime.split(':').map(Number);

  const totalMinutes = endHours * MINUTES_IN_HOUR + endMinutes;
  const startTotalMinutes = totalMinutes - duration;

  const startHours = Math.floor(startTotalMinutes / MINUTES_IN_HOUR);
  const startMinutes = startTotalMinutes % MINUTES_IN_HOUR;
  const startTime = `${String(startHours).padStart(2, '0')}:${String(startMinutes).padStart(2, '0')}`;

  return startTime;
};

export const calculateAvailableWindowDuration = (
  object: React.MutableRefObject<Array<ItemRef>>,
  selectedTime: string,
) => {
  const selectedTimeIndex = object.current.findIndex(slot => slot.key === selectedTime);
  let startTime = null;
  let endTime = null;

  if (selectedTimeIndex !== -1) {
    for (let i = selectedTimeIndex; i >= 0; i--) {
      if (object.current[i].available) {
        startTime = object.current[i].key;
      } else {
        break;
      }
    }

    for (let i = selectedTimeIndex; i < object.current.length; i++) {
      if (object.current[i].available) {
        endTime = object.current[i + 1].key;
      } else {
        break;
      }
    }
  }

  if (startTime && endTime) {
    const currentDate = new Date();

    const start = buildDateWithSpecifiedTime(currentDate, startTime);
    const end = buildDateWithSpecifiedTime(currentDate, endTime);

    const durationMinutes = (end.getTime() - start.getTime()) / (MILLISECONDS_IN_SECOND * MINUTES_IN_HOUR);

    return {
      endTime,
      durationMinutes,
    };
  }

  return null;
};

export const buildDateWithSpecifiedTime = (date: Date, time: string) =>
  new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    parseInt(time.split(':')[0]),
    parseInt(time.split(':')[1]),
  );
