import { AdditionalInfoModalWrapper } from 'pages/additional-information/styles';
import { ACTION_STATUSES, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts/';
import { AdditionalInformationModal } from '../additional-information-modal';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState, createContext } from 'react';
import { resourceState } from 'store/bookings/selectors';
import { useHqoTracking } from 'hooks/use-hqo-tracking.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { useResourcePageNavigation } from 'hooks/resource/use-resource-page-navigation.hook';
import { useDispatch, useSelector } from 'react-redux';
import { AdditionalInformationFlexDesktop } from './desktop/AdditionalInformationFlexDesktop';
import { useOnSubmitBookingRequest } from 'pages/schedule-select-page/components/use-on-submit-booking-request.hook';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { getResourceTrackInfo } from 'utils/getResourceTrackInfo';
import { useLocation } from 'react-router-dom';
import { push } from 'store/router/actions';
import { selectCurrentRoomLayout } from 'store/room-layout/selectors';
import { selectAllAddOns } from 'store/add-ons/selectors';
import { useAddOnsEnabled } from 'hooks/use-add-ons-enabled.hook';
import { useResetAddOns } from 'hooks/use-reset-add-ons.hook';
import { selectAllUnitCodes } from 'store/unit-codes/selectors';

type AdditionalInfoContextType = {
  selectedUnitCode: string;
  setSelectedUnitCode: Dispatch<SetStateAction<string>>;
};

export const AdditionalInfoContext = createContext<AdditionalInfoContextType | undefined>(undefined);

export const AdditionalInformationFlex = (): JSX.Element => {
  const unitCodes = useSelector(selectAllUnitCodes);
  const dispatch = useDispatch();
  const { onBackLinkClick } = useResourcePageNavigation();
  const onSubmitBookingRequest = useOnSubmitBookingRequest();
  const { pathname, search } = useLocation();
  const isSmallViewportWidth = useIsSmallViewportWidth();
  const client = useMiniappSdk();
  const { room_layout: currentRoomLayout } = useSelector(selectCurrentRoomLayout);
  const selectedAddOns = useSelector(selectAllAddOns);
  const addOnsEnabled = useAddOnsEnabled();
  const [selectedUnitCode, setSelectedUnitCode] = useState(unitCodes[0]);

  useResetAddOns();

  useEffect(() => {
    client?.swipe.enableBackSwipe();
  }, [client]);

  const { resource, getResource } = useSelector(resourceState);

  const onSubmit = useCallback(() => {
    if (resource.room_layouts?.length) {
      dispatch(push(`${pathname.replace('/additional-information', '/room-layouts')}${search}`));
    } else if (!resource.room_layouts?.length && resource.add_ons?.length && addOnsEnabled) {
      dispatch(push(`${pathname.replace('/additional-information', '/add-ons')}${search}`));
    } else {
      onSubmitBookingRequest({ currentRoomLayout, selectedAddOns, unitCode: selectedUnitCode });
    }
  }, [
    resource.room_layouts?.length,
    resource.add_ons?.length,
    dispatch,
    pathname,
    search,
    selectedAddOns,
    onSubmitBookingRequest,
    currentRoomLayout,
    selectedUnitCode,
  ]);

  useHqoTracking({
    isLoading: getResource.status !== ACTION_STATUSES.FULFILLED,
    trackEventName: TRACK_EVENT_NAMES.ADDITIONAL_INFO_IMPRESSION,
    metadata: {
      type: TRACK_EVENT_TYPES.IMPRESSION,
      resource: getResourceTrackInfo(resource),
    },
    trackConfig: { sendToHqoTracking: true },
  });

  return (
    <>
      {isSmallViewportWidth ? (
        <AdditionalInfoModalWrapper>
          <AdditionalInformationModal
            selectCode={setSelectedUnitCode}
            selectedUnitCode={selectedUnitCode}
            onSubmit={onSubmit}
          />
        </AdditionalInfoModalWrapper>
      ) : (
        <AdditionalInformationFlexDesktop
          selectCode={setSelectedUnitCode}
          selectedUnitCode={selectedUnitCode}
          unitCodes={unitCodes}
          onBackLinkClick={onBackLinkClick}
          onSubmitFlexUxBookingRequest={onSubmit}
        />
      )}
    </>
  );
};
