import styled from 'styled-components';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';

export const TitleContainer = styled.div`
  padding-left: 16px;
`;

export const PageContainer = styled.div``;

export const Title = styled.h1`
  margin: 30px 0 0 0;
  font: 700 36px/36px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    color: ${({ theme }) => theme.colors.fontColor};
    font: 700 24px/24px ${({ theme }) => theme.fonts.join()};
  }
`;

export const AddOnsContainer = styled.div`
  padding: 0 16px 130px 16px;
`;
