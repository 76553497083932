import { InteractionEvent } from 'floorplan/pages/floorplan/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/actions';
import { LinkedSpace } from 'store/spaceManager/types';

export const useUpdateAppInstanceConfigOnFloorplanClick = (
  clickedResource: InteractionEvent,
  linkedSpaces: Map<string, LinkedSpace>,
): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (clickedResource) {
      const appInstanceConfigUuid = linkedSpaces.get(clickedResource.uuid)?.app_instance_config_uuid;
      dispatch(updateCurrentAppInstanceConfigUuid({ currentAppInstanceConfigUuid: appInstanceConfigUuid }));
    }
  }, [clickedResource, linkedSpaces]);
};
