import { VisitorsCountContainer, Title, StyledVisitorsIcon } from './styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { faUserGroupSimple } from '@fortawesome/pro-regular-svg-icons';

interface VisitorsCountProps {
  visitorsCount: number;
}

export const VisitorsCount = ({ visitorsCount }: VisitorsCountProps): JSX.Element => {
  const intl = useIntl();

  return (
    <VisitorsCountContainer data-testid="visitors-count">
      <StyledVisitorsIcon icon={faUserGroupSimple} />
      <Title>{intl.formatMessage({ id: 'guests_count' }, { guestsCount: visitorsCount })}</Title>
    </VisitorsCountContainer>
  );
};
