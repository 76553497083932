import { Visitors } from '../adding-visitors-block';
import { useMemo, useState } from 'react';
import { formatAttendees } from 'components/payment/quick-checkout/components/utils/formatAttendees';
import { AttendeeType } from 'store/transactions/types';
import { FormattedAttendees } from 'store/cart/types';
import { VisitorsError } from 'components/adding-visitors-block/visitors-error.enum';
import { useSelector } from 'react-redux';
import { currentUser } from 'store/user/selectors';
import visitorEmailsValidator from 'components/adding-visitors-block/validators/visitorEmailsValidator';

interface UseAddingVisitorsBlockReturnValues {
  visitors: Visitors;
  formattedAttendees: Array<FormattedAttendees>;
  onChangeVisitors: (key: number, value: string) => void;
  error?: VisitorsError;
}

export const useAddingVisitorsBlock = (): UseAddingVisitorsBlockReturnValues => {
  const [visitors, setVisitors] = useState<Visitors>({});
  const user = useSelector(currentUser);

  const formattedVisitors = useMemo(
    () => Object.fromEntries(Object.entries(visitors).filter(([, value]) => Boolean(value))),
    [visitors],
  );

  const formattedAttendees = useMemo<Array<FormattedAttendees>>(
    () => formatAttendees(formattedVisitors, AttendeeType.VISITOR),
    [formattedVisitors],
  );

  const error = useMemo<VisitorsError>(
    () => visitorEmailsValidator(Object.values(formattedVisitors), user.email),
    [formattedVisitors, user],
  );

  const onChangeVisitors = (key: number, value: string) => {
    setVisitors(prevVisitors => ({
      ...prevVisitors,
      [key]: value,
    }));
  };

  return { visitors, onChangeVisitors, formattedAttendees, error };
};
