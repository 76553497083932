import * as actions from './actions';

import { CreateBookingAPIResponse, GetBookingsAPIResponse } from 'store/bookings/types';
import { Observable, concat, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';

export const getBookingsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getBookings.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getBookings(payload)
        .pipe(
          map(({ response }: AjaxResponse<GetBookingsAPIResponse>) => {
            return actions.getBookings.success({
              bookings: response.data.bookings,
              startDate: payload.start_date,
              endDate: payload.end_date,
            });
          }),
          catchError((error: Error) => of(actions.getBookings.failure({ error }))),
        );
    }),
  );

export const createBookingEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.createBooking.request)),
    withLatestFrom(state$),
    mergeMap(
      ([{ payload }, state]): Observable<RootAction> =>
        apiClient(state)
          .createBooking(payload)
          .pipe(
            switchMap(({ response }: AjaxResponse<CreateBookingAPIResponse>) =>
              concat(
                of(actions.createBooking.success(response.data.booking)),
                of(
                  actions.getBookings.request({
                    ownerType: payload.ownerType,
                    ownerUuid: payload.ownerUuid,
                    resourceUuid: payload.resourceUuid,
                  }),
                ),
              ),
            ),
            catchError((error: Error) => of(actions.createBooking.failure({ error }))),
          ),
    ),
  );

export const cancelBookingEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.cancelBooking.request)),
    withLatestFrom(state$),
    mergeMap(
      ([{ payload }, state]): Observable<RootAction> =>
        apiClient(state)
          .cancelBooking(payload)
          .pipe(
            switchMap(() => concat(of(actions.cancelBooking.success(payload)))),
            catchError((error: Error) => of(actions.cancelBooking.failure({ error }))),
          ),
    ),
  );
