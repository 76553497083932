import { FLOORPLAN_BACKGROUND_HEX, FLOORPLAN_ROOMSTAM_DISPLAY } from 'floorplan/const';
import { getArchilogicSpaceTypesInitialValues } from 'floorplan/utils/archilogic.helpers';
import { useMemo } from 'react';
import { LinkedSpace } from 'store/spaceManager/types';

export const useArchilogicConfiguration = (linkedSpaces: Map<string, LinkedSpace>) => {
  const spaceLabelMapping = useMemo(() => {
    let initialState = getArchilogicSpaceTypesInitialValues();

    if (linkedSpaces?.size) {
      for (const linkedSpace of linkedSpaces.values()) {
        initialState = { ...initialState, [linkedSpace.archilogic_id]: linkedSpace.name };
      }
    }

    return initialState;
  }, [linkedSpaces]);

  return {
    spaceLabelMapping,
    ui: { menu: false },
    theme: {
      background: {
        color: FLOORPLAN_BACKGROUND_HEX,
      },
      elements: {
        roomStamp: { textOutline: false, roomStampDisplay: FLOORPLAN_ROOMSTAM_DISPLAY },
      },
    },
  };
};
