import * as bookingsActions from 'store/bookings/actions';
import * as cartActions from 'store/cart/actions';
import * as resourceActions from 'store/resource/actions';
import * as resourcesActions from 'store/resources/actions';
import * as themeActions from 'store/theme/actions';
import * as userActions from 'store/user/actions';
import * as buildingActions from 'store/building/actions';
import * as floorActions from 'store/spaceManager/actions';

import { concatMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { showErrorNotification, showGenericErrorNotification, catchReauthError } from './actions';

import { ERROR_PATH } from 'shared/consts';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { replace } from 'store/router/actions';
import { getAppInstanceConfigs, emptyAppInstanceConfigs } from '../app-instance-configs/actions';
import { getFloorPlanAppInstanceConfig } from 'store/floorplan-app-instance-config/actions';
import { patchTransaction } from 'store/transactions/actions';
import { AjaxError } from 'rxjs/ajax';
import { RESPONSE_MESSAGE } from 'shared/consts/responseMessages';
import { HTTP_RESPONSE_STATUS_CODES } from 'shared/consts/http-status-codes';

export const handleFailedRequests: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(
      isActionOf([
        bookingsActions.cancelBooking.failure,
        bookingsActions.createBooking.failure,
        cartActions.createCart.failure,
      ]),
    ),
    map(action =>
      showErrorNotification({
        action: action.type,
        error: action.payload.error,
      }),
    ),
  );

export const handleGenericErrors: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        bookingsActions.getBookings.failure,
        resourceActions.getResource.failure,
        resourcesActions.getResources.failure,
        themeActions.getBuildingTheme.failure,
        buildingActions.getBuilding.failure,
        userActions.getCurrentUser.failure,
        floorActions.getSpaces.failure,
        getAppInstanceConfigs.failure,
        getFloorPlanAppInstanceConfig.failure,
        patchTransaction.failure,
        emptyAppInstanceConfigs,
      ]),
    ),
    withLatestFrom(state$),
    concatMap(([action, state]) => {
      const { status, response } = action.payload.error as unknown as AjaxError;
      if (
        state.featureFlags?.enableMiniappSdkLogout &&
        status === HTTP_RESPONSE_STATUS_CODES.UNAUTHORIZED &&
        response.message.code === RESPONSE_MESSAGE.RE_AUTHENTICATION_REQUIRED
      ) {
        return of(catchReauthError());
      }

      return of(
        showGenericErrorNotification({
          action: action.type,
          error: action.payload.error,
        }),
        replace(ERROR_PATH),
      );
    }),
  );
