import styled from 'styled-components';
import { FormField } from '@hqo/react-components-library';

export const VisitorBlockContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

export const Title = styled.div`
  font: 600 14px / normal ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemBlack};
`;

export const StyledFormField = styled(FormField)`
  width: 100%;
  height: 90px;

  span {
    padding: 2px 0 0 0;
  }

  .field-label {
    font: 400 italic 14px / normal ${({ theme }) => theme.fonts.join()};
    color: ${({ theme }) => theme.colors.palette.systemBlack};

    .input {
      margin-top: 8px;
    }
  }
`;
