import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { InteractionEvent } from 'floorplan/pages/floorplan/types';
import { BuildingGroupItem, FeatureValue, Floor, FloorItem } from 'store/spaceManager/types';

import { useFloorSelect } from 'hooks/use-floor-select.hook';
import { goBack, push, replace } from 'store/router/actions';
import { setSelectedFloor } from 'store/spaceManager/actions';

interface UseFloorSelectModalValues {
  floors: FloorItem[];
  pickedFloor: Floor;
  onDoneClick: VoidFunction;
  onWheelChange: (value: string) => void;
  isFloorPickerWheelShown: boolean;
  toggleFloorPickerWheel: VoidFunction;
  buildingGroups: BuildingGroupItem[];
  pickedBuildingGroup: FeatureValue | null;
  onWheelChangeBuildingGroup: (value: string) => void;
  isBuildingGroupPickerWheelShown: boolean;
  toggleBuildingGroupPickerWheel: VoidFunction;
  canOpenModal: boolean;
  onFloorPickerClick: VoidFunction;
  onSaveClick: VoidFunction;
  handleOpenFloorPickerModal: VoidFunction;
  onModalClose: VoidFunction;
}

export const useFloorSelectModal = (
  setClickedResource: React.Dispatch<React.SetStateAction<InteractionEvent>>,
): UseFloorSelectModalValues => {
  const dispatch = useDispatch();
  const [canOpenModal, setCanOpenModal] = useState(true);
  const { search, pathname } = useLocation();
  const {
    floors,
    onWheelChange,
    pickedFloor,
    isFloorPickerWheelShown,
    toggleFloorPickerWheel,
    buildingGroups,
    pickedBuildingGroup,
    onWheelChangeBuildingGroup,
    isBuildingGroupPickerWheelShown,
    toggleBuildingGroupPickerWheel,
  } = useFloorSelect();
  const floorItems: FloorItem[] = floors.map((floor: Floor) => {
    return { name: floor.name, uuid: floor.uuid };
  });

  const onDoneClick = useCallback(() => {
    dispatch(setSelectedFloor(floors.find(floor => floor.uuid === pickedFloor.uuid)));
    dispatch(replace(`/floorplan${search}`));
  }, [floors, pickedFloor, search]);

  const onFloorPickerClick = useCallback(() => {
    if (pathname.includes('filter-by')) {
      dispatch(replace(`select-floor-modal${search}`));
    } else {
      dispatch(push(`${pathname}/select-floor-modal${search}`));
    }
  }, [dispatch, search, pathname]);

  const onSaveClick = useCallback(() => {
    setClickedResource(null);
    setCanOpenModal(true);
    onDoneClick();
  }, [setClickedResource, onDoneClick]);

  const handleOpenFloorPickerModal = useCallback(() => {
    setCanOpenModal(false);
  }, [setCanOpenModal]);

  const onModalClose = useCallback(() => {
    setCanOpenModal(true);
    dispatch(goBack());
  }, []);

  return {
    floors: floorItems,
    pickedFloor,
    onDoneClick,
    onWheelChange,
    isFloorPickerWheelShown,
    toggleFloorPickerWheel,
    buildingGroups,
    pickedBuildingGroup,
    onWheelChangeBuildingGroup,
    isBuildingGroupPickerWheelShown,
    toggleBuildingGroupPickerWheel,
    canOpenModal,
    onFloorPickerClick,
    onSaveClick,
    handleOpenFloorPickerModal,
    onModalClose,
  };
};
