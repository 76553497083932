import {} from '../../shared/consts/presetWindowMap';

import * as actions from './actions';

import { FilterSpacesQueryParams, FloorsAPIResponse, SpaceAPIResponse } from 'store/spaceManager/types';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { FLOOR_SORTING, floorSortingByNameMapper } from './enums';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';

export const getFloorsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getFloors.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { buildingUuid, page } = payload;
      const floors_sorting = floorSortingByNameMapper.get(
        state.appInstanceConfigs.app_instance_configs?.length
          ? state.appInstanceConfigs.app_instance_configs[0].config.floors_sorting
          : FLOOR_SORTING.FLOOR_LEVEL,
      );

      const currentPage = page !== undefined ? page : 1;

      return apiClient(state)
        .getFloors({ buildingUuid, floors_sorting, page: `${currentPage}` })
        .pipe(
          mergeMap(({ response }: AjaxResponse<FloorsAPIResponse>) => {
            // Dispatch the success action for the current page
            const { data, pagination } = response;
            const { pages } = pagination;
            const successAction = actions.getFloors.success({ floors: data, buildingUuid });

            // If there are more pages, recursively fetch the next page until the last page
            if (currentPage < pages) {
              const nextPage = currentPage + 1;
              return of(successAction, actions.getFloors.request({ buildingUuid, page: nextPage }));
            }

            // If it's the last page, dispatch the success action for the last page and complete the observable
            return of(successAction);
          }),
          catchError((error: Error) => of(actions.getFloors.failure({ error }))),
        );
    }),
  );

export const getSpacesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getSpaces.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { floor_uuid, no_pagination } = payload as FilterSpacesQueryParams;

      return apiClient(state)
        .getSpacesByFloor({ floor_uuid, no_pagination })
        .pipe(
          map(({ response }: AjaxResponse<SpaceAPIResponse>) => {
            return actions.getSpaces.success(response.data);
          }),
          catchError((error: Error) => of(actions.getSpaces.failure({ error }))),
        );
    }),
  );
