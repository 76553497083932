import { useBooleanState } from '@hqo/react-components-library';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectFloor, selectFloorBuildingGroupFeatures, selectFloors } from 'store/spaceManager/selectors';
import { BuildingGroupItem, FeatureValue, Floor } from 'store/spaceManager/types';

interface UseFloorSelectValues {
  floors: Floor[];
  pickedFloor: Floor;
  onWheelChange: (value: string) => void;
  isFloorPickerWheelShown: boolean;
  toggleFloorPickerWheel: VoidFunction;
  buildingGroups: BuildingGroupItem[];
  pickedBuildingGroup: FeatureValue | null;
  onWheelChangeBuildingGroup: (value: string) => void;
  isBuildingGroupPickerWheelShown: boolean;
  toggleBuildingGroupPickerWheel: VoidFunction;
  setPickedBuildingGroup: React.Dispatch<any>;
  setPickedFloor: React.Dispatch<any>;
}

export const useFloorSelect = (): UseFloorSelectValues => {
  const floors: Floor[] = useSelector(selectFloors);
  const currentFloor: Floor = useSelector(selectFloor);
  const [pickedFloor, setPickedFloor] = useState(currentFloor);
  const buildingGroups: FeatureValue[] = useSelector(selectFloorBuildingGroupFeatures);
  const [pickedBuildingGroup, setPickedBuildingGroup] = useState(null);
  const [filteredFloors, setFilteredFloors] = useState(floors);
  const { value: isBuildingGroupPickerWheelShown, toggle: toggleBuildingGroupPickerWheel } = useBooleanState(false);
  const { value: isFloorPickerWheelShown, toggle: toggleFloorPickerWheel } = useBooleanState(false);
  const intl = useIntl();
  let allBuildingGroups = [
    {
      label: intl.formatMessage({ id: 'allBuildingGroups' }),
      value: intl.formatMessage({ id: 'allBuildingGroups' }),
    },
  ];
  const onWheelChangeBuildingGroup = useCallback(
    (buildingGroupValue: string) => {
      if (Array.isArray(buildingGroups)) {
        const selectedBuildingGroup = buildingGroups.find(buildingGroup => buildingGroup.value === buildingGroupValue);
        setPickedBuildingGroup(selectedBuildingGroup ? selectedBuildingGroup : allBuildingGroups[0]);
        const filteredBuildingGroupFloors = selectedBuildingGroup
          ? floors.filter(floor => floor.features?.some(feature => feature.value === selectedBuildingGroup.value))
          : floors;
        setFilteredFloors(filteredBuildingGroupFloors);
        setPickedFloor(filteredBuildingGroupFloors[0]);
      }
    },
    [pickedBuildingGroup, filteredFloors, setPickedFloor, buildingGroups, setPickedBuildingGroup],
  );
  const buildingGroupValues = useMemo(() => {
    if (Array.isArray(buildingGroups)) {
      buildingGroups.forEach((buildingGroupItem: FeatureValue) => {
        allBuildingGroups.push({
          label: buildingGroupItem.value,
          value: buildingGroupItem.value,
        });
      });
    }
    return allBuildingGroups;
  }, [buildingGroups]);
  const onWheelChange = useCallback(
    (floorUuid: string) => {
      if (Array.isArray(floors)) {
        setPickedFloor(floors.find(floor => floor.uuid === floorUuid));
      }
    },
    [floors, setPickedFloor],
  );
  return {
    floors: filteredFloors,
    pickedFloor,
    onWheelChange,
    isFloorPickerWheelShown,
    toggleFloorPickerWheel,
    buildingGroups: buildingGroupValues,
    pickedBuildingGroup,
    onWheelChangeBuildingGroup,
    isBuildingGroupPickerWheelShown,
    toggleBuildingGroupPickerWheel,
    setPickedBuildingGroup,
    setPickedFloor,
  };
};
