import React from 'react';
import { PatchTransaction, TransactionDetails } from 'store/transactions/types';
import { SwipeReceiptContent } from './swipe-receipt-content';
import { CancelBooking } from 'components/payment/cancel-booking';

interface SwipeModalContentProps {
  transaction: TransactionDetails;
  onCancelBookingClick: VoidFunction;
  patchTransactionData: Pick<PatchTransaction, 'status' | 'type'>;
  isCancellationExpiredState: boolean;
  isSwipeModalContent: boolean;
  isCancelBookingStep: boolean;
  setIsCancelBookingStep: React.Dispatch<React.SetStateAction<boolean>>;
  onCheckInClick: VoidFunction;
  onCloseClick: VoidFunction;
}

export const SwipeModalContent = ({
  isSwipeModalContent,
  isCancellationExpiredState,
  onCancelBookingClick,
  patchTransactionData,
  transaction,
  isCancelBookingStep,
  setIsCancelBookingStep,
  onCheckInClick,
  onCloseClick,
}: SwipeModalContentProps) => {
  return isCancelBookingStep ? (
    <CancelBooking setIsCancelBookingStep={setIsCancelBookingStep} transaction={transaction} />
  ) : (
    <SwipeReceiptContent
      transaction={transaction}
      onCancelBookingClick={onCancelBookingClick}
      patchTransactionData={patchTransactionData}
      isCancellationExpiredState={isCancellationExpiredState}
      isSwipeModalContent={isSwipeModalContent}
      onCheckInClick={onCheckInClick}
      onCloseClick={onCloseClick}
    />
  );
};
