import React from 'react';

import { ItemRef } from 'pages/resource/components/timeline/types';
import { TimeRange } from 'store/resource-time-ranges/types';
import { useTimelineBlock } from './hooks/use-timeline-block.hook';
import { TimelineBlockContainer, TimelineBlockWrapper, TimelineTick, TimeText } from './styles';
import { useAutoScroll } from './hooks/use-auto-scroll.hook';

interface TimeLineBlockProps {
  timeRange: TimeRange;
  index: number;
  itemRefs: React.MutableRefObject<Array<ItemRef>>;
  handleTimelineBlockClick: (index: number) => void;
  lastTimeRangeIndex: number;
  timelineWrapperRef: React.MutableRefObject<HTMLDivElement>;
}

export const TimelineBlock = ({
  itemRefs,
  index,
  timeRange,
  handleTimelineBlockClick,
  lastTimeRangeIndex,
  timelineWrapperRef,
}: TimeLineBlockProps) => {
  const { time, available } = timeRange || {};

  const { firstLeftBorder, lastRightBorder, timeText, timelineTickHeight, handleOnClick } = useTimelineBlock({
    available,
    time,
    handleTimelineBlockClick,
    index,
    lastTimeRangeIndex,
  });

  useAutoScroll({ itemRefs, timelineWrapperRef });

  return (
    <TimelineBlockWrapper ref={ref => (itemRefs.current[index] = { ref, key: time, available })} key={time}>
      <TimeText>{timeText}</TimeText>
      {index !== lastTimeRangeIndex && (
        <TimelineBlockContainer
          isAvailable={available}
          onClick={handleOnClick}
          firstLeftBorder={firstLeftBorder}
          lastRightBorder={lastRightBorder}
        >
          {!firstLeftBorder && <TimelineTick height={timelineTickHeight} />}
        </TimelineBlockContainer>
      )}
    </TimelineBlockWrapper>
  );
};
