import { AppInstanceConfigsState } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { configSelector } from 'store/config/selectors';

export const appInstanceConfigsSelector = (state: RootState): AppInstanceConfigsState => state.appInstanceConfigs;

export const selectAppInstanceConfigs = createSelector(
  [appInstanceConfigsSelector, configSelector],
  (appInstanceConfigState, config) => {
    const appInstanceConfigs = appInstanceConfigState.app_instance_configs ?? [];
    if (config.singleAppInstanceConfig && appInstanceConfigs.length) {
      const appInstanceConfigUUID = appInstanceConfigState.currentAppInstanceConfigUuid || appInstanceConfigs[0].uuid;

      return appInstanceConfigs.filter(appInstanceConfig => appInstanceConfig.uuid === appInstanceConfigUUID);
    }

    return appInstanceConfigs;
  },
);

export const selectAppInstanceConfigsStatus = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.getAppInstanceConfigs.status,
);

export const selectAppInstanceConfigsError = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.getAppInstanceConfigs.error,
);

export const selectCurrentAppInstanceConfigUuid = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.currentAppInstanceConfigUuid,
);

export const selectCurrentLocationId = createSelector([appInstanceConfigsSelector], configs => {
  return configs.currentLocationId;
});

export const selectIsCurrentLocationIdUpdating = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.isCurrentLocationIdUpdating,
);

export const selectOnboardUserStatus = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.onboardUser?.status,
);

export const selectOnboardUserError = createSelector(
  [appInstanceConfigsSelector],
  configs => configs.onboardUser.error,
);
