import styled from 'styled-components';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';
import { RadioButton } from '@hqo/react-components-library/dist/molecules/radio-button';
import { Image } from 'components/image';
import { skeletonAnimation } from 'components/skeleton/styles';

export const TitleContainer = styled.div`
  padding-left: 16px;
`;

export const PageContainer = styled.div``;

export const Title = styled.h1`
  margin: 30px 0 0 0;
  font: 700 36px/36px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    color: ${({ theme }) => theme.colors.fontColor};
    font: 700 24px/24px ${({ theme }) => theme.fonts.join()};
  }
`;

interface RadioButtonProps {
  isActive: boolean;
}

export const RadioButtonContainer = styled.div`
  .radio-group-item label {
    width: 100%;
  }

  padding: 17px;
  padding-bottom: 120px;
  label {
    font-weight: 500;
  }
`;

export const StyledRadioButton = styled(RadioButton)<RadioButtonProps>`
  margin-bottom: 24px;
  border-radius: 12px;
  border: ${({ isActive, theme }) => (isActive ? `2px solid ${theme.colors.primary}` : `2px solid transparent`)};
  box-shadow: ${({ theme }) => theme.shadows.depth_2};
  box-sizing: border-box;
`;

export const RadioButtonImage = styled(Image)`
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 200px;
  align-self: center;
`;

export const RadioButtonSkeleton = styled.div`
  width: 300px;
  height: 240px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;
