import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { TimeRange } from 'store/resource-time-ranges/types';
import { bookingTimeHandler } from 'utils/bookingTimeHandler';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DurationsAndInterval, ItemRef } from '../types';
import { BLOCK_WIDTH, DEFAULT_INTERVAL } from 'shared/consts/timeline';

interface UseTimelineReturnValues {
  itemRefs: React.MutableRefObject<Array<ItemRef>>;
  timelineWrapperRef: React.MutableRefObject<HTMLDivElement>;
  initialWidthSelectedBlock: number;
  initialStartTimeOffsetSelectedBlock: number;
  durationsAndInterval: DurationsAndInterval;
}

export const useTimeline = (filteredTimeRanges: Array<TimeRange>): UseTimelineReturnValues => {
  const itemRefs: React.MutableRefObject<Array<ItemRef>> = useRef([]);
  const timelineWrapperRef = useRef<HTMLDivElement>(null);
  const [initialWidthSelectedBlock, setInitialWidthSelectedBlock] = useState<number>(0);
  const [initialStartTimeOffsetSelectedBlock, setInitialStartTimeOffsetSelectedBlock] = useState<number>(0);
  const { startTime, endTime } = useSearchParams<searchParams>();

  const availableFilteredTimeRanges = useMemo(
    () => filteredTimeRanges?.filter(timeRange => timeRange.available),
    [filteredTimeRanges],
  );

  const { time_interval, minimum_duration, maximum_duration } = availableFilteredTimeRanges?.[0] || {};

  const minDuration = useMemo<number>(() => {
    if (time_interval > minimum_duration) {
      return time_interval;
    }

    return minimum_duration ?? time_interval ?? DEFAULT_INTERVAL;
  }, [time_interval, minimum_duration]);

  const durationsAndInterval: DurationsAndInterval = {
    minDuration: minDuration,
    maxDuration: maximum_duration,
    timeInterval: time_interval,
  };

  const getSelectedTimeBlock = useCallback(() => {
    if (startTime && endTime) {
      setInitialWidthSelectedBlock(
        (bookingTimeHandler({ startTime, endTime }).getTimeDiff() * BLOCK_WIDTH) / DEFAULT_INTERVAL,
      );
      setInitialStartTimeOffsetSelectedBlock(
        itemRefs.current.find((item: { key: string }) => item.key === startTime)?.ref?.offsetLeft,
      );
    }
  }, [endTime, startTime, itemRefs]);

  useEffect(() => {
    const isTimeLineContainsRenderedElement = itemRefs.current[1];

    if (isTimeLineContainsRenderedElement) {
      getSelectedTimeBlock();
    }
  }, [filteredTimeRanges, getSelectedTimeBlock, itemRefs]);

  return {
    itemRefs,
    timelineWrapperRef,
    initialWidthSelectedBlock,
    initialStartTimeOffsetSelectedBlock,
    durationsAndInterval,
  };
};
