import { useDispatch, useSelector } from 'react-redux';
import { selectResourceActionStatus, selectResourcesTypes } from 'store/resources/selectors';
import {
  ACTION_STATUSES,
  DEFAULT_BACKSLASH,
  DEFAULT_COMMA_BACKSLASH,
  DEFAULT_TYPE_COMMA_DELIMITER,
  QUERY_PARAMS,
} from 'shared/consts';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { selectIsCurrentLocationIdUpdating } from 'store/app-instance-configs/selectors';
import { resetCurrentLocationIdUpdating } from 'store/app-instance-configs/actions';
import { pick } from 'utils/pickObjectProperty';

export const useValidTypes = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { filterByType, ...restQueryParams } = useSearchParams();
  const getResourcesTypesStatus = useSelector(selectResourceActionStatus);
  const resourcesTypes = useSelector(selectResourcesTypes);
  const isLocationIdUpdating = useSelector(selectIsCurrentLocationIdUpdating);

  const appliedTypes = useMemo<string[]>(() => {
    const filterByTypeToString: string = filterByType ? filterByType?.toString() : '';
    if (!filterByTypeToString) {
      return [];
    }
    const filterByTypeToStringToArray = filterByTypeToString
      ?.replace(DEFAULT_COMMA_BACKSLASH, '')
      .split(',')
      .map(type => type.replace(DEFAULT_BACKSLASH, ','));
    const filterResourcesTypes = resourcesTypes?.filter(type => filterByTypeToStringToArray?.indexOf(type) !== -1);

    return filterResourcesTypes.map(type => type.replace(',', DEFAULT_TYPE_COMMA_DELIMITER));
  }, [resourcesTypes, filterByType]);

  useEffect(() => {
    if (getResourcesTypesStatus === ACTION_STATUSES.FULFILLED && isLocationIdUpdating) {
      const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

      const queryString = qs.stringify({
        ...pick(
          queryParams,
          QUERY_PARAMS.filter(param => param !== 'presetWindows'),
        ),
        filterByType: appliedTypes.join(',') || undefined,
      });
      dispatch(replace(`${pathname}?${queryString}`));
      dispatch(resetCurrentLocationIdUpdating());
    }
  }, [
    appliedTypes,
    dispatch,
    getResourcesTypesStatus,
    isLocationIdUpdating,
    pathname,
    JSON.stringify(restQueryParams),
  ]);
};
