import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectResource,
  selectResourceClosestAvailability,
  selectResourceClosestAvailableDayTimeRanges,
} from 'store/resource/selectors';
import { getResourceTimeRanges, setResourceTimeRanges } from 'store/resource-time-ranges/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import qs from 'qs';
import { replace } from 'store/router/actions';
import {
  selectPresetBookableWindows,
  selectResourceTimeRanges,
  selectResourceTimeRangesStatus,
} from 'store/resource-time-ranges/selectors';
import { useLocale } from 'hooks/use-locale.hook';
import { resolveGetResourceTimeRangesParams } from 'utils';
import { OwnerTypesEnum } from 'store/app-instance-configs/enums';
import moment from 'moment/moment';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { findClosestHourAvailability } from '../utils/findClosestHourAvailability';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TimeRange } from 'store/resource-time-ranges/types';
import { selectBuildingTimeZone } from 'store/building/selectors';
import { transformToSortedDatesList } from 'utils/formatDate';
import { PresetWindows } from 'store/resources/types';

export const useClosestAvailability = (): void => {
  const dispatch = useDispatch();
  const locale = useLocale();
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const { ownerType } = useOwnerParams();
  const { enableResourceDetailsDateTimeAutoselect } = useFlags();
  const { startDate, startTime, endTime, startDates, ...restQueryParams } = useSearchParams<searchParams>();
  const { presetWindows } = restQueryParams || {};
  const resourceClosestAvailableDayTimeRanges = useSelector(selectResourceClosestAvailableDayTimeRanges);
  const resourceTimeRanges = useSelector(selectResourceTimeRanges);
  const resourceTimeRangesStatus = useSelector(selectResourceTimeRangesStatus);
  const { start_at, end_at } = useSelector(selectResourceClosestAvailability) || {};
  const resource = useSelector(selectResource);
  const presetBookableWindows = useSelector(selectPresetBookableWindows);
  const { preset_booking_windows } = useSelector(selectResource) || {};

  const sortedDates = transformToSortedDatesList(startDates);
  const date = startDate || sortedDates?.[0];
  const isDatesExist = Boolean(date);
  const isTimeExist = Boolean(startTime) && Boolean(endTime);

  const areTimeRangesForSelectedDate = resourceTimeRanges?.length && date === resourceTimeRanges[0]?.date;

  const areTimeRangesWithoutPresetBookableWindows =
    preset_booking_windows === PresetWindows.FULL_DAY &&
    !presetBookableWindows &&
    resourceTimeRanges?.length &&
    !resourceTimeRangesStatus;

  const shouldDispatchGetResourceTimeRanges: boolean =
    isDatesExist && resource?.id && (!areTimeRangesForSelectedDate || areTimeRangesWithoutPresetBookableWindows);

  const availableTimeRanges = useMemo<Array<TimeRange>>(() => {
    const filteredTimeRanges = resourceTimeRanges?.filter(timeRange => timeRange.available);

    if (startTime) {
      const startTimeIndex = filteredTimeRanges?.findIndex(timeRange => timeRange.time === startTime);

      return filteredTimeRanges?.slice(startTimeIndex);
    }

    return filteredTimeRanges;
  }, [resourceTimeRanges, startTime]);

  const formatTime = (time: string, isEndTime: boolean = false) => {
    const [hours, minutes] = time.split(':');
    const midnightFormatOptions = hours === '0' && minutes === '0' && isEndTime ? { hour12: false } : {};

    return new Date(time).toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: buildingTimeZone,
      hourCycle: 'h23',
      ...midnightFormatOptions,
    });
  };

  useEffect(() => {
    if (resourceClosestAvailableDayTimeRanges?.length && !resourceTimeRanges?.length) {
      dispatch(setResourceTimeRanges(resourceClosestAvailableDayTimeRanges));
    }
  }, [resourceClosestAvailableDayTimeRanges, resourceTimeRanges]);

  const { shouldSetOnlyTime, shouldSetTimeAndDate } = useMemo(() => {
    const hasOnlyDate: boolean =
      isDatesExist &&
      enableResourceDetailsDateTimeAutoselect &&
      !!availableTimeRanges?.length &&
      !!findClosestHourAvailability(availableTimeRanges) &&
      !isTimeExist &&
      !presetWindows;

    const hasNoDateOrTime: boolean =
      !isDatesExist && !isTimeExist && !!start_at && !!end_at && !!resourceTimeRanges?.length;

    return { shouldSetOnlyTime: hasOnlyDate, shouldSetTimeAndDate: hasNoDateOrTime };
  }, [
    presetWindows,
    availableTimeRanges,
    enableResourceDetailsDateTimeAutoselect,
    end_at,
    isDatesExist,
    isTimeExist,
    resourceTimeRanges,
    start_at,
  ]);

  useEffect(() => {
    if (shouldSetOnlyTime) {
      const queryParams = {
        ...restQueryParams,
        ...(startDate && { startDate }),
        ...(startDates && { startDates }),
        ...findClosestHourAvailability(availableTimeRanges),
        isExact: true,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [dispatch, availableTimeRanges, restQueryParams, startDates, shouldSetOnlyTime, startDate]);

  useEffect(() => {
    if (shouldSetTimeAndDate) {
      const queryParams = {
        ...restQueryParams,
        startDate: resourceTimeRanges[0].date,
        startTime: formatTime(start_at),
        endTime: formatTime(end_at, true),
        isExact: true,
      };
      const queryString = qs.stringify(queryParams);
      dispatch(replace(`${location.pathname}?${queryString}`));
    }
  }, [dispatch, start_at, end_at, resourceTimeRanges, restQueryParams, shouldSetTimeAndDate]);

  useEffect(() => {
    if (shouldDispatchGetResourceTimeRanges) {
      const getResourceTimeRangesParams = resolveGetResourceTimeRangesParams({
        ownerType: ownerType as OwnerTypesEnum,
        resourceId: resource.id,
        start: date as string,
        end: moment(date as string)
          .add(1, 'days')
          .format('YYYY-MM-DD'),
        ...(startDates && { startDates }),
      });

      dispatch(getResourceTimeRanges.request(getResourceTimeRangesParams));
    }
  }, [startDate, ownerType, shouldDispatchGetResourceTimeRanges, startDates]);
};
