import styled from 'styled-components';
import { DIMENSIONS, HqOCalendar } from '@hqo/react-components-library';

export const Container = styled.div`
  width: 370px;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: -50px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
  }
`;

export const StyledCalendar = styled(HqOCalendar)`
  .days-view {
    border: none;
  }

  .day-cell {
    border: none;
    border-radius: 50%;
  }

  .button-view {
    margin-top: 0;
  }
`;
