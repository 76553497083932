import { useDispatch, useSelector } from 'react-redux';

import { Building } from 'store/building/types';
import { getBuilding } from 'store/building/actions';
import { selectBuilding } from 'store/building/selectors';
import { useEffect } from 'react';
import { configSelector, hasToken } from 'store/config/selectors';

export const useBuilding = (): Building => {
  const dispatch = useDispatch();
  const { buildingUuid } = useSelector(configSelector);
  const building = useSelector(selectBuilding);
  const hasUserToken = useSelector(hasToken);

  useEffect(() => {
    const getBuildingTimeout = setTimeout(() => {
      if ((buildingUuid && hasUserToken && !building) || !building?.currency_type) {
        dispatch(getBuilding.request(buildingUuid as string));
      }
    }, 1000);

    return () => {
      clearTimeout(getBuildingTimeout);
    };
  }, [buildingUuid, hasUserToken, building]);

  return building;
};
