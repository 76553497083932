import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { BuildingGroupItem, FeatureValue } from 'store/spaceManager/types';
import { Select } from '@hqo/react-components-library';
import { TitleComponent } from './styles';
import { BuildingGroupPickerWheel } from 'components/building-group-picker-wheel';

interface BuildingGroupSelectModalContentProps {
  onWheelChange: React.Dispatch<React.SetStateAction<string>>;
  pickedBuildingGroup: FeatureValue | null;
  options: BuildingGroupItem[];
  isBuildingGroupPickerWheelShown: boolean;
  toggleBuildingGroupPickerWheel: VoidFunction;
}

export const BuildingGroupSelectModalContent = ({
  onWheelChange,
  pickedBuildingGroup,
  options,
  isBuildingGroupPickerWheelShown,
  toggleBuildingGroupPickerWheel,
}: BuildingGroupSelectModalContentProps): JSX.Element => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const intl = useIntl();
  const selectedValue = pickedBuildingGroup
    ? pickedBuildingGroup.value
    : intl.formatMessage({ id: 'noBuildingGroupSelected' });
  const onOpen = useCallback(() => {
    setIsSelectOpen(false);
    if (!isBuildingGroupPickerWheelShown) {
      toggleBuildingGroupPickerWheel();
    }
  }, [setIsSelectOpen, toggleBuildingGroupPickerWheel, isBuildingGroupPickerWheelShown]);

  return (
    <>
      <Select
        data-testid="building-group"
        value={selectedValue}
        titleComponent={<TitleComponent>{intl.formatMessage({ id: 'building' })}</TitleComponent>}
        open={isSelectOpen}
        displayEmpty
        onOpen={onOpen}
      />
      {isBuildingGroupPickerWheelShown && (
        <BuildingGroupPickerWheel
          onWheelChange={onWheelChange}
          options={options}
          pickedBuildingGroupValue={pickedBuildingGroup?.value}
          toggleBuildingGroupPickerWheel={toggleBuildingGroupPickerWheel}
        />
      )}
    </>
  );
};
