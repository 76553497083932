import React from 'react';
import { Container, FlexContainer, ResourceImage } from './styles';
import { ResourceDescription } from './components/resource-description';
import { ResourceOpeningHours } from './components/resource-opening-hours';
import { ResourceAmenities } from './components/resource-amenities';
import { ResourceInformation } from './components/resource-information';

export const ResourceSkeleton = (): JSX.Element => {
  return (
    <Container data-testid="skeleton">
      <FlexContainer gap="38" isWidth isColumnDirection className="desktop">
        <ResourceImage />
        <ResourceInformation />
        <ResourceDescription />
        <ResourceAmenities />
        <ResourceOpeningHours />
      </FlexContainer>
      <FlexContainer gap="38" isColumnDirection isWidth className="mobile">
        <ResourceImage />
        <ResourceInformation />
        <ResourceDescription />
        <ResourceAmenities />
        <ResourceOpeningHours />
      </FlexContainer>
    </Container>
  );
};
