import { CustomQuestionWrapper, DescriptionWrapper } from 'pages/additional-information/styles';
import { Route, Routes } from 'react-router-dom';
import AdditionalInformation from 'pages/additional-information/AdditionalInformation';
import AdditionalSelectors from 'pages/additional-information/AdditionalSelectors';
import { FlexDesktopWrapper } from 'components/flex-desktop-wrapper';
import { PaymentIFrame } from 'components/paymentIFrame';
import React, { Dispatch } from 'react';
import { usePaymentModal } from 'hooks/payment-hook/use-payment-modal.hook';
import { SwipePaymentIFrame } from 'components/swipePaymentIFrame';
import { useAddtitionalInformation } from 'hooks/additional-information/useAdditionalInformation.hook';
import { DateTimeSelectModal } from 'pages/resource/date-time-select-modal';
import { useDateScheduleModalNavigation } from 'hooks/use-date-schedule-modal-navigation.hook';
import { useQuickCheckout } from 'hooks/use-quick-checkout.hook';
import { PaymentContent } from 'components/payment-content';
import { useCheckoutScreen } from 'hooks/payment-content/use-checkout-screen.hook';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';
import { ModalContainer } from 'pages/resource/components/terms-and-conditions/components/modal-container';
import { UnitCodeSection } from 'components/payment/quick-checkout/components/unit-code-section';

interface AdditionalInformationFlexDesktopProps {
  onBackLinkClick: VoidFunction;
  onSubmitFlexUxBookingRequest: VoidFunction;
  unitCodes?: string[];
  selectCode?: Dispatch<React.SetStateAction<string>>;
  selectedUnitCode?: string;
}

export const AdditionalInformationFlexDesktop = ({
  onBackLinkClick,
  onSubmitFlexUxBookingRequest,
  unitCodes,
  selectCode,
  selectedUnitCode,
}: AdditionalInformationFlexDesktopProps): JSX.Element => {
  const { setUserResponse, userResponse } = useAddtitionalInformation();
  const { closeQuickCheckoutHandler, openQuickCheckoutHandler } = useQuickCheckout();
  const { reverseAnimation } = usePaymentModal(openQuickCheckoutHandler);
  const {
    onTimeLinkClick,
    onDateLinkClick,
    onNextClick,
    backToDateStep,
    isDateStep,
    onPressBack,
    onPressSave,
    isModalWithoutSlideAnimation,
    reverseHorizontalAnimation,
    toggleModal,
  } = useDateScheduleModalNavigation();
  const { onCloseCheckout, openCheckout } = useCheckoutScreen();
  const {
    isCancelBookingStep,
    setIsCancelBookingStep,
    onCloseReceipt,
    onPressBack: onPressBackReceipt,
    openReceipt,
  } = useReceiptScreen();

  return (
    <FlexDesktopWrapper
      onBackLinkClick={onBackLinkClick}
      onContinueClick={onSubmitFlexUxBookingRequest}
      userResponse={userResponse}
    >
      <DescriptionWrapper>
        <AdditionalInformation />
      </DescriptionWrapper>
      <CustomQuestionWrapper>
        {unitCodes?.length > 1 && (
          <UnitCodeSection unitCodes={unitCodes} selectCode={selectCode} selectedUnitCode={selectedUnitCode} />
        )}
        <AdditionalSelectors userResponse={userResponse} setUserResponse={setUserResponse} />
      </CustomQuestionWrapper>
      <PaymentContent
        openReceipt={openReceipt}
        reverseAnimation={reverseAnimation}
        closeQuickCheckoutHandler={closeQuickCheckoutHandler}
        isCancelBookingStep={isCancelBookingStep}
        setIsCancelBookingStep={setIsCancelBookingStep}
        onCloseReceipt={onCloseReceipt}
        onPressBack={onPressBackReceipt}
        onCloseCheckout={onCloseCheckout}
        openCheckout={openCheckout}
      />
      <Routes>
        <Route
          path="date-schedule-select"
          element={
            <DateTimeSelectModal
              reverseHorizontalAnimation={reverseHorizontalAnimation}
              isModalWithoutSlideAnimation={isModalWithoutSlideAnimation}
              onPressSave={onPressSave}
              onClose={toggleModal}
              isDateStep={isDateStep}
              onNextClick={onNextClick}
              onDateLinkClick={onDateLinkClick}
              onTimeLinkClick={onTimeLinkClick}
              onPressBack={onPressBack}
              backToDateStep={backToDateStep}
            />
          }
        />
        <Route path="payment" element={<PaymentIFrame reverseAnimation={reverseAnimation} />} />
        <Route path="swipe-payment" element={<SwipePaymentIFrame />} />
        <Route path="quick-checkout/terms-and-conditions" element={<ModalContainer />} />
      </Routes>
    </FlexDesktopWrapper>
  );
};
