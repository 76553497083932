import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { push } from 'store/router/actions';
import { selectCurrentRoomLayout } from 'store/room-layout/selectors';
import { selectRoomLayouts, selectAddOns, selectGetResourceStatus, selectResource } from 'store/resource/selectors';
import { RoomLayout } from 'store/resource/types';
import { setRoomLayout, resetRoomLayout } from 'store/room-layout/actions';
import { useBooleanState } from '@hqo/react-components-library';
import { useOnSubmitBookingRequest } from '../schedule-select-page/components/use-on-submit-booking-request.hook';
import { ACTION_STATUSES } from 'shared/consts/const';
import { getResource } from 'store/resource/actions';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { selectCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/selectors';
import { selectAllAddOns } from 'store/add-ons/selectors';
import { useAddOnsEnabled } from 'hooks/use-add-ons-enabled.hook';

interface ReturnValues {
  isRoomLayoutSelected: boolean;
  onContinue: VoidFunction;
  currentRoomLayout: RoomLayout;
  roomLayouts: Array<RoomLayout>;
  handleRadioButtonChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSkipButtonShown: boolean;
  handleRadioButtonDeselect: (event: React.MouseEvent<HTMLInputElement>) => void;
  isResourceLoading: boolean;
  buttonText: () => string;
}

export const useRoomLayout = (): ReturnValues => {
  const appInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const resource = useSelector(selectResource);
  const { ownerType, ownerUuid } = useOwnerParams();
  const { resourceUuid } = useParams();
  const dispatch = useDispatch();
  const addOnsEnabled = useAddOnsEnabled();

  const { search, pathname } = useLocation();

  const addOns = useSelector(selectAddOns);
  const { room_layout: currentRoomLayout } = useSelector(selectCurrentRoomLayout);
  const selectedAddOns = useSelector(selectAllAddOns);
  const onSubmitBooking = useOnSubmitBookingRequest();
  const onContinue = useCallback(() => {
    if (addOns?.length > 0 && addOnsEnabled) {
      dispatch(push(`${pathname.replace('room-layouts', 'add-ons')}${search}`));
    } else {
      onSubmitBooking({ currentRoomLayout, selectedAddOns });
    }
  }, [dispatch, pathname, search, currentRoomLayout, selectedAddOns]);

  const roomLayouts = useSelector(selectRoomLayouts);

  const isRoomLayoutSelected = !!currentRoomLayout;

  const { value: isSkipButtonShown, toggle: toggleIsSkipButtonShown } = useBooleanState(true);
  const handleRadioButtonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!currentRoomLayout) {
        toggleIsSkipButtonShown();
      }

      dispatch(setRoomLayout(roomLayouts.find(roomLayout => roomLayout.name === event.target.value)));
    },
    [dispatch, currentRoomLayout, roomLayouts, toggleIsSkipButtonShown],
  );

  const handleRadioButtonDeselect = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      const inputElement = event.target as HTMLInputElement;
      if (inputElement.value === currentRoomLayout?.name) {
        toggleIsSkipButtonShown();
        dispatch(resetRoomLayout());
      }
    },
    [dispatch, currentRoomLayout, roomLayouts, isSkipButtonShown, resetRoomLayout, toggleIsSkipButtonShown],
  );
  const getResourceStatus = useSelector(selectGetResourceStatus);
  const isResourceLoading: boolean = getResourceStatus !== ACTION_STATUSES.FULFILLED;

  const buttonText = useCallback(() => {
    if (!isRoomLayoutSelected) {
      return 'skip';
    }
    if (addOns?.length > 0 && addOnsEnabled) {
      return 'continue';
    }
    return 'complete_booking';
  }, [isRoomLayoutSelected, addOns]);

  useEffect(() => {
    dispatch(resetRoomLayout());
  }, []);

  useEffect(() => {
    if (!resource) {
      dispatch(getResource.request({ ownerUuid, ownerType, resourceUuid }));
    }
  }, [dispatch, ownerType, resourceUuid, appInstanceConfigUuid]);

  return {
    isRoomLayoutSelected,
    onContinue,
    currentRoomLayout,
    roomLayouts,
    handleRadioButtonChange,
    isSkipButtonShown,
    handleRadioButtonDeselect,
    isResourceLoading,
    buttonText,
  };
};
