import React from 'react';
import { ResourceBookingHeader } from 'components/headers/resourceBookingHeader';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';
import { useHeader } from './hooks/use-header.hook';

export const Header = (): JSX.Element => {
  const isBackButtonVisible = useBackButtonVisibility();
  const { currentConfigName, isHeaderVisible, onPressBack } = useHeader();

  if (!isHeaderVisible) return null;

  return (
    <ResourceBookingHeader title={currentConfigName} withBackButton={isBackButtonVisible} onBackClick={onPressBack} />
  );
};
