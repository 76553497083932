import { useMemo } from 'react';
import { Resource } from 'store/resource/types';
import { LinkedSpace } from 'store/spaceManager/types';

export const useLinkedSpacesFiltering = (
  linkedSpaces: Map<string, LinkedSpace>,
  allResources: Resource[],
): Map<string, LinkedSpace> => {
  const filteredLinkedSpaces = useMemo(() => {
    if (linkedSpaces?.size) {
      const allResourcesIds = allResources?.map(resource => resource.uuid);
      return new Map([...linkedSpaces].filter(([key]) => allResourcesIds?.includes(key)));
    }
  }, [allResources, linkedSpaces]);

  return filteredLinkedSpaces;
};
