import {
  Container,
  StyledIcon,
  StyledText,
} from 'pages/resource/components/date-select-timeline/time-picker-input/styles';
import React from 'react';

interface TimePickerInputProps {
  value: string;
  handleOnClick: VoidFunction;
}

export const TimePickerInput = ({ value, handleOnClick }: TimePickerInputProps): JSX.Element => {
  return (
    <Container className="time-picker" data-testid="time-picker-input" onClick={handleOnClick}>
      <StyledIcon variant="adminGreyDark" data-testid="time-picker-input-icon" />
      <StyledText>{value}</StyledText>
    </Container>
  );
};
