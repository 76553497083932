import { useFilteringEnabled } from 'hooks/use-filtering-enabled.hook';
import { useResourceLoadingHandler } from 'pages/resources/hooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useExchangeAdapterHandling } from 'hooks/use-exchange-adapter-handling.hook';
import { resourcesState, selectInfiniteResources } from 'store/resources/selectors';

interface UseResourcesCounterReturnValues {
  isResourcesCounterBlockVisible: boolean;
  numberOfResources: number;
}

export const useResourcesCounter = (): UseResourcesCounterReturnValues => {
  const isFilteringEnabled = useFilteringEnabled();
  const isLoadingState = useResourceLoadingHandler(isFilteringEnabled);
  const { showResourceBookingMiniappFiltering: isResourceFilteringVisible } = useFlags();
  const { isCompactUiEnabled } = useExchangeAdapterHandling();
  const { resources } = useSelector(resourcesState);
  const infiniteResources = useSelector(selectInfiniteResources);
  const numberOfResources = useMemo<number>(
    () =>
      isCompactUiEnabled ? Object.values(infiniteResources)?.flat()?.length + resources?.length : resources?.length,
    [infiniteResources, resources, isCompactUiEnabled],
  );
  const isResourcesCounterBlockVisible = useMemo<boolean>(
    () => isResourceFilteringVisible && !isLoadingState,
    [isLoadingState, isResourceFilteringVisible],
  );

  return {
    isResourcesCounterBlockVisible,
    numberOfResources,
  };
};
