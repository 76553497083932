import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { AppInstanceConfigsState } from './types';
const queryParams = new URLSearchParams(window.location.search);
const locationIdFromUrl = queryParams.get('locationId');
const initialState: AppInstanceConfigsState = {
  app_instance_configs: null,
  currentAppInstanceConfigUuid: null,
  currentLocationId: locationIdFromUrl,
  isCurrentLocationIdUpdating: null,
  getAppInstanceConfigs: {
    status: null,
    error: null,
  },
  onboardUser: {
    status: null,
    error: null,
  },
};

const getAppInstanceConfigsRequestHandler = (state: AppInstanceConfigsState): AppInstanceConfigsState => ({
  ...state,
  getAppInstanceConfigs: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getAppInstanceConfigsSuccessHandler = (
  state: AppInstanceConfigsState,
  { payload }: ActionType<typeof actions.getAppInstanceConfigs.success>,
): AppInstanceConfigsState => ({
  ...state,
  app_instance_configs: payload.app_instance_configs,
  currentAppInstanceConfigUuid: state.currentAppInstanceConfigUuid
    ? state.currentAppInstanceConfigUuid
    : payload.app_instance_configs[0]?.uuid,
  getAppInstanceConfigs: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getAppInstanceConfigsFailureHandler = (
  state: AppInstanceConfigsState,
  { payload: error }: ActionType<typeof actions.getAppInstanceConfigs.failure>,
): AppInstanceConfigsState => ({
  ...state,
  getAppInstanceConfigs: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const updateCurrentAppInstanceConfigUuidHandler = (
  state: AppInstanceConfigsState,
  { payload }: ActionType<typeof actions.updateCurrentAppInstanceConfigUuid>,
): AppInstanceConfigsState => ({
  ...state,
  currentAppInstanceConfigUuid: payload.currentAppInstanceConfigUuid,
});

const isCurrentLocationIdUpdatingHandler = (
  state: AppInstanceConfigsState,
  { payload }: ActionType<typeof actions.isCurrentLocationIdUpdating>,
): AppInstanceConfigsState => ({
  ...state,
  isCurrentLocationIdUpdating: payload.isCurrentLocationIdUpdating,
});

const resetCurrentLocationIdUpdatingHandler = (state: AppInstanceConfigsState): AppInstanceConfigsState => ({
  ...state,
  isCurrentLocationIdUpdating: null,
});

const updateCurrentLocationIdHandler = (
  state: AppInstanceConfigsState,
  { payload }: ActionType<typeof actions.updateCurrentLocationId>,
): AppInstanceConfigsState => {
  queryParams.set('locationId', payload.currentLocationId);

  return {
    ...state,
    currentLocationId: payload.currentLocationId,
  };
};

const onboardUserRequestHandler = (state: AppInstanceConfigsState): AppInstanceConfigsState => ({
  ...state,
  onboardUser: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const onboardUserSuccessHandler = (state: AppInstanceConfigsState): AppInstanceConfigsState => ({
  ...state,
  onboardUser: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const onboardUserFailureHandler = (
  state: AppInstanceConfigsState,
  { payload: error }: ActionType<typeof actions.onboardUser.failure>,
): AppInstanceConfigsState => ({
  ...state,
  onboardUser: {
    error: error.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetAppInstanceConfigHandler = (): AppInstanceConfigsState => ({
  app_instance_configs: null,
  currentAppInstanceConfigUuid: null,
  currentLocationId: null,
  isCurrentLocationIdUpdating: null,
  getAppInstanceConfigs: {
    status: null,
    error: null,
  },
  onboardUser: {
    status: null,
    error: null,
  },
});

const resetOnboardUserStatusHandler = (state: AppInstanceConfigsState): AppInstanceConfigsState => ({
  ...state,
  onboardUser: {
    status: null,
    error: null,
  },
});

const appInstanceConfigsReducer = createReducer(initialState)
  .handleAction(actions.getAppInstanceConfigs.request, getAppInstanceConfigsRequestHandler)
  .handleAction(actions.getAppInstanceConfigs.success, getAppInstanceConfigsSuccessHandler)
  .handleAction(actions.getAppInstanceConfigs.failure, getAppInstanceConfigsFailureHandler)
  .handleAction(actions.updateCurrentAppInstanceConfigUuid, updateCurrentAppInstanceConfigUuidHandler)
  .handleAction(actions.updateCurrentLocationId, updateCurrentLocationIdHandler)
  .handleAction(actions.isCurrentLocationIdUpdating, isCurrentLocationIdUpdatingHandler)
  .handleAction(actions.resetCurrentLocationIdUpdating, resetCurrentLocationIdUpdatingHandler)
  .handleAction(actions.onboardUser.request, onboardUserRequestHandler)
  .handleAction(actions.onboardUser.success, onboardUserSuccessHandler)
  .handleAction(actions.onboardUser.failure, onboardUserFailureHandler)
  .handleAction(actions.resetAppInstanceConfig, resetAppInstanceConfigHandler)
  .handleAction(actions.resetOnboardUserStatus, resetOnboardUserStatusHandler);

export default appInstanceConfigsReducer;
