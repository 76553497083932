import React from 'react';
import {
  AvailableNowTile,
  AvailableNowContainer,
  AvailableNowSubtitle,
  AvailableNowTitle,
  AvailableNowItemWrapper,
  AvailableNowSectionTitle,
} from '../styles';
import { useExchangeAdapterHandling } from 'hooks/use-exchange-adapter-handling.hook';
import { useIntl } from 'react-intl';

export const AvailableNowSkeleton = (): JSX.Element => {
  const { isCompactUiEnabled } = useExchangeAdapterHandling();
  const intl = useIntl();

  return (
    <>
      <AvailableNowSectionTitle>{intl.formatMessage({ id: 'available_now' })}</AvailableNowSectionTitle>
      <AvailableNowContainer gap={19} isWidth flex={1} data-testid="available-now-skeleton">
        <AvailableNowItemWrapper isCompactUiEnabled={isCompactUiEnabled}>
          <AvailableNowTile />
          {!isCompactUiEnabled && (
            <>
              <AvailableNowTitle className="title" data-testid="available-now-skeleton-tile-title-one" />
              <AvailableNowSubtitle className="subtitle" data-testid="available-now-skeleton-tile-subtitle-one" />
            </>
          )}
        </AvailableNowItemWrapper>
        <AvailableNowItemWrapper isCompactUiEnabled={isCompactUiEnabled}>
          <AvailableNowTile />
          {!isCompactUiEnabled && (
            <>
              <AvailableNowTitle className="title" data-testid="available-now-skeleton-tile-title-two" />
              <AvailableNowSubtitle className="subtitle" data-testid="available-now-skeleton-tile-subtitle-two" />
            </>
          )}
        </AvailableNowItemWrapper>
      </AvailableNowContainer>
    </>
  );
};
