import { SelectedTimelineBlockVariants } from 'pages/resource/components/selected-timeline-block/selected-timeline-block.enum';
import { TIMELINE_BLOCK_HEIGHT, TIMELINE_TOP_HEIGHT } from 'shared/consts/timeline';
import styled, { css } from 'styled-components';

interface StyledSelectedTimelineBlockProps {
  width: number;
  startTimeOffset: number;
  variant: string;
}

export const selectedTimeLineBlockVariants: Record<
  NonNullable<StyledSelectedTimelineBlockProps['variant']>,
  ReturnType<typeof css>
> = {
  [SelectedTimelineBlockVariants.AVAILABLE]: css`
    background-color: ${({ theme }) => theme.colors.palette.modifiedMalibuBlue}80;
    border: 2px solid ${({ theme }) => theme.colors.palette.modifiedMalibuBlue};
  `,
  [SelectedTimelineBlockVariants.FULL_DAY]: css`
    background-color: ${({ theme }) => theme.colors.palette.modifiedMalibuBlue}B3;
    border: 2px solid ${({ theme }) => theme.colors.palette.modifiedMalibuBlue};
  `,
  [SelectedTimelineBlockVariants.UNAVAILABLE]: css`
    background-color: ${({ theme }) => theme.colors.palette.systemGreyDarkAlt}80;
    border: 2px solid ${({ theme }) => theme.colors.palette.systemGreyDarkAlt};
  `,
};

export const StyledSelectedTimelineBlock = styled.div<StyledSelectedTimelineBlockProps>`
  display: flex;
  position: absolute;
  width: ${({ width }) => width}px;
  border-radius: 8px;
  top: ${TIMELINE_TOP_HEIGHT}px;
  height: ${TIMELINE_BLOCK_HEIGHT}px;
  left: ${({ startTimeOffset }) => `${startTimeOffset}px`};
  padding: 0;
  ${({ variant }) => selectedTimeLineBlockVariants[variant]}
  user-select: none;
  justify-content: space-evenly;
`;

export const DraggableSection = styled.div`
  width: 100%;
  cursor: pointer;
  user-select: none;
`;
