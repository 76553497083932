import styled from 'styled-components';
import { PAYMENT_CONTENT_WIDTH } from 'shared/consts';
import { ModalWrapper } from '@hqo/react-components-library/dist/molecules/modals/wrapper';
import { DIMENSIONS } from '@hqo/react-components-library';
import { TwoColumnModal } from '@hqo/react-components-library/dist/molecules/modals/twoColumnModal';

export const ReceiptContainer = styled.div``;

export const StyledTwoColumnModal = styled(TwoColumnModal)`
  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}) {
    height: 100vh;
    padding: 24px 0 0 0;

    .button-container {
      border-top: 1px solid ${({ theme }) => theme.colors.greys.adminGreyLight};
      padding: 12px 15px;
      width: 100%;
      margin-bottom: 25px;
    }

    .right-pane {
      padding: 0 16px;
    }
  }
`;

export const LeftPaneContentContainer = styled.div``;

export const CancellationExpiredText = styled.div`
  font: 400 14px/16px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
  display: flex;
  align-items: center;
  margin: 0 43px 0 0;

  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}) {
    justify-content: center;
    margin: 10px 0;
  }
`;

export const ButtonContainer = styled.div<{ isButtonLabelVisible: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-top: 10px;
`;

export const DoublePane = styled.div<{ isCancellationExpiredTextVisible: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ isCancellationExpiredTextVisible }) =>
    isCancellationExpiredTextVisible ? 'calc(100vh - 185px)' : 'calc(100vh - 150px)'};
  gap: 20px;
  flex-grow: 1;
`;

export const RightPane = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
    background: ${({ theme }) => theme.colors.$transparent};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.$sharedGreyLight};
    border-radius: 4px;
  }
`;

export const LeftPane = styled.div``;

export const StyledModalWrapper = styled(ModalWrapper)<{ isCancelBookingPage?: boolean }>`
  width: ${PAYMENT_CONTENT_WIDTH};

  .modal-background {
    backdrop-filter: none;
    background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  }

  @media only screen and (max-width: ${DIMENSIONS.XLARGE}) {
    .modal-wrapper {
      height: ${({ isCancelBookingPage }) => isCancelBookingPage && '538px'};
    }
  }

  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}) {
    width: 100%;
    .modal-wrapper-exit-button {
      z-index: 15;
    }
  }
`;
