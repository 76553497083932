import { MILLISECONDS_IN_SECOND, MINUTES_IN_HOUR } from 'shared/consts';

export const MONTH_OFFSET = 1;

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const IMAGE_PLACEHOLDER_HEIGHT = 300;

export enum RESOURCE_SECTIONS_Z_INDEXES {
  IMAGE_SECTION = 1,
  SCROLLABLE_CONTENT_SECTION = 2,
  HEADER_AND_FOOTER = 3,
}

export const CONTENT_BORDER_RADIUS = 8;

export const REFRESH_INTERVAL_MS = 5 * MINUTES_IN_HOUR * MILLISECONDS_IN_SECOND; // 5 minutes
