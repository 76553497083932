import { useSelector } from 'react-redux';

import { selectResourcesTypes, selectResourceActionStatus } from 'store/resources/selectors';
import { ACTION_STATUSES } from 'shared/consts';

export const useResourcesTypes = () => {
  const resourceTypes = useSelector(selectResourcesTypes);

  const isResourceTypesLoading = useSelector(selectResourceActionStatus) === ACTION_STATUSES.PENDING;

  return { resourceTypes, isResourceTypesLoading };
};
