import { CustomQuestionProps } from 'pages/additional-information/AdditionalSelectors';
import { useCallback } from 'react';

interface UseCustomQuestionHook {
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useCustomQuestionHook = ({ question, onInputChange }: CustomQuestionProps): UseCustomQuestionHook => {
  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onInputChange({ ...question, answer: event.target?.value });
    },
    [question],
  );

  return { handleChangeInput };
};

interface UseAdditionalSelectorHook {
  onInputChangeHandler: (questions: FormResponse) => void;
}

export const useAdditionalSelectorHook = (
  setUserResponse: React.Dispatch<React.SetStateAction<FormResponse[]>>,
): UseAdditionalSelectorHook => {
  const onInputChangeHandler = useCallback(
    (questions: FormResponse) => {
      setUserResponse(userResponses =>
        userResponses.map(response => (response.question === questions.question ? questions : response)),
      );
    },
    [setUserResponse],
  );

  return { onInputChangeHandler };
};
