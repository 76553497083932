import styled from 'styled-components';

import { DIMENSIONS } from '@hqo/react-components-library';
import IframeModalWrapper from 'components/modals/iframeWrapper';
import { CHECKOUT_BREAK_POINT } from 'shared/consts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { reverseSlide, slide } from 'hocs/shared-styles';

export const QuickCheckoutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 32px 10px 20px 10px;
  background: ${({ theme }) => theme.colors.universal.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 14;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${CHECKOUT_BREAK_POINT}px) {
    border-radius: 8px 8px 0 0;
    transform: translate(0%, 0%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => `${theme.colorsRgba.black_5}`};
  z-index: 13;
`;

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 375px;
  border-radius: 8px 8px 0 0;

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    align-items: flex-start;
  }
`;

export const StyledModalWrapper = styled(IframeModalWrapper)<{ reverseAnimation: boolean }>`
  width: 543px;
  transition: width 1s;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 375px;
    width: 100%;

    .iframe-wrapper {
      height: 375px;
      border-radius: 8px 8px 0 0;
      top: auto;
      animation: ${props => (props.reverseAnimation ? reverseSlide : slide)} 0.5s;
    }

    .iframe-modal-content {
      border-radius: 8px 8px 0 0;
      height: auto;
    }

    .iframe-modal-content > div {
      border-radius: 8px 8px 0 0;
      height: 100%;
      inset: 0;
    }
  }

  @media only screen and (max-width: ${DIMENSIONS.LARGE}) {
    width: 100%;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  flex: 0 1 auto;
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const Title = styled.div`
  font: 600 28px/33px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.darkGrey7};
  margin-bottom: 24px;
`;

export const Subtitle = styled.div`
  font: 400 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
  text-align: center;
  padding: 0 16px;
  margin-bottom: 10px;
`;

export const StyledButton = styled(Button)`
  height: 36px;
  width: 110px;
  padding: 16px;
  border-radius: 4px;
  font-size: 16px;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    height: 48px;
    width: 100%;
  }
`;
