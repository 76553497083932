import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library';

export const UpcomingBookingsContainer = styled.div<{ isEmpty: boolean }>`
  margin: 0 -16px;
  ${({ isEmpty }) =>
    isEmpty &&
    `
  `}

  .carousel-list {
    gap: 26px;
    padding: 5px 0px 13px 13px;

    &::after {
      content: '';
      flex: 0 0 1px;
    }

    & > div:last-child {
      margin-right: -13px;
    }
  }

  .carousel-title {
    margin-left: 16px;
  }

  .upcoming-booking {
    min-width: 293px;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    .upcoming-booking {
      width: 272px;
      min-width: 272px;
    }
  }
`;
