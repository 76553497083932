import React from 'react';
import { FlexContainer, SkeletonBlock } from '../styles';
import { OpeningHourBlock } from './opening-hour-block';

export const ResourceOpeningHours = (): JSX.Element => {
  return (
    <FlexContainer gap="23" isColumnDirection top="15" left={16}>
      <SkeletonBlock width="171" />
      <FlexContainer gap="23" isColumnDirection>
        <OpeningHourBlock />
        <OpeningHourBlock />
        <OpeningHourBlock />
        <OpeningHourBlock />
      </FlexContainer>
    </FlexContainer>
  );
};
