import { useCallback } from 'react';

interface getIconValuesReturnValue {
  iconType: string;
  iconName: string;
}
export const useIconValues = () => {
  const getIconValues = useCallback((icon: string): getIconValuesReturnValue => {
    const iconValues = icon?.split(' ');
    if (iconValues?.length === 2) {
      return {
        iconType: iconValues?.[0],
        iconName: iconValues?.[1]?.split('-')?.slice(1)?.join('-'),
      };
    }
    return {
      iconType: icon?.split('-')?.slice(0, 1)?.[0],
      iconName: icon?.split('-')?.slice(1)?.join('-'),
    };
  }, []);
  return { getIconValues };
};
