import { useDispatch, useSelector } from 'react-redux';

import { getFloorPlanAppInstanceConfig } from 'store/floorplan-app-instance-config/actions';
import { selectFloorPlanAppInstanceConfig } from 'store/floorplan-app-instance-config/selectors';
import { useEffect } from 'react';
import { AppInstanceConfig } from 'store/app-instance-configs/types';
import { useBuilding } from 'hooks/use-building.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { selectFloorBuilding } from 'store/spaceManager/selectors';

export const useFloorPlanConfiguration = (): AppInstanceConfig => {
  const building = useBuilding();
  const dispatch = useDispatch();
  const { showFloorPlan } = useFlags();
  const config = useSelector(selectFloorPlanAppInstanceConfig);
  const floorBuildingUuid = useSelector(selectFloorBuilding);
  useEffect(() => {
    if (building && showFloorPlan && (building?.uuid !== floorBuildingUuid || !config)) {
      const payload = { building_uuid: building.uuid };
      dispatch(getFloorPlanAppInstanceConfig.request(payload));
    }
  }, [building, showFloorPlan, floorBuildingUuid]);

  return config;
};
