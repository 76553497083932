import {
  AvailabilityDay,
  BackLinkWrapper,
  Container,
  DayText,
  HoursContainer,
  HoursRangeText,
  PageContainer,
  ResourceContainer,
  ScrollableTrayContainer,
  StyledImage,
  StyledMobileHeaderWrapper,
} from './styles';
import { IntlShape, useIntl } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import { RESOURCE_TRACKING_STATUSES, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';

import { ArrowBackLink } from 'components/arrow-back-link';
import { BookingWindow } from 'store/resource/types';
import { CtaButton } from 'pages/resource/cta-button';
import { PaymentIFrame } from 'components/paymentIFrame';
import React, { useMemo, useRef, useState } from 'react';
import { ResourceSkeleton } from 'components/resource-skeleton';
import { ResourceImageSkeleton } from 'components/resource-skeleton/styles';
import { SwipePaymentIFrame } from 'components/swipePaymentIFrame';
import { daysOfWeek } from 'utils/getOpeningHours/getOpeningHours.enum';
import { getOpeningHours } from 'utils/getOpeningHours/getOpeningHours';
import { searchParams } from 'pages/resource/cta-button/cta-button';
import { useBackButtonVisibility } from 'hooks/use-back-button-visibility.hook';
import { useCurrentLocation } from 'hooks/use-current-location.hook';
import { useOnSubmitBookingRequest } from 'pages/schedule-select-page/components/use-on-submit-booking-request.hook';
import { usePaymentModal } from 'hooks/payment-hook/use-payment-modal.hook';
import { useResourcePage } from './use-resource-page.hook';
import { useResourcePageNavigation } from 'hooks/resource/use-resource-page-navigation.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { replace } from 'store/router/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getResourceTrackInfo } from 'utils/getResourceTrackInfo';
import { getUniqueImageUrl } from 'utils/getUniqueImageUrl';
import { useIsUniqueImageUrl } from 'hooks/use-resource-image.hook';
import { useQuickCheckout } from 'hooks/use-quick-checkout.hook';
import { PaymentContent } from 'components/payment-content';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';
import { useCheckoutScreen } from 'hooks/payment-content/use-checkout-screen.hook';
import { usePrice } from 'pages/resource/use-price.hook';
import { useHqoTracking } from 'hooks/use-hqo-tracking.hook';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { BackArrowMobileHeader } from 'components/back-arrow-mobile-header';
import { useToggleNativeHeader } from 'hooks/use-toggle-native-header/use-toggle-native-header.hook';
import { IMAGE_PLACEHOLDER_HEIGHT } from 'pages/resource/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ResourceContent } from 'pages/resource/components/resource-content/resource-content';
import { imageIsLoadedSelector } from 'store/resource/selectors';
import { useBackArrowMobileHeader } from 'components/back-arrow-mobile-header/hooks/use-back-arrow-mobile-header';
import { ModalContainer } from 'pages/resource/components/terms-and-conditions/components/modal-container';
import { PresetWindows } from 'store/resources/types';

export const renderOpeningHours = (
  bookingWindows: BookingWindow[],
  intl: IntlShape,
  locale: string,
  timezone: string,
) => {
  const filteredBookingWindows = bookingWindows.filter(window => window.type !== 'Unavailable');

  const mappedWeekDays = getOpeningHours(filteredBookingWindows, timezone, locale);

  return (
    <>
      {daysOfWeek.map(day => {
        const isClosed = mappedWeekDays[day].openingTime === 'closed' && mappedWeekDays[day].closingTime === 'closed';

        return (
          <AvailabilityDay key={day}>
            <DayText>{intl.formatMessage({ id: `${day.toLowerCase()}` })}</DayText>
            {isClosed ? (
              <HoursRangeText>{intl.formatMessage({ id: 'closed' })}</HoursRangeText>
            ) : (
              <HoursContainer>
                {mappedWeekDays[day].openingHours.map(time => (
                  <HoursRangeText key={time.open}>{`${time.open} - ${time.close}`}</HoursRangeText>
                ))}
              </HoursContainer>
            )}
          </AvailabilityDay>
        );
      })}
    </>
  );
};

export const ResourcePage = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { showResourceDetailsDateTimeInputs } = useFlags();
  const {
    resourceUuid,
    resource,
    isResourceLoading,
    search,
    isTermsAndConditionsVisible,
    shouldDisplayMobileHeader,
    isValidBooking,
    filteredTimeRanges,
  } = useResourcePage();
  const [imageHeight, setImageHeight] = useState<number>(IMAGE_PLACEHOLDER_HEIGHT);
  const currentLocationName = useCurrentLocation();
  const { onBackLinkClick } = useResourcePageNavigation();
  const { startDate, startTime, endTime, startDates, source, presetWindows } = useSearchParams<searchParams>();
  const onSubmitBookingRequest = useOnSubmitBookingRequest(source);
  const isUniqueUrl = useIsUniqueImageUrl();
  const { closeQuickCheckoutHandler, openQuickCheckoutHandler } = useQuickCheckout();
  const isBackButtonVisible = useBackButtonVisibility();
  const { reverseAnimation } = usePaymentModal(openQuickCheckoutHandler);
  const { onCloseCheckout, openCheckout } = useCheckoutScreen();
  const { isCancelBookingStep, setIsCancelBookingStep, onCloseReceipt, onPressBack, openReceipt } = useReceiptScreen();
  usePrice();
  useToggleNativeHeader(showResourceDetailsDateTimeInputs);
  const isMobileDevice = useIsSmallViewportWidth();
  const imageExist = useSelector(imageIsLoadedSelector);
  const backArrowMobileHeaderRef = useRef(null);
  const resourceDetailsContainerRef = useRef(null);
  const scrollableContainerRef = useRef(null);
  useBackArrowMobileHeader({
    backArrowMobileHeaderRef,
    containerRef: resourceDetailsContainerRef,
    scrollableContainerRef,
  });

  const isReadyToCheckout = (!!startDate || !!startDates) && !!startTime && !!endTime;
  let ctaButtonValue: string;
  if (isReadyToCheckout) {
    // We want to keep the current behavior the same if the LD showResourceDetailsDateTimeInputs is false
    // In this case, the messageId should be 'book' if the LD flag is false
    const messageId = !isValidBooking && showResourceDetailsDateTimeInputs ? 'unavailable' : 'book';
    ctaButtonValue = intl.formatMessage({ id: messageId });
  } else {
    ctaButtonValue = intl.formatMessage({ id: 'view_availability' });
  }
  const resourceContainerRef = useRef(null);

  const resourceTrackingStatus = useMemo<RESOURCE_TRACKING_STATUSES>(() => {
    if (!isValidBooking) {
      return RESOURCE_TRACKING_STATUSES.UNAVAILABLE;
    }

    return presetWindows === PresetWindows.FULL_DAY
      ? RESOURCE_TRACKING_STATUSES.ALL_DAY
      : RESOURCE_TRACKING_STATUSES.AVAILABLE;
  }, [isValidBooking, presetWindows]);

  useHqoTracking({
    isLoading: isResourceLoading,
    trackEventName: TRACK_EVENT_NAMES.RESOURCE_DETAIL_IMPRESSION,
    metadata: {
      type: TRACK_EVENT_TYPES.IMPRESSION,
      button_cta: ctaButtonValue,
      location_name: currentLocationName,
      resource: { ...getResourceTrackInfo(resource), multiDay: resource.supports_multi_day_bookings },
      status: resourceTrackingStatus,
    },
    trackConfig: { sendToHqoTracking: true },
  });

  if (isResourceLoading) {
    return <ResourceSkeleton />;
  }

  if (resource.uuid !== resourceUuid && resource && !isResourceLoading) {
    dispatch(replace(`/${search}`));
    return null;
  }

  return (
    <PageContainer>
      {isMobileDevice && shouldDisplayMobileHeader && (
        <StyledMobileHeaderWrapper>
          <BackArrowMobileHeader backArrowMobileHeaderRef={backArrowMobileHeaderRef} />
        </StyledMobileHeaderWrapper>
      )}
      <Container
        showResourceDetailsDateTimeInputs={showResourceDetailsDateTimeInputs}
        ref={resourceDetailsContainerRef}
      >
        <ResourceContainer ref={resourceContainerRef}>
          {isBackButtonVisible && (
            <BackLinkWrapper>
              <ArrowBackLink onClick={onBackLinkClick} />
            </BackLinkWrapper>
          )}
          <StyledImage
            src={getUniqueImageUrl(resource.image_url, isUniqueUrl)}
            alt="resource image"
            skeleton={imageExist ? null : <ResourceImageSkeleton />}
            setImageHeight={setImageHeight}
            showResourceDetailsDateTimeInputs={showResourceDetailsDateTimeInputs}
          />
          {showResourceDetailsDateTimeInputs ? (
            <ScrollableTrayContainer
              marginTop={imageHeight}
              isMobileDevice={isMobileDevice}
              data-testid="scrollable-tray"
              ref={scrollableContainerRef}
            >
              <ResourceContent
                filteredTimeRanges={filteredTimeRanges}
                resource={resource}
                isTermsAndConditionsVisible={isTermsAndConditionsVisible}
              />
            </ScrollableTrayContainer>
          ) : (
            <ResourceContent resource={resource} isTermsAndConditionsVisible={isTermsAndConditionsVisible} />
          )}
        </ResourceContainer>
        <CtaButton
          resourceContainerRef={resourceContainerRef}
          onSubmitBookingRequest={onSubmitBookingRequest}
          ctaButtonValue={ctaButtonValue}
          disabled={showResourceDetailsDateTimeInputs && !isValidBooking}
        />
        <PaymentContent
          openReceipt={openReceipt}
          reverseAnimation={reverseAnimation}
          closeQuickCheckoutHandler={closeQuickCheckoutHandler}
          isCancelBookingStep={isCancelBookingStep}
          setIsCancelBookingStep={setIsCancelBookingStep}
          onCloseReceipt={onCloseReceipt}
          onPressBack={onPressBack}
          onCloseCheckout={onCloseCheckout}
          openCheckout={openCheckout}
          showNativeMobileHeader={!showResourceDetailsDateTimeInputs}
        />
        <Routes>
          <Route path="payment" element={<PaymentIFrame reverseAnimation={reverseAnimation} />} />
          <Route path="swipe-payment" element={<SwipePaymentIFrame />} />
          <Route path="quick-checkout/terms-and-conditions" element={<ModalContainer />} />
        </Routes>
      </Container>
    </PageContainer>
  );
};
