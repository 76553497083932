import { AvailableNow, BookingWindow } from 'store/resource/types';
import { AvailableNowIcons, useAvailableNow } from 'hooks/use-available-now.hook';
import { StyledVerticalContentTile } from './styles';
import { AvailableNowTile } from '../skeleton/styles';
import React, { useCallback } from 'react';

import { LabelPill } from 'components/label-pill';
import { getCurrencySymbol } from 'utils/getCurrencySymbol';
import { getCurrentBookingWindow } from 'utils/getCurrentBookingWindow';
import { useCurrencyType } from 'hooks/use-currency-type.hook';
import { useIntl } from 'react-intl';
import { useLocale } from 'hooks/use-locale.hook';
import { CapacityBlock } from 'components/capacity-block';
import { useExchangeAdapterHandling } from 'hooks/use-exchange-adapter-handling.hook';

export interface AvailableNowItemProps {
  uuid: string;
  title: string;
  subtitle: string;
  image_url: string;
  resourceType: string;
  bookingWindows: BookingWindow[];
  availableNow: AvailableNow;
  capacity: number;
  onClick?: (
    resourceUuid: string,
    availableNow: AvailableNow,
    minimumDuration: number,
    maximumDuration: number,
    resourceType: string,
  ) => void;
}

export const AvailableNowItem: React.FC<AvailableNowItemProps> = ({
  uuid,
  title,
  subtitle,
  image_url,
  resourceType,
  bookingWindows,
  availableNow,
  capacity,
  onClick,
}: AvailableNowItemProps): JSX.Element => {
  const intl = useIntl();
  const languageCode = useLocale();
  const currencyCode = useCurrencyType();
  const currencySymbol = getCurrencySymbol(languageCode, currencyCode);
  const { buildAvailableNowIcons } = useAvailableNow();
  const { isCompactUiEnabled } = useExchangeAdapterHandling();
  const { currentBookingWindow } = getCurrentBookingWindow({
    bookingWindows,
    date: availableNow.start_at,
  });
  const minimumDuration = currentBookingWindow?.minimum_duration;
  const maximumDuration = currentBookingWindow?.maximum_duration;
  const verticalContentTileVariant = isCompactUiEnabled ? 'compact' : 'standard';

  const onTileClick = useCallback(() => {
    onClick(uuid, availableNow, minimumDuration, maximumDuration, resourceType);
  }, [onClick, uuid, availableNow, minimumDuration, maximumDuration, resourceType]);

  const availableNowIcons = useCallback((): AvailableNowIcons => {
    return {
      paidResourceIcon: <LabelPill text={currencySymbol} key="1" />,
      freeResourceIcon: <LabelPill text={intl.formatMessage({ id: 'free' })} key="2" />,
      capacityIcon: <CapacityBlock capacity={capacity} title={title} key="3" />,
    };
  }, [currencySymbol, title, capacity]);

  return (
    <StyledVerticalContentTile
      className="available-now-tile"
      title={title}
      subtitle={subtitle}
      isCompactUiEnabled={isCompactUiEnabled}
      variant={verticalContentTileVariant}
      imageUrl={image_url}
      onClick={onTileClick}
      statusLabels={buildAvailableNowIcons(bookingWindows, capacity, availableNowIcons())}
      imageClassName="responsive-image"
      skeleton={<AvailableNowTile />}
    />
  );
};
