import { AvailabilityInfo } from 'floorplan/pages/floorplan/types';
import { useCallback } from 'react';
import { selectAppInstanceConfigs } from 'store/app-instance-configs/selectors';
import { useSelector } from 'react-redux';

export const useResourceAdapterConfiguration = () => {
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);

  const hasCompactUISupport = useCallback(
    (resourceAvailabilityInfo: AvailabilityInfo) => {
      if (resourceAvailabilityInfo) {
        const resourceRBAppInstanceConfigUUID = resourceAvailabilityInfo.linkedSpace.app_instance_config_uuid;
        if (resourceRBAppInstanceConfigUUID) {
          const resourceAppInstanceConfig = appInstanceConfigs.find(
            adapter => adapter.uuid === resourceRBAppInstanceConfigUUID,
          );
          return resourceAppInstanceConfig?.config?.compact_ui_enabled ?? false;
        }
      }
      return false;
    },
    [appInstanceConfigs],
  );

  return {
    hasCompactUISupport,
  };
};
