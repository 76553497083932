import { ACTION_STATUSES, CREDIT_CARD_PAYMENT_GATEWAY_ID, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import {
  CloseButtonContainer,
  ContentContainer,
  EditButton,
  QuickCheckoutContent,
  StyledPriceBlock,
  StyledTitleBlock,
} from './styles';
import {
  CREDIT_PAYMENT_TYPE_ID,
  CREDIT_POINTS,
  INVOICE,
  INVOICE_PAYMENT_TYPE_ID,
} from 'shared/consts/payment-method-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { resetComplete3DSCart, resetGetCartStatus, resetSubmitCartStatus, submitCart } from 'store/cart/actions';
import { selectBuildingTimeZone } from 'store/building/selectors';
import {
  selectCartDiscountSummary,
  selectComplete3DSCartStatus,
  selectSubmitCartError,
  selectSubmitCartStatus,
  shouldSubmit3DSecure,
} from 'store/cart/selectors';
import { selectTransaction, selectTransactionStatus } from 'store/transactions/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { CloseButton } from 'components/close-button';
import { Order } from 'store/cart/types';
import { SavedPaymentMethod } from 'store/payment/types';
import { formatCartItemsIds } from 'utils/formatTrackEventProps';
import { formatDate } from 'utils/formatDate';
import { formatSummaryValues } from 'utils/formatSummaryValues';
import { getPrice } from 'utils/getPrice';
import { getTransaction } from 'store/transactions/actions';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { searchParams } from 'pages/date-select-page/interface';
import { selectCurrentLocationId } from 'store/app-instance-configs/selectors';
import { track } from '@hqo/web-tracking';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIntl } from 'react-intl';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useLocale } from 'hooks/use-locale.hook';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { ItemQuantityBlock } from '@hqo/react-components-library/dist/molecules/itemQuantityBlock';
import { selectResource } from 'store/resource/selectors';
import { resetAddOnsState } from 'store/add-ons/actions';
import { PaymentMethodsSection } from 'components/payment/quick-checkout/components/payment-methods-section';
import { Button } from './button/button';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hook';
import { useNativePaymentMethod } from 'components/payment/quick-checkout/components/use-native-payment-method.hook';
import { getTaxSummary } from 'utils/getTaxSummary';
import { CheckoutTermsAndConditions } from 'components/payment/quick-checkout/components/terms-and-conditions';
import { AddingVisitorsBlock } from 'components/adding-visitors-block';
import { useAddingVisitorsBlock } from 'components/adding-visitors-block/hooks/use-adding-visitors-block.hook';
import { Alert } from 'components/alert';
import { VisitorsError } from 'components/adding-visitors-block/visitors-error.enum';

interface Props {
  cart: Order;
  paymentMethods: SavedPaymentMethod[];
  setProcessPaymentFailed: () => void;
  isProcessPaymentFailed: boolean;
  currentPaymentMethodId: string;
  closeQuickCheckoutHandler: VoidFunction;
  openCheckout: VoidFunction;
}

export const Container = ({
  cart,
  paymentMethods,
  currentPaymentMethodId,
  setProcessPaymentFailed,
  isProcessPaymentFailed,
  closeQuickCheckoutHandler,
  openCheckout,
}: Props) => {
  const client = useMiniappSdk();
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const submitCartStatus = useSelector(selectSubmitCartStatus);
  const buildingTimeZone = useSelector(selectBuildingTimeZone);
  const contentRef = useRef(null);
  const { showMiniappPaymentsNavigation } = useFlags();
  const resource = useSelector(selectResource);
  const submit3DSecure = useSelector(shouldSubmit3DSecure);
  const cartError = useSelector(selectSubmitCartError);
  const locale = useLocale();
  const transaction = useSelector(selectTransaction);
  const transactionStatus = useSelector(selectTransactionStatus);
  const complete3DSCartStatus = useSelector(selectComplete3DSCartStatus);
  const { showResourceBookingResourceTermsAndConditions } = useFlags();
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const discountSummary = useSelector(selectCartDiscountSummary);
  const { total, currencyType } = formatSummaryValues(cart?.total_summary);
  const isMobileDevice = useIsSmallViewportWidth();
  const cartItemsIds = formatCartItemsIds(cart);
  const { locationId: currentLocationId, ...queryParams } = useSearchParams<searchParams>();
  const { pathname } = useLocation();
  const queryString = qs.stringify(queryParams);
  const locationId = useSelector(selectCurrentLocationId) || currentLocationId;
  const { isApplePayMethodSelected, setPendingNativePayment, pendingNativePayment, isNativePaymentError } =
    useNativePaymentMethod({ cart, locationId });

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getTransaction.request(cart?.transaction_uuid));
    }
  }, [cart?.transaction_uuid, dispatch, submitCartStatus]);

  const isPaymentMethodsAvailable = !!paymentMethods?.length;
  const isFreeCart = !cart.total_summary.total;

  const paymentMethod = useMemo<SavedPaymentMethod>(() => {
    const creditPointsMethod = paymentMethods?.find(method => method?.payment_method_type === CREDIT_POINTS);
    if (creditPointsMethod && creditPointsMethod.points_balance >= cart.total_summary.total) {
      return creditPointsMethod;
    }

    return paymentMethods?.find(({ id }) => id?.toString() === currentPaymentMethodId);
  }, [cart?.total_summary?.total, currentPaymentMethodId, paymentMethods]);

  const { eventWeekDay, eventDateMonthLong, formatEventStartTime, eventDateDay } = formatDate(
    cart?.items[0]?.resource_booking?.start_at,
    locale as string,
    buildingTimeZone ?? resource.timezone,
  );

  const isVisiblePaymentMethodBlock = !isFreeCart || (!isFreeCart && paymentMethod?.payment_method_type === INVOICE);

  const isTermsVisible = useMemo(
    () => showResourceBookingResourceTermsAndConditions && !!resource.terms_and_conditions,
    [showResourceBookingResourceTermsAndConditions, resource],
  );

  const hasInvoiceWithoutCreditCard = useMemo(() => {
    const hasCreditCardOption = cart.config && cart.config.payment_gateway_id === CREDIT_CARD_PAYMENT_GATEWAY_ID;
    const hasOnlyInvoiceOption = paymentMethods.length === 1 && paymentMethod?.payment_method_type === INVOICE;
    return hasCreditCardOption && hasOnlyInvoiceOption;
  }, [cart.config, paymentMethod, paymentMethods]);

  const { visitors, onChangeVisitors, formattedAttendees, error: visitorError } = useAddingVisitorsBlock();

  const isAllowedVisitorsExist = Boolean(resource?.allowed_visitors);

  useEffect(() => {
    if (submitCartStatus === ACTION_STATUSES.REJECTED) {
      setProcessPaymentFailed();
    }
  }, [setProcessPaymentFailed, submitCartStatus]);

  useEffect(() => {
    const hasSuccessfullyPurchasedWithCreditCard =
      submitCartStatus === ACTION_STATUSES.FULFILLED && transactionStatus === ACTION_STATUSES.FULFILLED;

    if (
      hasSuccessfullyPurchasedWithCreditCard &&
      (!submit3DSecure ||
        paymentMethod?.id.toString() === CREDIT_PAYMENT_TYPE_ID ||
        paymentMethod?.id.toString() === INVOICE_PAYMENT_TYPE_ID)
    ) {
      dispatch(resetAddOnsState());
      dispatch(replace(`${pathname.replace('quick-checkout', 'receipt')}?${queryString}`));
      dispatch(resetSubmitCartStatus());
      dispatch(resetGetCartStatus());
    }
  }, [
    showMiniappPaymentsNavigation,
    submitCartStatus,
    dispatch,
    paymentMethod,
    isMobileDevice,
    cart,
    transactionStatus,
    transaction?.details.id,
  ]);

  const trackCheckoutClick = useCallback(() => {
    track(
      TRACK_EVENT_NAMES.CHECKOUT_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        payment_method: paymentMethod?.payment_method_type,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
      },
      { sendToHqoTracking: true },
    );
  }, []);

  const onCTAClick = useCallback(() => {
    if (!isPaymentMethodsAvailable && !isFreeCart) {
      openCheckout();
    }
    if (isFreeCart) {
      trackCheckoutClick();
      dispatch(
        submitCart.request({
          cart_id: cart.id as string,
          locationId,
          ...(Boolean(formattedAttendees?.length) && { attendees: formattedAttendees }),
        }),
      );
    } else if (isApplePayMethodSelected) {
      setPendingNativePayment(true);
      client?.payments.showNativePayment(
        null,
        {
          currency: cart.total_summary.currency_type,
          total: cart.total_summary.total / 100,
          tax: cart.total_summary.tax_summary.taxes_total / 100,
          authorization_total: cart.total_summary.total / 100,
        },
        cart.config.payment_gateway_id,
      );
    } else if (isPaymentMethodsAvailable) {
      trackCheckoutClick();
      const paymentInfo = {
        cart_id: cart.id as string,
        payment_method_id: paymentMethod?.id.toString(),
        locationId,
      };
      if (complete3DSCartStatus === ACTION_STATUSES.REJECTED) {
        dispatch(resetComplete3DSCart());
      }

      const searchString = qs.stringify({ ...queryParams, cartId: cart?.id, paymentId: paymentInfo.payment_method_id });

      dispatch(
        submitCart.request(
          submit3DSecure &&
            paymentMethod?.id.toString() !== CREDIT_PAYMENT_TYPE_ID &&
            paymentMethod?.id.toString() !== INVOICE_PAYMENT_TYPE_ID
            ? {
                ...paymentInfo,
                browser_info: window.Spreedly?.ThreeDS?.serialize('01'),
                attempt_3dsecure: true,
                three_ds_version: '2',
                callback_url: `${window.location.origin}/redirect?${searchString}`,
                redirect_url: `${window.location.origin}/redirect?${searchString}`,
              }
            : paymentInfo,
        ),
      );
    }
  }, [
    isFreeCart,
    isPaymentMethodsAvailable,
    trackCheckoutClick,
    dispatch,
    cart?.id,
    locationId,
    paymentMethod?.id,
    complete3DSCartStatus,
    submit3DSecure,
    location?.search,
    isApplePayMethodSelected,
    formattedAttendees,
  ]);

  useEffect(() => {
    track(
      TRACK_EVENT_NAMES.QUICK_CHECKOUT_IMPRESSION,
      {
        type: TRACK_EVENT_TYPES.IMPRESSION,
        cart_total: total,
        currency_code: currencyType,
        promo_code_applied: !!discountSummary,
        cart_type: cart.type,
        items: cartItemsIds,
        adapter: cart.config?.config?.adapter,
      },
      { sendToHqoTracking: true },
    );
  }, []);

  const onEditClick = useCallback(() => {
    openCheckout();
  }, []);

  const resolveAddOnsText = (): string => {
    const addOns = cart.items[0].resource_booking.add_ons;
    let addOnsText = '';
    addOns?.forEach(addOn => {
      addOnsText += `+${addOn.quantity} ${addOn.name}${addOn.id !== addOns[addOns?.length - 1].id ? ', ' : ''}`;
    });

    return addOnsText;
  };

  const resolveAdditionalPriceText = (): string => {
    if (!cart?.total_summary?.tax_summary?.taxes_total || isFreeCart) {
      return null;
    }

    return `${getTaxSummary(cart, locale)} ${intl.formatMessage({ id: 'tax_text' })}`;
  };

  const checkoutDisabled = useMemo(() => {
    return (isTermsVisible && !isTermsChecked) || (Boolean(formattedAttendees?.length) && Boolean(visitorError));
  }, [isTermsVisible, isTermsChecked, formattedAttendees, visitorError]);

  return (
    <ContentContainer>
      <CloseButtonContainer>
        <CloseButton onClose={closeQuickCheckoutHandler} />
      </CloseButtonContainer>
      <QuickCheckoutContent className="quick-checkout-content" ref={contentRef}>
        <StyledTitleBlock
          spacing={20}
          title={intl.formatMessage({ id: 'checkout' })}
          subtitle={intl.formatMessage(
            { id: 'you_are_about_book' },
            {
              serviceName: cart.items?.[0]?.display_info.title,
              serviceTime: `${eventWeekDay}, ${eventDateMonthLong} ${eventDateDay}, ${intl.formatMessage({
                id: 'at',
              })} ${formatEventStartTime}`,
            },
          )}
        />
        <StyledPriceBlock>
          {cart.items?.map(item => (
            <ItemQuantityBlock
              key={item.id}
              title={item.display_info.title}
              subtitle={resolveAddOnsText() || item.display_info.subtitle}
              price={getPrice(isFreeCart, cart, intl.formatMessage({ id: 'free' }), locale)}
              additionalPrice={resolveAdditionalPriceText()}
            />
          ))}
        </StyledPriceBlock>
        {isAllowedVisitorsExist && <AddingVisitorsBlock visitors={visitors} onChangeVisitors={onChangeVisitors} />}
        <PaymentMethodsSection
          isAllowedVisitorsExist={isAllowedVisitorsExist}
          isVisiblePaymentMethodBlock={isVisiblePaymentMethodBlock}
          onEditClick={onEditClick}
          cart={cart}
          isProcessPaymentFailed={isProcessPaymentFailed}
          currentPaymentMethodId={currentPaymentMethodId}
          paymentMethod={paymentMethod}
          cartError={cartError}
          isNativePaymentError={isNativePaymentError}
        />
        {hasInvoiceWithoutCreditCard && (
          <EditButton data-testid={'add-cards-option'} onClick={onEditClick}>
            {intl.formatMessage({ id: 'add_cards_option' })}
          </EditButton>
        )}
      </QuickCheckoutContent>
      {isTermsVisible && (
        <CheckoutTermsAndConditions termsChecked={isTermsChecked} setIsTermsChecked={setIsTermsChecked} />
      )}
      {Boolean(visitorError) && visitorError !== VisitorsError.INVALID_EMAIL && (
        <Alert label={visitorError} testId="visitor-error-alert" />
      )}
      <Button
        isPaymentMethodsAvailable={isPaymentMethodsAvailable}
        isFreeCart={isFreeCart}
        submitCartStatus={submitCartStatus}
        onCTAClick={onCTAClick}
        pendingNativeMethod={pendingNativePayment}
        checkoutDisabled={checkoutDisabled}
      />
    </ContentContainer>
  );
};
