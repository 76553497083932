import React from 'react';
import { PageContainer, TitleContainer, Title, AddOnsContainer } from './styles';
import { useIntl } from 'react-intl';
import { Footer } from 'components/footer/footer';
import { PaymentContent } from 'components/payment-content/payment-content';
import { usePaymentModal } from 'hooks/payment-hook/use-payment-modal.hook';
import { useQuickCheckout } from 'hooks/use-quick-checkout.hook';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';
import { useCheckoutScreen } from 'hooks/payment-content/use-checkout-screen.hook';
import { useAddOns } from './use-add-ons.hook';
import { Skeleton } from 'pages/room-layout/skeleton/room-layout-skeleton';
import { AddOnComponent } from './components/add-on-component';
import { AddOnDetails } from './components/add-on-details';
import { useAddOnModal } from './use-add-on-modal.hook';
import { useSelector } from 'react-redux';
import { selectCreateCartStatus } from 'store/cart/selectors';
import { isButtonLoading } from 'utils/isButtonLoading';

export const AddOnsPage = (): JSX.Element => {
  const intl = useIntl();
  const { closeQuickCheckoutHandler, openQuickCheckoutHandler } = useQuickCheckout();
  const { onCloseCheckout, openCheckout } = useCheckoutScreen();
  const { reverseAnimation } = usePaymentModal(openQuickCheckoutHandler);
  const createCartStatus = useSelector(selectCreateCartStatus);
  const { isCancelBookingStep, setIsCancelBookingStep, onCloseReceipt, onPressBack, openReceipt } = useReceiptScreen();
  const { onButtonClick, buttonText, isSkipButton, addOns, isResourceLoading } = useAddOns();
  const { onAddOnClick, isModalVisible, selectedAddOn, onModalClose } = useAddOnModal(addOns);

  if (isResourceLoading) {
    return <Skeleton />;
  }

  return (
    <PageContainer>
      <TitleContainer>
        <Title>{intl.formatMessage({ id: 'add_ons' })}</Title>
      </TitleContainer>
      <AddOnsContainer>
        {addOns?.map(addOn => (
          <AddOnComponent key={addOn?.id} addOn={addOn} onAddOnClick={onAddOnClick} />
        ))}
      </AddOnsContainer>
      <AddOnDetails isModalVisible={isModalVisible} selectedAddOn={selectedAddOn} onModalClose={onModalClose} />
      <Footer
        isSkipCtaButton={isSkipButton}
        isSelectedDateTimeFilterTextOnly
        onCtaButtonClick={onButtonClick}
        ctaButtonText={intl.formatMessage({ id: `${buttonText}` })}
        isButtonDisabled={isButtonLoading(createCartStatus)}
        isButtonLoading={isButtonLoading(createCartStatus)}
      />
      <PaymentContent
        reverseAnimation={reverseAnimation}
        closeQuickCheckoutHandler={closeQuickCheckoutHandler}
        isCancelBookingStep={isCancelBookingStep}
        setIsCancelBookingStep={setIsCancelBookingStep}
        onCloseReceipt={onCloseReceipt}
        onPressBack={onPressBack}
        onCloseCheckout={onCloseCheckout}
        openCheckout={openCheckout}
        openReceipt={openReceipt}
      />
    </PageContainer>
  );
};
