import { Container, LinkTitle } from './styles';
import { useIntl } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { ModalContainer } from './components/modal-container';
import { useModalNavigation } from './use-modal-navigation.hook';

export const TermsAndConditions = (): JSX.Element => {
  const intl = useIntl();
  const { handleOpenModal } = useModalNavigation();

  return (
    <Container>
      <LinkTitle onClick={handleOpenModal}>{intl.formatMessage({ id: 'terms_and_conditions' })}</LinkTitle>
      <Routes>
        <Route path="terms-and-conditions" element={<ModalContainer />} />
      </Routes>
    </Container>
  );
};
