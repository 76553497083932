import { Input } from '@hqo/react-components-library';

import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';
import styled, { css } from 'styled-components';

export const skeletonAnimation = () => css`
  background: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground};
  background: ${({ theme }) =>
    `linear-gradient(110deg, ${theme?.colors?.greys?.adminGreyMedium} 8%, ${theme?.colors?.greys?.adminGreyLightAlt} 18%, ${theme?.colors?.greys?.adminGreyMedium} 33%)`};
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow-y: auto;
  height: 100%;
`;

export const AdditionalInfoModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.universal.white};
  padding: 16px;
  max-height: none;
`;

export const DoublePane = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin-bottom: 130px;
  overflow: auto;

  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    grid-template-columns: 1fr;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  position: sticky;
  top: 0;
  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    position: static;
    justify-content: center;
  }
`;

export const CustomQuestionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

export const FormContainer = styled.div`
  margin-bottom: 32px;
  width: 100%;
`;

export const StyledInput = styled(Input)`
  label {
    color: ${({ theme }) => theme.colors.greys.adminBlack};
  }
`;

export const GhostContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
`;

export const GhostCustomQuestion = styled.div`
  width: 60%;
  height: 25px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme?.colors?.greys?.sharedGreyBackground ?? '#EDEDED'};
  ${skeletonAnimation};
`;
