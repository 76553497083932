import { CheckboxForm, CheckboxOption } from '@hqo/react-components-library/dist/molecules/checkboxForm';
import { RadioForm, RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';

import { LocationsListContainer } from './styles';
import React, { ChangeEvent } from 'react';
import { useToggleNativeHeader } from 'hooks/use-toggle-native-header/use-toggle-native-header.hook';
import { useToggleSwipeGestures } from 'hooks/use-toggle-swipe-gestures/use-toggle-swipe-gestures.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface SelectLocationModalContentProps {
  onRadioButtonClick: (event: ChangeEvent<HTMLInputElement>) => void;
  onLocationsChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedLocationName: string;
  options: CheckboxOption[] | RadioOption[];
}

export const SelectLocationModalContent = ({
  onRadioButtonClick,
  onLocationsChange,
  selectedLocationName,
  options,
}: SelectLocationModalContentProps): JSX.Element => {
  useToggleNativeHeader();
  useToggleSwipeGestures();

  const { resourceBookingPortfolioBased } = useFlags();

  return (
    <LocationsListContainer>
      {resourceBookingPortfolioBased ? (
        <CheckboxForm className="locations-list" options={options} onChange={onLocationsChange} disabled={false} />
      ) : (
        <RadioForm
          className="locations-list"
          options={options as RadioOption[]}
          onPress={onRadioButtonClick}
          selected={selectedLocationName}
          isExpandedTouchArea
          disabled={false}
        />
      )}
    </LocationsListContainer>
  );
};
