import { ButtonsWrapper, StyledButton } from './styles';

import { DateSelectPageFooterProps, searchParams } from '../interface';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { MultidayWarningMessage } from 'components/multiday-warning-message/multiday-warning-message';

export const DateSelectPageFooter = ({ onNextClick }: DateSelectPageFooterProps): JSX.Element => {
  const intl = useIntl();
  const { startDate, startDates } = useSearchParams<searchParams>();
  const isButtonDisabled: boolean = !(startDate || startDates);
  const { isMultiDayBookingEnabled } = useAppInstanceConfigFeature();

  return (
    <ButtonsWrapper data-testid="date-select-buttons-wrapper">
      {isMultiDayBookingEnabled && <MultidayWarningMessage />}
      <StyledButton
        key="next"
        data-testid="next-button"
        onClick={onNextClick}
        text={intl.formatMessage({ id: 'next' })}
        disabled={isButtonDisabled}
        multiline={false}
      />
    </ButtonsWrapper>
  );
};
