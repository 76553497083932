import { Button } from '@hqo/react-components-library';
import styled from 'styled-components';
import { PAYMENT_CONTENT_WIDTH } from 'shared/consts';

export const DoneButton = styled(Button)`
  align-self: flex-end;
  width: 100%;
  height: 48px;
  margin-top: 0;
  padding: 4px 23px;
  background-color: ${({ theme }) => theme.colors.$primary};
  border-color: ${({ theme }) => theme.colors.$primary};
`;

export const ButtonContainer = styled.div`
  width: 215px;

  @media only screen and (max-width: ${PAYMENT_CONTENT_WIDTH}) {
    width: 100%;
  }
`;
