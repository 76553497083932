import styled from 'styled-components';
import { DIMENSIONS } from '@hqo/react-components-library';

export const Container = styled.div<{ isDisabled: boolean }>`
  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    pointer-events: none;
    cursor: not-allowed;
    .picker-label, .picker-value, .time-picker-icon {
      color: ${theme.colors.greys.sharedMediumGrey};
      cursor: not-allowed;
    }
  `}

  width: 370px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 31px;

  .picker-icon {
    margin-right: 10px;
  }

  .picker-field {
    padding: 0 10px;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 100%;
    gap: 21px;
  }
`;
