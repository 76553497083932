import { Container, Grid } from './styles';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Content } from './content';
import { Skeleton } from 'components/skeleton';
import { usePassedTime } from './hooks';
import { useResourcesPage } from './hooks/use-resources-page.hook';
import { useValidTypes } from './hooks/use-valid-types.hook';
import { useOnPopState } from './hooks/use-on-pop-state.hook';
import { Header } from './components/header';
import { useWebTracking } from './hooks/use-web-tracking.hook';
import { ArrowBackButton } from './components/ arrow-back-button';
import { LocationPicker } from 'pages/resources/components/location-picker';
import { FilterPicker } from './components/filter-picker';
import { ResourcesCounterBlock } from './components/resources-counter-block';
import { TermsAndConditions } from 'components/termsAndConditions';
import { ModalContainer } from 'pages/resource/components/terms-and-conditions/components/modal-container';

export const ResourcesPage = (): JSX.Element => {
  usePassedTime();
  useValidTypes();
  useOnPopState();
  useWebTracking();
  const { resources, isLoadingState, isCompactUiEnabled } = useResourcesPage();

  return (
    <>
      <Header />
      <Container isLoading={isLoadingState}>
        <ArrowBackButton />
        <LocationPicker />
        <FilterPicker />
        <ResourcesCounterBlock />
        {isLoadingState ? (
          <Skeleton isCompactUiEnabled={isCompactUiEnabled} />
        ) : (
          <Grid isNoResources={resources?.length === 0 && !isLoadingState} isCompactUiEnabled={isCompactUiEnabled}>
            <Content resources={resources} />
          </Grid>
        )}
        <Routes>
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="quick-checkout/terms-and-conditions" element={<ModalContainer />} />
        </Routes>
      </Container>
    </>
  );
};
