import React, { useCallback } from 'react';
import { SavedPaymentMethod } from 'store/payment/types';
import { formatSavedPaymentMethodLabel } from 'utils/formatSavedPaymentMethodLabel';
import { getCartErrorMessage } from 'utils/getCartErrorMessage';
import { INVOICE, APPLE_PAY } from 'shared/consts/payment-method-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectSubmitCartError } from 'store/cart/selectors';
import { RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';
import { ApplePayIcon, Icon } from 'components/payment/quick-checkout/components/payment-methods-selector/styles';
import { faApplePay } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';

interface UsePaymentLabelsReturnValues {
  buildSavedPaymentLabels: () => RadioOption[];
}

interface UsePaymentLabelsProps {
  paymentMethods: Array<SavedPaymentMethod>;
  errorPaymentId: number;
  total: number;
  isApplePaySupported: boolean;
}

export const usePaymentLabels = ({
  paymentMethods,
  errorPaymentId,
  total,
  isApplePaySupported,
}: UsePaymentLabelsProps): UsePaymentLabelsReturnValues => {
  const intl = useIntl();
  const cartError = useSelector(selectSubmitCartError);

  const buildSavedPaymentLabel = useCallback(
    (paymentMethod: SavedPaymentMethod) => {
      if (paymentMethod.payment_method_type === APPLE_PAY) {
        return intl.formatMessage({ id: 'apple_pay' });
      }
      const { capitalizedBrand, formattedExpMonth, formattedExpYear } = formatSavedPaymentMethodLabel(paymentMethod);

      return intl.formatMessage(
        {
          id: 'saved_payment_method_option',
        },
        {
          cardBrand: capitalizedBrand,
          cardLastFour: paymentMethod.card_last_four,
          cardExpMonth: formattedExpMonth,
          cardExpYear: formattedExpYear,
        },
      );
    },
    [intl],
  );

  const getPaymentMethodIcon = useCallback((paymentMethod: SavedPaymentMethod) => {
    if (paymentMethod.payment_method_type === APPLE_PAY) {
      return <ApplePayIcon icon={faApplePay} size="2x" />;
    }
    return <Icon icon={faCreditCard} />;
  }, []);

  const buildSavedPaymentLabels = useCallback(() => {
    return paymentMethods
      ?.map(paymentMethod => {
        const errorMessage = intl.formatMessage({ id: getCartErrorMessage(cartError) });
        const notEnoughCredits = paymentMethod?.points_balance < total;
        let label;
        let subtitle;
        if (paymentMethod?.payment_method_type === INVOICE) {
          label = intl.formatMessage({ id: 'invoice' });
          subtitle = intl.formatMessage({ id: 'invoice_text' });
        } else {
          label = buildSavedPaymentLabel(paymentMethod);
          subtitle = null;
        }

        return {
          label,
          subtitle,
          value: paymentMethod?.id.toString(),
          canRemoveOption:
            paymentMethod?.payment_method_type !== INVOICE && paymentMethod?.payment_method_type !== APPLE_PAY,
          errorMessage: paymentMethod?.id === errorPaymentId && !isApplePaySupported ? errorMessage : undefined,
          disabled: notEnoughCredits,
          icon: getPaymentMethodIcon(paymentMethod),
        };
      })
      .reverse();
  }, [paymentMethods, buildSavedPaymentLabel, errorPaymentId, intl, isApplePaySupported]);

  return {
    buildSavedPaymentLabels,
  };
};
