import { FormContainer, GhostContainer, GhostCustomQuestion, StyledInput } from './styles';
import { RadioForm, RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';
import React from 'react';

import { useIntl } from 'react-intl';
import {
  useAdditionalSelectorHook,
  useCustomQuestionHook,
} from 'pages/additional-information/useAdditionalSelectors.hook';
import { useSelector } from 'react-redux';
import { selectGetResourceStatus } from 'store/resource/selectors';
import { ACTION_STATUSES } from 'shared/consts';

const QUESTION_TYPES = {
  TEXT: 'Text',
  NUMBER: 'Number',
  YESNO: 'Yes/No',
};

interface Props {
  userResponse: Array<FormResponse>;
  setUserResponse: React.Dispatch<React.SetStateAction<FormResponse[]>>;
}

export interface CustomQuestionProps {
  question: FormResponse;
  onInputChange: (response: FormResponse) => void;
}

export const CustomQuestion = ({ question, onInputChange }: CustomQuestionProps) => {
  const intl = useIntl();

  const radioOptions = [
    { label: intl.formatMessage({ id: 'yes' }), value: intl.formatMessage({ id: 'yes' }) },
    { label: intl.formatMessage({ id: 'no' }), value: intl.formatMessage({ id: 'no' }) },
  ];

  const { handleChangeInput } = useCustomQuestionHook({ question, onInputChange });

  switch (question.type) {
    case QUESTION_TYPES.TEXT:
      return (
        <StyledInput
          required={question.required}
          value={question.answer}
          type="text"
          label={question.question}
          placeholder={intl.formatMessage({ id: 'enter_response_here' })}
          onChange={handleChangeInput}
        />
      );
    case QUESTION_TYPES.NUMBER:
      return (
        <StyledInput
          required={question.required}
          value={question.answer}
          type="number"
          inputMode="decimal"
          label={question.question}
          placeholder={intl.formatMessage({ id: 'enter_response_here' })}
          onChange={handleChangeInput}
        />
      );
    case QUESTION_TYPES.YESNO:
      return (
        <RadioForm
          required={question.required}
          title={question.question}
          selected={question.answer}
          options={radioOptions as RadioOption[]}
          onPress={handleChangeInput}
        />
      );
    default:
      return null;
  }
};

const AdditionalSelectors = ({ userResponse, setUserResponse }: Props) => {
  const { onInputChangeHandler } = useAdditionalSelectorHook(setUserResponse);
  const isLoading = useSelector(selectGetResourceStatus) === ACTION_STATUSES.PENDING;

  return (
    <div>
      {isLoading ? (
        <GhostContainer data-testid="ghost-additional-questions">
          <GhostCustomQuestion />
          <GhostCustomQuestion />
        </GhostContainer>
      ) : (
        <>
          {userResponse?.map(item => {
            return (
              <FormContainer key={item.question}>
                <CustomQuestion question={item} onInputChange={onInputChangeHandler} />
              </FormContainer>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AdditionalSelectors;
