import { useCallback, useRef, useState } from 'react';
import { THRESHOLD_WIDTH } from 'shared/consts/timeline';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import {
  checkIsCurrentPositionOverTimeline,
  checkIsSelectedBlockNearTimelineStartEnd,
  getAutoScrollSpeedAndFrameDuration,
  getUpdatedSelectedBlockAndScrollPositions,
  getUpdatedSelectedBlockPositionForOppositeDirection,
} from 'pages/resource/components/selected-timeline-block/utils/use-timeline-auto-scroll.utils';

interface UseResizeSelectedBlockWithAutoScrollProps {
  selectedBlockRef: React.MutableRefObject<HTMLDivElement>;
  timelineWrapperRef: React.MutableRefObject<HTMLDivElement>;
  isLeftClick: boolean;
  timelineFirstPosition: number;
  timelineLastPosition: number;
  initialLeft: number;
  initialWidth: number;
  shouldStopResizing: (updatedLeft: number, updatedRight: number, updatedWidth: number) => boolean;
  maxDurationWidth: number;
}

interface UseResizeSelectedBlockWithAutoScrollReturnValues {
  resizeWithAutoScroll: (currentPosition: number) => void;
  resizeOppositeDirectionAfterAutoScrolling: (currentPosition: number, timelineWrapperScrollLeft: number) => void;
  scrollLeftThreshold: number;
  scrollRightThreshold: number;
  resizeAutoScrollRef: React.MutableRefObject<number>;
  setIsAutoScrolling: React.Dispatch<React.SetStateAction<boolean>>;
  isAutoScrolling: boolean;
  stopAutoScrolling: VoidFunction;
}

interface UseUpdateInitialScrollOnThresholdProps {
  selectedBlockRef: React.MutableRefObject<HTMLDivElement>;
  timelineWrapperRef: React.MutableRefObject<HTMLDivElement>;
}

/*
When the selected block's width is at its minimum duration, relying solely on resizeWithAutoScroll to adjust scrollLeft can disrupt resizing
because it may unintentionally shrink the block's width by updating the selected block's offset. To ensure that resizing continues smoothly,
we update scrollLeft to position the selected block's offsetLeft precisely at the threshold.
*/
export const useUpdateInitialScrollOnThreshold = ({
  selectedBlockRef,
  timelineWrapperRef,
}: UseUpdateInitialScrollOnThresholdProps): ((isLeftHandleClicked: boolean) => void) => {
  const isIPhoneDevice = navigator.userAgent.includes('iPhone');
  const timelineWRapperBoundClientRect = timelineWrapperRef?.current?.getBoundingClientRect();
  const scrollLeftThreshold: number = timelineWRapperBoundClientRect?.left + THRESHOLD_WIDTH;
  const scrollRightThreshold: number = timelineWRapperBoundClientRect?.right - THRESHOLD_WIDTH;

  const updateInitialScroll = useCallback(
    isLeftHandleClicked => {
      // This function is called before updating the overflow style. Currently, the overflow should be set to 'auto'. Android phones
      // require 'overflow: auto' to update the scroll. However iPhones require 'overflow: hidden' to update the scrollLeft smoothly
      if (isIPhoneDevice) {
        timelineWrapperRef.current.style.overflow = 'hidden';
      }
      const selectedBlockRefBoundingClientRect = selectedBlockRef?.current?.getBoundingClientRect();
      const isPastLeftThreshold = isLeftHandleClicked && selectedBlockRefBoundingClientRect.left < scrollLeftThreshold;
      const isPastRightThreshold =
        !isLeftHandleClicked && selectedBlockRefBoundingClientRect.right > scrollRightThreshold;

      if (isPastLeftThreshold) {
        const diff = selectedBlockRefBoundingClientRect.left - timelineWRapperBoundClientRect.left;
        const newScroll = THRESHOLD_WIDTH - diff;
        timelineWrapperRef.current.scrollLeft -= newScroll;
      } else if (isPastRightThreshold) {
        const diff = timelineWRapperBoundClientRect.right - selectedBlockRefBoundingClientRect.right;
        const newScroll = THRESHOLD_WIDTH - diff;
        timelineWrapperRef.current.scrollLeft += newScroll;
      }
    },
    [
      isIPhoneDevice,
      scrollLeftThreshold,
      scrollRightThreshold,
      selectedBlockRef,
      timelineWRapperBoundClientRect,
      timelineWrapperRef,
    ],
  );
  return updateInitialScroll;
};

export const useResizeSelectedBlockWithAutoScroll = ({
  selectedBlockRef,
  timelineWrapperRef,
  isLeftClick,
  timelineFirstPosition,
  timelineLastPosition,
  initialLeft,
  initialWidth,
  shouldStopResizing,
  maxDurationWidth,
}: UseResizeSelectedBlockWithAutoScrollProps): UseResizeSelectedBlockWithAutoScrollReturnValues => {
  const isMobile = useIsSmallViewportWidth();
  const resizeAutoScrollRef = useRef(null);
  const lastTimestampRef = useRef<number | null>(null); // Create a useRef for lastTimestamp
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);

  const selectedBlockRefParentElement = selectedBlockRef.current?.parentElement;
  const scrollLeftEnd = timelineWrapperRef.current.scrollWidth - timelineWrapperRef.current.clientWidth;
  const timelineOffsetLeft = selectedBlockRefParentElement?.offsetLeft;
  const timeLineOffsetLeftAndThresholdDistance = timelineOffsetLeft - THRESHOLD_WIDTH;
  const visibleTimelineWrapperWithRightThresholdWidth =
    timelineWrapperRef.current.clientWidth - THRESHOLD_WIDTH - timelineOffsetLeft;
  const timelineWRapperBoundClientRect = timelineWrapperRef?.current?.getBoundingClientRect();
  const scrollLeftThreshold: number = timelineWRapperBoundClientRect?.left + THRESHOLD_WIDTH;
  const scrollRightThreshold: number = timelineWRapperBoundClientRect?.right - THRESHOLD_WIDTH;

  const { scrollSpeedDistance, frameDuration } = getAutoScrollSpeedAndFrameDuration(isMobile);

  const stopAutoScrolling = useCallback(() => {
    cancelAnimationFrame(resizeAutoScrollRef.current);
    setIsAutoScrolling(false);
    resizeAutoScrollRef.current = null;
  }, []);

  const updateSelectedBlockAndScrollToFinalDestination = useCallback(
    (selectedBlockOffsetLeft: number, selectedBlockOffsetWidth: number) => {
      cancelAnimationFrame(resizeAutoScrollRef.current);
      const finalWidth = isLeftClick
        ? selectedBlockOffsetWidth + selectedBlockOffsetLeft
        : timelineLastPosition - selectedBlockOffsetLeft;
      selectedBlockRef.current.style.width = `${finalWidth}px`;

      if (isLeftClick) {
        selectedBlockRef.current.style.left = `${timelineFirstPosition}px`;
        timelineWrapperRef.current.scrollLeft = 0;
      } else {
        timelineWrapperRef.current.scrollLeft = scrollLeftEnd;
      }
    },
    [isLeftClick, scrollLeftEnd, selectedBlockRef, timelineFirstPosition, timelineLastPosition, timelineWrapperRef],
  );

  const performAutoScroll = useCallback(
    (currentPosition: number) => {
      const { scrollLeft: timelineWrapperScrollLeft } = timelineWrapperRef.current;
      const {
        offsetLeft: selectedBlockOffsetLeft,
        offsetWidth: selectedBlockOffsetWidth,
        offsetParent: selectedBlockOffsetParent,
      } = selectedBlockRef.current;
      const selectedBlockParentBoundingClient = selectedBlockOffsetParent.getBoundingClientRect();

      const isCurrentPositionOverTimeline = checkIsCurrentPositionOverTimeline({
        currentPosition,
        timelineWrapperScrollLeft,
        selectedBlockParentBoundingClient,
        isLeftClick,
        scrollLeftEnd,
      });

      if (isCurrentPositionOverTimeline) {
        cancelAnimationFrame(resizeAutoScrollRef.current);
        return;
      }
      const isSelectedBlockNearTimelineStartEnd = checkIsSelectedBlockNearTimelineStartEnd({
        selectedBlockOffsetLeft,
        selectedBlockOffsetWidth,
        isLeftClick,
        timelineFirstPosition,
        timelineLastPosition,
      });

      if (isSelectedBlockNearTimelineStartEnd) {
        updateSelectedBlockAndScrollToFinalDestination(selectedBlockOffsetLeft, selectedBlockOffsetWidth);
        return;
      }

      const {
        updatedSelectedBlockOffsetRight,
        updatedSelectedBlockOffsetWidth,
        updatedScrollPosition,
        updatedSelectedBlockOffsetLeft,
      } = getUpdatedSelectedBlockAndScrollPositions({
        timelineWrapperScrollLeft,
        isLeftClick,
        initialLeft,
        initialWidth,
        scrollSpeedDistance,
        timeLineOffsetLeftAndThresholdDistance,
        visibleTimelineWrapperWithRightThresholdWidth,
      });

      if (
        shouldStopResizing(
          updatedSelectedBlockOffsetLeft,
          updatedSelectedBlockOffsetRight,
          updatedSelectedBlockOffsetWidth,
        )
      ) {
        cancelAnimationFrame(resizeAutoScrollRef.current);
        return;
      }

      if (!maxDurationWidth || updatedSelectedBlockOffsetWidth <= maxDurationWidth) {
        timelineWrapperRef.current.scrollLeft = updatedScrollPosition;
        selectedBlockRef.current.style.width = `${updatedSelectedBlockOffsetWidth}px`;

        if (isLeftClick) {
          selectedBlockRef.current.style.left = `${updatedSelectedBlockOffsetLeft}px`;
        }
      }
    },
    [
      initialLeft,
      initialWidth,
      isLeftClick,
      maxDurationWidth,
      scrollLeftEnd,
      scrollSpeedDistance,
      selectedBlockRef,
      shouldStopResizing,
      timeLineOffsetLeftAndThresholdDistance,
      timelineFirstPosition,
      timelineLastPosition,
      timelineWrapperRef,
      updateSelectedBlockAndScrollToFinalDestination,
      visibleTimelineWrapperWithRightThresholdWidth,
    ],
  );

  /*
    DEV NOTE: The requestAnimationFrame function is designed to provide smooth and synchronized animations, ensuring consistent frame rates
    for updates to the scrollLeft property and other animations. However, the level of synchronization can be influenced by the performance of
    the device. If there are issues with scrolling or animation performance, you can update getAutoScrollSpeedAndFrameDuration.
  */
  const resizeWithAutoScroll = useCallback(
    (currentPosition: number) => {
      const requestAnimate = (timestamp: number) => {
        if (!lastTimestampRef.current) {
          lastTimestampRef.current = timestamp;
        }
        const timeElapsed = timestamp - lastTimestampRef.current;

        if (timeElapsed >= frameDuration) {
          performAutoScroll(currentPosition);
          lastTimestampRef.current = timestamp;
        }
        resizeAutoScrollRef.current = requestAnimationFrame(requestAnimate);
      };
      requestAnimationFrame(requestAnimate);
    },
    [frameDuration, performAutoScroll, resizeAutoScrollRef],
  );

  const resizeOppositeDirectionAfterAutoScrolling = useCallback(
    (currentPosition: number, timelineWrapperScrollLeft: number) => {
      cancelAnimationFrame(resizeAutoScrollRef.current);
      resizeAutoScrollRef.current = null;
      const { updatedSelectedBlockOffsetLeft, updatedSelectedBlockOffsetRight, updatedSelectedBlockOffsetWidth } =
        getUpdatedSelectedBlockPositionForOppositeDirection({
          currentPosition,
          timelineWrapperScrollLeft,
          isLeftClick,
          scrollLeftThreshold,
          timelineWRapperBoundClientRectRight: timelineWRapperBoundClientRect.right,
          visibleTimelineWrapperWithRightThresholdWidth,
          initialWidth,
          initialLeft,
          timelineOffsetLeft,
        });

      if (
        shouldStopResizing(
          updatedSelectedBlockOffsetLeft,
          updatedSelectedBlockOffsetRight,
          updatedSelectedBlockOffsetWidth,
        )
      ) {
        return;
      }

      if (!maxDurationWidth || updatedSelectedBlockOffsetWidth <= maxDurationWidth) {
        selectedBlockRef.current.style.width = `${updatedSelectedBlockOffsetWidth}px`;

        if (isLeftClick) {
          selectedBlockRef.current.style.left = `${updatedSelectedBlockOffsetLeft}px`;
        }
      }
    },
    [
      initialLeft,
      initialWidth,
      isLeftClick,
      maxDurationWidth,
      scrollLeftThreshold,
      selectedBlockRef,
      shouldStopResizing,
      timelineOffsetLeft,
      timelineWRapperBoundClientRect,
      visibleTimelineWrapperWithRightThresholdWidth,
    ],
  );

  return {
    resizeWithAutoScroll,
    resizeOppositeDirectionAfterAutoScrolling,
    scrollLeftThreshold,
    scrollRightThreshold,
    resizeAutoScrollRef,
    stopAutoScrolling,
    isAutoScrolling,
    setIsAutoScrolling,
  };
};
