import React, { useMemo } from 'react';
import { Title, Description, BoldText, Content, Container, Wrapper, BackArrowIcon } from './styles';
import { useIntl } from 'react-intl';
import { CancelButtonBlock } from '../receipt/components/cancel-button';
import { useCancelBooking } from './use-cancel-booking.hook';
import { TransactionDetails } from 'store/transactions/types';

interface CancelBookingProps {
  setIsCancelBookingStep?: React.Dispatch<React.SetStateAction<boolean>>;
  transaction?: TransactionDetails;
}

export const CancelBooking = ({ setIsCancelBookingStep, transaction }: CancelBookingProps): JSX.Element => {
  const intl = useIntl();
  const {
    onBack,
    onCancelButtonClick,
    formattedDate,
    vendorName,
    isMobileDevice,
    showMiniappPaymentsNavigation,
    patchTransactionData,
  } = useCancelBooking({ setIsCancelBookingStep, transaction });
  const showBackButton = useMemo(
    () => !(showMiniappPaymentsNavigation && isMobileDevice),
    [showMiniappPaymentsNavigation, isMobileDevice],
  );

  return (
    <Container wrapping={showMiniappPaymentsNavigation && isMobileDevice ? 'externalModal' : 'internalModal'}>
      <Wrapper>
        <Content>
          {showBackButton && <BackArrowIcon onClick={onBack} data-testid="modal-wrapper-back-button" />}
          <Title>{intl.formatMessage({ id: 'cancel_booking?' })}</Title>
          <Description>
            {/* @ts-ignore */}
            {intl.formatMessage(
              { id: 'you_are_about_cancel_booking' },
              {
                vendorName: <BoldText>{vendorName}</BoldText>,
                bookingTime: (
                  <BoldText>
                    {`${formattedDate.eventWeekDay}, ${formattedDate.eventDateMonthLong} ${
                      formattedDate.eventDateDay
                    }, ${intl.formatMessage({
                      id: 'at',
                    })} ${formattedDate.formatEventStartTime}`}
                  </BoldText>
                ),
              },
            )}
          </Description>
        </Content>
        <CancelButtonBlock patchTransactionData={patchTransactionData} onCancel={onCancelButtonClick} />
      </Wrapper>
    </Container>
  );
};
