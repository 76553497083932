import { DIMENSIONS } from '@hqo/react-components-library';
import styled from 'styled-components';

export const LandingWrapper = styled.div`
  padding: 34px 52px;
  height: 100%;
  width: 100%;

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 28px 16px;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 0 21px 0;

  h1 {
    margin: 0;
  }
`;

export const LandingContainer = styled.div`
  width: 100%;
`;
