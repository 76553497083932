import { faApplePay } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons';
import { ApplePayIcon, Icon } from 'components/payment/quick-checkout/components/payment-methods-selector/styles';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { APPLE_PAY, INVOICE } from 'shared/consts/payment-method-types';
import { SavedPaymentMethod } from 'store/payment/types';
import { formatSavedPaymentMethodLabel } from 'utils/formatSavedPaymentMethodLabel';

export const usePaymentMethodsSelector = (paymentMethods: Array<SavedPaymentMethod>) => {
  const intl = useIntl();

  const buildSavedPaymentLabel = useCallback(
    (paymentMethod: SavedPaymentMethod) => {
      if (paymentMethod.payment_method_type === APPLE_PAY) {
        return intl.formatMessage({ id: 'apple_pay' });
      }
      if (paymentMethod.payment_method_type === INVOICE) {
        return intl.formatMessage({ id: 'invoice' });
      }
      const { capitalizedBrand, formattedExpMonth, formattedExpYear } =
        formatSavedPaymentMethodLabel(paymentMethod) || {};

      return intl.formatMessage(
        {
          id: 'saved_payment_method_option',
        },
        {
          cardBrand: capitalizedBrand,
          cardLastFour: paymentMethod?.card_last_four,
          cardExpMonth: formattedExpMonth,
          cardExpYear: formattedExpYear,
        },
      );
    },
    [intl],
  );

  const getPaymentMethodIcon = useCallback((paymentMethod: SavedPaymentMethod) => {
    if (paymentMethod.payment_method_type === APPLE_PAY) {
      return <ApplePayIcon icon={faApplePay} size="2x" />;
    }
    if (paymentMethod.payment_method_type === INVOICE) {
      return;
    }
    return <Icon icon={faCreditCard} />;
  }, []);

  return useMemo(
    () =>
      paymentMethods
        .map(paymentMethod => {
          return {
            value: paymentMethod?.id.toString(),
            label: buildSavedPaymentLabel(paymentMethod),
            icon: getPaymentMethodIcon(paymentMethod),
          };
        })
        .reverse(),
    [paymentMethods],
  );
};
