import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';

export const BLOCK_WIDTH = 20;
export const DEFAULT_INTERVAL = 15;
export const TIMELINE_TOP_HEIGHT = 20;
export const TIMELINE_BLOCK_HEIGHT = 70;
export const DRAGGING_TOP_DIFFERENCE = 2;
export const DRAGGING_HEIGHT_DIFFERENCE = DRAGGING_TOP_DIFFERENCE * 2;
export const MOBILE_SCROLL_DIFFERENCE = 170;
export const DESKTOP_SCROLL_DIFFERENCE = 495;
export const MINIMUM_LEFT_SCROLL = 110;
export const LAST_INVISIBLE_BLOCK_WIDTH = 24;
export const HANDLE_WIDTH = 14;
export const TOUCH_HANDLE_SIZE = HANDLE_WIDTH / 2;
export const THRESHOLD_WIDTH = 2 * BLOCK_WIDTH;

export enum SLOTS_15_MINUTES {
  ZERO_MINUTES = '00',
  FIFTEEN_MINUTES = '15',
  THIRTY_MINUTES = '30',
  FORTY_FIVE_MINUTES = '45',
}

export enum SELECTED_BLOCK_HANDLERS {
  LEFT = 'left',
  RIGHT = 'right',
}

export const SELECTED_BLOCK_DROP_SHADOW = `0px 2px 10px 0px ${defaultTheme.colors.$sharedGreyDark}`;

export const DEFAULT_SELECTED_BLOCK_STYLES = {
  top: TIMELINE_TOP_HEIGHT,
  height: TIMELINE_BLOCK_HEIGHT,
  boxShadow: 'none',
};

export const DRAGGING_SELECTED_BLOCK_STYLES = {
  top: TIMELINE_TOP_HEIGHT - DRAGGING_TOP_DIFFERENCE,
  height: TIMELINE_BLOCK_HEIGHT + DRAGGING_HEIGHT_DIFFERENCE,
  boxShadow: SELECTED_BLOCK_DROP_SHADOW,
};
