import { defaultTheme } from '@hqo/react-components-library';
import React, { useCallback, useEffect } from 'react';

interface Props {
  backArrowMobileHeaderRef: React.MutableRefObject<HTMLDivElement>;
  containerRef: React.MutableRefObject<HTMLDivElement>;
  scrollableContainerRef: React.MutableRefObject<HTMLDivElement>;
}

export const useBackArrowMobileHeader = ({
  backArrowMobileHeaderRef,
  containerRef,
  scrollableContainerRef,
}: Props): void => {
  const handleScroll = useCallback(() => {
    const headerPositions = backArrowMobileHeaderRef?.current?.getBoundingClientRect();
    const headerBottomPosition = headerPositions?.bottom;

    const scrollableContainer = scrollableContainerRef?.current?.getBoundingClientRect();
    const scrollableContainerTopPosition = scrollableContainer?.top;

    if (
      containerRef.current &&
      backArrowMobileHeaderRef?.current &&
      headerBottomPosition / 2 >= scrollableContainerTopPosition
    ) {
      backArrowMobileHeaderRef.current.style.backgroundColor = `${defaultTheme.colors.$white}`;
      backArrowMobileHeaderRef.current.style.boxShadow = `0px 1px ${defaultTheme.colors.$greyDark}33`;
    } else if (containerRef.current && backArrowMobileHeaderRef?.current) {
      backArrowMobileHeaderRef.current.style.backgroundColor = `${defaultTheme.colors.$transparent}`;
      backArrowMobileHeaderRef.current.style.boxShadow = `none`;
    }
  }, [backArrowMobileHeaderRef, containerRef, scrollableContainerRef]);

  useEffect(() => {
    const currentContainerRef = containerRef?.current;

    currentContainerRef?.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      currentContainerRef?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef?.current, handleScroll]);
};
