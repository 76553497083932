import styled from 'styled-components';
import { Modal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { DIMENSIONS } from '@hqo/react-components-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledModal = styled(Modal)`
  overflow: auto;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 348px;
    max-height: 683px;
    max-width: 963px;
    padding: 24px 32px;
    margin: auto;
    overflow: initial;
  }

  @media only screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    padding: 24px 56px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
`;

export const Content = styled.div`
  overflow-y: auto;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  flex-grow: 1;
`;

export const SelectLocationContainer = styled.div`
  width: 400px;
`;

export const Footer = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  padding: 16px 0;
  font: 700 28px/33px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
  box-sizing: border-box;
  justify-content: flex-start;
`;

export const FooterContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const InfoIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.greys.adminBlack};
  margin-left: 15px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;
