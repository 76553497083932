import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { goBack, push } from 'store/router/actions';
import { useLocation, useParams } from 'react-router-dom';

interface UseNavigationReturnValues {
  onBackLinkClick: VoidFunction;
  onContinue: VoidFunction;
}

export const useResourcePageNavigation = (): UseNavigationReturnValues => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { resourceUuid } = useParams();

  const onBackLinkClick = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const onContinue = useCallback(() => {
    dispatch(push(`resource-booking/resources/${resourceUuid}/room-layouts${search}`));
  }, [dispatch, resourceUuid]);

  return {
    onBackLinkClick,
    onContinue,
  };
};
