import { useCallback } from 'react';
import { resetCart, resetGetCartStatus, resetSubmitCartStatus } from 'store/cart/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from './use-search-params.hook';
import { searchParams } from '../pages/date-select-page/interface';
import { useLocation } from 'react-router';
import { goBack, push } from 'store/router/actions';
import qs from 'qs';
import { selectCartId } from 'store/cart/selectors';
import { selectCurrentLocationId } from 'store/app-instance-configs/selectors';

interface UseQuickCheckoutReturnValues {
  closeQuickCheckoutHandler: VoidFunction;
  openQuickCheckoutHandler: VoidFunction;
}

export const useQuickCheckout = (): UseQuickCheckoutReturnValues => {
  const dispatch = useDispatch();
  const { ...queryParams } = useSearchParams<searchParams>();
  const { pathname } = useLocation();
  const cartId = useSelector(selectCartId);
  const locationId = useSelector(selectCurrentLocationId);

  const closeQuickCheckoutHandler = useCallback(() => {
    dispatch(resetSubmitCartStatus());
    dispatch(resetGetCartStatus());
    dispatch(resetCart());
    dispatch(goBack());
  }, [dispatch]);

  const openQuickCheckoutHandler = useCallback(() => {
    dispatch(resetGetCartStatus());
    const queryString = qs.stringify({ ...queryParams, cartId, locationId });
    dispatch(push(`${pathname.replace(/\/$/, '')}/quick-checkout?${queryString}`));
  }, [dispatch, cartId, locationId]);

  return {
    closeQuickCheckoutHandler,
    openQuickCheckoutHandler,
  };
};
