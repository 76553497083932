import React, { ChangeEvent, useEffect } from 'react';
import {
  StyledModal,
  Container,
  Content,
  Footer,
  Title,
  FooterContainer,
  HeaderContainer,
  SelectLocationContainer,
} from './styles';
import { ModalPortal } from 'components/modals/modal-portal/modal-portal';
import { SelectLocationModalContent } from 'components/select-location-modal/select-location.modal-content';
import { RadioOption } from '@hqo/react-components-library/dist/molecules/radioForm';
import { SelectLocationModalFooter } from 'components/select-location-modal/select-location-modal-footer';
import { useIntl } from 'react-intl';
import { InfoCircle } from '@hqo/react-components-library/dist/icons';
import { Alert } from 'components/alert';

export interface LocationPickerDesktopProps {
  onClose: VoidFunction;
  onButtonClick: VoidFunction;
  onRadioButtonClick: (event: ChangeEvent<HTMLInputElement>) => void;
  onLocationsChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedLocationName: string;
  options: RadioOption[];
  noLocationSelected?: boolean;
}

export const LocationPickerDesktop = ({
  onClose,
  options,
  onRadioButtonClick,
  selectedLocationName,
  onButtonClick,
  onLocationsChange,
  noLocationSelected = false,
}: LocationPickerDesktopProps): JSX.Element => {
  const intl = useIntl();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <ModalPortal>
      <StyledModal opened onClose={onClose} dataTestId="modal-window" data-cy="modal-window">
        <Container className="location-picker-desktop" data-testid="location-picker-desktop">
          <HeaderContainer>
            <Title>{intl.formatMessage({ id: 'locations' })}</Title>
            <InfoCircle size="lg" variant="adminBlack" />
          </HeaderContainer>
          <Content>
            <SelectLocationContainer>
              {noLocationSelected && <Alert testId="select_location_alert" label="please_select_1_building" />}
              <SelectLocationModalContent
                onRadioButtonClick={onRadioButtonClick}
                onLocationsChange={onLocationsChange}
                options={options}
                selectedLocationName={selectedLocationName}
              />
            </SelectLocationContainer>
          </Content>
          <FooterContainer>
            <Footer>
              <SelectLocationModalFooter handleClick={onButtonClick} disabled={noLocationSelected} />
            </Footer>
          </FooterContainer>
        </Container>
      </StyledModal>
    </ModalPortal>
  );
};
