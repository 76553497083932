import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAppInstanceConfigs } from 'store/app-instance-configs/selectors';
import {
  getAvailableResources,
  getAllResources,
  resetFloorPlanResources,
  resetFloorPlanAvailabilityResources,
} from 'store/floorplan-resources/actions';
import {
  selectAllResources,
  selectAvailableResources,
  selectIsAvailabilityLoading,
} from 'store/floorplan-resources/selectors';
import { FilterParams } from 'store/resources/types';
import { selectFloor } from 'store/spaceManager/selectors';
import { getFilterParams } from 'utils';

export const useFloorPlanAvailability = () => {
  const availableResources = useSelector(selectAvailableResources);
  const allResources = useSelector(selectAllResources);
  const isAvailabilityLoading = useSelector(selectIsAvailabilityLoading);
  const { showFloorPlan } = useFlags();
  const dispatch = useDispatch();
  const { ownerUuid, ownerType } = useOwnerParams();
  const searchParams = useSearchParams();
  const { pathname } = useLocation();
  const floor = useSelector(selectFloor);
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);

  const hasFilterRequirement = useMemo(() => {
    return !pathname.includes('date-time-select') && (searchParams.startDate || searchParams.startDates);
  }, [searchParams, pathname]);

  useEffect(() => {
    if (showFloorPlan && appInstanceConfigs && hasFilterRequirement && floor?.uuid) {
      const filterParams = getFilterParams({
        types: searchParams.filterByType,
        floor: floor?.uuid,
        ...searchParams,
      } as FilterParams);

      appInstanceConfigs.forEach(appInstanceConfig => {
        dispatch(
          getAvailableResources.request({
            ownerType,
            ownerUuid,
            filterParams: {
              ...filterParams,
              appInstanceConfigUuid: appInstanceConfig.uuid,
            },
          }),
        );
      });
    }
  }, [
    showFloorPlan,
    appInstanceConfigs.length,
    floor,
    ownerType,
    ownerUuid,
    searchParams.filterByType,
    searchParams.startDate,
    searchParams.startTime,
    searchParams.endTime,
    searchParams.duration,
    searchParams.presetWindows,
    hasFilterRequirement,
  ]);

  useEffect(() => {
    if (showFloorPlan && appInstanceConfigs && floor?.uuid) {
      appInstanceConfigs.forEach(appInstanceConfig => {
        dispatch(
          getAllResources.request({
            ownerType,
            ownerUuid,
            filterParams: {
              floor: floor?.uuid,
              appInstanceConfigUuid: appInstanceConfig.uuid,
            },
          }),
        );
      });
    }
  }, [showFloorPlan, appInstanceConfigs, ownerType, ownerUuid, floor]);

  useEffect(() => {
    dispatch(resetFloorPlanResources());
  }, [floor?.uuid]);

  useEffect(() => {
    dispatch(resetFloorPlanAvailabilityResources());
  }, [
    ownerType,
    ownerUuid,
    searchParams.filterByType,
    searchParams.startDate,
    searchParams.startTime,
    searchParams.endTime,
    searchParams.duration,
    searchParams.presetWindows,
  ]);

  return {
    availableResources,
    allResources,
    isAvailabilityEnabled: showFloorPlan,
    isAvailabilityLoading,
  };
};
