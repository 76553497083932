import React from 'react';
import { ResourcesTypesContainer, Wrapper } from './styles';
import { TypesTitle } from './types-title';
import { TypesContent } from './types-content';
import { useSelector } from 'react-redux';
import { selectResourcesTypes } from 'store/resources/selectors';

export const ResourcesTypes = (): JSX.Element => {
  const resourceTypes = useSelector(selectResourcesTypes);

  return (
    <Wrapper data-testid="resources-types-wrapper">
      <TypesTitle />
      <ResourcesTypesContainer>
        <TypesContent resourceTypes={resourceTypes} />
      </ResourcesTypesContainer>
    </Wrapper>
  );
};
