import React, { useCallback, useRef } from 'react';

import { SwipeModal } from '@hqo/react-components-library/dist/molecules/modals/swipe-modal';
import { FloorPlanDrawerContent } from './floor-plan-drawer-content';
import { FloorPlanResourceInfo } from 'components/floor-plan-list-view/floor-plan-list.interface';

interface FloorPlanDrawerParamsInterface {
  resourceListViewAvailability: FloorPlanResourceInfo[];
  toggleReverseAnimation?: VoidFunction;
  toggleModalSlideAnimation?: VoidFunction;
}

export const FloorPlanDrawer = ({
  resourceListViewAvailability,
  toggleReverseAnimation,
  toggleModalSlideAnimation,
}: FloorPlanDrawerParamsInterface): JSX.Element => {
  const modalRef = useRef(null);

  const onClose = useCallback(() => {
    document.getElementById('scroll-container').scrollTo(0, 0);
  }, []);

  return (
    <SwipeModal
      content={
        <FloorPlanDrawerContent
          toggleModalSlideAnimation={toggleModalSlideAnimation}
          toggleReverseAnimation={toggleReverseAnimation}
          resourceListViewAvailability={resourceListViewAvailability}
          modalRef={modalRef}
        />
      }
      onClose={onClose}
      hasBackground={false}
      initialPosition={100}
      isContentSlidable
      reverseAnimation={false}
      leftIcon={<></>}
      ref={modalRef}
    />
  );
};
