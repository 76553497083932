import { LandingContainer, LandingWrapper, PageHeader } from './styles';

import { AvailableNowBlockComponent } from 'components/available-now-block/available-now-block';
import { LocationSelector } from './components/location-selector';
import { PageTitle } from '@hqo/react-components-library';
import React, { useState } from 'react';
import { ResourceBookingHeader } from 'components/headers/resourceBookingHeader';
import { Skeleton } from 'components/skeleton';
import { TouchSearchBarComponent } from 'components/touch-search-bar';
import { UpcomingBookings } from 'components/upcoming-bookings';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIntl } from 'react-intl';
import { useLandingPage } from './hooks/use-landing-page.hook';
import { useExchangeAdapterHandling } from 'hooks/use-exchange-adapter-handling.hook';
import { Route, Routes } from 'react-router-dom';
import { PaymentIFrame } from 'components/paymentIFrame';
import { usePaymentModal } from 'hooks/payment-hook/use-payment-modal.hook';
import { SwipePaymentIFrame } from 'components/swipePaymentIFrame';
import { DefaultFilter } from './components/default-filter/default-filter';
import { FloorPlanFooter } from 'components/floor-plan-footer';
import { useQuickCheckout } from 'hooks/use-quick-checkout.hook';
import { useReceiptScreen } from 'hooks/payment-content/use-receipt-screen.hook';
import { PaymentContent } from 'components/payment-content';
import { useCheckoutScreen } from 'hooks/payment-content/use-checkout-screen.hook';
import { TransactionFailedModal } from 'components/modals/transaction-failed-modal/transaction-failed-modal';
import { useAppInstanceConfigFeature } from 'hooks/use-app-instance-config-feature.hook';
import { TermsAndConditions } from 'components/termsAndConditions';
import { ModalContainer } from 'pages/resource/components/terms-and-conditions/components/modal-container';

export const LandingPage = (): JSX.Element => {
  const intl = useIntl();
  const { isDataLoading, transactions, resourcesTypes, isMobileDevice, themeFontTitle } = useLandingPage();
  const { defaultFilter } = useExchangeAdapterHandling();
  const { closeQuickCheckoutHandler, openQuickCheckoutHandler } = useQuickCheckout();
  const { reverseAnimation } = usePaymentModal(openQuickCheckoutHandler);
  const [bookingId, setBookingId] = useState<number | string>(null);
  const { isCancelBookingStep, setIsCancelBookingStep, onCloseReceipt, onPressBack, openReceipt } = useReceiptScreen();
  const { onCloseCheckout, openCheckout } = useCheckoutScreen();

  const { showResourceBookingPortfolioNetworkCrossLocation: isLocationFeatureVisible, showFloorPlan } = useFlags();
  const { isAvailableNowEnabled: availableNowEnabled } = useAppInstanceConfigFeature();

  if (isDataLoading || !transactions || !resourcesTypes) {
    return <Skeleton />;
  }

  return (
    <LandingContainer>
      {!isMobileDevice && (
        <ResourceBookingHeader title={intl.formatMessage({ id: 'resource_booking' })} withBackButton={false} />
      )}
      <LandingWrapper>
        <UpcomingBookings setBookingId={setBookingId} openReceipt={openReceipt} />
        <PageHeader>
          <PageTitle font={themeFontTitle} data-testid="landing-title">
            {intl.formatMessage({ id: 'reserveSpace' })}
          </PageTitle>
        </PageHeader>
        {isLocationFeatureVisible && <LocationSelector />}
        <TouchSearchBarComponent />
        {availableNowEnabled && <AvailableNowBlockComponent />}
        <DefaultFilter defaultFilter={defaultFilter} />
        <PaymentContent
          openReceipt={openReceipt}
          bookingId={bookingId}
          reverseAnimation={reverseAnimation}
          closeQuickCheckoutHandler={closeQuickCheckoutHandler}
          isCancelBookingStep={isCancelBookingStep}
          setIsCancelBookingStep={setIsCancelBookingStep}
          onCloseReceipt={onCloseReceipt}
          onPressBack={onPressBack}
          onCloseCheckout={onCloseCheckout}
          openCheckout={openCheckout}
        />
        <Routes>
          <Route
            path="payment"
            element={
              <PaymentIFrame reverseAnimation={reverseAnimation} bookingId={bookingId} setBookingId={setBookingId} />
            }
          />
          <Route
            path="swipe-payment"
            element={<SwipePaymentIFrame bookingId={bookingId} setBookingId={setBookingId} />}
          />
          <Route path="transaction-failed" element={<TransactionFailedModal reverseAnimation={reverseAnimation} />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="quick-checkout/terms-and-conditions" element={<ModalContainer />} />
        </Routes>
      </LandingWrapper>
      {showFloorPlan && <FloorPlanFooter buttonTextId="floorPlan" />}
    </LandingContainer>
  );
};
