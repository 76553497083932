import React, { useMemo } from 'react';
import { AddingVisitorsBlockContainer, Title, Subtitle } from './styles';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectResource } from 'store/resource/selectors';
import { generateArrayOfNumbersByLength } from 'utils/generateArrayOfNumbersByLength';
import { VisitorBlock } from './components/visitor-block';

export interface Visitors {
  [key: number]: string;
}

interface AddingVisitorsBlockProps {
  visitors: Visitors;
  onChangeVisitors: (key: number, value: string) => void;
}

export const AddingVisitorsBlock: React.FC<AddingVisitorsBlockProps> = ({
  visitors,
  onChangeVisitors,
}: AddingVisitorsBlockProps): JSX.Element => {
  const intl = useIntl();
  const { allowed_visitors: allowedVisitors } = useSelector(selectResource) || {};
  const visitorsArray = useMemo(
    () => generateArrayOfNumbersByLength(allowedVisitors),
    [allowedVisitors, generateArrayOfNumbersByLength],
  );

  return (
    <AddingVisitorsBlockContainer data-testid="adding-visitors-block">
      <Title>{intl.formatMessage({ id: 'add_guests' })}</Title>
      <Subtitle>{intl.formatMessage({ id: 'can_invite_guests' }, { guestsNumber: allowedVisitors })}</Subtitle>
      {visitorsArray.map(visitorNumber => (
        <VisitorBlock
          key={visitorNumber}
          value={visitors?.[visitorNumber]}
          onChangeVisitors={onChangeVisitors}
          visitorNumber={visitorNumber}
        />
      ))}
    </AddingVisitorsBlockContainer>
  );
};
