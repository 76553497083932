import { FloorPlanResourceInfo } from 'components/floor-plan-list-view/floor-plan-list.interface';
import { useMemo } from 'react';
import { Resource } from 'store/resource/types';
import { LinkedSpace } from 'store/spaceManager/types';

export const useListView = (
  filteredLinkedSpaces: Map<string, LinkedSpace>,
  allResources: Resource[],
  availableResources: Resource[],
): FloorPlanResourceInfo[] => {
  const resourceByUuid = useMemo(() => {
    return new Map(allResources.map(resource => [resource.uuid, resource]));
  }, [allResources]);

  const availableResourceUuids = useMemo(() => {
    return availableResources.map(resource => resource.uuid);
  }, [availableResources]);

  const floorPlanResourcesInfo = useMemo(() => {
    if (filteredLinkedSpaces?.size) {
      return Array.from(filteredLinkedSpaces.entries())
        .map(([resourceUuid, linkedSpace]) => ({
          resource: resourceByUuid.get(resourceUuid),
          appInstanceConfigUuid: linkedSpace.app_instance_config_uuid,
          isAvailable: availableResourceUuids.includes(resourceUuid),
        }))
        .filter(resourceInfo => resourceInfo.resource);
    }
    return [];
  }, [filteredLinkedSpaces, resourceByUuid, availableResourceUuids]);

  return floorPlanResourcesInfo;
};
