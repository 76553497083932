import { BLOCK_WIDTH, LAST_INVISIBLE_BLOCK_WIDTH, THRESHOLD_WIDTH, TOUCH_HANDLE_SIZE } from 'shared/consts/timeline';

interface CheckIsCurrentPositionOverTimelineProps {
  currentPosition: number;
  timelineWrapperScrollLeft: number;
  selectedBlockParentBoundingClient: DOMRect;
  isLeftClick: boolean;
  scrollLeftEnd: number;
}

interface CheckIsSelectedBlockNearTimelineStartEndProps {
  selectedBlockOffsetLeft: number;
  selectedBlockOffsetWidth: number;
  isLeftClick: boolean;
  timelineFirstPosition: number;
  timelineLastPosition: number;
}

interface GetUpdatedSelectedBlockAndScrollPositionsProps {
  timelineWrapperScrollLeft: number;
  isLeftClick: boolean;
  initialLeft: number;
  initialWidth: number;
  scrollSpeedDistance: number;
  timeLineOffsetLeftAndThresholdDistance: number;
  visibleTimelineWrapperWithRightThresholdWidth: number;
}

interface UpdatedSelectedBlockAndScrollPositions {
  updatedSelectedBlockOffsetLeft: number;
  updatedSelectedBlockOffsetRight: number;
  updatedSelectedBlockOffsetWidth: number;
  updatedScrollPosition: number;
}

interface GetUpdatedSelectedBlockPositionForOppositeDirectionProps {
  currentPosition: number;
  timelineWrapperScrollLeft: number;
  isLeftClick: boolean;
  scrollLeftThreshold: number;
  timelineWRapperBoundClientRectRight: number;
  visibleTimelineWrapperWithRightThresholdWidth: number;
  initialWidth: number;
  initialLeft: number;
  timelineOffsetLeft: number;
}

interface GetUpdatedSelectedBlockPositionForOppositeDirectionResult {
  updatedSelectedBlockOffsetLeft: number;
  updatedSelectedBlockOffsetRight: number;
  updatedSelectedBlockOffsetWidth: number;
}
/*
In November 2023, some testing was conducted on Chrome, Firefox, Safari, Android/Pixel, and iPhone to determine
optimal/decent (non-iPhone mobile device (Android/Pixel) is jumpy) values for the scrollSpeedDistance and frameDuration for requestAnimationFrame.
If there are issues with scrolling or animation performance, you can adjust the animation speed and frame duration.
*/
export const getAutoScrollSpeedAndFrameDuration = (isMobile: boolean) => {
  if (navigator.userAgent.includes('iPhone')) {
    return {
      scrollSpeedDistance: 5,
      frameDuration: 3,
    };
  }
  if (isMobile) {
    return {
      scrollSpeedDistance: 20,
      frameDuration: 66,
    };
  }
  return {
    scrollSpeedDistance: 20,
    frameDuration: 33,
  };
};

export const checkIsCurrentPositionOverTimeline = ({
  currentPosition,
  timelineWrapperScrollLeft,
  selectedBlockParentBoundingClient,
  isLeftClick,
  scrollLeftEnd,
}: CheckIsCurrentPositionOverTimelineProps): boolean => {
  const isCurrentPositionBeforeTimelineStart =
    timelineWrapperScrollLeft === 0 && currentPosition < selectedBlockParentBoundingClient.left;
  const isCurrentPositionAfterTimelineEnd =
    timelineWrapperScrollLeft === scrollLeftEnd &&
    currentPosition > selectedBlockParentBoundingClient.right - LAST_INVISIBLE_BLOCK_WIDTH;
  return isLeftClick ? isCurrentPositionBeforeTimelineStart : isCurrentPositionAfterTimelineEnd;
};

export const checkIsSelectedBlockNearTimelineStartEnd = ({
  selectedBlockOffsetLeft,
  selectedBlockOffsetWidth,
  isLeftClick,
  timelineFirstPosition,
  timelineLastPosition,
}: CheckIsSelectedBlockNearTimelineStartEndProps): boolean => {
  const isSelectedBlockBeforeTimelineStart = selectedBlockOffsetLeft <= timelineFirstPosition + BLOCK_WIDTH / 2;
  const currentAutoScrollWidth = selectedBlockOffsetLeft + selectedBlockOffsetWidth;
  const isSelectedBlockAfterTimelineEnd = currentAutoScrollWidth >= timelineLastPosition - BLOCK_WIDTH / 2;

  return isLeftClick ? isSelectedBlockBeforeTimelineStart : isSelectedBlockAfterTimelineEnd;
};

/*
  Changing the scrollLeft property of an element can trigger a browser layout and repaint operation, which can be relatively expensive
  in terms of performance. This operation is necessary to update the visible part of the scrollable area.
  In contrast, changing offsetLeft directly affects the element's position, and this change is reflected more quickly because it doesn't
  involve reflowing the entire page. Therefore, we are using the updated scrollLeft position to update the selected block offsetLeft
  */
export const getUpdatedSelectedBlockAndScrollPositions = ({
  timelineWrapperScrollLeft,
  isLeftClick,
  initialLeft,
  initialWidth,
  scrollSpeedDistance,
  timeLineOffsetLeftAndThresholdDistance,
  visibleTimelineWrapperWithRightThresholdWidth,
}: GetUpdatedSelectedBlockAndScrollPositionsProps): UpdatedSelectedBlockAndScrollPositions => {
  const updatedScrollPosition = isLeftClick
    ? timelineWrapperScrollLeft - scrollSpeedDistance
    : timelineWrapperScrollLeft + scrollSpeedDistance;

  const updatedSelectedBlockOffsetLeft = isLeftClick
    ? updatedScrollPosition - timeLineOffsetLeftAndThresholdDistance
    : initialLeft;

  const updatedSelectedBlockWidthForRightHandleResizing =
    updatedScrollPosition + visibleTimelineWrapperWithRightThresholdWidth - initialLeft;
  const updatedSelectedBlockWidthForLeftHandleResizing = initialWidth + initialLeft - updatedSelectedBlockOffsetLeft;

  const updatedSelectedBlockOffsetRight = isLeftClick
    ? updatedSelectedBlockOffsetLeft + updatedSelectedBlockWidthForLeftHandleResizing
    : initialLeft + updatedSelectedBlockWidthForRightHandleResizing;

  const updatedSelectedBlockOffsetWidth = isLeftClick
    ? updatedSelectedBlockWidthForLeftHandleResizing
    : updatedSelectedBlockWidthForRightHandleResizing;

  return {
    updatedSelectedBlockOffsetRight,
    updatedSelectedBlockOffsetWidth,
    updatedScrollPosition,
    updatedSelectedBlockOffsetLeft,
  };
};

export const getUpdatedSelectedBlockPositionForOppositeDirection = ({
  currentPosition,
  timelineWrapperScrollLeft,
  isLeftClick,
  scrollLeftThreshold,
  timelineWRapperBoundClientRectRight,
  visibleTimelineWrapperWithRightThresholdWidth,
  initialWidth,
  initialLeft,
  timelineOffsetLeft,
}: GetUpdatedSelectedBlockPositionForOppositeDirectionProps): GetUpdatedSelectedBlockPositionForOppositeDirectionResult => {
  const scrollLeftPosition = timelineWrapperScrollLeft + THRESHOLD_WIDTH;
  const scrollDiff = isLeftClick
    ? currentPosition - scrollLeftThreshold
    : timelineWRapperBoundClientRectRight - BLOCK_WIDTH - currentPosition;

  const initialSelectedBlockOffsetRight =
    timelineWrapperScrollLeft + visibleTimelineWrapperWithRightThresholdWidth + BLOCK_WIDTH;
  const initialSelectedBlockWidth = isLeftClick
    ? initialWidth + (initialLeft - scrollLeftPosition + timelineOffsetLeft + TOUCH_HANDLE_SIZE)
    : initialSelectedBlockOffsetRight - initialLeft + TOUCH_HANDLE_SIZE;
  const updatedSelectedBlockOffsetWidth = initialSelectedBlockWidth - scrollDiff;

  const updatedSelectedBlockOffsetLeft = isLeftClick
    ? scrollLeftPosition - timelineOffsetLeft + scrollDiff - TOUCH_HANDLE_SIZE
    : initialLeft;
  const updatedSelectedBlockOffsetRight = updatedSelectedBlockOffsetLeft + updatedSelectedBlockOffsetWidth;

  return {
    updatedSelectedBlockOffsetLeft,
    updatedSelectedBlockOffsetRight,
    updatedSelectedBlockOffsetWidth,
  };
};
