import { selectAppInstanceConfigs, selectCurrentAppInstanceConfigUuid } from '../store/app-instance-configs/selectors';
import { selectGetResourcesLoadingStatus, selectInfiniteResourcesTotal } from 'store/resources/selectors';

import { DefaultFilterEnum } from 'shared/consts/const';
import { useBooleanState } from '@hqo/react-components-library';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport/';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'pages/date-select-page/interface';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface UseFilterModalReturnValues {
  resourcesAmount: number;
  isLoadingResources: boolean;
  isMobileDevice: boolean;
  isOverlayVisible: boolean;
  toggleOverlay: VoidFunction;
  contentHeight: number;
  setContentHeight: React.Dispatch<React.SetStateAction<number>>;
  defaultFilter: DefaultFilterEnum;
  isActive: boolean;
  isPresetWindowFilterVisible: boolean;
}

export const useFilterModal = (): UseFilterModalReturnValues => {
  const resourcesAmount = useSelector(selectInfiniteResourcesTotal);
  const isLoadingResources = useSelector(selectGetResourcesLoadingStatus);
  const isMobileDevice = useIsSmallViewportWidth();
  const { showResourceBookingFullDayToggle } = useFlags();
  const { startDate, filterByType, capacity, filterByFloor } = useSearchParams<searchParams>();
  const { value: isOverlayVisible, toggle: toggleOverlay } = useBooleanState(false);
  const [contentHeight, setContentHeight] = useState<number>();
  const appInstanceConfigs = useSelector(selectAppInstanceConfigs);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const currentAppInstanceConfig = appInstanceConfigs?.find(config => config.uuid === currentAppInstanceConfigUuid);
  const defaultFilter = currentAppInstanceConfig?.config?.default_filter;
  const isActive = useMemo<boolean>(
    () => !!(startDate || filterByType || capacity || filterByFloor),
    [startDate, filterByType, capacity, filterByFloor],
  );
  const isPresetWindowFilterVisible: boolean =
    showResourceBookingFullDayToggle && currentAppInstanceConfig?.config?.preset_booking_windows_enabled;

  return {
    resourcesAmount,
    isLoadingResources,
    isMobileDevice,
    isOverlayVisible,
    toggleOverlay,
    contentHeight,
    setContentHeight,
    defaultFilter,
    isActive,
    isPresetWindowFilterVisible,
  };
};
