import { ACTION_STATUSES, BOOKING_STATUSES, PATCH_STATUSES } from 'shared/consts';
import React from 'react';
import { CustomIconCancel, CustomIconConfirmed } from 'components/payment/receipt/components/styles';
import { PatchTransaction } from 'store/transactions/types';
import { BookingStatus } from 'store/bookings/types';
import { Item } from 'store/cart/types';

export const getReceiptIcon = (bookingStatus: string, { status, type }: Pick<PatchTransaction, 'status' | 'type'>) => {
  if (status === ACTION_STATUSES.FULFILLED && type === PATCH_STATUSES.CANCELLED) {
    return <CustomIconCancel />;
  }
  if (bookingStatus === BOOKING_STATUSES.ACCEPTED) {
    return <CustomIconConfirmed />;
  }

  return null;
};

export const getReceiptIconFromCart = (bookingItems: Array<Item>) => {
  if (bookingItems.every(booking => booking.status === BookingStatus.ACCEPTED)) {
    return <CustomIconConfirmed />;
  }

  return null;
};
