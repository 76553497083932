import * as actions from './actions';

import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AjaxResponse } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { GetResourceAvailableDatesAPIResponse } from './types';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of, takeUntil } from 'rxjs';

export const getResourceAvailableDatesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getResourceAvailableDates.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      return apiClient(state)
        .getResourceAvailableDates(payload)
        .pipe(
          map(({ response }: AjaxResponse<GetResourceAvailableDatesAPIResponse>) => {
            return actions.getResourceAvailableDates.success({
              indexOfMonth: payload.indexOfMonth.toString(),
              available_dates: response.data.available_dates,
            });
          }),
          catchError((error: Error) => of(actions.getResourceAvailableDates.failure({ error }))),
        );
    }),
  );

export const getResourceInfiniteAvailableDatesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getResourceInfiniteAvailableDates.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      return apiClient(state)
        .getResourceAvailableDates(payload)
        .pipe(
          takeUntil(action$.pipe(filter(isActionOf(actions.resetResourceAvailableDates)))),
          map(({ response }: AjaxResponse<GetResourceAvailableDatesAPIResponse>) => {
            return actions.getResourceInfiniteAvailableDates.success({
              indexOfMonth: payload.indexOfMonth.toString(),
              available_dates: response.data.available_dates,
            });
          }),
          catchError((error: Error) => of(actions.getResourceInfiniteAvailableDates.failure({ error }))),
        );
    }),
  );
