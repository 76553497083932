import * as actions from './actions';

import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of, concat } from 'rxjs';
import { SavedPaymentMethod } from './types';
import { selectConfigPaymentGatewayId } from 'store/cart/selectors';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

const checkPaymentMethods = (
  paymentMethods: Array<SavedPaymentMethod>,
  paymentMethodId: number,
  featureFlags: LDFlagSet,
) => {
  if (
    paymentMethods.length &&
    paymentMethods.length > 1 &&
    !navigator.userAgent.includes('iPhone') &&
    !featureFlags?.enableHqoMiniappApplePay
  ) {
    return of(
      actions.deletePaymentMethod.success(),
      actions.updatePaymentMethod.request({
        default: true,
        paymentMethodId: paymentMethods.filter(method => method.id !== paymentMethodId).pop().id,
      }),
    );
  }

  return of(actions.deletePaymentMethod.success());
};

export const getPaymentMethodsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.getPaymentMethods.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .getPaymentMethods(payload)
        .pipe(
          map(data => {
            return actions.getPaymentMethods.success(data.response);
          }),
          catchError((error: Error) => of(actions.getPaymentMethods.failure({ error }))),
        );
    }),
  );

export const savePaymentMethodEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.savePaymentMethod.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const paymentGatewayId = selectConfigPaymentGatewayId(state);
      return apiClient(state)
        .savePaymentMethod({ ...payload, payment_gateway_id: paymentGatewayId })
        .pipe(
          mergeMap(data => {
            return of(actions.savePaymentMethod.success(data.response));
          }),
          catchError((error: Error) => of(actions.savePaymentMethod.failure({ error }))),
        );
    }),
  );

export const deletePaymentMethodEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.deletePaymentMethod.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { paymentMethodId, paymentMethods } = payload;
      return apiClient(state)
        .deletePaymentMethod(paymentMethodId)
        .pipe(
          mergeMap(() => checkPaymentMethods(paymentMethods, paymentMethodId, state.featureFlags)),
          catchError((error: Error) => of(actions.deletePaymentMethod.failure({ error }))),
        );
    }),
  );

export const updatePaymentMethodEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(actions.updatePaymentMethod.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      return apiClient(state)
        .updatePaymentMethod(payload)
        .pipe(
          mergeMap(() =>
            concat(
              of(actions.updatePaymentMethod.success()),
              of(actions.localUpdatePaymentMethod(payload.paymentMethodId)),
            ),
          ),
          catchError((error: Error) => of(actions.updatePaymentMethod.failure({ error }))),
        );
    }),
  );
