import React, { useCallback } from 'react';

import styled from 'styled-components';
import { CapacityBlock } from 'components/capacity-block';
import { ResourceTile } from 'components/skeleton/styles';
import { ContentTile } from '@hqo/react-components-library/dist/molecules/tiles/content-tile';
import { DIMENSIONS } from '@hqo/react-components-library';

const StyledContentTitle = styled(ContentTile)`
  color: ${({ theme }) => theme.colors.fontColor};
`;

const ResponsiveResourceTile = styled(ResourceTile)`
  border-radius: 6px;
  width: 200px;
  height: 128px;
  object-fit: cover;

  @media screen and (max-width: ${DIMENSIONS.MEDIUM}) {
    width: 120px;
    height: 80px;
  }
`;

interface ResourceItemProps {
  uuid: string;
  title: string;
  capacity: number;
  subtitle: string;
  image_url: string;
  onClick?: (resourceUuid: string) => void;
}

export const ResourceItem: React.FC<ResourceItemProps> = ({
  uuid,
  title,
  subtitle,
  image_url,
  capacity,
  onClick,
}: ResourceItemProps): JSX.Element => {
  const onTileClick = useCallback(() => {
    onClick(uuid);
  }, [onClick, uuid]);

  return (
    <StyledContentTitle
      title={title}
      subtitle={subtitle}
      imageUrl={image_url}
      onClick={onTileClick}
      statusLabels={[<CapacityBlock capacity={capacity} title={title} key="3" />]}
      skeleton={<ResponsiveResourceTile />}
    />
  );
};
