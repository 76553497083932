import { MILLISECONDS_IN_MIN } from 'shared/consts/time';

export const isCancellationExpired = (startAt: string, minutes: number): boolean => {
  if (!startAt) {
    return false;
  }

  if (new Date().getTime() > new Date(startAt).getTime()) {
    return true;
  }

  if (!minutes) {
    return false;
  }

  const limitTime = new Date(new Date(startAt).getTime() - minutes * MILLISECONDS_IN_MIN);

  return new Date().getTime() > new Date(limitTime).getTime();
};
