import { Skeleton } from 'components/skeleton';
import { DeepLinkData } from 'pages/deeplink/interfaces';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'store/router/actions';

export const DeepLinkHandlePage = (): JSX.Element => {
  const { payload } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    let payloadData: DeepLinkData;
    try {
      const payloadBase64Decoded = Buffer.from(payload, 'base64');
      payloadData = JSON.parse(payloadBase64Decoded.toString());
    } catch {
      dispatch(push('/'));
      return;
    }

    if (payloadData?.showBookingDetails || payloadData?.a) {
      const transactionUuid = payloadData.showBookingDetails?.transactionUuid ?? payloadData?.a?.a;
      if (transactionUuid) {
        dispatch(push(`/receipt/?transactionUuid=${transactionUuid}`));
        return;
      }
    }

    dispatch(push('/'));
  }, [payload]);

  return <Skeleton />;
};
