import styled from 'styled-components';
import { Paper, Popover } from '@hqo/react-components-library';

export const StyledPaper = styled(Paper)`
  padding: 24px 12px;
`;

export const StyledPopover = styled(Popover)`
  z-index: 1;
`;
