import React, { useEffect } from 'react';
import { ItemRef } from 'pages/resource/components/timeline/types';
import { DESKTOP_SCROLL_DIFFERENCE, MINIMUM_LEFT_SCROLL, MOBILE_SCROLL_DIFFERENCE } from 'shared/consts/timeline';
import { useIsSmallViewportWidth } from '@hqo/react-components-library/dist/viewport';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { searchParams } from 'shared/types/search-params';

interface UseTimelineBlockProps {
  itemRefs: React.MutableRefObject<Array<ItemRef>>;
  timelineWrapperRef: React.MutableRefObject<HTMLDivElement>;
}

export const useAutoScroll = ({ itemRefs, timelineWrapperRef }: UseTimelineBlockProps): void => {
  const wrapper = timelineWrapperRef?.current;
  const { startTime } = useSearchParams<searchParams>();
  const isMobileDevice = useIsSmallViewportWidth();

  let scrollLeftPosition = 0;

  const lastItemRef = itemRefs?.current?.[itemRefs?.current?.length - 1]?.ref;

  const scrollDifference = isMobileDevice ? MOBILE_SCROLL_DIFFERENCE : DESKTOP_SCROLL_DIFFERENCE;

  const selectedItemRef = itemRefs?.current?.find(itemRef => itemRef.key === startTime);

  const offsetLeft = selectedItemRef?.ref?.offsetLeft;
  const maxOffsetLeft = lastItemRef?.offsetLeft - scrollDifference;

  if (offsetLeft > maxOffsetLeft) {
    scrollLeftPosition = lastItemRef?.offsetLeft;
  } else {
    scrollLeftPosition = offsetLeft;
  }

  useEffect(() => {
    if (wrapper && itemRefs?.current?.length && !wrapper.scrollLeft) {
      wrapper.scrollLeft = scrollLeftPosition < MINIMUM_LEFT_SCROLL ? 0 : scrollLeftPosition;
    }
  }, [wrapper, itemRefs?.current, scrollLeftPosition]);
};
