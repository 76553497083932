import {
  BLOCK_WIDTH,
  LAST_INVISIBLE_BLOCK_WIDTH,
  TIMELINE_BLOCK_HEIGHT,
  TIMELINE_TOP_HEIGHT,
} from 'shared/consts/timeline';
import styled from 'styled-components';

const dashedLinearGradient =
  'linear-gradient(135deg, rgba(0, 0, 0, 0) 49.9%, #d8d8d8 49.9%, #d8d8d8 60%, rgba(0, 0, 0, 0) 60%), linear-gradient(135deg, #d8d8d8 10%, rgba(0, 0, 0, 0) 10%)';

export const TimeText = styled.div`
  display: flex;
  position: relative;
  height: ${TIMELINE_TOP_HEIGHT}px;
  white-space: nowrap;
  right: 50%;
  user-select: none;
  font: 500 12px/14px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.greys.adminGreyDark};
`;

export const TimelineBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${BLOCK_WIDTH}px;

  &:last-child {
    width: ${LAST_INVISIBLE_BLOCK_WIDTH}px;
  }
`;

export const TimelineBlockContainer = styled.div<{
  firstLeftBorder: boolean;
  lastRightBorder: boolean;
  isAvailable?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  height: ${TIMELINE_BLOCK_HEIGHT}px;
  border: 1px solid ${({ theme }) => theme.colors.greys.sharedMediumGrey};
  border-right: ${({ lastRightBorder, theme }) =>
    lastRightBorder ? `1px solid ${theme.colors.greys.sharedMediumGrey}` : 'none'};
  border-left: ${({ firstLeftBorder, theme }) =>
    firstLeftBorder ? `1px solid ${theme.colors.greys.sharedMediumGrey}` : 'none'};
  margin-left: ${({ firstLeftBorder }) => (firstLeftBorder ? '-1px' : '0')};
  background: ${({ isAvailable }) => (isAvailable ? 'none' : dashedLinearGradient)};
  background-color: ${({ isAvailable, theme }) =>
    isAvailable ? theme.colors.universal.white : theme.colors.greys.lightGrey4};
  background-size: ${({ isAvailable }) => (isAvailable ? 0 : '10px 10px')};
  ${({ firstLeftBorder, lastRightBorder }) => {
    if (firstLeftBorder) {
      return 'border-top-left-radius: 8px; border-bottom-left-radius: 8px;';
    } else if (lastRightBorder) {
      return 'border-top-right-radius: 8px; border-bottom-right-radius: 8px;';
    }
    return 'border-radius: 0;';
  }}
`;

export const TimelineTick = styled.div<{ height: string }>`
  border-right: 1px solid ${({ theme }) => theme.colors.greys.sharedMediumGrey};
  align-self: flex-end;
  height: ${({ height }) => height};
`;
