import {
  NameCapacityWrapper,
  PopupWrapper,
  Text,
  ActionButton,
  FixedWrapper,
  SimpleWrapper,
} from 'components/floor-plan-popup/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { RESOURCE_PATH, TRACK_EVENT_SOURCES } from 'shared/consts';
import { push } from 'store/router/actions';
import { useIntl } from 'react-intl';
import { CapacityBlock } from 'components/capacity-block';
import { selectCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/selectors';
import { stripQueryParams } from 'floorplan/utils/archilogic.helpers';
import { updateCurrentAppInstanceConfigUuid } from 'store/app-instance-configs/actions';
import { useOwnerParams } from 'hooks/use-owner-params.hook';
import { getResource } from 'store/resource/actions';
import { getResources } from 'store/resources/actions';
import { useSearchParams } from 'hooks/use-search-params.hook';
import { resourcesState } from 'store/resources/selectors';

interface FloorPlanPopupInterface {
  resourceUuid: string;
  resourceName: string;
  capacity?: string | number;
  isAvailable?: boolean;
  isCompactUI?: boolean;
  onClick?: (resourceUuid: string, ignoreParams?: boolean) => void;
  isFixed?: boolean;
  resourceAppInstanceConfig?: string;
  toggleReverseAnimation?: () => void;
  toggleModalSlideAnimation?: () => void;
}

export const FloorplanPopup = ({
  resourceUuid,
  resourceName,
  capacity,
  isAvailable,
  isCompactUI,
  onClick,
  isFixed = true,
  resourceAppInstanceConfig,
  toggleReverseAnimation,
  toggleModalSlideAnimation,
}: FloorPlanPopupInterface): JSX.Element => {
  const { ownerType, ownerUuid } = useOwnerParams();
  const intl = useIntl();
  const { resources } = useSelector(resourcesState);
  const currentAppInstanceConfigUuid = useSelector(selectCurrentAppInstanceConfigUuid);
  const { search } = useLocation();
  const [triggerRedirection, setTriggerRedirection] = useState(false);
  const searchParams = useSearchParams();
  const dispatch = useDispatch();
  const source = TRACK_EVENT_SOURCES.FLOOR_PLAN;

  const redirectToResourceBookingPage = useCallback(() => {
    if (resourceAppInstanceConfig && !triggerRedirection) {
      dispatch(updateCurrentAppInstanceConfigUuid({ currentAppInstanceConfigUuid: resourceAppInstanceConfig }));
      setTriggerRedirection(true);
      return;
    }

    if (resourceUuid) {
      dispatch(getResource.request({ ownerUuid, ownerType, resourceUuid }));
      if (isAvailable) {
        if (isCompactUI) {
          onClick(resourceUuid, false);
        } else {
          dispatch(push(`${RESOURCE_PATH}/${resourceUuid}${search}&isExact=true&source=${source}`));
        }
      } else if (isCompactUI) {
        if (toggleReverseAnimation) {
          toggleReverseAnimation();
        }
        if (toggleModalSlideAnimation) {
          toggleModalSlideAnimation();
        }
        if (!resources) {
          dispatch(getResources.request({ ...searchParams, ownerType, ownerUuid }));
        }
        dispatch(
          push(
            `${RESOURCE_PATH}s/date-schedule-select?${stripQueryParams(search, [
              'startDate',
              'startTime',
              'endTime',
              'endDate',
              'duration',
              'isExact',
              'presetWindows',
            ])}&source=${source}`,
          ),
        );
      } else {
        dispatch(
          push(
            `${RESOURCE_PATH}/${resourceUuid}?${stripQueryParams(search, [
              'startDate',
              'startTime',
              'endTime',
              'endDate',
              'duration',
              'isExact',
              'presetWindows',
            ])}&source=${source}`,
          ),
        );
      }
    }
  }, [resourceUuid, search, triggerRedirection, getResources, searchParams]);

  useEffect(() => {
    if (triggerRedirection && currentAppInstanceConfigUuid === resourceAppInstanceConfig) {
      redirectToResourceBookingPage();
      setTriggerRedirection(false);
    }
  }, [triggerRedirection, currentAppInstanceConfigUuid, resourceAppInstanceConfig]);

  const components = [SimpleWrapper, FixedWrapper];
  const Wrapper = components[+isFixed];

  return (
    <Wrapper>
      <PopupWrapper data-testid={`floorplan-popup-wrapper-${resourceUuid}`}>
        <NameCapacityWrapper data-testid={`floorplan-popup-capacity-name-wrapper-${resourceUuid}`}>
          <Text>{resourceName}</Text>
          {capacity && <CapacityBlock capacity={+capacity} title={resourceName} />}
        </NameCapacityWrapper>
        <ActionButton
          variant={isAvailable ? 'primary' : 'secondary'}
          text={intl.formatMessage({ id: isAvailable ? 'book' : 'view' })}
          size="xxs"
          onClick={redirectToResourceBookingPage}
        />
      </PopupWrapper>
    </Wrapper>
  );
};
