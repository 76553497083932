import styled from 'styled-components';
import { SMALL_DEVICE_MAX_WIDTH } from 'shared/consts';

interface HeaderProps {
  zIndex?: number;
}

interface ContainerProps {
  isMobile?: boolean;
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  padding: 12px 8px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.colors.universal.white};
  z-index: ${({ zIndex }) => zIndex};
  box-shadow: ${({ theme }) => `0px 0px 2px ${theme.colors.greys.adminBlack}33`};
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ isMobile }) => isMobile && '34px 52px'};
  overflow-y: auto;
  min-height: 100vh;
  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0;
  }
`;

export const ListViewContainer = styled.div`
  width: 30%;
  height: calc(100vh - 48px);
  max-height: calc(100vh - 48px);
  overflow: auto;
  padding-top: 30px;
`;

export const FloorPlanListViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const FloorPlanContainer = styled.div`
  position: relative;
  width: 70%;
  height: 100%;
  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    width: 100%;
  }
`;

export const HeaderSpan = styled.div`
  width: '16px';
`;

export const FloorPlanPageContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  @media only screen and (max-width: ${SMALL_DEVICE_MAX_WIDTH}px) {
    height: 100vh;
  }
`;
