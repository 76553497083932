import styled from 'styled-components';
import { CaretDown } from '@hqo/react-components-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CalendarFieldStyled = styled.div<{ isEmpty?: boolean }>`
  padding-right: 16px;
  width: 100%;
  height: 48px;
  font-family: ${({ theme }) => theme.fonts.join()};
  font-size: 16px;
  color: ${({ isEmpty, theme }) => (isEmpty ? theme.colors.greys.adminGreyMediumAlt : theme.colors.fontColor)};
  background-color: ${({ theme }) => theme.colors.universal.white};
  border: 1px solid ${({ theme }) => theme.colors.greys.adminGreyMedium};
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.span`
  margin-right: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CaretDownIcon = styled(CaretDown)`
  margin-top: 10px;
  width: 10px;
  height: 10px;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const StyledCalendarIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
  margin: 0 12px;
  margin-top: -4px;
`;
