import { CAPACITY_VALUES } from 'shared/consts/capacity';
import React, { useCallback } from 'react';
import { StyledLabelBlock, CapacityContainer, Container, CapacityNumber } from './styles';
import { useIntl } from 'react-intl';
import { useCapacityContent } from './use-capacity-content.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-light-svg-icons';

interface CapacityWrapperProps {
  capacity: number;
  onClick: (value: number) => void;
}

const CapacityBlock = ({ capacity, onClick }: CapacityWrapperProps) => {
  const intl = useIntl();
  const label = (
    <Container>
      {capacity ? <FontAwesomeIcon icon={faUsers} data-testid="capacity-icon" key="3" /> : null}
      <CapacityNumber isAnyCapacity={capacity === 0}>
        {intl.formatMessage({ id: 'capacityValue' }, { capacity, type: !capacity && 'all' })}
      </CapacityNumber>
    </Container>
  );

  const onLabelClick = useCallback(() => {
    onClick(capacity);
  }, [onClick, capacity]);

  return <StyledLabelBlock label={label} key={capacity} className="capacity" onClick={onLabelClick} />;
};

export const CapacityContent = (): JSX.Element => {
  const { isFilteringEnabled, onLabelClick, onAllCapacityLabelClick } = useCapacityContent();

  return (
    <CapacityContainer>
      <CapacityBlock capacity={0} onClick={onAllCapacityLabelClick} />
      {isFilteringEnabled &&
        CAPACITY_VALUES.map(capacity => <CapacityBlock capacity={capacity} onClick={onLabelClick} key={capacity} />)}
    </CapacityContainer>
  );
};
