import moment from 'moment';
import {
  DateSelectTimelineContainer,
  StyledDateInputWrapper,
  StyledDateTimeWrapper,
  DateTimeContainer,
} from 'pages/resource/components/date-select-timeline/styles';
import React from 'react';
import { DatePickerInput } from 'components/date-picker/date-picker-input';
import { TimePickerInput } from 'pages/resource/components/date-select-timeline/time-picker-input/time-picker-input';
import { TimeRange } from 'store/resource-time-ranges/types';
import { Timeline } from '../timeline';
import { useDateSelectTimeline } from './hooks/use-date-select-timeline.hook';

interface DateSelectTimelineProps {
  onDateInputClick: VoidFunction;
  onTimeInputClick: VoidFunction;
  filteredTimeRanges: Array<TimeRange>;
}

export const DateSelectTimeline = ({
  onDateInputClick,
  onTimeInputClick,
  filteredTimeRanges,
}: DateSelectTimelineProps): JSX.Element => {
  const { formattedDates, datePickerValue, timePickerValue, startTimeLine, endTimeLine } =
    useDateSelectTimeline(filteredTimeRanges);

  return (
    <DateSelectTimelineContainer data-testid="date-select-timeline-container">
      <DateTimeContainer>
        <StyledDateTimeWrapper>
          <StyledDateInputWrapper>
            <DatePickerInput
              onClick={onDateInputClick}
              value={moment(datePickerValue)}
              formattedDate={formattedDates}
            />
          </StyledDateInputWrapper>
          <TimePickerInput handleOnClick={onTimeInputClick} value={timePickerValue} />
        </StyledDateTimeWrapper>
      </DateTimeContainer>
      <Timeline startTimeLine={startTimeLine} endTimeLine={endTimeLine} filteredTimeRanges={filteredTimeRanges} />
    </DateSelectTimelineContainer>
  );
};
