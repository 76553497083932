import { DEFAULT_OWNER_TYPE } from 'shared/consts';
import { OwnerParams } from 'shared/types';
import { parseQueryValue } from 'shared/utils/parse-query-value';
import { useSearchParams } from './use-search-params.hook';

export const useOwnerParams = (): OwnerParams => {
  const { ownerType, ownerUuid, buildingUuid } = useSearchParams();

  if (ownerType && ownerUuid) {
    return {
      ownerType: parseQueryValue(ownerType),
      ownerUuid: parseQueryValue(ownerUuid),
    };
  }

  return {
    ownerType: DEFAULT_OWNER_TYPE,
    ownerUuid: parseQueryValue(ownerUuid ?? buildingUuid),
  };
};
